import { Row, Col } from "react-bootstrap";
import { Menu, Maximize, Power } from "lucide-react";
import logo from "../assets/Travel partener2.svg";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Aside from "./Aside";
import Nav from "./Nav";
import LangChanger from "./LangChanger";
import { useTranslation } from "react-i18next";

function Header({ handleLogout }) {

  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const location = useLocation();

  const handleFullscreenToggle = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const hiddenRoutes = ["/hotel", "/product/hosting"];

  const isHidden = hiddenRoutes.includes(location.pathname);

  if (isHidden) {
    return null;
  }

  return (
    <header className="w-100 bg-white py-3 border-bottom">
      <div className="container-xl text-dark">
        <Aside show={show} handleClose={handleClose} />
        <Row className="justify-content-between align-items-center">
          <Col xs="1" className="d-lg-none">
            <div role="button" onClick={handleShow}>
              <Menu />
            </div>
          </Col>
          <Col xs="4" sm="3" md="2">
            <img alt="Logo de idhtours - Site de réservations de voyages en ligne" width={60} height={60} src={logo} />
          </Col>
          <Col lg="7" xl="6" className="d-none d-lg-block">
            <Nav t={t} handleLogout={handleLogout} />
          </Col>

          <Col sm="6" md="4" lg="3" xl="2" className="d-none d-sm-block">
            <div className="d-flex justify-content-end align-items-center text-dark">
              <span className="mx-3">
                <LangChanger t={t} location={location} />
              </span>

              <span className="mx-3" role="button" onClick={handleFullscreenToggle}>
                <Maximize size={20} />
              </span>

              <span className="mx-3 text-red" role="button" onClick={handleLogout}>
                <Power size={20} />
              </span>

            </div>
          </Col>
        </Row>
      </div>
    </header>
  );
}

export default Header;
