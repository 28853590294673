import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import CheckInput from '../../../components/forms/CheckInput'
import { useTranslation } from "react-i18next";

const Restrictions = () => {
    const { t } = useTranslation();
    const [infos, setInfos] = useState({
        restrictions: false,
        timeLimit: false,
        devicesLimit: false
    });

    const handleRestrictions = () => {
        setInfos({ ...infos, restrictions: !infos.restrictions });
    };

    const handleTimeLimit = () => {
        setInfos({ ...infos, timeLimit: !infos.timeLimit });
    };

    const handleDevicesLimit = () => {
        setInfos({ ...infos, devicesLimit: !infos.devicesLimit });
    };

    const time = [
        { title: t('hours'), value: "Heures" },
        { title: t('minutes'), value: "Minutes" },
    ]

    const duration = [
        { title: t('per_day'), value: "jour" },
        { title: t('per_stay'), value: "séjour" },
    ]

    const devices_data = Array.from({ length: 15 }, (_, index) => ({
        title: `${index + 1}`,
        value: index + 1,
    }));

    return (
        <div className='p-3 fs-6'>
            <div className='d-flex align-items-center'>
                <input id="restrictions" type="checkbox" value={infos.restrictions} onChange={handleRestrictions} />
                <label htmlFor="restrictions" className='mx-2'>{t('restrictions_apply')}</label>
            </div>

            {infos.restrictions && (
                <div className='mx-3'>


                    <div className='my-3'>
                        <CheckInput id="LimiteDeTemps" label={t('time_limit')} value={infos.timeLimit} onChange={handleTimeLimit} />
                    </div>

                    {infos.timeLimit && (
                        <Row>
                            <Col md="4" xl="4" className='my-3'>
                                <Input placeholder={t('limited_hours')} type="number" />
                            </Col>

                            <Col md="4" xl="4" className='my-3'>
                                <Select data={time} />
                            </Col>

                            <Col md="4" xl="4" className='my-3'>
                                <Select data={duration} />
                            </Col>
                        </Row>
                    )}

                    <div className='my-3'>
                        <CheckInput id="LimiteAppareils" label={t('limited_devices')} value={infos.devicesLimit} onChange={handleDevicesLimit} />
                    </div>

                    {infos.devicesLimit && (
                        <Row>
                            <Col md="6" className='d-flex align-items-center my-3'>
                                <span className='fs-6 fw-medium text-dark'>{t('maximum_devices')}</span>
                            </Col>
                            <Col md="6" className='my-3'>
                                <Select data={devices_data} />
                            </Col>
                        </Row>
                    )}
                </div>
            )}
        </div>
    )
}

export default Restrictions
