import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { DatePicker, DateRangePicker } from "rsuite";
import ValidityAndDuration from "./ValidityAndDuration";
import Cancel from "./Cancel";

const Policy = ({ t }) => {

  const { beforeToday } = DateRangePicker;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('activityperso')}</h2>
              <p className="fs-5 fw-light">{t('activitypersoP')}</p>
              <div className="fs-6">
                <ValidityAndDuration />
                <div className="my-4">
                  <>
                    <div className="fs-5 fw-medium text-dark">{t('PackagesDesDates')}</div>
                    <p className="fs-6">{t('PackagesDesDatesP')}</p>
                    <Row className="fs-6">
                      <Col md="6" className="my-3">
                        <DatePicker className="border w-100 p-3" oneTap placeholder="Début" onChange={handleStartDate} value={startDate} shouldDisableDate={beforeToday()} />
                      </Col>

                      <Col md="6" className="my-3">
                        <DatePicker className="border w-100 p-3" oneTap placeholder="Fin" onChange={handleEndDate} value={endDate} shouldDisableDate={beforeToday()} />
                      </Col>
                    </Row>
                  </>
                </div>
                <Cancel />
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Policy;
