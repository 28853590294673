import Menu from "./Menu"


const Home = () => {

    return (
        <Menu/>
    )
}

export default Home