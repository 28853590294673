import React, { useState } from "react"
import { Button, Table } from "rsuite"
import { Row, Col } from "react-bootstrap"
import { FaFileAlt } from "react-icons/fa"
import { FaFileCircleExclamation } from "react-icons/fa6"
import { FaFileUpload } from "react-icons/fa"
import { FaFileCircleCheck } from "react-icons/fa6"
import { IoMdTrendingDown } from "react-icons/io"
import { FaArrowTrendUp } from "react-icons/fa6"
import ManageHeader from "../../components/ManageHeader";
import { Link } from 'react-router-dom'
import { FiEye } from "react-icons/fi"
import Input from "../../components/forms/Input"
import DateInput from "../../components/forms/DateInput"
import Select from "../../components/forms/Select"
import { ScrollText } from "lucide-react"
import { useTranslation } from "react-i18next";

const { Column, HeaderCell, Cell } = Table;

const Paymentglobal = () => {

    const { t } = useTranslation();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleModifierClick = () => {
        setShowSecondModal(false);
        setShowModal(true);
    }
    const [showThirdModal, setShowThirdModal] = useState(false);

    const handleOpenThirdModal = () => {
        setShowSecondModal(false);
        setShowThirdModal(true);
    };

    const handleOpenDatePicker = () => {
        setShowDatePicker(true);
    };

    const handleCloseDatePicker = () => {
        setShowDatePicker(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        handleCloseDatePicker();
    };

    const [showModal, setShowModal] = useState(false);

    const handleConfirmDeactivate = () => {
        setShowModal(false);
        handleShowSuccessMessage();
    };

    const handleShowSuccessMessage = () => {
        setShowSuccessMessage(true);
    };

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleEditButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [bookings, setBookings] = useState([
        { id: 1, numerofacture: "4953455341", nomclient: "Oasis Hotel", datecreation: "2024-05-15", montant: "EUR 57.49", status: "payé", date: "2024-05-20", typefacture: "Commission" },
        { id: 2, numerofacture: "8573455341", nomclient: "Roza Hotel", datecreation: "2024-05-16", montant: "EUR 25.55", status: "En attente", date: "2024-05-21", typefacture: "Commission" },
        { id: 3, numerofacture: "5847455341", nomclient: "Hotel Mazafran", datecreation: "2024-05-17", montant: "EUR 65.15", status: "payé", date: "2024-05-22", typefacture: "Incorrect" },
        { id: 4, numerofacture: "4953455341", nomclient: "Hotel De L'aeroport", datecreation: "2024-05-15", montant: "EUR 1.24", status: "Annulé", date: "2024-05-20", typefacture: "Note de crédit" },
        { id: 5, numerofacture: "8573455341", nomclient: "Hotel El Biar", datecreation: "2024-05-16", montant: "EUR 158.47", status: "En attente", date: "2024-05-21", typefacture: "Commission" },
        { id: 6, numerofacture: "5847455341", nomclient: "MARAVAL", datecreation: "2024-05-17", montant: "EUR 48.87", status: "payé", date: "2024-05-22", typefacture: "Incorrect" },
        { id: 7, numerofacture: "4953455341", nomclient: "Oasis Hotel", datecreation: "2024-05-15", montant: "EUR 57.9", status: "payé", date: "2024-05-20", typefacture: "Note de crédit" },
        { id: 8, numerofacture: "8573455341", nomclient: "Hotel Mazafran", datecreation: "2024-05-16", montant: "EUR 12.58", status: "En attente", date: "2024-05-21", typefacture: "Commission" },
        { id: 9, numerofacture: "5847455341", nomclient: "HOTEL SOLTANE", datecreation: "2024-05-17", montant: "EUR 5.49", status: "payé", date: "2024-05-22", typefacture: "Commission" },
        { id: 10, numerofacture: "4953455341", nomclient: "Hotel De L'aeroport", datecreation: "2024-05-15", montant: "EUR 7.49", status: "Annulé", date: "2024-05-20", typefacture: "Incorrect" },
        { id: 11, numerofacture: "8573455341", nomclient: "Hotel Mazafran", datecreation: "2024-05-16", montant: "EUR EUR 9.68", status: "En attente", date: "2024-05-21", typefacture: "Commission" },
        { id: 12, numerofacture: "5847455341", nomclient: "Roza Hotel", datecreation: "2024-05-17", montant: "33.83", status: "payé", date: "2024-05-22", typefacture: "Commission" }
    ]);


    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleSelectAll = () => {
        const newCheckedItems = {};
        bookings.forEach((booking, index) => {
            newCheckedItems[index] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (index) => {
        const newCheckedItems = { ...checkedItems, [index]: !checkedItems[index] };
        setCheckedItems(newCheckedItems);

        const allChecked = Object.values(newCheckedItems).every((value) => value);
        setSelectAll(allChecked);
    };

    const handleSearch = () => {
        const filteredBookings = bookings.filter((booking) => {
            if (!startDate || !endDate) return true;
            const bookingArrivalDate = new Date(booking.arrival);
            const bookingDepartureDate = new Date(booking.departure);
            return bookingArrivalDate >= startDate && bookingDepartureDate <= endDate;
        });
        return filteredBookings;
    };

    const [showSecondModal, setShowSecondModal] = useState(false);
    const handleOpenSecondModal = () => {
        setShowModal(false);
        setShowSecondModal(true);
    };

    const handleCloseSecondModal = () => {
        setShowSecondModal(false);
    };


    const data = [
        { title: "Payée", value: "payed" },
        { title: "En attente", value: "pounding" },
        { title: "Annulée", value: "cancelled" },
    ]

    return (
        <section >
            <ManageHeader
                title={t('ho_act_facturation')}
                content={t('ho_act_facturation_txt')}
                icon={<ScrollText size={25} strokeWidth={1.5} />}
            />

            <div className="container p-4" >
                <Row className="my-3">
                    <Col className="col-3">
                        <div style={{ backgroundColor: '#f2f0fe' }} className="p-4 d-flex flex-column rounded">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="fw-bolder fs-6">{t('ho_act_reservation_det_mont')}</span>
                                <span className="p-1 rounded text-white" style={{ backgroundColor: '#8b74f9' }}><FaFileAlt /></span>
                            </div>

                            <div className="mb-1 fw-bolder fs-4" style={{ color: '#8b74f9' }}>$ 427.98k</div>
                            <div className="mb-1 fw-medium fs-7" style={{ color: '#8b74f9' }}>+3.25 <FaArrowTrendUp /></div>
                            <div className=" fw-medium "><span>{t('ho_act_facturation_lastMonth')}</span></div>
                        </div>
                    </Col>
                    <Col className="col-3">
                        <div style={{ backgroundColor: '#e8f9ee' }} className="p-4 d-flex flex-column rounded">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="fw-bolder fs-6">{t('ho_act_facturation_payees')}</span>
                                <span className="p-1 rounded text-white" style={{ backgroundColor: '#29c763' }}><FaFileCircleCheck /></span>
                            </div>

                            <div className="mb-1 fw-bolder fs-4" style={{ color: '#29c763' }}>$ 165.21k </div>
                            <div className="mb-1 fw-medium fs-7" style={{ color: '#29c763' }}>+8.5 <FaArrowTrendUp /></div>
                            <div className=" fw-medium "><span>{t('ho_act_facturation_lastMonth')}</span></div>
                        </div>
                    </Col>
                    <Col className="col-3">
                        <div style={{ backgroundColor: '#fef0e7' }} className="p-4 d-flex flex-column rounded">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="fw-bolder fs-6">{t('ho_act_facturation_attente')}</span>
                                <span className="p-1 rounded text-white" style={{ backgroundColor: '#f97316' }}><FaFileUpload /></span>
                            </div>

                            <div className="mb-1 fw-bolder fs-4" style={{ color: '#f97316' }}>$ 42k </div>
                            <div className="mb-1 fw-medium fs-7" style={{ color: '#f97316' }}>+5.7 <FaArrowTrendUp /></div>
                            <div className=" fw-medium "><span>{t('ho_act_facturation_lastMonth')}</span></div>
                        </div>
                    </Col>
                    <Col className="col-3">
                        <div style={{ backgroundColor: '#fef2f2' }} className="p-4 d-flex flex-column rounded">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="fw-bolder fs-6">{t('ho_act_facturation_retard')}</span>
                                <span className="p-1 rounded text-white" style={{ backgroundColor: '#ef4444' }}><FaFileCircleExclamation /></span>
                            </div>

                            <div className="mb-1 fw-bolder fs-4" style={{ color: '#ef4444' }}>$ 427.98k</div>
                            <div className="mb-1 fw-medium fs-7" style={{ color: '#ef4444' }}>-3.25 <IoMdTrendingDown /></div>
                            <div className=" fw-medium "><span>{t('ho_act_facturation_lastMonth')}</span></div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl="4" className="my-3">
                        <Input placeholder={t('ho_act_facturation_num')} type="text" />
                    </Col>
                    <Col xl="3" className="my-3">
                        <DateInput leftLabel={t('from')} rightLabel={t('to')} />
                    </Col>
                    <Col xl="3" className="my-3">
                        <Select data={data} />
                    </Col>
                    <Col xl="2" className="my-3">
                        <button className="btn-blue w-100 p-4" onClick={handleSearch}>{t('filtrer')}</button>
                    </Col>
                </Row>







                <div className="my-3 bg-white border rounded">
                    <Table height={400} data={bookings} autoHeight className="w-100">
                        <Column flexGrow={1} sortable>
                            <HeaderCell>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            </HeaderCell>
                            <Cell>
                                {(rowData, rowIndex) => (
                                    <input
                                        type="checkbox"
                                        checked={checkedItems[rowIndex]}
                                        onChange={() => handleCheckboxChange(rowIndex)}
                                    />
                                )}
                            </Cell>
                        </Column>
                        <Column width={160} >
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_facturation_num')}</HeaderCell>
                            <Cell dataKey="numerofacture" />
                        </Column>
                        <Column width={160}>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_facturation_hotelname')}</HeaderCell>
                            <Cell dataKey="nomclient" />
                        </Column>
                        <Column width={160}>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_facturation_datefacture')}</HeaderCell>
                            <Cell dataKey="datecreation" />
                        </Column>
                        <Column flexGrow={1} sortable>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('amount')}</HeaderCell>
                            <Cell dataKey="montant" />
                        </Column>

                        <Column width={160}>
                            <HeaderCell className="fs-6 fw-bold text-dark text-center">{t('status')}</HeaderCell>
                            <Cell dataKey="status">
                                {(rowData) => {
                                    let backgroundColor = '';
                                    let color = '';

                                    switch (rowData.status) {
                                        case 'payé':
                                            backgroundColor = '#e8f9ee';
                                            color = '#29c763';
                                            break;
                                        case 'Annulé':
                                            backgroundColor = '#fef0e7';
                                            color = '#f97316';
                                            break;
                                        case 'En attente':
                                            backgroundColor = '#f2f0fe';
                                            color = '#8b74f9';
                                            break;
                                        default:
                                            backgroundColor = '#f3f6f9';
                                            color = '#000000';
                                    }

                                    return (
                                        <div className="p-1 rounded-4 text-center" style={{ marginTop: '-5px', backgroundColor, color }}>
                                            {rowData.status}
                                        </div>
                                    );
                                }}
                            </Cell>
                        </Column>
                        <Column width={160}>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_facturation_datePaiement')}</HeaderCell>
                            <Cell dataKey="date" className="" />
                        </Column>

                        <Column width={140}>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_facturation_type')}</HeaderCell>
                            <Cell dataKey="typefacture" className="" />
                        </Column>

                        <Column flexGrow={1} sortable>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('actions')}</HeaderCell>
                            <Cell>
                                {(rowData) => (
                                    <div className="d-flex flex-row  align-items-center" style={{ marginTop: '-5px' }} >

                                        <Link to="/myhotel/invoicedetails" >
                                            <Button className="border px-4 me-1 rounded">
                                                <FiEye />
                                            </Button>
                                        </Link>
                                    </div>
                                )}
                            </Cell>
                        </Column>
                    </Table>
                </div>
            </div>
        </section>
    );
};

export default Paymentglobal;
