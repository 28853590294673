import { Col, Row } from 'react-bootstrap'
import Select from './forms/Select'
import Input from './forms/Input'
import { useState } from 'react'
import { t } from 'i18next';
const AmountValue = () => {

    const [extraType, setExtraType] = useState('amount');

    const handleExtraType = (e) => {
        setExtraType(e.target.value);
    };

    const type = [
        { title: t('amount'), value: "Montant" },
        { title: t('percentage'), value: "Pourcentage" }
    ]

    return (
        <Row className='fs-6 align-items-center'>
            <Col sm="6" className='my-3'>
                <Input type="number" placeholder={extraType === "Montant" ? "$" : "%"} />
            </Col>
            <Col sm="6" className='my-3'>
                <Select data={type} value={extraType} handleChange={handleExtraType} />
            </Col>
        </Row>
    )
}

export default AmountValue