import { Col } from 'react-bootstrap';
import RadioInput from '../../../components/forms/RadioInput';
import Input from '../../../components/forms/Input';
import { useState } from 'react';

const Minight = ({ t }) => {
    const [minight, setMinight] = useState({
        minight: ""
    });

    const handleMinight = (value) => {
        setMinight({ ...minight, minight: value });
    };

    const renderMinight = (data) => {
        if (data === 'yes') {
            return (
                <Col md="8" className='px-4'>
                    <li className='my-3'>{t('ho_act_newrate_stayadvanced1')}</li>
                    <Input placeholder="00" type="number" />
                    <li className='my-3'>{t('ho_act_newrate_stayadvanced2')}</li>
                    <Input placeholder="500" type="number" />
                </Col>
            );
        } else {
            return null;
        }
    };

    const minightdata = [
        { title: t('yes'), value: "yes" },
        { title: t('no'), value: "no" },
    ];

    return (
        <div className='my-4 bg-white p-3'>
            <span className='fs-4 fw-medium text-dark'>{t('ho_act_newrate_stayadvanced')}</span>
            <p className='fs-6 my-3'>{t('ho_act_newrate_stayadvancedP')}</p><div className='my-3 mt-4'>

                {minightdata.map((policy, index) => (
                    <div key={index} className='my-3 fs-6'>
                        <RadioInput id={policy.value} name="minight" label={policy.title} value={policy.value} onChange={() => handleMinight(policy.value)} />
                        {minight.minight === policy.value && (
                            renderMinight(policy.value)
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Minight;
