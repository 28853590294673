import { Button } from "rsuite"
import { Modal } from "react-bootstrap"
import { FaRegCalendarCheck } from "react-icons/fa6"
import { IoPricetagSharp } from "react-icons/io5"


const BeforeSaveRates = (props) => {
    return (
        <Modal show={props.showSecondModal} onHide={props.handleCloseSecondModal} size="lg" className='rounded-2 p-2'>
            <Modal.Header className='bg-white' closeButton>
                <Modal.Title className="text-modal fw-bold fs-5 ms-2">{props.t('ho_act_reservation_maj')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-white text-modal d-flex flex-column'>
                <div className="p-2 my-2">
                    <span className=" fs-7">{props.t('ho_act_reservation_maj_txt')}</span>
                </div>
                <div className="d-flex flex-row justify-content-between">
                    <div className='p-2'>
                        <FaRegCalendarCheck className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">{props.t('the_dates')}</span>
                        <div className='d-flex flex-column ms-4'>
                            <div className='d-flex flex-column  mt-3'>
                                <span className="fs-8 my-1 text-red"><s>sam. 4 mai 2024 — lun. 6 mai 2024</s></span>
                                <span className="fs-5 fw-bold text-dark my-1">dim. 26 mai 2024 — mer. 29 mai 2024</span>
                                <Button className="px-5 mt-2 mb-2 w-50" onClick={props.handleModifierClick}>
                                    {props.t('edit')}
                                </Button>

                            </div>
                        </div>
                    </div>
                    <div className='p-2 '>
                        <IoPricetagSharp className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">{props.t('rates')}</span>
                        <div className='d-flex flex-column ms-4'>
                            <div className='d-flex flex-column  mt-3'>
                                <span className="my-1">{props.t('total_amount_acc')}</span>
                                <span className="fs-7 my-2 "><s>DZD 46 000.00 </s></span>
                                <input type="text" placeholder="DZD 96 000.00" className="form-control w-50 mt-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-white'>
                <span className="text-modal p-2">{props.t('total_amount_acc_txt')}</span>
                <Button appearance="primary" color="blue" onClick={props.handleOpenThirdModal}>
                    {props.t('next')}
                </Button>
                <Button appearance="primary" color="cyan" onClick={props.handleCloseSecondModal}>
                    {props.t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default BeforeSaveRates
