import { Row, Col } from "react-bootstrap";
import ValidityAndDuration from "./ValidityAndDuration";
import Cancel from "./Cancel";

const Policy = ({ t }) => {

  return (
    <section className="bg-white position-relative form-transition">
      <Row className="justify-content-center">
        <Col lg="9" xl="6" className="my-3">
          <>
            <h2 className="fs-3 lh-base fw-medium text-dark">{t('activityperso')}</h2>
            <p className="fs-5 fw-light">{t('TransportPersonaliserP')}.</p>
            <div className="fs-6">
              <ValidityAndDuration />
              <div className="my-4">
                <Cancel />
              </div>
            </div>
          </>
        </Col>
      </Row>
    </section>
  );
};

export default Policy;
