import Select from "../../../components/forms/Select";
import RadioInput from "../../../components/forms/RadioInput";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Cancel = () => {
  const { t } = useTranslation();
  const [infos, setInfo] = useState({
    cancel: "",
  });

  const handleCancel = (value) => {
    setInfo({ ...infos, cancel: value });
  };

  const taxesData = [
    { title: t('50_from_booking_amount'), value: "50%" },
    { title: t('100_from_booking_amount'), value: "100%" },
  ];

  const renderCancel = (data) => (
    <>
      <ul>
        <li className="my-3">{t('travelers_that_cancel')} {data} {t('check_in_day_fees')}</li>
        <li className="my-3">{t('travelers_cancel_les_than_24')} {data} {t('are_billed')}</li>
      </ul>
      <div className="my-3">
      <Select data={taxesData} />
      </div>
    </>
  );

  const cancelationData = [
    { title: t('24h_cancellation'), value: "24hours", content: renderCancel("24") },
    { title: t('48h_cancellation'), value: "48hours", content: renderCancel("48") },
    { title: t('72h_cancellation'), value: "72hours", content: renderCancel("72") },
    {
      title: t('non_refundable'),
      value: "nonrefundable",
      content: (
        <ul>
          <li className="my-3">{t('travelers_cancelllation')}</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="my-4">
      <span className="fs-5 fw-medium text-dark">{t('default_cancel_policy')}</span>
      <p className="fs-6 fw-light mt-1">{t('penalty_period')}</p>

      <div className="my-3">
        {cancelationData.map((policy, index) => (
          <div key={index} className="my-4 fs-6">
            <RadioInput id={policy.value} name="cancellationPolicy" label={policy.title} value={policy.value} onChange={() => handleCancel(policy.value)} />

            {infos.cancel === policy.value && <p>{policy.content}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cancel;
