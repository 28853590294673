import { Info } from 'lucide-react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const ToolTip = ({ header,body , placement }) => {

    const popover = (
        <Popover id="popover-basic" className='border-2 border-primary'>
            <Popover.Header>{header}</Popover.Header>
            <Popover.Body>
                {body}
            </Popover.Body>
        </Popover>
    )

    return (
        <OverlayTrigger trigger="hover" placement={placement} overlay={popover}>
            <span className='text-blue' role='button'><Info size={20} /></span>
        </OverlayTrigger>
    )
}

export default ToolTip