import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsSpa = ({ checked }) => {
  const { t } = useTranslation();

  const rooms = [
    { title: t("room_treatment_for_couples"), value: "SallesPourCouples" },
    { title: t("treatment_room_outdoor"), value: "ZonesExtérieures" },
  ];

  const massages = [
    { title: t("deep_tissu_massage"), value: "MassageProfonds" },
    { title: t("hot_stone_massage"), value: "MassageAuxPierresChaudes" },
    { title: t("prenatal_massage"), value: "Massage-prénatal" },
    { title: t("sports_massage"), value: "Massage-sportif" },
    { title: t("swedish_massage"), value: "Massage-suédois" },
    { title: t("thai_massage"), value: "Massage-thaï" },
  ];

  const care = [
    { title: t("aromatherapy_treats"), value: "aromathérapie" },
    { title: t("ayurvedic_treatments"), value: "ayurvédiques" },
    { title: t("body_scrubs"), value: "Gommages-corporels" },
    { title: t("body_care"), value: "Soins-corporels" },
    { title: t("body_wraps"), value: "Enveloppements-corporels" },
    { title: t("hydrotherapeutic_treatments"), value: "hydrothérapiques" },
    { title: t("manicures_and_pedicures"), value: "Manucures-pédicures" },
    { title: t("thalassotherapy"), value: "Thalassothérapie" },
    { title: t("detoxifying_wrap"), value: "Enveloppement-détoxifiant" },
    { title: t("reflexology"), value: "Réflexologie" },
    { title: t("turkish_bath"), value: "Bain-turc" },
  ];

  const age = Array.from({ length: 18 }, (_, index) => ({
    title: `${index + 1}`,
    value: index + 1,
  }));


  const length = Array.from({ length: 50 }, (_, index) => ({
    title: `${index + 1}`,
    value: index + 1,
  }));

  return (
    <div className="fs-6 my-3">

      {checked === "MassagesSpa" && (
        <>
          <div className="d-flex align-items-center my-3">
            <CheckInput
              id="reservations-spa"
              label={t("booking_in_advance_for_massage")}
            />
          </div>
          <div className="my-3 text-blue">
            {t("massages_available")}
          </div>
          {massages.map((item, indexOf) => (
            <div key={indexOf} className="my-3">
              <CheckInput id={item.value} label={item.title} />
            </div>
          ))}
        </>
      )}

      {checked === 'SurveillanceAdulte' && (
        <Row className='fs-6 align-items-center'>
          <Col md="6" className='d-flex align-items-center my-3'>
            <span className='fw-medium text-dark'>{t('max_child_age')}</span>
          </Col>
          <Col md="6" className='d-flex align-items-center my-3'>
            <Select data={age} />
          </Col>
        </Row>
      )}

      {checked === 'RestrictionSpa' && (
        <Row className='fs-6 align-items-center'>
          <Col md="6" className='d-flex align-items-center my-3'>
            <span className='fw-medium text-dark'>{t('min_child_age')}</span>
          </Col>
          <Col md="6" className='d-flex align-items-center my-3'>
            <Select data={age} />
          </Col>
        </Row>
      )}

      {
        checked === "Soins-spa" && (
          <>
            {care.map((item, indexOf) => (
              <div key={indexOf} className="my-3">
                <div className="d-flex align-items-center">
                  <CheckInput id={item.value} label={item.title} />
                </div>
              </div>
            ))}
          </>
        )
      }

      {
        checked === "Salle-spa" && (
          <>
            <Row className="fs-5 align-items-center">
              <Col
                md="6"
                className="d-flex align-items-center my-3"
              >
                <span className="fs-6 fw-medium text-dark">
                  {t("treat_rooms_count")}
                </span>
              </Col>
              <Col
                md="6"
                className="d-flex align-items-center my-3"
              >
                <Select data={length} />
              </Col>
            </Row>
            {rooms.map((item, indexOf) => (
              <div key={indexOf} className="my-3">
                <div className="d-flex align-items-center">
                  <CheckInput id={item.value} label={item.title} />
                </div>
              </div>
            ))}
          </>
        )
      }


    </div >
  );
};

export default DetailsSpa;
