import { Col, Row } from "react-bootstrap";
import Input from "./forms/Input";
import { useTranslation } from "react-i18next";

const Size = ({label}) => {
    const { t } = useTranslation();

    return (
        <div className='fs-6'>
            <span className='text-blue'>{label}</span>
            <Row className='align-items-center'>
                <Col md="6" className='my-3'>
                    <Input placeholder={t('square_feet')} type="number" />
                </Col>
                <Col md="6" className='my-3'>
                    <Input placeholder={t('square_meters')} type="number" />
                </Col>
            </Row>
        </div>
    )
}

export default Size