import { Mail, MapPin, PhoneCall } from 'lucide-react';
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import Input from '../components/forms/Input'
import Textarea from '../components/forms/Textarea'
import Faq from '../components/home/Faq';
const Contact = () => {

    const { t } = useTranslation();

    const data = [
        { city: "United states", location: "456 Park Avenue New York, NY 10022 USA", phone: "+1 (212) 555-7890", mail: "uscontact@travelp.com" },
        { city: "Spain", location: "Calle Falsa 123 28013 Madrid España", phone: "+34 912 345 678", mail: "spaincontact@travelp.com" },
    ]

    return (
        <div className='container py-5'>
            <div className='fs-5 fw-light text-blue'>{t('contact_us')}</div>
            <Row className='justify-content-between mt-3'>
                <Col lg="6" xl="5">
                    <div className='fs-1 fw-bold text-dark'>{t('contant_title')}</div>
                    <p className='fs-5 my-3 fw-light'>{t('contact_text')}</p>

                    <div className='fs-3 fw-medium text-dark'>{t('our_office')}</div>
                    <Row className='fs-6'>
                        {data.map(item => (
                            <Col xl="6" className='my-3'>
                                <div className='fs-4 fw-light text-dark'>{item.city}</div>
                                <div className='my-3'><span className='text-blue'><MapPin size={15} /></span> <span>{item.location}</span></div>
                                <div className='my-3'><span className='text-blue'><PhoneCall size={15} /></span> <span>{item.phone}</span></div>
                                <div className='my-3'><span className='text-blue'><Mail size={15} /></span> <span>{item.mail}</span></div>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col lg="6" xl="6">
                    <img className='rounded' src='https://taxitema.tokotema.com/wp-content/uploads/2023/12/portrait-of-smiling-male-customer-service-operator-2023-11-27-05-22-13-utc-1.jpg' alt="Illustration team working" width="100%" height="100%" style={{ minHeight: "460px", maxHeight: "460px" }} />
                </Col>
            </Row>

            <Row className='mt-5'>
                <Col lg="4" className='my-3'>
                    <Input placeholder={t('name')} type="text" />
                </Col>
                <Col lg="4" className='my-3'>
                    <Input placeholder={t('email')} type="email" />
                </Col>
                <Col lg="4" className='my-3'>
                    <Input placeholder={t('phone')} type="phone" />
                </Col>
                <Col xs="12" className='my-3'>
                    <Textarea placeholder={t('content')} rows="6" />
                </Col>
            </Row>

            <Faq />

        </div>
    )
}

export default Contact
