import { CakeSlice, Salad, Soup, Beef, Fish, Drumstick, Sandwich, Pizza, EggFried, CupSoda, Milk, Wine, Coffee, Dessert, Cookie, Lollipop, Cherry, Nut, Popsicle } from "lucide-react";
import { Row, Col } from "react-bootstrap";
import Checkbox from "../../../components/forms/Checkbox";
import { useState } from "react";

const Meals = ({t}) => {
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, title: t('salads'), isChecked: false, value: "Salades", icon: <Salad strokeWidth={1.2} size={30} /> },
    { id: 2, title: t('dishes'), isChecked: false, value: "Plats", icon: <Soup strokeWidth={1.2} size={30} /> },
    { id: 3, title: t('red_meat'), isChecked: false, value: "Viande rouge", icon: <Beef strokeWidth={1.2} size={30} /> },
    { id: 4, title: t('fish'), isChecked: false, value: "Poisson", icon: <Fish strokeWidth={1.2} size={30} /> },
    { id: 5, title: t('RestauInfos5'), isChecked: false, value: "Viande blanche", icon: <Drumstick strokeWidth={1.2} size={30} /> },
    { id: 6, title: t('RestauInfos6'), isChecked: false, value: "Sandwichs", icon: <Sandwich strokeWidth={1.2} size={30} /> },
    { id: 7, title: t('RestauInfos7'), isChecked: false, value: "Pizzas", icon: <Pizza strokeWidth={1.2} size={30} /> },
    { id: 8, title: t('RestauInfos8'), isChecked: false, value: "Oeufs", icon: <EggFried strokeWidth={1.2} size={30} /> },
    { id: 9, title: t('RestauInfos9'), isChecked: false, value: "Boissons", icon: <CupSoda strokeWidth={1.2} size={30} /> },
    { id: 10, title: t('RestauInfos10'), isChecked: false, value: "Lait", icon: <Milk strokeWidth={1.2} size={30} /> },
    { id: 11, title: t('RestauInfos11'), isChecked: false, value: "Boissons chaudes", icon: <Coffee strokeWidth={1.2} size={30} /> },
    { id: 12, title: t('RestauInfos12'), isChecked: false, value: "Alcool", icon: <Wine strokeWidth={1.2} size={30} /> },
    { id: 13, title: t('RestauInfos13'), isChecked: false, value: "Gateaux", icon: <CakeSlice strokeWidth={1.2} size={30} /> },
    { id: 14, title: t('RestauInfos14'), isChecked: false, value: "Desserts", icon: <Dessert strokeWidth={1.2} size={30} /> },
    { id: 15, title: t('RestauInfos15'), isChecked: false, value: "Collations", icon: <Cookie strokeWidth={1.2} size={30} /> },
    { id: 16, title: t('RestauInfos16'), isChecked: false, value: "Fréandises", icon: <Lollipop strokeWidth={1.2} size={30} /> },
    { id: 17, title: t('RestauInfos17'), isChecked: false, value: "Glaces", icon: <Popsicle strokeWidth={1.2} size={30} /> },
    { id: 18, title: t('RestauInfos18'), isChecked: false, value: "Fruits", icon: <Cherry strokeWidth={1.2} size={30} /> },
    { id: 19, title: t('RestauInfos19'), isChecked: false, value: "Fruits secs", icon: <Nut strokeWidth={1.2} size={30} /> },
  ]);

  const toggleCheckbox = (id) => {
    setCheckboxes((prevState) => prevState.map((checkbox) => (checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox)));
  };

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('RestauInfos')}</h2>
              <p className="fs-5 fw-light">{t('RestauInfosP')}</p>

              <div className="my-3">
                <Row>
                  {checkboxes.map((checkbox, index) => (
                    <Col key={index} xs="6" sm="4" md="4" className="my-2">
                      <Checkbox key={checkbox.id} id={checkbox.id} isChecked={checkbox.isChecked} value={checkbox.value} onChange={toggleCheckbox} title={checkbox.title} icon={checkbox.icon} />
                    </Col>
                  ))}
                </Row>
                {/* <div>
                  {getCheckedValues().map((value) => (
                    <p key={value}>{value}</p>
                  ))}
                </div> */}
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Meals;
