import { Col, Row } from 'react-bootstrap'
import { ArrowDown, ArrowUp } from "lucide-react";
import img4 from "../../assets/icones/1.svg";
import img1 from "../../assets/icones/4.svg";
import img2 from "../../assets/icones/2.svg";
import img3 from "../../assets/icones/3.svg";
import { useTranslation } from "react-i18next";

const Stats = ({ products }) => {

    const { t } = useTranslation();

    const bgBlue = {
        background: "linear-gradient(180deg, #8077ec 0%, #B8B2F8 100%)",
        overflow: "hidden",
    };

    let sales = 0;
    let earnings = 0;

    products.forEach(element => {
        sales += element.bookings;
        earnings += element.earnings;
    });


    const data = [
        { id: 1, title: t("products"), value: products.length, growth: 2, status: "down", image: img1 },
        { id: 2, title: t("earnings"), value: `${earnings}€`, growth: 25, status: "up", image: img2 },
        { id: 3, title: t("sales"), value: sales, growth: 5, status: "down", image: img3 },
        { id: 4, title: t("custumers"), value: 9, growth: 45, status: "up", image: img4 },
    ]

    return (
        <Row className="my-3">
            <Col md="8" lg="6" xl="4" className="my-2">
                <div style={bgBlue} className="d-flex justify-content-between p-3 py-4 text-white rounded">
                    <div className=''>
                        <div className="fs-4 fw-bold">{t("welcome_back")} User.</div>
                        <p className="my-2 fs-6 fw-light">{t("dashboard_text")}</p>
                    </div>


                    <img width="120" height="120" src="https://modernize-angular-main.netlify.app/assets/images/backgrounds/welcome-bg2.png" alt="" />

                </div>
            </Col>

            {data.map(item => (
                <Col sm="6" md="4" lg="3" xl="2" className="my-2">
                    <div className="d-flex flex-column justify-content-center bg-white border rounded p-3 h-100">
                        <div className="d-flex justify-content-between">
                            <div>
                                <div className="fs-5 fw-light">{item.title}</div>
                                <div className="fs-3 fw-medium text-dark my-2">{item.value}</div>
                            </div>
                            <div>
                                <img width={60} height={60} src={item.image} alt="" />
                            </div>
                        </div>
                        <div className='my-2'>
                            {item.status === "up" ?
                                <span className="text-green">
                                    <ArrowUp size={15} /> {item.growth}%
                                </span>
                                :
                                <span className="text-red">
                                    <ArrowDown size={15} /> {item.growth}%
                                </span>
                            }
                            <span> {t('last_month')}</span>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    )
}

export default Stats
