import Promo from "./components/Promo";
import ManageHeader from "../../components/ManageHeader";
import { Megaphone } from "lucide-react";

const Promotions = ({t}) => {
  return (
    <section>
      <ManageHeader
        title={t('promotions')}
        content={t('promotions_caption')}
        icon={<Megaphone size={25} strokeWidth={1.5}/>}
      />

      <div className="my-4 container">
        <Promo t={t} />
      </div>
    </section>
  );
};

export default Promotions;
