import Home from './Home'


function Main(props) {

    const composite = {
        home: <Home />,
    };

    return (
        <>
            {eval("composite." + props.component)}
        </>

    );

}

export default Main;