import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink } from "react-router-dom";
import { ChevronRight } from "lucide-react";

const Aside = ({ show, handleClose }) => {
  return (
    <aside>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div onClick={handleClose}>
            <nav>
              <ul className="left-menu-list list-unstyled fs-5 fw-bold">
                <li className="p-3 border-bottom d-flex justify-content-between align-items-center ">
                  <NavLink to="/">Accueil</NavLink>
                  <ChevronRight strokeWidth={1.5} size={15} />
                </li>
                <li className="p-3 border-bottom d-flex justify-content-between align-items-center">
                  <NavLink to="/hotel">Hôtels</NavLink>
                  <ChevronRight strokeWidth={1.5} size={15} />
                </li>
                <li className="p-3 border-bottom d-flex justify-content-between align-items-center">
                  <NavLink to="/rental">Logements</NavLink>
                  <ChevronRight strokeWidth={1.5} size={15} />
                </li>
                <li className="p-3 border-bottom d-flex justify-content-between align-items-center">
                  <NavLink to="/activity">Activités</NavLink>
                  <ChevronRight strokeWidth={1.5} size={15} />
                </li>
                <li className="p-3 border-bottom d-flex justify-content-between align-items-center">
                  <NavLink to="/transport">Transports</NavLink>
                  <ChevronRight strokeWidth={1.5} size={15} />
                </li>
                <li className="p-3 border-bottom d-flex justify-content-between align-items-center">
                  <NavLink to="/package">Packages</NavLink>
                  <ChevronRight strokeWidth={1.5} size={15} />
                </li>
                <li className="p-3 border-bottom d-flex justify-content-between align-items-center">
                  <NavLink to="/car">Voitures</NavLink>
                  <ChevronRight strokeWidth={1.5} size={15} />
                </li>
                <li className="p-3 border-bottom d-flex justify-content-between align-items-center">
                  <NavLink to="/restaurant">Restaurants</NavLink>
                  <ChevronRight strokeWidth={1.5} size={15} />
                </li>
                <li className="p-3 border-bottom d-flex justify-content-between align-items-center">
                  <NavLink to="/settings">Paramétres</NavLink>
                  <ChevronRight strokeWidth={1.5} size={15} />
                </li>
              </ul>
            </nav>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </aside>
  );
};

export default Aside;
