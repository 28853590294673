import { useState } from "react";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsCity = ({ checked }) => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedPlaces, setCheckedPlaces] = useState({});

  const handlePlaces = (value) => {
    setCheckedPlaces((prevPlaces) => ({
      ...prevPlaces,
      [value]: !prevPlaces[value],
    }));
  };

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const connexion = [
    { title: t("linked_to_airport"), value: "ReliéAéroport" },
    { title: t("linked_to_convention_center"), value: "ReliéCongrès" },
    { title: t("linked_to_station"), value: "ReliéMétro" },
    { title: t("linked_to_shopping_center"), value: "ReliéCommercial" },
  ];

  const place = [
    { title: t("in_business_area"), value: "quartier-affaires" },
    { title: t("downtown"), value: "centre-ville" },
    { title: t("in_entertainment_area"), value: "quartier-divertissements" },
    { title: t("in_historic_area"), value: "quartier-historique" },
    { title: t("in_shopping_area"), value: "quartier-commerçant" },
  ];

  const near = [
    { title: t("airport_nearby"), value: "AéroportProximité" },
    { title: t("casino_nearby"), value: "CasinoProximité" },
    { title: t("spa_nearby"), value: "SpaProximité" },
    { title: t("hospital_nearby"), value: "HôpitalProximité" },
    { title: t("laundry_nearby"), value: "LaverieProximité" },
    { title: t("metro_station_nearby"), value: "StationProximité" },
    { title: t("museum_nearby"), value: "MuséeProximité" },
    { title: t("outlet_store_nearby"), value: "BoutiquesProximité" },
    { title: t("parks_nearby"), value: "ParcProximité" },
    { title: t("station_nearby"), value: "GareProximité" },
    { title: t("zoo_nearby"), value: "ZooProximité" },
  ];

  return (
    <div className="my-3 fs-6">
      {checked === "LieuHébergement" && (
        <>
          <div className="text-blue mt-3">
            {t("select_two_elements")}
          </div>
          {place.map((item, indexOf) => (
            <div key={indexOf} className="px-3 my-3">
              <div className="d-flex align-items-center">
                <CheckInput
                  id={item.value}
                  label={item.title}
                  checked={checkedPlaces[item.value] || false}
                  onChange={() => handlePlaces(item.value)}
                  disabled={Object.keys(checkedPlaces).length > 1}
                />
              </div>
            </div>
          ))}
        </>
      )}

      {checked === "ConnexionHébergement" && (
        <>
          {connexion.map((item, indexOf) => (
            <div key={indexOf} className="px-3 my-3">
              <div className="d-flex align-items-center">
                <CheckInput
                  id={item.value}
                  label={item.title}
                  checked={checkedItems[item.value] || false}
                  onChange={() => handleCheckboxChange(item.value)}
                />
              </div>
            </div>
          ))}
        </>
      )}

      {checked === "ProcheHébergement" && (
        <>
          {near.map((item, indexOf) => (
            <div key={indexOf} className="px-3 my-3">
              <div className="d-flex align-items-center">
                <CheckInput
                  id={item.value}
                  label={item.title}
                  checked={checkedItems[item.value] || false}
                  onChange={() => handleCheckboxChange(item.value)}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default DetailsCity;
