import { Row, Col } from "react-bootstrap";
import { RadioTile, RadioTileGroup } from "rsuite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TbUser,TbUserStar } from "react-icons/tb";

const Visibility = () => {

  const { t } = useTranslation();


  const [infos, setInfos] = useState({
    choice: "",
  });

  const handleChoice = (value) => {
    setInfos({ ...infos, choice: value });
  };

  const data = [
    {
      title: t('any_traveler'),
      icon: <TbUser strokeWidth={1.3} size={25} />,
      value: "NimporteQuelVoyageur",
      content: t('any_traveler_text'),
    },
    {
      title: t('experienced_traveler'),
      icon: <TbUserStar strokeWidth={1.3} size={25} />,
      value: "UnVoyageurExpérimenté",
      content: t('experienced_traveler_text'),
    },
  ];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('fist_booking_title')}</h2>
              <p className="fs-5 fw-light">{t('first_booking_text')}</p>
              <RadioTileGroup defaultValue="LogementEniter">
                <Row className="mt-3">
                  {data.map((choice, index) => (
                    <Col key={index} xs="12" className="my-2">
                      <RadioTile onChange={handleChoice} value={choice.value} className={`rounded-4 py-4 p-3 h-100 ${infos.choice === choice.value ? "border-dark" : "border"}`}>
                        <div className="text-dark d-flex">
                          <div>{choice.icon}</div>
                          <div className="mx-3 fs-5 fw-medium">
                            <span>{choice.title}</span>
                            <p className="mt-2 fs-6 fw-light">{choice.content}</p>
                          </div>
                        </div>
                      </RadioTile>
                    </Col>
                  ))}
                </Row>
              </RadioTileGroup>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Visibility;
