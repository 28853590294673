import { Container, Sidebar, Sidenav, Navbar, Nav } from "rsuite";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import DashboardIcon from "@rsuite/icons/Dashboard";
import CalendarIcon from "@rsuite/icons/Calendar";
import TagIcon from "@rsuite/icons/Tag";
import ListIcon from "@rsuite/icons/List";
import BarChartIcon from "@rsuite/icons/BarChart";
import OffIcon from "@rsuite/icons/Off";
import { useState } from "react";
import Details from "./Details";
import Promotions from "./Promotions";
import Availability from "./Availability";
import Performances from "./Performances";
import Administration from "./Administration";
import { useTranslation } from "react-i18next";

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
          {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

const Menu = () => {
  const [expand, setExpand] = useState(false);

  const [boutonActif, setBoutonActif] = useState("home");

  const handleClick = (bouton) => {
    setBoutonActif(bouton);
  };

  const { t } = useTranslation();

  const data = [
    { id: 1, type: "button", title: t('home'), value: "home", icon: <DashboardIcon /> },
    { id: 2, type: "button", title: t('availability'), value: "availability", icon: <CalendarIcon /> },
    { id: 3, type: "button", title: t('promotions'), value: "promotions", icon: <TagIcon /> },
    { id: 5, type: "button", title: t('bookings'), value: "bookings", icon: <BarChartIcon /> },
    { id: 6, type: "button", title: t('admin'), value: "admin", icon: <ListIcon /> },
    { id: 7, type: "button", title: t('disable'), value: "", icon: <OffIcon /> },
  ];

  return (
    <div className="show-fake-browser sidebar-page">
      <Container>
        <Sidebar style={{ display: "flex", flexDirection: "column" }} width={expand ? 260 : 56} collapsible className="border-end">
          <Sidenav expanded={expand} defaultOpenKeys={["3"]} appearance="white">
            <Sidenav.Body>
              <Nav>
                {data.map((item) => (
                  <>
                    {item.type === "button" ? (
                      <Nav.Item className={`fs-6 fw-light ${boutonActif === item.value ? "text-blue" : "text-dark"}`} eventKey={item.id} icon={item.icon} onClick={() => handleClick(item.value)}>
                        {item.title}
                      </Nav.Item>
                    ) : (
                      <Nav.Menu eventKey={item.id} trigger="hover" title={item.title} icon={item.icon} placement="rightStart" className="fs-6 fw-light text-dark">
                        {item.buttons.map((button) => (
                          <Nav.Item key={button.id} className={`${boutonActif === button.value ? "text-blue" : "text-dark"}`} eventKey={`${item.id} - ${button.id}`} onClick={() => handleClick(button.value)}>
                            {button.title}
                          </Nav.Item>
                        ))}
                      </Nav.Menu>
                    )}
                  </>
                ))}
              </Nav>
            </Sidenav.Body>
          </Sidenav>
          {/* <NavToggle className="d-none" expand={expand} onChange={() => setExpand(!expand)} /> */}
        </Sidebar>

        <div className="w-100">
          <>
            {boutonActif === "home" && <Details t={t} />}
            {boutonActif === "availability" && <Availability t={t} />}
            {boutonActif === "promotions" && <Promotions t={t} />}
            {boutonActif === "bookings" && <Performances t={t}  />}
            {boutonActif === "admin" && <Administration t={t} />}
          </>
        </div>
      </Container>
    </div>
  );
};

export default Menu;
