import { RefreshCcw } from "lucide-react";
import ManageHeader from "../../components/ManageHeader";

const Renovations = () => {
    
  return (
    <section>
        <ManageHeader
                title="Rénovations et fermetures"
                content="Si vous devez fermer définitivement votre établissement, veuillez."
                icon={<RefreshCcw size={25} strokeWidth={1.5} />}
            />
        <div className="container my-4">
          Renovations
        </div>
    </section>
  )
}

export default Renovations
