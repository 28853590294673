import React, { useState } from 'react';
import { Panel, PanelGroup } from 'rsuite';
import { Row, Col } from 'react-bootstrap';
import CheckInput from '../../../components/forms/CheckInput';
import DateInput from '../../../components/forms/DateInput';
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/Select';

function Selectfees({ t }) {
    const [activeKey, setActiveKey] = useState(null);
    const [selectStatus, setSelectStatus] = useState({
        roomCol1: false,
        roomCol2: false,
        roomCol3: false,
    });

    const handleSelect = (eventKey, event) => {
        event.preventDefault();
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    const handleSelectAll = (colId) => {
        const newSelectStatus = { ...selectStatus, [colId]: !selectStatus[colId] };
        setSelectStatus(newSelectStatus);
    };

    const renderRoomPanel = (title, idPrefix) => {
        const options = [
            "Breakfast Included (S)",
            "Full Board (S)",
            "Half Board (S)",
            "Breakfast Included (P)",
            "Full Board (P)",
            "Half Board (P)"
        ];

        return (
            <Col md="4" xl="4" className='mb-3' id={idPrefix}>
                <span className='fs-4 fw-bold text-dark'>{title}</span>

                <div className='my-3 text-blue' onClick={() => handleSelectAll(idPrefix)}>
                    <CheckInput id={`${idPrefix}_select_all`} label={selectStatus[idPrefix] ? t('deselect_all') : t('select_all')} />
                </div>

                {options.map(option => (
                    <div key={option} className='my-3'>
                        <CheckInput
                            id={`${idPrefix}_${option}`}
                            label={option}
                            checked={selectStatus[idPrefix]}
                            onChange={() => handleSelectAll(idPrefix)}
                        />
                    </div>
                ))}
            </Col>
        );
    };

    const ages = [
        { title: t('adult'), value: "adult" },
        { title: t('child'), value: "child" },
        { title: t('baby'), value: "baby" },
    ];

    const extra = [
        { title: t('first'), value: "Premier" },
        { title: t('second'), value: "Deuxième" },
        { title: t('third'), value: "Troisième" },
        { title: t('fourth'), value: "Quatrième" },
        { title: t('fifth'), value: "Cinquième" },
        { title: t('sixth'), value: "Sixième" },
        { title: t('seventh'), value: "Septième" },
        { title: t('eighth'), value: "Huitième" },
        { title: t('ninth'), value: "Neuvième" },
        { title: t('tenth'), value: "Dixième" }
    ];

    return (
        <PanelGroup accordion defaultActiveKey={1} bordered onSelect={handleSelect}>
            <Panel header={t('ho_act_additionel')} className='fw-bolder' eventKey={1}>
                <span className='text-black fw-medium fs-6'>1. {t('ho_act_newFeesfixer')}</span>
                <Row>
                    <Col md="12" xl="12" className='bg-white my-3'>
                        <span className='fs-6 fw-medium text-blue'>{t('ho_act_newFeesCreer')}</span>
                    </Col>
                    <Col md="12" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesDes')}</span>
                        <Input type="text" />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesTranche')}</span>
                        <Select data={ages} />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesgest')}</span>
                        <Select data={extra} />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesTTC')}</span>
                        <Input type="text" />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesperiode')}</span>
                        <DateInput />
                    </Col>
                    <span className='text-black fw-medium fs-6'>2. {t('ho_act_chambres')}</span>
                    <Col md="12" xl="12" className='d-flex flex-column bg-white'>
                        <span className='mt-1 mb-4'>{t('ho_act_chambreTxt')}</span>
                        <Row className='d-flex flex-row'>
                            {renderRoomPanel('Standard double room', 'roomCol1')}
                            {renderRoomPanel('Single room', 'roomCol2')}
                            {renderRoomPanel('Suite', 'roomCol3')}
                        </Row>
                    </Col>
                </Row>
            </Panel>

            <Panel header={t('ho_act_frais_parPerson')} className='fw-bolder' eventKey={2}>
                <span className='text-black fw-medium fs-6'>1. {t('ho_act_configurer')}</span>
                <Row>
                    <Col md="12" className='bg-white my-3'>
                        <span className='fs-6 fw-medium text-blue'>{t('ho_act_newperiod')}</span>
                    </Col>
                    <Col md="12" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesDes')}</span>
                        <Input type="text" />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesTranche')}</span>
                        <Select data={ages} />
                    </Col>

                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_tarif_nuit')} (EUR)</span>
                        <Input type="text" />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_sejour')} (EUR)</span>
                        <Input type="text" />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesperiode')}</span>
                        <DateInput />
                    </Col>
                    <span className='text-black fw-medium fs-6'>2. {t('ho_act_chambres')}</span>
                    <Col md="12" xl="12" className='d-flex flex-column bg-white'>
                        <span className='mt-1 mb-4'>{t('ho_act_chambreTxt')}</span>
                        <Row className='d-flex flex-row'>
                            {renderRoomPanel('Standard double room', 'roomCol1')}
                            {renderRoomPanel('Single room', 'roomCol2')}
                            {renderRoomPanel('Suite', 'roomCol3')}
                        </Row>
                    </Col>
                </Row>
            </Panel>

            <Panel header={t('fees_per_bedroom')} className='fw-bolder' eventKey={3}>
                <span className='text-black fw-medium fs-6'>1. {t('ho_act_setupfees')}</span>
                <Row>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_tarif_nuit')}</span>
                        <Input type="text" />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_fixes')}</span>
                        <Input type="text" />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesTranche')}</span>
                        <Select data={ages} />
                    </Col>
                    <Col md="6" className='d-flex flex-column bg-white my-3'>
                        <span className='my-2'>{t('ho_act_newFeesperiode')}</span>
                        <DateInput />
                    </Col>
                    <span className='text-black fw-medium fs-6'>2. {t('ho_act_chambres')}</span>
                    <Col md="12" xl="12" className='d-flex flex-column bg-white'>
                        <span className='mt-1 mb-4'>{t('ho_act_chambreTxt')}</span>
                        <Row className='d-flex flex-row'>
                            {renderRoomPanel('Standard double room', 'roomCol1')}
                            {renderRoomPanel('Single room', 'roomCol2')}
                            {renderRoomPanel('Suite', 'roomCol3')}
                        </Row>
                    </Col>
                </Row>
            </Panel>
        </PanelGroup>
    );
}

export default Selectfees;
