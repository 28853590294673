import Home from "./Home";
import Operations from "./Operations";

function Main(props) {
  const components = {
    home: <Home />,
    hosting: <Operations />
  };

  const Component = components[props.component];

  return Component || null;
}

export default Main;
