import RadioInput from "../../../components/forms/RadioInput";
import CheckInput from "../../../components/forms/CheckInput";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const popover = [
  
  

  <Popover id="popover-basic">
    <Popover.Header>Si les taxes sont comprises dans votre tarif, cela ressemblera à :</Popover.Header>
    <Popover.Body>
      <div className="d-flex justify-content-between bg-dark text-white px-1">
        <span>Tarif indiqué</span> 100 USD
      </div>
      <div className="d-flex justify-content-between bg-dark text-white px-1">
        <span>Taxes et frais</span> Inclus
      </div>
      <div className="d-flex justify-content-between">
        <span>Le voyageur paie</span> 100 USD
      </div>
    </Popover.Body>
  </Popover>,
  <Popover id="popover-basic">
    <Popover.Header>Si les taxes ne sont pas comprises dans votre tarif, cela ressemblera à :</Popover.Header>
    <Popover.Body>
      <div className="d-flex justify-content-between bg-dark text-white px-1">
        <span>Tarif indiqué</span> 80 USD
      </div>
      <div className="d-flex justify-content-between bg-dark text-white px-1">
        <span>Taxes et frais</span> 20 USD
      </div>
      <div className="d-flex justify-content-between">
        <span>Le voyageur paie</span> 100 USD
      </div>
    </Popover.Body>
  </Popover>,
];

const TaxesDetails = () => {
  const { t } = useTranslation();
  return (
    <div className="my-4">
      <span className="fs-5 fw-medium text-dark">{t('taxes_and_fees')}</span>
      <div className="my-3">
        <p className="fs-6 fw-medium text-dark mt-3">{t('room_taxes_included')}</p>
        <ul className="fs-6">
          <li className="my-3">{t('hotel_tva')}</li>
          <li className="my-3">{t('tourism_taxe')}</li>
        </ul>
      </div>

      <div className="fs-6 my-4">
        <div className="d-flex align-items-center">
          <RadioInput id="TaxesComprises" label={t('price_included_taxe')} name="TaxesType" />
          <OverlayTrigger trigger="hover" placement="top" overlay={popover[0]}>
            <span role="button" className="text-blue mx-2">
              {t('see_exemple')}
            </span>
          </OverlayTrigger>
        </div>

        <div className="d-flex align-items-center">
          <RadioInput id="TaxesNonComprises" label={t('include_taxe_to_price')} name="TaxesType" />
          <OverlayTrigger trigger="hover" placement="top" overlay={popover[1]}>
            <span role="button" className="text-blue mx-2">
            {t('see_exemple')}
            </span>
          </OverlayTrigger>
        </div>
      </div>

      <div className="my-3">
        <p className="fs-6 fw-medium text-dark mt-3">{t('taxes_visible_to_clients')}</p>
        <ul className="fs-6">
          <li className="my-3">{t('taxes_visible_to_clients')} DZD 50 {t('stay_taxe')}</li>
        </ul>
      </div>

      <div className="my-3 fs-6">
        <p>{t('not_taxes_for_stay')}</p>
        <div className="my-3">
          <CheckInput id="HelpTeam" label={t('ask_help_for_taxes_team')} />
        </div>
      </div>
    </div>
  );
};

export default TaxesDetails;
