import React from 'react'
import { Col, Row } from 'react-bootstrap'
import img1 from "../../assets/icones/undraw_travel_booking_re_6umu.svg";
import img2 from "../../assets/icones/undraw_trip_re_f724.svg";
import img3 from "../../assets/icones/undraw_remotely_-2-j6y.svg";

const Prologue = ({ t, onStart, isLoading }) => {

    const data = [
        { id: 1, title: t('step1_title'), content: t('step1_text'), img: img1 },
        { id: 2, title: t('step2_title'), content: t('step2_text'), img: img2 },
        { id: 3, title: t('step3_title'), content: t('step3_text'), img: img3 },
    ]
    return (
        <>
            <div className="container my-5">
                <Row className='justify-content-between align-items-start'>
                    <Col xl="6" className='my-1'>
                        <div className='fs-1 text-dark fw-bold' dangerouslySetInnerHTML={{ __html: t('prologue_tilte') }}>
                        </div>
                        <div className='fs-3 fw-light text-dark my-3' dangerouslySetInnerHTML={{ __html: t('prologue_subtitle') }}></div>
                        <p className='my-3 fs-5 fw-light' dangerouslySetInnerHTML={{ __html: t('prologue_content') }}></p>
                    </Col>
                    <Col xl="5" className='my-1'>
                        <div>
                            {data.map(item => (
                                <Row className='align-items-center'>
                                    <Col xs="10" className='my-3'>
                                        <div key={item.id} className='d-flex'>
                                            <span style={{ width: "40px", height: "40px" }} className='d-flex align-items-center justify-content-center border p-4 rounded-circle fs-5 text-dark fw-medium'>{item.id}</span>
                                            <span className='mx-3'>
                                                <div className='fs-5 fw-medium text-dark'>{item.title}</div>
                                                <div className='fs-6 fw-light mt-2'>{item.content}</div>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs="2" className='my-3'>
                                        <img src={item.img} width="90" height="90" alt={`Illustration de l'étape ${item.id}`} />
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-start mt-3'>
                    <Col xs="4" md="3" xl="2">
                        <a href="/product/hosting" className='btn-blue p-3 rounded-5 w-100 text-center text-white'>{t('start')}</a>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Prologue
