import { Row, Col } from "react-bootstrap";
import TextArea from "../../components/forms/Textarea";
import { useState } from "react";
import HighlightInput from "../../components/forms/Highlights";
import { useTranslation } from "react-i18next";

const Description = (props) => {

  const { t } = useTranslation();

  const [intro, setIntro] = useState("");
  const [description, setDescription] = useState("");

  const handleIntro = (event) => {
    setIntro(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  return (
    <section className="bg-white form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg={`${props.lg ? props.lg : "7"}`} className="my-3">
            <>
              <h2 className="fs-2 lh-base fw-medium text-dark">{t('description_title')}</h2>
              <p className="fs-5 fw-light">{t('description_caption')}</p>

              <div className="my-4">
                <>
                  <div className="fs-5 fw-medium text-dark">{t('introduce_activity')}</div>
                  <p className="fs-6">{t('introduce_activity_caption')}</p>
                  <div className="mt-3">
                    <TextArea value={intro} handleChange={handleIntro} rows={4} maxlength={32} />
                  </div>
                  <div className="text-secondary fw-bold">{t('min_of')} {32 - intro.length} {t('chars_left')}</div>
                </>
              </div>

              <div className="my-4">
                <>
                  <div className="fs-5 fw-medium text-dark">{t('add_detailed_description')}</div>
                  <p className="fs-6">{t('add_detailed_description_caption')}</p>
                  <div className="mt-3">
                    <TextArea value={description} handleChange={handleDescription} rows={12} maxlength={800} />
                  </div>
                  <div className="text-secondary fw-bold">{t('min_of')} {500 - description.length} {t('chars_left')}</div>
                </>
              </div>

              <div className="my-4">
                <>
                  <div className="fs-5 fw-medium text-dark">{t('resume_highlights')}</div>
                  <p className="fs-6">{t('resume_highlights_caption')}</p>
                  <HighlightInput />
                </>
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Description;
