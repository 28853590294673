import "./App.css";
import Header from "./layout/Header";
import Routes from "./Routes";
import "rsuite/dist/rsuite.min.css";
import { useState, useEffect } from "react";
import Landing from "./pages/landing/Landing";
import Loader from "./layout/Loader";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    if (loggedInStatus === "true") {
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  }, []);

  const handleLogin = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoggedIn(true);
      setIsLoading(false);
      localStorage.setItem("isLoggedIn", "true");
    }, 3000);
  };

  const handleLogout = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoggedIn(false);
      setIsLoading(false);
      localStorage.removeItem("isLoggedIn");
    }, 3000);
  };

  return (
    <div className="main">
      {isLoading ? (
        <Loader />
      ) : isLoggedIn ? (
        <>
          <Header handleLogout={handleLogout} />
          <Routes />
        </>
      ) : (
        <Landing handleLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
