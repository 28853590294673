import React from 'react'
import CheckInput from '../../../components/forms/CheckInput'
import Select from '../../../components/forms/Select'
import Input from '../../../components/forms/Input'
import { Col, Row } from 'react-bootstrap'
import { useState } from 'react'
import RadioInput from '../../../components/forms/RadioInput'
import { useTranslation } from "react-i18next";

const Cleaning = () => {
    const { t } = useTranslation();
    const [infos, setInfos] = useState({
        cleaning: false,
        fees: t('amount'),

    });

    const handleFees = (value) => {
        setInfos({ ...infos, fees: value });
    };

    const handleCleaning = () => {
        setInfos({ ...infos, cleaning: !infos.cleaning });
    };

    const data = [
        { title: t('per_night'), value: "ParNuit" },
        { title: t('per_stay'), value: "ParSejour" },
        { title: t('per_week'), value: "ParSemaine" },
    ]

    const fees = [
        { title: t('per_person'), value: "ParPersonne" },
        { title: t('per_accommodation'), value: "ParHébérgement" },
    ]

    return (
        <div className='fs-6'>
            <CheckInput id="FraisMénage" label={t('cleaning_fee')} onChange={handleCleaning} />
            {infos.cleaning && (
                <>
                    <div className="d-flex fs-6">
                        <div className="mx-2">
                            <RadioInput name="FraisMénage" label={t('amount')} id="Montant" value="Montant" onChange={() => handleFees(t('amount'))} />
                        </div>

                        <div className="mx-2">
                            <RadioInput name="FraisMénage" label={t('percentage')} id="Pourcentage" value="Pourcentage" onChange={() => handleFees(t('percentage'))} />
                        </div>
                    </div>

                    <Row>
                        <Col md="4" className='my-3'>
                            <Input placeholder={`${infos.fees === t('percentage') ? t('amount') : 'Pourcentage (%)'}`} type="number" />
                        </Col>
                        <Col md="4" className='my-3'>
                            <Select data={data} />
                        </Col>

                        <Col md="4" className='my-3'>
                            <Select data={fees} />
                        </Col>
                    </Row>

                    <div className='fs-6'>
                        <div className='my-3'>
                            <CheckInput id="stay" label={t('stay_duration_cleaning_fees')} />
                        </div>
                        <div className='my-3'>
                            <CheckInput id="rooms" label={t('stay_unit_cleaning_fees')} />
                        </div>
                        
                    </div>
                </>
            )}

        </div>
    )
}

export default Cleaning