import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Hotel from "../../assets/slide/ka.jpg";
import Hotel2 from "../../assets/slide/2.jpg";
import { Cigarette, BedDouble, User, Bed } from "lucide-react";
import Tarif from "./components/Tarif";
import Draggable from "react-draggable";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import ManageHeader from "../../components/ManageHeader";

const RoomType = ({ t }) => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleDeactivateClick = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmDeactivate = () => {
    setTimeout(() => {
      setShowModal(false);
      setShowSuccessMessage(true);
    }, 800);
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  return (
    <section>
      <ManageHeader title={t('ho_act_type')} content={t('ho_act_typeP')} icon={<Bed size={25} strokeWidth={1.5} />} />

      <div className="container my-4">
        <Row>
          <Col lg="10" xl="12" className="p-1">
            <div className="my-2 bg-white p-2">
              <div className="bg-white rounded-2 border recommendations-item">
                <Row>
                  <Col xl="3">
                    <div className="h-100 p-1">
                      <img className="rounded" alt="myhotel" src="https://images.pexels.com/photos/2598638/pexels-photo-2598638.jpeg?auto=compress&cs=tinysrgb&w=600" height="100%" width="100%" />
                    </div>
                  </Col>
                  <Col xl="6" className="mt-2 d-flex align-items-center">
                    <div className="w-100 px-3 py-3 pb-3">
                      <div className="w-100 title-blue fw-bold my-2 fs-5">Classic Room with Twin Beds</div>
                      <div className="w-100 d-flex align-items-center my-2 ">
                        <div className="w-100 d-flex flex-column justify-content-around">
                          <div className="mt-1 d-flex flex-row justify-content-between">
                            <span className="">{t('ho_act_RoomNumber')} :</span>
                            <b className="">102377512</b>
                          </div>
                          <div className="mt-1 d-flex flex-row justify-content-between">
                            <span className="">{t('ho_act_segmentType')} :</span>
                            <b className="">Classic Twin Room</b>
                          </div>
                          <div className="mt-1 d-flex flex-row justify-content-between">
                            <span className="">{t('ho_act_best')} :</span>
                            <b className="">100 EUR</b>
                          </div>
                        </div>
                      </div>
                      <div className="pt-2">
                        <Link to="../updatecancel" className="link-style me-3">
                          {t('ho_act_update')}
                        </Link>
                        <a href="true" className="mx-1" onClick={handleDeactivateClick}>
                          {t('disable')}
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" className="mt-4 ">
                    <div className="border-start d-flex flex-column align-items-left justify-content-between py-4 ">
                      <span className="mt-4 fw-bold">
                        <Cigarette className="mx-3" strokeWidth={1.4} /> Smoking permitted{" "}
                      </span>
                      <span className="mt-1 fw-bold">
                        <BedDouble className="mx-3" strokeWidth={1.5} /> 2 single beds{" "}
                      </span>
                      <span className="mt-1 fw-bold">
                        <User className="mx-3" strokeWidth={1.5} /> Capacity 1 adult
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <Tarif />
            </div>
          </Col>
        </Row>
      </div>
      <div className="container">
        <Row>
          <Col lg="10" xl="12" className="p-1">
            <div className="my-2 bg-white p-2">
              <div className="bg-white rounded-2 border recommendations-item">
                <Row>
                  <Col xl="3">
                    <div className="h-100 p-1">
                      <img className="rounded" alt="myhotel" src={Hotel} height="100%" width="100%" />
                    </div>
                  </Col>
                  <Col xl="6" className="mt-2 d-flex align-items-center">
                    <div className="w-100 px-3 py-3 pb-3">
                      <div className="w-100 title-blue fw-bold my-2 fs-5">Classic Room with Twin Beds</div>
                      <div className="w-100 d-flex align-items-center my-2 ">
                        <div className="w-100 d-flex flex-column justify-content-around">
                          <div className="mt-1 d-flex flex-row justify-content-between">
                            <span className="">{t('ho_act_RoomNumber')} :</span>
                            <b className="">102377512</b>
                          </div>
                          <div className="mt-1 d-flex flex-row justify-content-between">
                            <span className="">{t('ho_act_segmentType')} :</span>
                            <b className="">Classic Twin Room</b>
                          </div>
                          <div className="mt-1 d-flex flex-row justify-content-between">
                            <span className="">{t('ho_act_best')} :</span>
                            <b className="">100 EUR</b>
                          </div>
                        </div>
                      </div>
                      <div className="pt-2">
                        <Link to="../updatecancel" className="link-style me-3">
                          {t('ho_act_update')}
                        </Link>
                        <a href="true" className="mx-1" onClick={handleDeactivateClick}>
                          {t('disable')}
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" className="mt-4 ">
                    <div className="border-start d-flex flex-column align-items-left justify-content-between py-4 ">
                      <span className="mt-4 fw-bold">
                        <Cigarette className="mx-3" strokeWidth={1.4} /> Smoking permitted{" "}
                      </span>
                      <span className="mt-1 fw-bold">
                        <BedDouble className="mx-3" strokeWidth={1.5} /> 2 single beds{" "}
                      </span>
                      <span className="mt-1 fw-bold">
                        <User className="mx-3" strokeWidth={1.5} /> Capacity 1 adult
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <Tarif />
            </div>
          </Col>
        </Row>
      </div>
      <div className="container">
        <Row>
          <Col lg="10" xl="12" className="p-1">
            <div className="my-2 bg-white p-2">
              <div className="bg-white rounded-2 border recommendations-item">
                <Row>
                  <Col xl="3">
                    <div className="h-100 p-1">
                      <img className="rounded" alt="myhotel" src={Hotel2} height="100%" width="100%" />
                    </div>
                  </Col>
                  <Col xl="6" className="mt-2 d-flex align-items-center">
                    <div className="w-100 px-3 py-3 pb-3">
                      <div className="w-100 title-blue fw-bold my-2 fs-5">Classic Room with Twin Beds</div>
                      <div className="w-100 d-flex align-items-center my-2 ">
                        <div className="w-100 d-flex flex-column justify-content-around">
                          <div className="mt-1 d-flex flex-row justify-content-between">
                            <span className="">{t('ho_act_RoomNumber')} :</span>
                            <b className="">102377512</b>
                          </div>
                          <div className="mt-1 d-flex flex-row justify-content-between">
                            <span className="">{t('ho_act_segmentType')} :</span>
                            <b className="">Classic Twin Room</b>
                          </div>
                          <div className="mt-1 d-flex flex-row justify-content-between">
                            <span className="">{t('ho_act_best')} :</span>
                            <b className="">100 EUR</b>
                          </div>
                        </div>
                      </div>
                      <div className="pt-2">
                        <Link to="../updatecancel" className="link-style me-3">
                          {t('ho_act_update')}
                        </Link>
                        <a href="true" className="mx-1" onClick={handleDeactivateClick}>
                          {t('disable')}
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" className="mt-4 ">
                    <div className="border-start d-flex flex-column align-items-left justify-content-between py-4 ">
                      <span className="mt-4 fw-bold">
                        <Cigarette className="mx-3" strokeWidth={1.4} /> Smoking permitted{" "}
                      </span>
                      <span className="mt-1 fw-bold">
                        <BedDouble className="mx-3" strokeWidth={1.5} /> 2 single beds{" "}
                      </span>
                      <span className="mt-1 fw-bold">
                        <User className="mx-3" strokeWidth={1.5} /> Capacity 1 adult
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <Tarif t={t} />
            </div>
          </Col>
        </Row>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} className="rounded-top border p-2">
        <Draggable>
          <div>
            <Modal.Header className="bg-white" closeButton>
              <Modal.Title className="text-modal fw-bolder"> {t('disable_the_room')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-white text-modal d-flex flex-column">
              <Row className="py-3">
                <Col className="d-flex">
                  <span className="fs-4 fw-medium text-dark">{t('disable_room_txt')}</span>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-white">
              <Button variant="warning" onClick={handleCloseModal}>
                {t('cancel')}
              </Button>
              <Button variant="primary" onClick={handleConfirmDeactivate}>
                {t('disable')}
              </Button>
            </Modal.Footer>
          </div>
        </Draggable>
      </Modal>
      <Modal show={showSuccessMessage} onHide={handleCloseSuccessMessage} backdrop="static" className="rounded-top border p-2">
        <Modal.Body className="bg-white text-white d-flex flex-column">
          <Row className="py-3">
            <Col className="d-flex flex-row">
              <AiOutlineCheckCircle style={{ color: "#008000", fontSize: "28px" }} />
              <span className="fs-5 fw-medium ps-3" style={{ color: "#008000" }}>
                {t('disabled_txt')}
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <Button variant="light" onClick={handleCloseSuccessMessage}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default RoomType;
