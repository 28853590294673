import Choice from "../../../components/Choice";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import ExtraCharge from "../../../components/ExtraCharge";
import Input from "../../../components/forms/Input";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";


const AnimalsOptions = () => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState({
    fees: "no",
    amount: false,
  });


  const handleFees = (value) => {
    setInfos({ ...infos, fees: value });
  };

  const handleAmount = () => {
    setInfos({ ...infos, amount: !infos.amount });
  };

  const animals = [
    { title: t('only_pets'), value: "ChiensSeulement" },
    { title: t('only_cats'), value: "ChatsSeulement" },
    { title: t('only_cats_pets'), value: "ChiensEtChats" },
  ];

    const extra = [
    { title: t('per_animal'), value: "animal" },
    { title: t('per_accommodation'), value: "hébérgement" },
  ];


  return (
    <div className="my-3 fs-6">
      <>
        <Choice text={t('do_you_charges_for_animals')} onChange={handleFees} selectedChoice={infos.fees} />

        {infos.fees === "yes" && (
          <>
            <Row className="align-items-center">
              <Col xl="9">
                <ExtraCharge />
              </Col>
              <Col xl="3">
                <Select data={extra} />
              </Col>
            </Row>
            <div className="px-3">
              <div className="my-3">
                <CheckInput id="MontantMaximumParSéjour" label={t('max_amount_per_stay')} onChange={handleAmount} />
              </div>

              {infos.amount && (
                <Row className="fs-6 align-items-center">
                  <Col md="6" className="my-3">
                    <span className="fw-medium text-dark">{t('cost')}</span>
                  </Col>
                  <Col md="6" className="my-3">
                    <Input placeholder={t('amount_and_taxes')} />
                  </Col>
                </Row>
              )}

              <div className="my-3">
                <CheckInput id="FraisAnimauxVariables" label={t('animals_fees_per_stay_duration')} />
              </div>
            </div>
          </>
        )}

        <Row className="fs-6 align-items-center">
          <Col md="6" className="d-flex align-items-center my-3">
            <span className="fw-medium text-dark">{t('do_you_have_animals_restrictions')}</span>
          </Col>
          <Col md="6" className="d-flex align-items-center my-3">
            <Select data={animals} />
          </Col>
        </Row>
      </>
    </div>
  );
};

export default AnimalsOptions;
