import React from "react";
import { Row, Col } from "react-bootstrap";
import { Badge } from "rsuite";
import { ArrowUp } from "lucide-react";
import { FaUserAlt } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa";
import { FiActivity } from "react-icons/fi";
import { LuBadgeCheck } from "react-icons/lu";
import { MdOutlineNightsStay } from "react-icons/md";
import { FaChartLine } from "react-icons/fa6";
import { SiMoneygram } from "react-icons/si";
import { IoMdLogOut } from "react-icons/io";
import { Button } from "rsuite";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

const Adjustedrate = () => {

  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleCloseSecondModal = () => setShowSecondModal(false);

  const handleShowModal = () => setShowModal(true);

  const handleOpenModal = () => setShowModal(true);
  const handleOpenSecondModal = () => setShowSecondModal(true);
  const handleShowSecondModal = () => {
    handleCloseModal();
    setShowSecondModal(true);
  };

  return (
    <section className="container">
      <div className="bg-white p-5">
        <Row className="border rounded">
          <Col md="6" lg="12" className="p-3">
            <div className="p-2">
              <div className="backimgcontent">
                <Badge color="orange" content="Benefits"></Badge>
                <div className="fs-5 fw-bold text-dark mt-2 mb-3">{t('ho_act_tarification_auto_MesTarifs')}</div>
                <div>
                  <span className="" style={{ color: "#333333" }}>
                    {t('ho_act_tarification_auto_MesTarifs_txt')}
                  </span>
                </div>
                <span className="fs-8 fw-bold text-dark" to="#">
                  <div className="mt-3 d-flex justify-content-between">
                    {t('ho_act_tarification_auto_MesTarifs_txt1')}
                    <span className="text-orange d-flex justify-content-end">
                      <LuBadgeCheck className="fs-6" />
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="px-5">
        <Row className="">
          <Col md="6" lg="3" className="my-2">
            <div className="bg-white border rounded p-3 h-100">
              <div className="d-flex justify-content-between">
                <div className="">
                  <div className="fs-5">{t('ho_act_tarification_auto_checks')}</div>
                  <div className="fs-5 fw-bold text-dark my-2">{t('registred')}</div>
                </div>
                <div>
                  <FaUserAlt className="mt-4" style={{ width: "50px", height: "50px", color: "gainsboro" }} />
                </div>
              </div>
            </div>
          </Col>
          <Col md="6" lg="3" className="my-2">
            <div className="bg-white border rounded p-3 h-100">
              <div className="d-flex justify-content-between">
                <div className="">
                  <div className="fs-5">{t('ho_act_tarification_starting')}</div>
                  <div className="fs-5 fw-bold text-dark my-2">07/10/2023</div>
                </div>
                <div>
                  <FaCalendar className="pt-4" style={{ width: "74px", height: "74px", color: "lightblue" }} />
                </div>
              </div>
            </div>
          </Col>
          <Col md="6" lg="6" className="my-2">
            <div className="bg-white border rounded p-3 h-100">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="fs-5">{t('ho_act_tarification_auto_avantages')}</div>
                  <div className="fs-7 fw-bold text-dark my-2">{t('ho_act_tarification_auto_avantagesTxt')}</div>
                </div>
                <div>
                  <FiActivity className="" style={{ width: "50px", height: "50px", color: "ButtonHighlight" }} />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span className="text-orange">
                  <ArrowUp /> 25%
                </span>
                <span className="ms-4">
                  <Badge className="p-1" color="green" content={t('heighten_presence')}></Badge>
                </span>
              </div>
            </div>
          </Col>
          <Col md="" lg="3" className="my-2">
            <div className="bg-white border rounded p-3 h-100">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="fs-5">{t('ho_act_tarification_auto_secu')}</div>
                  <div className="fs-7 fw-bold text-dark my-2">{t('ho_act_tarification_auto_secuTxt')}</div>
                </div>
                <div>
                  <FaChartLine className="text-green" style={{ width: "40px", height: "40px" }} />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between mt-2">
                <span className="text-orange">
                  <ArrowUp />
                  66%
                </span>
              </div>
            </div>
          </Col>
          <Col md="" lg="3" className="my-2">
            <div className="bg-white border rounded p-3 h-100">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="fs-5">{t('ho_act_tarification_auto_nbrNuits')}</div>
                  <div className="fs-7 fw-bold text-dark my-2">{t('ho_act_tarification_auto_nbrTxt')}</div>
                </div>
                <div>
                  <MdOutlineNightsStay style={{ width: "50px", height: "50px", color: "ButtonHighlight" }} />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span className="text-orange">
                  <ArrowUp />
                  93%
                </span>
              </div>
            </div>
          </Col>
          <Col md="" lg="3" className="my-2">
            <div className="bg-white border rounded p-3 h-100">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="fs-5">{t('ho_act_tarification_auto_nbrNuits')}</div>
                  <div className="fs-7 fw-bold text-dark my-2">{t('ho_act_tarification_auto_nbrNuits')}</div>
                </div>
                <div>
                  <SiMoneygram className="text-blue" style={{ width: "34px", height: "34px" }} />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span className="text-orange">
                  <ArrowUp /> 86%
                </span>
              </div>
            </div>
          </Col>
          <Col md="" lg="3" className="my-2">
            <div className="bg-white border rounded p-3 h-100">
              <div className="d-flex justify-content-between mb-1">
                <div>
                  <div className="fs-5">{t('ho_act_tarification_auto_offer')}</div>
                  <div className="fs-7 fw-bold text-dark my-2">{t('ho_act_tarification_auto_offerTxt')}</div>
                </div>
                <div>
                  <IoMdLogOut style={{ width: "40px", height: "40px", color: "burlywood" }} />
                </div>
              </div>
              <div className="text-right mt-auto d-flex justify-content-end">
                <Button appearance="primary" color="orange" onClick={handleOpenModal}>
                {t('unsubscribe')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Modal show={showModal} onHide={handleCloseModal} centered className="rounded-2 p-2">
          <Draggable>
            <div className="border rounded bg-white">
              <Modal.Header closeButton className="modal-header">
                <Modal.Title className="text-modal">{t('ho_act_tarification_auto_sure')}</Modal.Title>
              </Modal.Header>

              <Modal.Body className="bg-white text-modal d-flex flex-column">
                <p>{t('tarifcation_auto_txt')}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button color="cyan" appearance="primary" onClick={handleShowSecondModal} className="me-2">
                  {t('unsubscribe')}
                </Button>
                <Button color="orange" appearance="primary" onClick={handleCloseModal}>
                  {t('cancel')}
                </Button>
              </Modal.Footer>
            </div>
          </Draggable>
        </Modal>
        <Modal show={showSecondModal} onHide={handleCloseSecondModal} centered className="rounded-2 p-2">
          <Draggable>
            <div className="border rounded bg-white">
              <Modal.Header closeButton className="modal-header">
                <Modal.Title className="text-modal">{t('ho_act_tarification_auto_confirm')}</Modal.Title>
              </Modal.Header>

              <Modal.Body className="bg-white text-modal d-flex flex-column">
                <p>{t('ho_act_tarification_auto_confirm_txt')}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button color="orange" appearance="primary" onClick={handleCloseSecondModal}>
                  {t('close')}
                </Button>
              </Modal.Footer>
            </div>
          </Draggable>
        </Modal>
      </div>
    </section>
  );
};

export default Adjustedrate;
