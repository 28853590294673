import React, { useState } from "react";
import { Row, Col } from "rsuite";
import { BsBuildingCheck } from "react-icons/bs";
import ManageHeader from "../../components/ManageHeader";
import { Receipt } from "lucide-react";

const Paymentinfos = ({t}) => {
  
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleModifierClick = () => {
    setShowSecondModal(false);
    setShowModal(true);
  };
  const [showThirdModal, setShowThirdModal] = useState(false);

  const handleOpenThirdModal = () => {
    setShowSecondModal(false);
    setShowThirdModal(true);
  };

  const handleOpenDatePicker = () => {
    setShowDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleCloseDatePicker();
  };

  const [showModal, setShowModal] = useState(false);

  const handleConfirmDeactivate = () => {
    setShowModal(false);
    handleShowSuccessMessage();
  };

  const handleShowSuccessMessage = () => {
    setShowSuccessMessage(true);
  };

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleEditButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showSecondModal, setShowSecondModal] = useState(false);
  const handleOpenSecondModal = () => {
    setShowModal(false);
    setShowSecondModal(true);
  };

  const handleCloseSecondModal = () => {
    setShowSecondModal(false);
  };

  const handlePrintButtonClick = () => {
    window.print();
  };
  return (
    <section>
      <ManageHeader title={t('ho_act_infosPaiement')} content={t('ho_act_infosPaiement_txt')} icon={<Receipt size={25} strokeWidth={1.5} />} />

      <div className="container p-4">
        <Row className=" d-flex flex-column">
          <Col className="d-flex flex-row justify-content-between border-bottom ">
            <span className="fs-5 fw-bold mb-2 pb-1 text-dark">{t('ho_act_infosPaiement_group')}</span>
            <span className="fs-5 fw-bold mb-2 text-dark">{t('currency')} DZD </span>
          </Col>
          <Col className="mt-3 fw-bold">
            <BsBuildingCheck className="me-2" />
            <span className="me-5">{t('ho_act_infosPaiement_devise_txt')}</span>
          </Col>
        </Row>
        <Row className="border-bottom">
          <Col className="col-md-6 col-12 d-flex flex-column mt-3 pb-3">
            <span className="mt-3">{t('ho_act_infosPaiement_adresse')}</span>
            <span className="mt-1 text-dark fw-medium">aoubellil@idhtours.com</span>
          </Col>

          <Col className="col-md-6 col-12 d-flex flex-column mt-3">
            <span className="mt-3">{t('ho_act_infosPaiement_modalite')}</span>
            <span className="mt-1 text-dark fw-medium">{t('monthly_payment')}</span>
          </Col>
        </Row>
        <Row className="">
          <Col className="col-md-6 col-12 d-flex flex-column mt-3 pb-3">
            <span className="mt-3">{t('ho_act_infosPaiement_compte')}</span>
            <span className="mt-1 text-dark fw-medium">XXXXXROUP</span>
          </Col>

          <Col className="col-md-6 col-12 d-flex flex-column mt-3">
            <span className="mt-3">{t('ho_act_infosPaiement_banque')}</span>
            <span className="mt-1 text-dark fw-medium">BANQUE INTERNATIONALE ARABE DE TUNISIE</span>
          </Col>
        </Row>
        <Row className="border-bottom">
          <Col className="col-md-6 col-12 d-flex flex-column  pb-3">
            <span className="mt-3">{t('ho_act_infosPaiement_iban')}</span>
            <span className="mt-1 text-dark fw-medium">XXXXXXXXXXXXXXXXXXXX2024</span>
          </Col>
        </Row>
        <Row className="border-bottom">
          <Col className="col-md-6 col-12 d-flex flex-column mt-3 pb-3">
            <span className="mt-3">{t('ho_act_infosPaiement_modepa')}</span>
            <span className="mt-1 text-dark fw-medium">Transfert électronique de fonds</span>
          </Col>

          <Col className="col-md-6 col-12 d-flex flex-column mt-3">
            <span className="mt-3">{t('ho_act_infosPaiement_inclut')}</span>
            <span className="mt-1 text-dark fw-medium">Tarif de base</span>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Paymentinfos;
