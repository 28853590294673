import CheckInput from "../../../components/forms/CheckInput"
import { useTranslation } from "react-i18next";
const ParkingType = () => {
    const { t } = useTranslation();

    const parking_type = [
        { title: t('parking_open'), value: "Couvert" },
        { title: t('parking_close'), value: "NonCouvert" },
        { title: t('parking_secured'), value: "Securisé" },
    ]

    return (
        <div className='fs-6 p-3'>
            {parking_type.map((item, index) => (
                <div key={index} className="my-3">
                    <CheckInput id={item.value} label={item.title} /> 
                </div>
            ))}
        </div>
    )
}

export default ParkingType