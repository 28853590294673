import React from 'react'
import { Rate } from 'antd';
import { Radio } from 'antd';

const Rating = ({ t }) => {

    const stars = [
        0, 1, 2, 3, 4, 5
    ]

    return (
        <>
            <div className='fs-5 fw-medium text-dark'>{t('hotel_rating_title')}</div>
            <Radio.Group name="radiogroup" defaultValue={1}>
                {stars.map(item => (
                    <div className='d-flex flex-column my-3'>
                        <Radio value={item}>
                            {item >= 1 ?
                                <div className='d-flex align-items-center'>
                                    <span className='fw-light fs-6'>{item} {item >= 2 ? t('stars') : t('star')}</span><span className='mx-3'>{<Rate value={item} disabled />}</span>
                                </div>
                                : "N/A"}
                        </Radio>
                    </div>
                ))}
            </Radio.Group>
        </>
    )
}

export default Rating
