import { useState } from "react";
import Choice from "../../../components/Choice";
import { useTranslation } from "react-i18next";

const Transport = (props) => {

    const { t } = useTranslation();

    const items = [
        { title: t('bike'), value: "vélo" },
        { title: t('segway'), value: "segway" },
        { title: t('motorbike'), value: "moto" },
        { title: t('car'), value: "voiture" },
        { title: t('suv'), value: "suv" },
        { title: t('van'), value: "fourgonnette" },
        { title: t('bus'), value: "bus / autocar" },
        { title: t('electric_car'), value: "voiture électrique" },
        { title: t('sailing_ship'), value: "voilier" },
        { title: t('jet_ski'), value: "jet ski" },
        { title: t('kayak'), value: "kayak" },
        { title: t('raft'), value: "radeau" },
        { title: t('yacht'), value: "yacht" },
        { title: t('canoe'), value: "canoë" },
        { title: t('surf_board'), value: "planche de surf" },
        { title: t('sub_marin'), value: "sous-marin" },
        { title: "Banana boat", value: "banana boat" },
        { title: t('hydravion'), value: "hydravion" },
        { title: t('train'), value: "train" },
        { title: t('tram'), value: "tramway" },
        { title: t('metro'), value: "métro" },
        { title: t('helicopter'), value: "hélicoptère" },
        { title: t('plane'), value: "avion" },
        { title: t('balloon'), value: "montgolfière" },
        { title: t('common_transport'), value: "transport en commun" },
        { title: t('cable_car'), value: "téléphérique" },
        { title: t('quad'), value: "quad" },
        { title: t('carriage'), value: "calèche" },
        { title: t('horse'), value: "cheval" },
        { title: t('camel'), value: "chameau" },
        { title: t('snow_mobil'), value: "motoneige" },
        { title: t('walk'), value: "à pied" },

    ];

    const [infos, setInfos] = useState({
        restrictions: false,
        transport: "yes",
        elements: [],
        options: items,
        selected: "",
    });

    const handleTransport = (value) => {
        setInfos({ ...infos, transport: value });
    };

    const handleSelectChange = (event) => {
        const value = event.target.value;

        if (!infos.elements.map((opt) => opt.value).includes(value)) {
            const selectedOption = infos.options.find((option) => option.value === value);
            setInfos((prevState) => ({
                ...prevState,
                elements: [...prevState.elements, selectedOption],
                options: prevState.options.filter((option) => option.value !== value),
            }));
        }
    };

    const handleDelete = (value) => {
        setInfos((prevState) => ({
            ...prevState,
            elements: prevState.elements.filter((item) => item.value !== value.value),
            options: [...prevState.options, value],
        }));
    };

    return (
        <>

            <Choice
                text={<div className="fs-5 lh-base fw-medium text-dark">{t('activity2Transport')}</div>}
                selectedChoice={infos.transport}
                onChange={handleTransport}
            />
            {infos.transport === "yes" && (
                <div className="mt-5 fs-6">
                    <>
                        <span className="text-blue">{t('add_transport')}</span>
                        <select value={infos.selected} onChange={handleSelectChange} className="input-blue w-100 p-4 border my-2">
                            <option>-- {t('select_option')} --</option>
                            {infos.options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.title}
                                </option>
                            ))}
                        </select>

                        <div className="my-3">
                            <div className="border-start p-3">
                                {infos.elements.map((item) => (
                                    <div key={item.value} className="d-flex align-items-center m-2 border p-3">
                                        <span className="fs-5 fw-medium text-dark">{item.title}</span>
                                        <span role="button" onClick={() => handleDelete(item)} className="text-danger mx-2">
                                            {t('delete')}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                </div>
            )}
        </>
    )
}

export default Transport
