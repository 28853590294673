import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Row, Col } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import Input from "../../../components/forms/Input";
import TableItem from "./TableItem";

const Bookings = ({ t }) => {


    const data = [{
        "id": 1,
        "number": "0849169577",
        "date": "12/14/2022",
        "location": "Room 71",
        "client": "Damon Baynom",
        "age": 39,
        "mail": "dbaynom0@oaic.gov.au",
        "phone": "659-485-8044",
        "status": "waiting"
    }, {
        "id": 2,
        "number": "8787207451",
        "date": "9/13/2022",
        "location": "11th Floor",
        "client": "Janel Fallen",
        "age": 49,
        "mail": "jfallen1@pen.io",
        "phone": "114-442-8223",
        "status": "cancelled"
    }, {
        "id": 3,
        "number": "5338178001",
        "date": "12/22/2022",
        "location": "PO Box 6552",
        "client": "Adolpho Marde",
        "age": 24,
        "mail": "amarde2@live.com",
        "phone": "414-592-8145",
        "status": "waiting"
    }, {
        "id": 4,
        "number": "1450585108",
        "date": "6/30/2022",
        "location": "Room 269",
        "client": "Catherine Rossborough",
        "age": 25,
        "mail": "crossborough3@symantec.com",
        "phone": "636-144-9617",
        "status": "confirmed"
    }, {
        "id": 5,
        "number": "6001747512",
        "date": "8/6/2022",
        "location": "Suite 100",
        "client": "Raffarty MacKeogh",
        "age": 42,
        "mail": "rmackeogh4@edublogs.org",
        "phone": "142-114-8892",
        "status": "confirmed"
    }, {
        "id": 6,
        "number": "3248639741",
        "date": "1/19/2022",
        "location": "Suite 51",
        "client": "Bond Wohlers",
        "age": 31,
        "mail": "bwohlers5@google.com.au",
        "phone": "172-203-5977",
        "status": "waiting"
    }, {
        "id": 7,
        "number": "6805055458",
        "date": "8/28/2022",
        "location": "13th Floor",
        "client": "Rachelle Pettegre",
        "age": 29,
        "mail": "rpettegre6@hud.gov",
        "phone": "726-322-9175",
        "status": "confirmed"
    }, {
        "id": 8,
        "number": "8013901777",
        "date": "3/19/2022",
        "location": "Apt 1354",
        "client": "Filmer Stevens",
        "age": 54,
        "mail": "fstevens7@go.com",
        "phone": "550-249-1069",
        "status": "cancelled"
    }, {
        "id": 9,
        "number": "1320694373",
        "date": "7/15/2022",
        "location": "Room 578",
        "client": "Hallsy Bockett",
        "age": 67,
        "mail": "hbockett8@bravesites.com",
        "phone": "609-736-2460",
        "status": "waiting"
    }, {
        "id": 10,
        "number": "4822025969",
        "date": "1/4/2022",
        "location": "Suite 82",
        "client": "Felice Esley",
        "age": 24,
        "mail": "fesley9@topsy.com",
        "phone": "736-771-1679",
        "status": "waiting"
    }]

    const [bookings] = useState(data);

    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [filterStatus, setFilterStatus] = useState("Tous");
    const [searchName, setSearchName] = useState("");





    const getData = () => {
        let filteredData = bookings;

        if (filterStatus !== "Tous") {
            filteredData = filteredData.filter(item => item.status === filterStatus);
        }

        if (searchName !== "") {
            filteredData = filteredData.filter(item =>
                item.client.toLowerCase().includes(searchName.toLowerCase())
            );
        }

        if (sortColumn && sortType) {
            filteredData = filteredData.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = filteredData.slice(startIndex, endIndex);

        return paginatedData;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const status = [
        { title: t('per_status'), value: "Tous" },
        { title: t('confirmed'), value: "confirmed" },
        { title: t('waiting'), value: "waiting" },
        { title: t('cancelled'), value: "cancelled" }
    ];

    const handlePageChange = (page) => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            setCurrentPage(page);
        }, 500);
    };

    const filteredData = bookings.filter(item =>
        filterStatus === "Tous" || item.status === filterStatus
    ).filter(item =>
        searchName === "" || item.client.toLowerCase().includes(searchName.toLowerCase())
    ).sort((a, b) => {
        if (sortColumn && sortType) {
            let x = a[sortColumn];
            let y = b[sortColumn];
            if (typeof x === 'string') {
                x = x.charCodeAt();
            }
            if (typeof y === 'string') {
                y = y.charCodeAt();
            }
            if (sortType === 'asc') {
                return x - y;
            } else {
                return y - x;
            }
        } else {
            return 0;
        }
    });

    const pageCount = Math.ceil(filteredData.length / pageSize);

    return (
        <>
            <div className="border rounded">
                <Row className="px-3 align-items-center">
                    <Col xl="4" className="my-3">
                        <div className="fs-5 fw-medium text-blue">{t('bookings')}</div>
                    </Col>
                    <Col xl="4" className="my-3">
                        <Input placeholder={t('search_per_name')} value={searchName} handleChange={(e) => setSearchName(e.target.value)} type="text" />
                    </Col>
                    <Col xl="4" className="my-3">
                        <Select data={status} value={filterStatus} handleChange={(e) => setFilterStatus(e.target.value)} />
                    </Col>
                </Row>
                <hr className="m-0 border-secondary" />
                <TableItem t={t} bookings={bookings} getData={getData()} sortColumn={sortColumn} sortType={sortType} handleSortColumn={handleSortColumn}
                    loading={loading} />
                <div className="d-flex align-items-center justify-content-between border-top p-3 mt-3">
                    <span className="fs-6 fw-light">
                        {t('displaying')} 1 {t('to')} {pageSize} {t('of')} {bookings.length} {t('entries')}
                    </span>
                    <span className="d-flex align-items-center">
                        <button className="bg-white text-blue p-2 mx-3" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}><ChevronLeft size={20} /></button>

                        {Array.from({ length: pageCount }, (_, index) => (
                            <button key={index} className={`bg-white fs-5 mx-3 ${currentPage === index + 1 ? 'text-blue' : 'text-secondary'}`} onClick={() => handlePageChange(index + 1)} disabled={currentPage === index + 1}>
                                {index + 1}
                            </button>
                        ))}

                        <button className="bg-white text-blue p-2 mx-3" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}><ChevronRight size={20} /></button>
                    </span>
                </div>
            </div>
        </>
    );
};

export default Bookings;

