import React, { useState } from 'react'
import CalendarDays from './CalendarDays'
import CalendarMonths from './CalendarMonths'
import { Modal } from 'react-bootstrap';
import Input from '../../../components/forms/Input';

const Calendar = ({ t }) => {
    const [view, setView] = useState("days")

    const toggleView = (value) => {
        setView(value)
    }

    const getAllDaysOfYear = () => {
        const today = new Date();
        const year = today.getFullYear();
        const nextYear = year + 1;

        const daysOfYear = [];
        for (let yearIndex = 0; yearIndex < 2; yearIndex++) {
            const targetYear = yearIndex === 0 ? year : nextYear;
            for (let month = 0; month < 12; month++) {
                const daysInMonth = new Date(targetYear, month + 1, 0).getDate();
                const monthDays = [];
                for (let day = 1; day <= daysInMonth; day++) {
                    monthDays.push({
                        day: day,
                        price: 50,
                        isBlocked: false
                    });
                }
                if (!daysOfYear[yearIndex]) {
                    daysOfYear[yearIndex] = [];
                }
                daysOfYear[yearIndex].push(monthDays);
            }
        }

        return daysOfYear;
    };

    const [daysOfYear, setDaysOfYear] = useState(getAllDaysOfYear());
    const [showModal, setShowModal] = useState(false);
    const [modalDay, setModalDay] = useState({});
    const [modalYearIndex, setModalYearIndex] = useState(0);
    const [modalMonthIndex, setModalMonthIndex] = useState(0);
    const [modalDayIndex, setModalDayIndex] = useState(0);

    const openModal = (yearIndex, monthIndex, dayIndex, day) => {
        setModalDay(day);
        setModalYearIndex(yearIndex);
        setModalMonthIndex(monthIndex);
        setModalDayIndex(dayIndex);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handlePriceChange = (newPrice) => {
        const updatedDays = [...daysOfYear];
        updatedDays[modalYearIndex][modalMonthIndex][modalDayIndex].price = newPrice;
        setDaysOfYear(updatedDays);
        closeModal();
    };

    const handleBlockDay = () => {
        const updatedDays = [...daysOfYear];
        updatedDays[modalYearIndex][modalMonthIndex][modalDayIndex].isBlocked = !modalDay.isBlocked;
        setDaysOfYear(updatedDays);
        closeModal();
    };

    const isPastDate = (day, month, yearIndex) => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();
        const currentDay = today.getDate();

        if (yearIndex === 0) {
            return month < currentMonth || (month === currentMonth && day < currentDay);
        }
        return false;   
    };

    const getMonthName = (monthIndex) => {
        const months = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'),
        t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
        return months[monthIndex];
    };
    return (
        <div>


            <div className='py-5'>
                {view === "days" && <CalendarDays t={t} view={view} toggleView={toggleView} daysOfYear={daysOfYear} getMonthName={getMonthName} isPastDate={isPastDate} openModal={openModal} />}
                {view === "months" && <CalendarMonths t={t} view={view} toggleView={toggleView} a daysOfYear={daysOfYear} getMonthName={getMonthName} isPastDate={isPastDate} openModal={openModal} />}
            </div>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <div className='text-dark'>{getMonthName(modalMonthIndex)} {modalDay.day}, {daysOfYear[modalYearIndex] && (new Date().getFullYear() + modalYearIndex)}</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-dark'>
                        <Input type="number" value={modalDay.price} handleChange={(e) => setModalDay({ ...modalDay, price: parseInt(e.target.value) })} placeholder={t('set_new_price')} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-outline-blue fs-6 my-3 p-3' onClick={handleBlockDay}>
                        {modalDay.isBlocked ? t('unblock_date') : t('block_date')}
                    </button>
                    <button className='btn-blue p-3' onClick={() => handlePriceChange(modalDay.price)}>
                        {t('apply')}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Calendar
