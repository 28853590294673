import React from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import { useState } from "react";
import supplierData from "../../../data/globaldata.json";
import Choice from "../../../components/Choice";
import DepartFees from "./DepartFees";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const Depart = () => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState({
    depart: "no",
  });

  const handleLateDepart = (value) => {
    setInfos({ ...infos, depart: value });
  };

  return (
    <div className="my-3 fs-6">
      <span className="fw-medium text-dark">{t('when_travelers_leave')}</span>
      <Row className="align-items-center">
        <Col md="6" className="d-flex align-items-center my-3">
          <span className="fw-medium text-dark">{t('departure_time')}</span>
        </Col>
        <Col md="6" className="d-flex align-items-center my-3">
          <Select data={supplierData.schedules} />
        </Col>
      </Row>

      <div className="d-flex align-items-center">
        <CheckInput id="DépartExpress" label={t('depart_express')} info={t('clients_can_leave')} />
      </div>

      <div className="my-3">
        <Choice text={t('late_departure_service')} selectedChoice={infos.depart} onChange={handleLateDepart} />
      </div>

      {infos.depart === "yes" && <DepartFees text={t('late_departure_fees')} />}
    </div>
  );
};

export default Depart;
