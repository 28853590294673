import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Choice from "../../../components/Choice";
import CheckInput from "../../../components/forms/CheckInput";

const Services = ({ t }) => {

  const [infos, setInfos] = useState({
    events: "yes",
  });

  const HnaleEvent = (value) => {
    setInfos({ ...infos, events: value });
  };

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const data = [
    { title: t('restauAtt1'), value: "Menus végétariens" },
    { title: t('restauAtt2'), value: "Menus enfants" },
    { title: t('restauAtt3'), value: "Buffet à volonté" },
    { title: t('restauAtt4'), value: "Bar" },
    { title: t('restauAtt5'), value: "Cuisine ouverte" },
    { title: t('restauAtt6'), value: "Réservations en ligne" },
    { title: t('restauAtt7'), value: "Livraison" },
    { title: t('restauAtt8'), value: "Vente à emporter" },
    { title: t('restauAtt9'), value: "Payement par carte" },
    { title: t('restauAtt10'), value: "Cours de cuisine" },
    { title: t('restauAtt11'), value: "Parking" },
    { title: t('restauAtt12'), value: "Terasse" },
    { title: t('restauAtt13'), value: "Espace fumeurs" },
    { title: t('restauAtt14'), value: "Musique live" },
    { title: t('restauAtt15'), value: "Prises" },
    { title: t('restauAtt16'), value: "Wifi" },
    { title: t('restauAtt17'), value: "Téléviseur" },
    { title: t('restauAtt18'), value: "Climatiseur" },
  ];

  return (
    <section className="container form-transition">
      <section className="container form-transition">
      <Row className="justify-content-center">
        <Col lg="7" xl="8" className="my-3">
          <>
            <Choice text={
                <div>
                  <span className="fs-3 lh-base fw-medium text-dark">{t('RestauServices')}</span>
                  <p className="fs-5 fw-light">{t('RestauServicesP')}</p>
                </div>
              } selectedChoice={infos.events} onChange={HnaleEvent} />
            {infos.events === "yes" && (
              <div className="fs-6 mt-5">
                {data.map((check, index) => (
                  <div key={index} className="my-3">
                    <CheckInput id={check.value} label={check.title} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                  </div>
                ))}
              </div>
            )}
          </>
        </Col>
      </Row>
    </section>

    </section>
  );
};

export default Services;
