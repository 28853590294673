import { Settings } from "lucide-react";
import ManageHeader from "../../components/ManageHeader";

const SettingsComponent = () => {
    
  return (
    <section>
        <ManageHeader
                title="Frais, politiques et paramètres"
                content="Gérez les paramètres de votre hébergement pour les frais, les politiques, les cautions et les informations générales."
                icon={<Settings size={25} strokeWidth={1.5} />}
            />
        <div className="container my-4">
            Settings
        </div>
    </section>
  )
}

export default SettingsComponent
