import ErrorPage from "./pages/404";
import { Route, Routes } from "react-router-dom";
import DashboardMain from "./pages/dashboard/Main";
import Login from "./components/forms/Login";
import Myhotel from "./pages/myhotel/Main";
import Myactivity from "./pages/myactivity/Main";
import Product from './pages/product/Main'
import About from './pages/About'
import Contact from './pages/Contact'

function RouteComponents() {
  return (
    <Routes>
      <Route path="/">
        <Route path="" exact element={<DashboardMain component="home" />} />
        <Route path="settings" exact element={<DashboardMain component="settings" />} />
      </Route>

      <Route path="product">
        <Route path="" element={<Product component="home" />} />
        <Route path="hosting" element={<Product component="hosting" />} />
      </Route>

      <Route path="/myhotel">
        <Route path="" element={<Myhotel component="home" />} />
        <Route path="HeaNav" element={<Myhotel component="HeaNav" />} />
        <Route path="skoop" element={<Myhotel component="skoop" />} />
        <Route path="tarifs" element={<Myhotel component="tarifs" />} />
        <Route path="fees" element={<Myhotel component="fees" />} />
        <Route path="details" element={<Myhotel component="details" />} />
        <Route path="customers" element={<Myhotel component="customers" />} />
        <Route path="newrate" element={<Myhotel component="newrate" />} />
        <Route path="restep" element={<Myhotel component="restep" />} />
        <Route path="updatecancel" element={<Myhotel component="updatecancel" />} />
        <Route path="newfees" element={<Myhotel component="newfees" />} />
        <Route path="adjustedrate" element={<Myhotel component="adjustedrate" />} />
        <Route path="bookinghotels" element={<Myhotel component="bookinghotels" />} />
        <Route path="bookingdetails" element={<Myhotel component="bookingdetails" />} />
        <Route path="invoicedetails" element={<Myhotel component="invoicedetails" />} />
        <Route path="paymentglobal" element={<Myhotel component="paymentglobal" />} />
        <Route path="facturedetails" element={<Myhotel component="facturedetails" />} />
        <Route path="bookingdetails1" element={<Myhotel component="bookingdetails1" />} />
        <Route path="demanderpayment" element={<Myhotel component="demanderpayment" />} />
      </Route>

      <Route path="/myactivity">
        <Route path=":id" element={<Myactivity component="home" />} />
      </Route>

      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" component={Login} />

      <Route path="*" element={<ErrorPage />} />

    </Routes>
  );
}
export default RouteComponents;
