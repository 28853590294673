
const Input = ({ id, name, value, handleChange, placeholder, type, maxlength, onFocus,disabled }) => {

    return (


        <input name={name} id={id} placeholder={placeholder} type={type} onChange={handleChange} value={value} className='input-blue w-100 p-4 border' max={maxlength} maxLength={maxlength} onFocus={onFocus} disabled={disabled} />
    )
}

export default Input