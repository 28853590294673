import { useState } from "react";
import Location from "./Location";
import PaymentOption from "./PaymentOption";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import CheckInput from "../../../components/forms/CheckInput"
import RadioInput from "../../../components/forms/RadioInput";
import Input from "../../../components/forms/Input";
import { useTranslation } from "react-i18next";

const DetailsBeach = ({ checked }) => {
    const { t } = useTranslation();

    const [infos, setInfos] = useState({
        beach: "",
        club: "",
    });

    const handleBeach = (value) => {
        setInfos({ ...infos, beach: value });
    };

    const handleClub = (value) => {
        setInfos({ ...infos, club: value });
    };

    const type = [
        { title: t('white_sand'), value: "Sable blanc" },
        { title: t('black_sand'), value: "Sable noir" },
        { title: t('sand'), value: "Sable" },
    ]

    const mesures = [
        { title: t('kilometers'), value: "Kilométres" },
        { title: t('meters'), value: "Métres" },
        { title: t('miles'), value: "Miles" },
        { title: t('yards'), value: "Yards" },
    ]

    const bars_length = Array.from({ length: 20 }, (_, index) => ({
        title: `${index + 1}`,
        value: index + 1,
    }));

    return (
        <div className='my-3 fs-6'>

            {checked === 'Accès' && (
                <>
                    <Location onChange={handleBeach} />

                    <Row className='fs-6 align-items-center'>
                        <Col md="6" className='d-flex align-items-center my-1'>
                            <span className='fw-medium text-dark'>{t('beach')}</span>
                        </Col>
                        <Col md="6" className='d-flex align-items-center my-1'>
                            <div className='mx-3'>
                                <RadioInput name="PlageType" id="PlagePublique" label={t('public_beach')} />
                            </div>

                            <div className='mx-3'>
                                <RadioInput name="PlageType" id="PlagePrivé" label={t('public_private')} />
                            </div>

                        </Col>
                    </Row>

                    <div className="my-3">
                        <CheckInput id="AccéesDirectAlaPlage" label={t('direct_access_to_the_beach')} />
                    </div>

                    {infos.beach === "Aproximite" && (
                        <Row className='fs-6 align-items-center'>
                            <Col md="6" className='my-3'>
                                <Input placeholder={t('distance')} />
                            </Col>
                            <Col md="6" className='d-flex align-items-center my-3'>
                                <Select data={mesures} />
                            </Col>
                        </Row>
                    )}

                    <Row className='fs-6 align-items-center'>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <span className='fw-medium text-dark'>{t('type_of_sand')}</span>
                        </Col>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <Select data={type} />
                        </Col>
                    </Row>
                </>
            )}

            {checked === 'Bars' && (
                <>
                    <Row className='fs-6 align-items-center'>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <span className='fw-medium text-dark'>{t('how_many')}</span>
                        </Col>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <Select data={bars_length} />
                        </Col>
                    </Row>
                </>
            )}

            {checked === 'Club' && (
                <>
                    <Location onChange={handleClub} />
                    <PaymentOption />
                </>
            )}

            {['Cabines', 'Navette'].includes(checked) && (
                <PaymentOption />
            )}
        </div>

    )
}

export default DetailsBeach