import { useState } from "react";
import RadioInput from "../../../components/forms/RadioInput";
import Card from "./Card";
import Uniforme from "./Uniforme";
import { Col, Row } from "react-bootstrap";

const RatesRestaurant = ({ t, price }) => {

  const [infos, setInfos] = useState({
    pricing: "Uniforme",
  });

  const handlePricing = (value) => {
    setInfos({ ...infos, pricing: value });
  };

  return (
    <div className="my-5">
      <h2 className="fs-5 lh-base fw-medium text-dark">{t('RestauFixerp')}</h2>
      <p className="fs-6 fw-light">{t('RestauFixerpP')}</p>

      <div className="my-3 text-dark fs-6">
        <>
          <Row>
            <Col xs="12">
              <RadioInput
                name="ActivityPricing"
                label={t('uniform')}
                id="Uniforme"
                onChange={() => {
                  handlePricing("Uniforme");
                }}
              />
            </Col>
            <Col xs="12">
              <RadioInput
                name="ActivityPricing"
                label={t('RestauCarte')}
                id="AlaCarte"
                onChange={() => {
                  handlePricing("AlaCarte");
                }}
              />
            </Col>
          </Row>

        </>

        {infos.pricing === "AlaCarte" && <Card t={t}/>}

        {infos.pricing === "Uniforme" && <Uniforme price={price} t={t} />}
      </div>
    </div>
  );
};

export default RatesRestaurant;
