import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CakeSlice, Salad, Soup, Beef, Fish, Drumstick, Sandwich, Pizza, EggFried, CupSoda, Milk, Wine, Coffee, Dessert, Cookie, Lollipop, Cherry, Nut, Popsicle } from "lucide-react";

const Uniforme = ({ t, price }) => {

  const items = [
    { id: 1, title: t('salads'), value: "Salades", icon: <Salad strokeWidth={1.2} size={25} /> },
    { id: 2, title: t('dishes'), value: "Plats", icon: <Soup strokeWidth={1.2} size={25} /> },
    { id: 3, title: t('red_meat'), value: "Viande rouge", icon: <Beef strokeWidth={1.2} size={25} /> },
    { id: 4, title: t('fish'), value: "Poisson", icon: <Fish strokeWidth={1.2} size={25} /> },
    { id: 5, title: t('RestauInfos5'), value: "Viande blanche", icon: <Drumstick strokeWidth={1.2} size={25} /> },
    { id: 6, title: t('RestauInfos6'), value: "Sandwichs", icon: <Sandwich strokeWidth={1.2} size={25} /> },
    { id: 7, title: t('RestauInfos7'), value: "Pizzas", icon: <Pizza strokeWidth={1.2} size={25} /> },
    { id: 8, title: t('RestauInfos8'), value: "Oeufs", icon: <EggFried strokeWidth={1.2} size={25} /> },
    { id: 9, title: t('RestauInfos9'), value: "Boissons", icon: <CupSoda strokeWidth={1.2} size={25} /> },
    { id: 10, title: t('RestauInfos10'), value: "Lait", icon: <Milk strokeWidth={1.2} size={25} /> },
    { id: 11, title: t('RestauInfos11'), value: "Boissons chaudes", icon: <Coffee strokeWidth={1.2} size={25} /> },
    { id: 12, title: t('RestauInfos12'), value: "Alcool", icon: <Wine strokeWidth={1.2} size={25} /> },
    { id: 13, title: t('RestauInfos13'), value: "Gateaux", icon: <CakeSlice strokeWidth={1.2} size={25} /> },
    { id: 14, title: t('RestauInfos14'), value: "Desserts", icon: <Dessert strokeWidth={1.2} size={25} /> },
    { id: 15, title: t('RestauInfos15'), value: "Collations", icon: <Cookie strokeWidth={1.2} size={25} /> },
    { id: 16, title: t('RestauInfos16'), value: "Fréandises", icon: <Lollipop strokeWidth={1.2} size={25} /> },
    { id: 17, title: t('RestauInfos17'), value: "Glaces", icon: <Popsicle strokeWidth={1.2} size={25} /> },
    { id: 18, title: t('RestauInfos18'), value: "Fruits", icon: <Cherry strokeWidth={1.2} size={25} /> },
    { id: 19, title: t('RestauInfos19'), value: "Fruits secs", icon: <Nut strokeWidth={1.2} size={25} /> },
  ];


  const [infos, setInfos] = useState({
    elements: [],
    options: items,
    selected: "",
  });

  const handleSelectChange = (event) => {
    const value = event.target.value;

    if (!infos.elements.map((opt) => opt.value).includes(value)) {
      const selectedOption = infos.options.find((option) => option.value === value);
      setInfos((prevState) => ({
        ...prevState,
        elements: [...prevState.elements, selectedOption],
        options: prevState.options.filter((option) => option.value !== value),
      }));
    }
  };

  const handleDelete = (value) => {
    setInfos((prevState) => ({
      ...prevState,
      elements: prevState.elements.filter((item) => item.value !== value.value),
      options: [...prevState.options, value],
    }));
  };

  return (
    <>
      <div className="fs-6 my-3">
        <div className="my-3">
          <Row className="align-items-start">
            <Col md="6">
              <span className="text-blue">{t('RestauFixerTarif')}</span>
              <div className="my-2 fs-3 fw-medium py-4">
                {price}€
              </div>
            </Col>
            <Col md="6">
              <span className="text-blue">{t('RestauFixerTarif1')}</span>
              <select value={infos.selected} onChange={handleSelectChange} className="input-blue w-100 p-4 border my-2">
                <option>-- {t('select_option')} --</option>
                {infos.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
        </div>
        {infos.elements.length > 0 && <>
          <div className="my-3">
            <div className="border-start p-3">
              {infos.elements.map((item) => (
                <div key={item.value} className="d-flex justify-content-between align-items-center m-2 border p-3">
                  <span className="d-flex align-items-center"><span>{item.icon}</span><span className="mx-2 fs-5 fw-light text-dark">{item.title}</span></span>
                  <span role="button" onClick={() => handleDelete(item)} className="text-danger mx-2">
                    {t('remove')}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </>}
      </div>
    </>
  );
};

export default Uniforme;
