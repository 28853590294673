import { Pause, Pencil, Tag, Trash2 } from "lucide-react";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import StandardPromo from "./StandardPromo";
import PromoCounts from "./PromoCounts";
import CreatePromo from "./CreatePromo";

const Promo = ({ t }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [active, setActive] = useState(false);

    const handleActive = (value) => {
        setActive(value)
    }

    const [createPromo, setCreatePromo] = useState(false);

    const ShowCreatePromo = () => setCreatePromo(true);
    const CloseCreatePromo = () => setCreatePromo(false);

    const data = [
        {
            id: 1,
            title: "Membres 15%",
            type: "Standard",
            discount: "Négociée",
            public: "Membres",
            datesOfStay: "29/11/2019 - Aucune date de fin",
            datesOfBooking: "29/11/2019 - Aucune date de fin",
            amount: "2037.58",
            income: "1528.72",
            booking: 9,
            deadline: "",
            status: "Opérationnelle"
        },
        {
            id: 2,
            title: "Promo 2024",
            type: "Standard",
            discount: "20%",
            public: "Tout le monde",
            datesOfStay: "29/11/2019 - Aucune date de fin",
            datesOfBooking: "29/11/2019 - Aucune date de fin",
            amount: "2037.58",
            income: "1528.72",
            booking: 9,
            deadline: "225 jours",
            status: "Interrompue"
        },

        {
            id: 3,
            title: "Promo vacances",
            type: "Standard",
            discount: "10%",
            public: "Membres seulement",
            datesOfStay: "29/11/2019 - Aucune date de fin",
            datesOfBooking: "29/11/2019 - Aucune date de fin",
            amount: "2037.58",
            income: "1528.72",
            booking: 9,
            deadline: "24 jours",
            status: "Opérationnelle"
        },
    ];


    const labels = [t('actions'), t('name'), t('type'), t('discount'), t('target'), t('stays'), t('dates'), t('amount'), t('income'), t('bookings'), t('deadline'), t('status')]


    const actions = [
        { id: 1, title: t('pausing'), icon: <Pause size={20} strokeWidth={1.5} />, className: "text-dark" },
        { id: 2, title: t('edit'), icon: <Pencil size={20} strokeWidth={1.5} />, className: "text-dark" },
        { id: 3, title: t('delete'), icon: <Trash2 size={20} strokeWidth={1.5} />, className: "text-danger" },
    ]

    return (
        <>
            <Row>
                <Col xl="8">
                    <div className="border h-100">
                        <Table responsive>
                            <thead>
                                <tr>
                                    {labels.map(label => (
                                        <th className="px-5 py-3 fw-light bg-light">{label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(promo => (
                                    <tr className="align-middle text-center">
                                        <td className="px-5 py-3">
                                            <div className="d-flex align-items-center">
                                                {actions.map(action => (
                                                    <span key={action.id} role="button" title={action.title} className={`mx-1 ${action.className}`}>{action.icon} </span>
                                                ))}
                                            </div>
                                        </td>
                                        <td className="px-5 py-3">{promo.title}</td>
                                        <td className="px-5 py-3">{promo.type}</td>
                                        <td className="px-5 py-3">{promo.discount}</td>
                                        <td className="px-5 py-3">{promo.public}</td>
                                        <td className="px-5 py-3">{promo.datesOfStay}</td>
                                        <td className="px-5 py-3">{promo.datesOfBooking}</td>
                                        <td className="px-5 py-3">{promo.amount}</td>
                                        <td className="px-5 py-3">{promo.income}</td>
                                        <td className="px-5 py-3">{promo.booking}</td>
                                        <td className="px-5 py-3">{promo.deadline}</td>
                                        <td className={`px-5 py-3`}>{promo.status}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col xl="4">
                    <div className="border text-dark h-100">
                        <div className="p-3 fs-6 text-center">
                            <div>Promotion standard : {active ? <span className="bg-success-subtle text-success p-1 mx-2">{t('operationnal')}</span> : <span className="bg-danger-subtle text-danger p-1 mx-2">{t('interrupted')}</span>}</div>
                            <button className="bg-white text-blue w-100 my-3" onClick={handleShow}>{t('edit')}</button>
                        </div>
                        <hr className="border-secondary m-0" />
                        <div className="p-4">
                            <Tag size={40} strokeWidth={1} />
                            <div className="fs-5 fw-medium my-3">{t('marketing_attirez')}</div>
                            <p className="fs-6 fw-light">{t('marketing_attirez_txt')}</p>
                            <button className="btn-blue rounded-5 p-3 w-100 my-3" onClick={ShowCreatePromo}>{t('marketing_btn')}</button>
                        </div>
                        <StandardPromo t={t} show={show} handleClose={handleClose} active={active} handleActive={handleActive} data={data} />

                        <CreatePromo t={t} setShow={setShow} createPromo={createPromo} ShowCreatePromo={ShowCreatePromo} CloseCreatePromo={CloseCreatePromo} />
                    </div>

                </Col>
            </Row>
            <PromoCounts t={t}  />
        </>
    );
};

export default Promo;
