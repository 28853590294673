import React, { useState, useEffect } from 'react';
import { Table, Tabs, Tab } from 'react-bootstrap';
import { PanelGroup, Panel } from 'rsuite';
import { Check, Lock } from 'lucide-react';
import Input from '../../../components/forms/Input';


const RoomsAVailability = ({ openModal, selectedDate,t }) => {


    const [halfBoardData, setHalfBoardData] = useState([]);
    const [fullBoardData, setFullBoardData] = useState([]);
    const [breakfastData, setBreakfastData] = useState([]);

    useEffect(() => {
        const updateData = (setData) => {
            const updatedData = [];
            if (selectedDate) {
                for (let i = 0; i < 14; i++) {
                    updatedData.push({
                        date: selectedDate,
                        selected: false,
                        statut: 'red',
                        disponibilite: '',
                        tarif: '',
                        netBooked: '',
                        minSejour: '',
                        maxSejour: ''
                    });
                }
                setData(updatedData);
            }
        };

        updateData(setHalfBoardData);
        updateData(setFullBoardData);
        updateData(setBreakfastData);
    }, [selectedDate]);

    const handleRectangleClick = (data, setData, rowIndex) => {
        setData(prevData => {
            const updatedData = prevData.map((rowData, index) => {
                if (index === rowIndex) {
                    if (rowData.selected) {
                        rowData.disponibilite = '';
                        rowData.tarif = '';
                        rowData.netBooked = '';
                        rowData.minSejour = '';
                        rowData.maxSejour = '';
                    }
                    return { ...rowData, selected: !rowData.selected, statut: rowData.selected ? 'red' : 'green' };
                } else {
                    return rowData;
                }
            });
            return updatedData;
        });
    };

    const handleInputChange = (data, setData, rowIndex, field, value) => {
        setData(prevData => {
            const updatedData = [...prevData];
            updatedData[rowIndex][field] = value;
            return updatedData;
        });
    };

    const rooms = [
        { title: "Half Board", value: halfBoardData, method: setHalfBoardData },
        { title: "Full Board", value: fullBoardData, method: setFullBoardData },
        { title: "Breakfast Included", value: breakfastData, method: setBreakfastData }
    ]


    const data = [
        {
            id: 1, title: "Chambre Simple", subtitle: t('ho_act_Opt_TarifTxt1'), roomId: 1177592801, types: rooms
        },
        {
            id: 2, title: "Chambre Double", subtitle: t('ho_act_Opt_TarifTxt1'), roomId: 1177592801, types: rooms
        },
        {
            id: 3, title: "Suite", subtitle: t('ho_act_Opt_TarifTxt1'), roomId: 1177592801, types: rooms
        }
    ]


    return (
        <PanelGroup accordion bordered defaultActiveKey={[]} className=''>

            {data.map(panel => (
                <>


                    <Panel header={
                        <div className='p-3'>
                            <span className='fs-5 fw-bold'>{panel.title} </span> <span className='fs-8 ms-1' style={{ color: 'gray' }}>{t('id_room')} : {panel.roomId}</span>
                            <p className='mt-1'>{panel.subtitle}</p>
                        </div>} defaultExpanded>

                        <Tabs defaultActiveKey={panel.types[0].title} id="board-tabs" className="custom-tabs">
                            {panel.types.map(tab => (
                                <Tab eventKey={tab.title} title={tab.title}>
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th>{t('date')}</th>
                                                <th>{t('status')}</th>
                                                <th>{t('availability')}</th>
                                                <th>{t('rate')} (EUR)</th>
                                                <th>{t('net_booked')}</th>
                                                <th>{t('ho_act_Opt_Tarif_duree')}</th>
                                                <th>{t('ho_act_Opt_Tarif_max')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tab.value.map((rowData, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td>{rowData.date}</td>
                                                    <td>
                                                        <div
                                                            role='button'
                                                            className="p-3"
                                                            style={{ backgroundColor: rowData.statut, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                            onClick={() => handleRectangleClick(tab.value, tab.method, rowIndex)}
                                                        >
                                                            {rowData.selected ? <Check color='white' size={15} /> : <Lock color='white' size={15} />}
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <Input value={rowData.disponibilite} placeholder="" type="text" handleChange={(e) => handleInputChange(tab.value, tab.method, rowIndex, 'disponibilite', e.target.value)} disabled={!rowData.selected} />
                                                    </td>
                                                    <td>
                                                        <Input value={rowData.tarif} placeholder="" type="text" handleChange={(e) => handleInputChange(tab.value, tab.method, rowIndex, 'tarif', e.target.value)} disabled={!rowData.selected} />

                                                    </td>
                                                    <td>
                                                        <Input value={rowData.netBooked} placeholder="" type="text" handleChange={(e) => handleInputChange(tab.value, tab.method, rowIndex, 'netBooked', e.target.value)} disabled={!rowData.selected} />

                                                    </td>
                                                    <td>
                                                        <Input value={rowData.minSejour} placeholder="" type="text" handleChange={(e) => handleInputChange(tab.value, tab.method, rowIndex, 'minSejour', e.target.value)} disabled={!rowData.selected} />

                                                    </td>
                                                    <td>
                                                        <Input value={rowData.maxSejour} placeholder="" type="text" handleChange={(e) => handleInputChange(tab.value, tab.method, rowIndex, 'maxSejour', e.target.value)} disabled={!rowData.selected} />

                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Tab>
                            ))}
                        </Tabs>
                        <div className="d-flex justify-content-end mt-3">
                            <button className='btn-blue p-3 px-5' onClick={openModal}>{t('ho_act_segmentConfirmU')}</button>
                        </div>
                    </Panel>

                </>
            ))}





        </PanelGroup>
    )
}

export default RoomsAVailability
