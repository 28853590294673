import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import Input from "../../../components/forms/Input";
import Schedules from "../../../components/Schedules";
import { useTranslation } from "react-i18next";

const DetailsSpring = ({ checked }) => {
    const { t } = useTranslation();

    const length = Array.from({ length: 15 }, (_, index) => ({
        title: `${index + 1}`,
        value: index + 1,
    }));

    return (

        <div className="my-3 fs-6">
            {checked === 'SouresSurPlace' && (
                <>
                    <Row className='fs-6 align-items-center'>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <span className='fw-medium text-dark'>{t('thermal_springs')}</span>
                        </Col>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <Select data={length} />
                        </Col>
                    </Row>

                    <Row className='fs-6 align-items-center'>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <span className='fw-medium text-dark'>{t('thermal_springs_indoor_count')}</span>
                        </Col>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <Select data={length} />
                        </Col>
                    </Row>

                    <Row className='fs-6 align-items-center'>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <span className='fw-medium text-dark'>{t('thermal_springs_open_count')}</span>
                        </Col>
                        <Col md="6" className='d-flex align-items-center my-3'>
                            <Select data={length} />
                        </Col>
                    </Row>


                    <div>
                        <span className="text-blue">{t('water_temp')}</span>
                        <Row className='fs-6 align-items-center'>
                            <Col md="6" className='my-3'>
                                <Input placeholder={t('degree_fahrenheit')} type="number" />
                            </Col>

                            <Col md="6" className='my-3'>
                                <Input placeholder={t('degree_celsius')} type="number" />
                            </Col>
                        </Row>
                    </div>

                    <div>
                        <span className="text-blue">{t('thermal_springs_open_hours')}</span>
                        <Schedules />
                    </div>
                </>
            )}
        </div>

    )
}

export default DetailsSpring