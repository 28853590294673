import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import Input from '../../../components/forms/Input';
import AmountOption from '../../../components/AmountOption';
import AmountValue from '../../../components/AmountValue';
import AddedValue from './AddedValue';

const ExtraPrices = ({ t }) => {

    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const formules = [
        { title: t('rates_title1'), value: "PetitDéjeunerInclus", content: t('rates_caption1') },
        { title: t('rates_title2'), value: "RéservationAnticipée", content: t('rates_caption2') },

        { title: t('rates_title3'), value: "ValeurAjoutée", content: t('rates_caption3') },
        { title: t('rates_title4'), value: "PensionComplète", content: t('rates_caption4') },
        { title: t('rates_title5'), value: "DemiPension", content: t('rates_caption5') },
        { title: t('rates_title6'), value: "DuréeDuSéjour", content: t('rates_caption6') },
    ]

    const breakfast = [
        { title: t('breakfast1'), value: "PetitDéjeunerAnglais" },
        { title: t('breakfast2'), value: "PetitDéjeunerBuffet" },
        { title: t('breakfast3'), value: "PetitDéjeunerComplet" },
        { title: t('breakfast4'), value: "PetitDéjeunerContinental" },
        { title: t('breakfast5'), value: "PetitDéjeunerGratuit" },
        { title: t('breakfast6'), value: "PetitDéjeuner01personne" },
        { title: t('breakfast7'), value: "PetitDéjeuner02personnes" }
    ]

    return (
        <div className='my-3 fs-6'>
            <>

                {formules.map((check, index) => (
                    <div key={index} className='my-3'>
                        <>
                            <div className='d-flex py-2'>
                                <input
                                    id={check.value}
                                    type="checkbox"
                                    value={check.value}
                                    checked={checkedItems[check.value] || false}
                                    onChange={() => handleCheckboxChange(check.value)}
                                />
                                <label htmlFor={check.value} className='mx-2'>
                                    <div className='fw-medium'>{check.title}</div>
                                    <div>{check.content}</div>
                                </label>
                            </div>

                        </>

                        {checkedItems[check.value] && (
                            <>
                                {check.value === 'PetitDéjeunerInclus' && (
                                    <>
                                        <Row className='align-items-center'>
                                            <Col xl="6" className='my-3'>
                                                <span className='fs-6 fw-medium text-dark'>{t('breakfast_type')}</span>
                                            </Col>
                                            <Col xl="6" className='my-3'>
                                                <Select data={breakfast} />
                                            </Col>
                                        </Row>
                                        <AmountOption />
                                        <AmountValue />
                                    </>
                                )}

                                {check.value === 'RéservationAnticipée' && (
                                    <>
                                        <AmountOption />
                                        <Row className='align-items-center'>
                                            <Col xl="6" className='my-3'>
                                                <Input type="number" placeholder={`${t('discount')} %`} />
                                            </Col>
                                            <Col xl="6" className='my-3'>
                                                <Input type="number" placeholder={t('bear_in_advance')} />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {check.value === 'ValeurAjoutée' && (
                                    <>
                                        <AmountOption />
                                        <AmountValue />
                                        <AddedValue />
                                    </>
                                )}



                                {['PensionComplète', 'DemiPension'].includes(check.value) && (
                                    <>
                                        <AmountOption />
                                        <AmountValue />
                                    </>
                                )}

                                {check.value === 'DuréeDuSéjour' && (
                                    <>
                                        <Row className='align-items-center'>
                                            <Col xl="6" className='my-2'>
                                                <Input type="number" placeholder="Remise %" />
                                            </Col>
                                            <Col xl="6" className='my-2'>
                                                <Input type="number" placeholder={t('stay_must_be')} />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                            </>
                        )}
                        <hr className='border-secondary' />

                    </div>
                ))}
            </>
        </div>
    )
}

export default ExtraPrices