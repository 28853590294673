import { HeartHandshake, ShoppingBag, Users2 } from 'lucide-react';
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import Faq from '../components/home/Faq'

const About = () => {

    const { t } = useTranslation();

    const data = [
        { title: t('happy_clients'), info: "2 K", icon: <HeartHandshake size={90} strokeWidth={1} /> },
        { title: t('products_added'), info: "3 M", icon: <ShoppingBag size={90} strokeWidth={1} /> },
        { title: t('travelers_around_the_world'), info: "15 K", icon: <Users2 size={90} strokeWidth={1} /> },
    ]

    return (
        <div className='container py-5'>
            <div className='fs-5 fw-light text-blue'>{t('about')}</div>
            <Row className='justify-content-between mt-3'>
                <Col lg="6" xl="5">
                    <div className='fs-1 fw-bold text-dark'>{t('about_title')}</div>
                    <p className='fs-5 my-3 fw-light'>{t('mission_text')}</p>

                    <div className='fs-3 fw-medium text-dark'>{t('our_vision')}</div>
                    <p className='fs-5 my-3 fw-light'>{t('vision_text')}</p>
                </Col>
                <Col lg="6" xl="6">
                    <Row>
                        <Col xs="6" lg="7" xl="6">
                            <img className='rounded' src='https://images.pexels.com/photos/3184423/pexels-photo-3184423.jpeg?auto=compress&cs=tinysrgb&w=600' alt="Illustration team working" width="100%" height="100%" style={{ minHeight: "460px", maxHeight: "460px" }} />
                        </Col>
                        <Col xs="6" lg="5" xl="6" className='h-100'>
                            <Row className='d-flex flex-column justify-content-between' style={{ rowGap: "20px" }}>
                                <Col xl="12">
                                    <img className='rounded' src='https://images.pexels.com/photos/1595385/pexels-photo-1595385.jpeg?auto=compress&cs=tinysrgb&w=600' alt="Illustration team working" width="100%" height="100%" style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                                <Col xl="12">
                                    <img className='rounded' src='https://images.pexels.com/photos/3756679/pexels-photo-3756679.jpeg?auto=compress&cs=tinysrgb&w=600' alt="Illustration team working" width="100%" height="100%" style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className='bg-dark p-5 my-5'>
                <Row>
                    {data.map((item, index) => (
                        <Col key={index} xl="4" className='my-5 d-flex justify-content-center'>
                            <div className='d-flex text-white'>
                                <span>{item.icon}</span>
                                <span className='mx-3'>
                                    <div className='fs-1 fw-bold text-blue'>{item.info}</div>
                                    <p className='fs-6 fw-light'>{item.title}</p>
                                </span>
                            </div>
                        </Col>
                    ))}

                </Row>
            </div>
            <Faq t={t} />
        </div>
    )
}

export default About
