import { Row, Col } from "react-bootstrap";
import TextArea from "../../components/forms/Textarea";
import { useEffect, useState } from "react";
import { Info } from "lucide-react";
import { useTranslation } from "react-i18next";
import React from 'react';

const Title = ({ xl, lg }) => {

  const { t } = useTranslation();

  const [title, setTitle] = useState("");

  useEffect(() => {
    sessionStorage.removeItem('title');
    setTitle('');
  }, []);

  const handleTitle = (event) => {
    const newTitle = event.target.value;
    setTitle(newTitle);
    sessionStorage.setItem('title', newTitle);
  };

  const [product, setproduct] = useState("");

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setproduct(savedType);
    }
  }, []);

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg={`${lg ? lg : "7"}`} xl={`${xl ? xl : "6"}`} className="my-3">
            <h2 className="fs-3 lh-base fw-medium text-dark">{product === "Hotel" ? t('hotel_name') : t('product_title')}</h2>
            <p className="fs-5 fw-light">{product === "Hotel" ? t('use_official_hotel_name') : t('product_title_text')}</p>
            <div className="mt-5">
              <TextArea value={title} handleChange={handleTitle} rows={8} maxlength={32} />
            </div>
            <div className="text-secondary fw-bold">{title.length}/32</div>
            {title.length > 31 && (
              <div className="mt-3 text-danger d-flex align-items-center">
                <Info size={20} />
                <p className="mx-2">32 {t('max_chars')}</p>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Title;
