import { Row, Col, Button } from "rsuite"
import { Modal } from "react-bootstrap"
import { FaHotel } from "react-icons/fa6"
import { FaRegCalendarCheck } from "react-icons/fa6"
import { IoPricetagSharp } from "react-icons/io5"

const SaveDateRates = (props) => {
    return (
        <Modal show={props.showThirdModal} onHide={() => props.setShowThirdModal(false)} size="lg" className='rounded-2 p-1'>
            <Modal.Header className='bg-white' closeButton>
                <Modal.Title className="text-modal fw-bold fs-5 ms-2">{props.t('ho_act_reservation_maj')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-white text-modal d-flex flex-column'>
                <div className="p-2 my-2">
                    <span className=" fs-7">{props.t('ho_act_reservation_maj_txt')}</span>
                </div>
                <Row className="d-flex flex-row justify-content-around">
                    <Col className=''>
                        <FaHotel className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">{props.t('accommodation')}</span>
                        <div className='d-flex flex-column ms-4'>
                            <div className='d-flex flex-column mt-3'>
                                <span className="my-2">Chambre Lits Jumeaux</span>
                                <span className="my-2">Chambre Triple</span>
                            </div>
                        </div>
                    </Col>
                    <Col className=''>
                        <FaRegCalendarCheck className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">{props.t('the_dates')}</span>
                        <div className='d-flex flex-column ms-4'>
                            <div className='d-flex flex-column  mt-3'>
                                <span className="fs-8 my-2 text-red"><s>sam. 4 mai 2024 — lun. 6 mai 2024</s></span>
                                <span className="fs-8 fw-bold text-dark my-2">dim. 26 mai 2024 — mer. 29 mai 2024</span>
                                <Button className="px-4 my-2 w-50" onClick={props.handleModifierClick}>
                                    {props.t('edit')}
                                </Button>

                            </div>
                        </div>
                    </Col>
                    <Col className=''>
                        <IoPricetagSharp className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">Les tarifs</span>
                        <div className='d-flex flex-column ms-4'>
                            <div className='d-flex flex-column  mt-3'>
                                <span className="fs-8 my-2 "><s>DZD 46 000.00 </s></span>
                                <span className="fs-8 my-2 fw-bold">DZD 96 000.00</span>
                                <Button className="px-5 my-2 w-50" onClick={() => { props.setShowThirdModal(false); props.setShowSecondModal(true); }}>
                                    {props.t('edit')}
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='bg-white'>

                <Button appearance="primary" color="blue" onClick={() => props.setShowThirdModal(false)}>
                    {props.t('save')}
                </Button>
                <Button appearance="primary" color="cyan" onClick={() => props.setShowThirdModal(false)}>
                    {props.t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SaveDateRates
