import { Container, Sidebar, Sidenav, Navbar, Nav } from "rsuite";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import DashboardIcon from "@rsuite/icons/Dashboard";
import CalendarIcon from "@rsuite/icons/Calendar";
import TagIcon from "@rsuite/icons/Tag";
import MessageIcon from "@rsuite/icons/Message";
import PeoplesIcon from "@rsuite/icons/Peoples";
import GrowthIcon from "@rsuite/icons/Growth";
import ListIcon from "@rsuite/icons/List";
import BarChartIcon from "@rsuite/icons/BarChart";
import OffIcon from "@rsuite/icons/Off";
import { useState } from "react";
import HotelDetails from "./HotelDetails";
import Booking from "./Booking";
import Facture from "./Facture";
import RoomType from "./RoomType";
import Availability from "./Availability";
import Rates from "./Rates";
import Restrictions from "./Restrictions";
import Cancel from "./Cancel";
import Rateadj from "./Rateadj";
import PersonOccu from "./PersonOccu";
import Promotions from "./Promotions";
import Newfees from "./Newfees";
import Messages from "./Messages";
import Paymentglobal from "./Paymentglobal";
import Paymentinfos from "./Paymentinfos";
import Administration from "./Administration";
import Photos from "./Photos";
import Services from "./Services";
import Settings from "./Settings";
import Renovations from "./Renovations";
import { useTranslation } from "react-i18next";

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
          {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

const Menu = () => {

  const { t } = useTranslation();

  const [expand, setExpand] = useState(false);

  const [boutonActif, setBoutonActif] = useState("home");

  const handleClick = (bouton) => {
    setBoutonActif(bouton);
  };

  const data = [
    { id: 1, type: "button", title: "Accueil", value: "home", icon: <DashboardIcon /> },
    {
      id: 2,
      type: "accordion",
      title: t('rooms_and_rates'),
      icon: <PeoplesIcon />,
      buttons: [
        { id: 1, title: t('ho_act_type'), value: "roomtype" },
        { id: 2, title: t('ho_act_Opt_Tarif'), value: "rates" },
        { id: 3, title: t('avl_man_segment'), value: "availability" },
        { id: 4, title: t('rates_and_rest'), value: "restrictions" },
        { id: 5, title: t('ho_act_cancellation'), value: "cancel" },
        { id: 6, title: t('ho_act_frais_ind'), value: "occupancy" },
        { id: 7, title: t('ho_act_tarification_auto'), value: "rateadj" },
      ],
    },
    { id: 3, type: "button", title: t('bookings'), value: "booking", icon: <CalendarIcon /> },
    { id: 4, type: "button", title: t('promotions'), value: "promotions", icon: <TagIcon /> },
    { id: 5, type: "button", title: t('messages'), value: "messages", icon: <MessageIcon /> },
    {
      id: 6,
      type: "accordion",
      title: "Paiements",
      icon: <GrowthIcon />,
      buttons: [
        { id: 1, title: t('billing'), value: "billing" },
        { id: 2, title: t('ho_act_paiement'), value: "paymentReception" },
        { id: 3, title: t('pay_infos'), value: "Paymentinfos" },
      ],
    },
    {
      id: 7,
      type: "accordion",
      title: "Détails de l'hôtel",
      icon: <ListIcon />,
      buttons: [
        { id: 1, title: t('admin'), value: "admin" },
        { id: 2, title: t('photos'), value: "photos" },
        { id: 3, title: t('services'), value: "services" },
        { id: 4, title: t('settings'), value: "settings" },
        { id: 5, title: t('ren_closure'), value: "renovations" },
      ],
    },
    { id: 8, type: "button", title: t('performances'), value: "", icon: <BarChartIcon /> },
    { id: 9, type: "button", title: t('log_out'), value: "", icon: <OffIcon /> },
  ];

  return (
    <div className="show-fake-browser sidebar-page">
      <Container>
        <Sidebar style={{ display: "flex", flexDirection: "column" }} width={expand ? 260 : 56} collapsible className="border-end">
          <Sidenav expanded={expand} defaultOpenKeys={["3"]} appearance="white">
            <Sidenav.Body>
              <Nav>
                {data.map((item) => (
                  <>
                    {item.type === "button" ? (
                      <Nav.Item className={`fs-6 fw-light ${boutonActif === item.value ? "text-blue" : "text-dark"}`} eventKey={item.id} icon={item.icon} onClick={() => handleClick(item.value)}>
                        {item.title}
                      </Nav.Item>
                    ) : (
                      <Nav.Menu eventKey={item.id} trigger="hover" title={item.title} icon={item.icon} placement="rightStart" className="fs-6 fw-light text-dark">
                        {item.buttons.map((button) => (
                          <Nav.Item key={button.id} className={`${boutonActif === button.value ? "text-blue" : "text-dark"}`} eventKey={`${item.id} - ${button.id}`} onClick={() => handleClick(button.value)}>
                            {button.title}
                          </Nav.Item>
                        ))}
                      </Nav.Menu>
                    )}
                  </>
                ))}
              </Nav>
            </Sidenav.Body>
          </Sidenav>

        </Sidebar>

        <div className="w-100">
          <>
            {boutonActif === "home" && <HotelDetails t={t} />}
            {boutonActif === "roomtype" && <RoomType t={t} />}
            {boutonActif === "rates" && <Rates t={t} />}
            {boutonActif === "availability" && <Availability t={t} />}
            {boutonActif === "restrictions" && <Restrictions t={t} />}
            {boutonActif === "cancel" && <Cancel t={t} />}
            {boutonActif === "occupancy" && <PersonOccu t={t} />}
            {boutonActif === "rateadj" && <Rateadj t={t} />}
            {boutonActif === "promotions" && <Promotions t={t} />}
            {boutonActif === "messages" && <Messages t={t} />}
            {boutonActif === "booking" && <Booking t={t} />}
            {boutonActif === "billing" && <Paymentglobal t={t} />}
            {boutonActif === "paymentReception" && <Facture t={t} />}
            {boutonActif === "Paymentinfos" && <Paymentinfos t={t} />}
            {boutonActif === "admin" && <Administration />}
            {boutonActif === "photos" && <Photos />}
            {boutonActif === "services" && <Services />}
            {boutonActif === "settings" && <Settings />}
            {boutonActif === "renovations" && <Renovations />}
            {boutonActif === "rooms/newfees" && <Newfees t={t} />}
          </>
        </div>
      </Container>
    </div>
  );
};

export default Menu;
