import { NavLink } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
const Nav = ({ t, handleLogout }) => {

  return (
    <nav>
      <ul className="header-nav-list m-0 d-flex justify-content-beween align-items-center fs-6 fw-medium" style={{ listStyle: "none" }}>
        <li>
          <NavLink target="_blank" to="/">
            {t('home')}
          </NavLink>
        </li>

        <li>
          <NavDropdown
            title={t('products')}
            menuVariant="light"
          >
            <a className="p-3" href="/product" target="_blank">{t('create_')}</a>
            <NavDropdown.Divider />
            <a className="p-3" href="/exemple" target="_blank">{t('handle_product')}</a>
          </NavDropdown>
        </li>

        <li>
          <NavLink target="_blank" to="/contact">
            {t('contact')}
          </NavLink>
        </li>

        <li>
          <NavLink target="_blank" to="/about">
            {t('about')}
          </NavLink>
        </li>

        <li className="my-2 p-3 py-1">
          <NavLink target="_blank" to="/settings">
            {t("settings")}
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
