import Select from '../../../components/forms/Select'
import RadioInput from '../../../components/forms/RadioInput'
import { useState } from 'react'
import { useTranslation } from "react-i18next";

const Annulation = () => {

    const { t } = useTranslation();

    const [infos, setInfo] = useState({
        cancel: ""
    })

    const handleCancel = (value) => {
        setInfo({ ...infos, cancel: value });
    };

    const taxesData = [
        { title: t('night_and_taxe'), value: "FirstNight" },
        { title: t('50_from_booking_amount'), value: "50%" },
        { title: t('100_from_booking_amount'), value: "100%" },
    ]

    const renderCancel = (data) => (
        <>
            <ul>
                <li className='my-3'>{t('cancel_txt1')} {data} {t('cancel_txt2')}</li>
                <li className='my-3'>{t('cancel_txt3')}</li>
            </ul>
            <div className='my-3'>
                <span className='fs-6'>{t('cancellation_fees')}</span>
                <Select data={taxesData} />
            </div>
        </>
    );

    const cancelationData = [
        { title: t('24_cancel_delay'), value: "24hours", content: renderCancel("24") },
        { title: t('48_cancel_delay'), value: "48hours", content: renderCancel("48") },
        { title: t('72_cancel_delay'), value: "72hours", content: renderCancel("72") },
        {
            title: t('non_refundable'), value: "nonrefundable", content: <ul>
                <li className='my-3'>{t('non_refundable_caption')}</li></ul>
        }
    ]

    return (
        <div className='my-4 bg-white p-3'>
            <span className='fs-4 fw-medium text-dark'>{t('cancel_policies')}</span>
            <p className='fs-6 my-3'>{t('cancel_policies_caption')}</p>

            <div className='my-3 mt-4'>
                <span className='fs-6 fw-bold text-dark'>{t('policy_options')}</span>
                <p className='fs-6 my-3'>{t('policy_options_caption')}</p>

                {cancelationData.map((policy, index) => (
                    <div key={index} className='my-3 fs-6'>
                        <RadioInput id={policy.value} name="cancellationPolicy" label={policy.title} value={policy.value} onChange={() => handleCancel(policy.value)} />

                        {infos.cancel === policy.value && (
                            <p>{policy.content}</p>
                        )}

                    </div>
                ))}
            </div>
        </div>
    )
}

export default Annulation