import { Col } from 'react-bootstrap'
import RadioInput from '../../../components/forms/RadioInput'
import Input from '../../../components/forms/Input'
import { useState } from 'react'

const Daysnum = ({ t }) => {

    const [daysnum, setDaysnum] = useState({
        daysnum: ""
    })

    const handleDaysnum = (value) => {
        setDaysnum({ ...daysnum, daysnum: value });
    };

    const renderDaysnum = (data) => {
        if (data === "no") {
            return null;
        }
        return (
            <Col md="8" className='px-4'>
                <span className='my-3'>{t('ho_act_newrate_stayadvanced5')}</span>
                <Input placeholder="1" type="number" />
            </Col>
        );
    };

    const cancelationData = [
        { title: t('yes'), value: "yes", content: renderDaysnum("yes") },
        { title: t('no'), value: "no", content: renderDaysnum("no") },

    ]

    return (
        <div className='bg-white p-3'>
            <span className='fs-4 fw-medium text-dark'>{t('ho_act_newrate_stayadvanced3')}</span>
            <p className='fs-6 my-3'>{t('ho_act_newrate_stayadvanced4')}</p>
            <div className='mt-4'>
                {cancelationData.map((policy, index) => (
                    <div key={index} className='fs-6'>
                        <RadioInput id={policy.title} name="daysnum" label={policy.title} value={policy.value} onChange={() => handleDaysnum(policy.value)} />

                        {daysnum.daysnum === policy.value && (
                            <p>{policy.content}</p>
                        )}

                    </div>
                ))}
            </div>
        </div>
    )
}

export default Daysnum;
