import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid';
import RadioInput from './forms/RadioInput'
import { t } from 'i18next';

const AmountOption = () => {

  const [groupId] = useState(uuidv4());

  return (

    <Row className='fs-6 align-items-center'>
      <Col md="6" className='d-flex align-items-center my-3'>
        <span className='fw-medium text-dark'>{t('rate_ajust')}</span>
      </Col>
      <Col md="6" className='d-flex align-items-center my-3'>
        <div className='mx-3'>
          <RadioInput id={`discount_${groupId}`} label={t('discount')} value="Remise" name={`amount_${groupId}`}/>
        </div>

        <div className='mx-3'>
          <RadioInput id={`increase_${groupId}`} label={t('increase')} value="Augmentation" name={`amount_${groupId}`} />
        </div>
      </Col>
    </Row>
  )
}

export default AmountOption