import Choice from "../../../components/Choice";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Input from "../../../components/forms/Input";
import CheckInput from "../../../components/forms/CheckInput";
import Select from "../../../components/forms/Select";
import Height from "../../../components/Height";
import { useTranslation } from "react-i18next";

const DetailsAccessibilityServices = () => {
    const { t } = useTranslation();

    const [infos, setInfos] = useState({
        accessibility: "no",
        parts: "no",
        elevator: "no"
    });

    const handleAccessibilityParts = (value) => {
        setInfos({ ...infos, parts: value });
    };

    const handleElevator = (value) => {
        setInfos({ ...infos, elevator: value });
    };


    const [accesItems, setaccesItems] = useState({});

    const handleaccesItems = (value) => {
        setaccesItems((prevAccesItems) => ({
            ...prevAccesItems,
            [value]: !prevAccesItems[value],
        }));
    };

    const data = [
        { title: t('reception'), value: "Réception" },
        { title: t('concierge'), value: "Conciergerie" },
        { title: t('fitness_center'), value: "RemiseEnForme" },
        { title: t('living_room'), value: "Salon" },
        { title: t('meeting_rooms'), value: "SallesReunion" },
        { title: t('onsite_restaurant'), value: "Restaurant" },
        { title: t('parking'), value: "Parking" },
        { title: t('van_parking'), value: "ParkingVans" },
        { title: t('valet_parking_equiped_with_wheelchair'), value: "ServiceVoiturier" },
        { title: t('ramp'), value: "RampeAccès" },
        { title: t('pool'), value: "Piscine" },
        { title: t('public_toilets'), value: "Toilettes" },
        { title: t('spa'), value: "Spa" },
        { title: t('shuttle_wheelchair_accessible'), value: "Navette" },
    ];

    const parkingLength = Array.from({ length: 20 }, (_, index) => ({
        title: `${index + 1}`,
        value: index + 1,
    }));

    return (

        <>
            <p className="fs-6 my-3">{t('accessibility_features_provide_comfort_for_travelers')}</p>
            <Choice text={t('common_areas_are_they_accessible_by_wheelchairs')} onChange={handleAccessibilityParts} selectedChoice={infos.parts} />

            {infos.parts === "yes" && (
                <>
                    <div className="my-3 fw-medium">{t('accessibility_features_for_wheelchairs')}</div>

                    {data.map((check, index) => (
                        <>
                            <div className="my-3">
                                <CheckInput key={index} id={check.value} label={check.title} checked={accesItems[check.value] || false} onChange={() => handleaccesItems(check.value)} />
                            </div>

                            {accesItems[check.value] && (
                                <>
                                    {check.value === "Réception" && <Height label={t('register_desk_height')} />}

                                    {check.value === "Parking" && (
                                        <>
                                            <Row className="fs-6 align-items-center">
                                                <Col md="6" className="d-flex align-items-center my-3">
                                                    <span className="fw-medium text-dark">{t('parking_places_accessible')}</span>
                                                </Col>
                                                <Col md="6" className="d-flex align-items-center my-3">
                                                    <Select data={parkingLength} />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ))}
                </>
            )}

            <Choice text={t('does_the_accommodation_have_elevators')} onChange={handleElevator} selectedChoice={infos.elevator} />

            {infos.elevator === "yes" ? (
                <>
                    <Row>
                        <Col md="6" className="my-3">
                            <Input placeholder={t('elevator_door_height_in_inches')} type="number" />
                        </Col>
                        <Col md="6" className="my-3">
                            <Input placeholder={t('elevator_door_height_in_centimeters')} type="number" />
                        </Col>
                    </Row>

                    <div className="my-3">
                        <CheckInput id="RampeDascenseur" label={t('elevator_ramp_wheelchair_accessible')} />
                    </div>
                </>
            ) : (
                <>
                    <div className="my-3">
                        <CheckInput id="PasDascenseur" label={t('no_elevator_needed')} />
                    </div>

                    <div className="my-3">
                        <CheckInput id="EtagesSuperieur" label={t('upper_floors_by_stairs')} />
                    </div>
                </>
            )}
        </>

    );
};

export default DetailsAccessibilityServices;
