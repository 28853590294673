import { Row, Col } from "react-bootstrap";
import Head from "../../components/Head";
import { Tag, CircleDollarSign } from "lucide-react";
import Skoop from "./components/Skoop";
import { Badge, Button } from "rsuite";
import { Link } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";
import { GiPayMoney } from "react-icons/gi";

const Details = ({ t }) => {
  return (
    <section className="mb-4">
      <Head data="Ibis Paris Maine Mont" dynamicData={t('status')} dynamicData1={t('hotel_num')} dd={t('bookable')} dd1="102377512" p_root="My products . Hotel" />

      <div lg="5" xl="5" sm="12" className="container py-4">
        <h1 className="text-capitalize text-dark fs-4 fw-bold">{t('main_hotel')}</h1>
        <p className="fs-8">{t('main_hotel_caption')}</p>
      </div>
      <div className="container">
        <Row className="d-flex flex-row justify-content-between">
          <Col lg="6" xl="6" sm="12" className="my-3  p-4  bg-white">
            <a href="true" className="text-dark text-decoration-none">
              <div>
                <Tag strokeWidth={1} size={22} className="text-blue" />
                <span className="fs-5 fw-bold text-dark mx-2">{t('hotel_actuel_promo')}</span>
                <p className="fs-8 mt-3">{t('hotel_actuel_promo_titre')}</p>
              </div>
              <div className="d-flex flex-row">
                <div className="d-flex justify-content-center align-items-center rounded-2 border mt-4 ">
                  <Badge color="orange" content="85">
                    <Button>{t('custumer_experience')}</Button>
                  </Badge>
                </div>
              </div>
            </a>
          </Col>

          <Col lg="5" xl="5" sm="12" className="my-3  p-4  bg-white">
            <a href="#" className="text-dark text-decoration-none">
              <div>
                <CircleDollarSign strokeWidth={1} size={28} className="text-blue mb-2" />
                <span className="fs-5 fw-bold text-dark mx-2">{t('hotel_actuel_sales')}</span>
                <p className="fs-8">{t('hotel_actuel_sales_txt')}</p>
              </div>
              <div className="d-flex flex-row">
                <div className="d-flex justify-content-center align-items-center rounded-2 border mt-3 ">
                  <>
                    <Badge color="cyan" content={90}>
                      <Button>{t('our_strength')}</Button> {/* cest un lien vers la page performances */}
                    </Badge>
                  </>
                </div>
              </div>
            </a>
          </Col>
        </Row>
      </div>
      <div className="container my-4">
        <Row className="d-flex justify-content-center">
          <Col md="4" lg="4" className="mt-3">
            <div className="coverdetails border rounded p-4" style={{ height: "100%" }}>
              <div className="backgroundimageDI"></div>
            </div>
          </Col>
          <Col lg="8" xl="8" className="my-3 p-4 rounded bg-white">
            <div style={{ height: "100%" }}>
              <span className="fs-4 fw-bold text-dark mx-2">{t('ho_act_skoop')}</span>
              <p className="fs-8 pb-4 mx-2">{t('ho_act_skoop_txt')}</p>
              <Skoop t={t} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="container d-flex flex-column">
        <Row className="d-flex flex-row bg-white">
          <Col md="6" lg="4" className="mt-2 p-3">
            <div className="coverdetails border rounded p-2" style={{ height: "20vw" }}>
              <div className="backgroundimageD"></div>
              <div className="content mt-4">
                <Badge color="" content="New"></Badge>
                <div className="fs-5 fw-bold text-white mt-2 mb-3">{t('ho_act_add_value')}</div> {/*promotion */}
                <div>
                  <span className="" style={{ color: "#E0E0E0" }}>
                    {t('ho_act_add_value_txt')}
                  </span>
                </div>
                <Link className="fs-8 fw-medium text-white" to="#">
                  <div className="mt-3 d-flex flex-row">
                    {t('ho_act_add_btn')}
                    <span className="mx-4 text-white">
                      <IoIosArrowDropright />
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col md="6" lg="8" className="mt-2 p-3">
            <div className=" border rounded p-2" style={{ height: "20vw" }}>
              <div className="backimg"></div>
              <div className="backimgcontent mt-3">
                <Badge color="blue" content="Campaign"></Badge>
                <div className="fs-5 fw-bold text-dark mt-2 mb-3">{t('ho_act_visibility')}</div>
                <div>
                  <span className="" style={{ color: "#333333" }}>
                    {t('ho_act_visibility_txt')}
                  </span>
                </div>
                <Link className="fs-8 fw-bold text-dark" to="#">
                  <div className="mt-3 d-flex justify-content-between">
                    {t('ho_act_visibility_txt1')}
                    <span className="text-dark">
                      <IoIosArrowDropright />
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex flex-row bg-white">
          <Col md="6" lg="9" className="mt-2 p-3">
            <div className="coverdetails border rounded p-2">
              <div className="backgroundimageL"></div>
              <div className="content mt-3">
                <Badge color="green" content="Today"></Badge>
                <div className="fs-5 fw-bold text-dark mt-2 mb-3">{t('ho_act_same_day')}</div> {/*compagne  */}
                <div>
                  <span className="" style={{ color: "#333333" }}>
                    {t('ho_act_same_day_txt')}
                  </span>
                </div>
                <Link className="fs-8 fw-bold text-dark" to="#">
                  <div className="mt-3 d-flex justify-content-between">
                    {t('ho_act_same_day_btn')}
                    <span className="text-dark">
                      <IoIosArrowDropright />
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col md="6" lg="3" className="mt-3 p-2">
            <div className="coverdetails border rounded p-2">
              <div className=""></div>
              <div className="content">
                <GiPayMoney style={{ fontSize: "4em", color: "orange" }} />
                <div className="fs-5 fw-bold text-dark mt-2">{t('ho_act_best_promo')}</div> {/*compagne  */}
                <div>
                  <span className="" style={{ color: "#333333" }}>
                    {t('ho_act_best_promo_txt')}
                  </span>
                </div>
                <Link className="fs-8 fw-bold text-dark" to="#">
                  <div className="mt-3 d-flex justify-content-between">
                    {t('ho_act_other_promo')}
                    <span className="text-orang">
                      <IoIosArrowDropright className="text-orange" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>

    </section>
  );
};

export default Details;
