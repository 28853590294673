import { Row, Col } from "react-bootstrap";
import Choice from '../../components/Choice'
import Cancelpolicy from "./components/Cancelpolicy";
import React, { useState } from "react";
import { Plus } from "lucide-react";
import Selectrate from "./components/Selectrate";
import { useTranslation } from "react-i18next";

const Updatecancel = () => {

  const { t } = useTranslation();

  const [cancelpo, setCancelpolicy] = useState({
    cancelpo: "no",
  });

  const handleCancelpolicy = (value) => {
    setCancelpolicy({ ...cancelpo, cancelpo: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <section>
      <div className="container">
        <Row>
          <Col className="my-3">
            <form onSubmit={handleSubmit}>
              <fieldset className="mt-3 bg-white p-5">
                <>
                  <span className="fs-4 fw-bold text-dark">{t('ho_act_detailscancel')}</span>
                  <p className="fs-6 mb-4">{t('ho_act_detailscancelP')}</p>
                </>
                <>
                  <span className="fs-4 fw-bold text-dark"> {t('ho_act_detailscancel1')} </span>
                  <p className="fs-6 ">
                    <ul className="mx-2 my-2">
                      <li>{t('ho_act_detailscancelLi')}</li>
                      <li>{t('ho_act_detailscancelLi1')}</li>
                    </ul>
                  </p>
                </>
                <Row className="m-auto">
                  <Col md="12" xl="12" className="mx-1 px-4 bg-white d-flex flex-column">
                    <div className="bg-white ">
                      <Choice text={t('ho_act_detailscancelYesNo')} onChange={handleCancelpolicy} selectedChoice={cancelpo.cancelpo} />
                      {cancelpo.cancelpo === "yes" && (
                        <div>
                          <Cancelpolicy t={t} />
                          <Col md="12" xl="12" className="mx-1 px-4 my-2 bg-white d-flex flex-column">
                            <span className="fs-4 mb-1 fw-bold text-dark">{t('ho_act_detailscancelDates')}</span>
                            <p className="fs-6  ">{t('ho_act_detailscancelAdd')}</p>
                          </Col>
                          <Col md="12" xl="12" className="mx-1 px-4 bg-white d-flex flex-row">
                            <Plus strokeWidth={1} size={22} className="text-blue pt-1" />
                            <span className="fs-5 text-dark mx-2">
                              {" "}
                              <a href="true">{t('ho_act_newperiod')}</a>{" "}
                            </span>
                          </Col>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col className="my-4">
                    <div className="my-4 bg-white ">
                      <span className="fs-4 fw-bold text-dark ">{t('ho_act_detailscancelTarif')}</span>
                      <p className="fs-6 mb-4">{t('ho_act_detailscancelTarifp')}</p>
                      <Selectrate />
                    </div>
                  </Col>
                </Row>
                <button type="submit" className="btn-blue p-3 mx-3 rounded-3 ">
                  {t('save')}
                </button>
                <button type="cancel" className="btn-orange p-3 px-5 rounded-3 ">
                  {t('cancel')}
                </button>
              </fieldset>
            </form>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Updatecancel;
