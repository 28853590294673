import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Title from "./Title";
import Target from "./Target";
import Offer from "./Offer";
import BookingDate from "./BookingDate";

const CreatePromo = ({createPromo,CloseCreatePromo,setShow,t }) => {

    const [step, setStep] = useState(1);

    const handleNextStep = () => {
      setStep(step + 1);
    };

    const handleClose =  () => {
      setShow(false);

    }
  
  return (
      <>
        <Modal show={createPromo} onHide={CloseCreatePromo} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="text-dark">{t('marketing_modal')}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-dark py-4 px-3">
                {step === 1 && <Title t={t} />} 
                {step === 2 && <Target t={t} />}   
                {step === 3 && <Offer t={t} />}
                {step === 4 && <BookingDate t={t} />}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {step < 4 ? <button
              className="btn-blue p-3 px-4 rounded-5"
              onClick={handleNextStep}
              disabled={step>=4}
            >
              {t('next')}
            </button> : <button
              className="btn-blue p-3 px-4 rounded-5"
              onClick={handleClose}
            >
              {t('create')}
            </button>}
          </Modal.Footer>
        </Modal>
      </>
  );
};

export default CreatePromo;
