import React from 'react'
import { Col, Row } from 'react-bootstrap'

const BookingsList = ({ data }) => {
    return (
        <>
            {
                data.map(item => (
                    <Row className='border my-4 px-3 align-items-center'>
                        <Col xs="3" md="2" lg="1" className='my-3'>
                            <img src={item.avatar} width="100%" height="100%" alt={`Ilusatrtion du produit - ${item.title}`} />
                        </Col>
                        <Col xs="9" md="8" lg="4" className='my-3'>
                            <div className='fs-6 fw-medium text-dark'>{item.title}</div>
                            <p>{item.date}</p>
                        </Col>
                        <Col xs="3" lg="2" className='my-3'>
                            John doe
                        </Col>
                        <Col xs="3" lg="2" className='my-3'>
                            258440181
                        </Col>
                        <Col xs="3" lg="2" className='my-3'>
                            <div className={`${item.status === "En marche" ? "text-green" : item.status === "Suspendu" ? "text-red" : 'text-orange'}`}>
                                {item.status}
                            </div>
                        </Col>
                        <Col xs="3" lg="1" className='my-3'>
                            {item.amount}€
                        </Col>
                    </Row>
                ))
            }
        </>
    )
}

export default BookingsList
