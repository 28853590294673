import { Coins, Eye, MoveUpRight, ShoppingCart } from 'lucide-react'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const PromoCounts = ({ t }) => {

    const data = [
        { content: t('marketing_Revenus_plus'), value: "€ 559.25", icon: <span className='bg-success-subtle text-success p-3'><Coins /></span> },
        { content: t('marketing_visibilite_plus'), value: "90%", icon: <span className='bg-primary-subtle text-primary p-3'><Eye /></span> },
        { content: t('marketing_reservations_plus'), value: "120%", icon: <span className='bg-warning-subtle text-warning p-3'><ShoppingCart /></span> }
    ]

    return (
        <Row className='my-4 text-dark'>
            {data.map(item => (
                <Col lg="4" className='my-3'>
                    <div className='border text-center p-4 h-100'>
                        <div className='d-flex justify-content-between fs-6'>{item.content} <span className='text-green'><MoveUpRight /></span></div>
                        <div className='fs-1 fw-medium my-3'>{item.value}</div>
                        <div className='d-flex justify-content-between align-items-center'><span className='text-blue text-decoration-underline'>{t('see_details')}</span> {item.icon}</div>
                    </div>
                </Col>
            ))}
        </Row>
    )
}

export default PromoCounts
