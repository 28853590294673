import { Row, Col } from 'react-bootstrap';

const CalendarDays = (props) => {

    return (
        <>
            {props.daysOfYear.map((year, yearIndex) => (
                <>
                    <Row className='align-items-center justify-content-between'>
                        <Col xs="4" lg="3" xl="2">
                            <div className='fs-1 fw-medium text-dark'>{props.daysOfYear[yearIndex] && (new Date().getFullYear() + yearIndex)}</div>
                        </Col>
                        <Col xs="8" md="5" lg="4" xl="3">
                            <button className={`w-50 p-3 px-4 ${props.view === "days" ? "btn-blue" : "btn-outline-blue"}`} onClick={() => (props.toggleView('days'))}>{props.t('per_day')}</button>
                            <button className={`w-50 p-3 px-4 ${props.view === "months" ? "btn-blue" : "btn-outline-blue"}`} onClick={() => (props.toggleView('months'))}>{props.t('per_month')}</button>
                        </Col>
                    </Row>
                    <Row key={yearIndex} className='py-3'>
                        {year.map((month, monthIndex) => (
                            <Col md="6" lg="4" xl="4" key={`${yearIndex}-${monthIndex}`} className='my-3'>
                                <div className='px-3'>
                                    <div className='fs-3 fw-medium text-dark'>{props.getMonthName(monthIndex)}</div>
                                    <Row>
                                        {month.map((day, dayIndex) => (
                                            <Col xs="3" sm="2" md="3" xl="2" key={dayIndex} className="my-1 p-2">
                                                <button
                                                    className={`p-1 border w-100 ${props.isPastDate(day.day, monthIndex, yearIndex) ? "bg-dark-subtle border-dark" :
                                                        day.isBlocked ? "bg-secondary-subtle border border-dark text-decoration-line-through" :
                                                            "bg-white border-blue"
                                                        }`}
                                                    onClick={() => props.openModal(yearIndex, monthIndex, dayIndex, day)}
                                                    disabled={props.isPastDate(day.day, monthIndex, yearIndex)}
                                                >
                                                    <div className='text-dark text-start fs-6 fw-medium'>{day.day}</div>
                                                    <div className='fw-light my-2'>{day.price}€</div>
                                                </button>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        ))}
                    </Row></>
            ))}
        </>
    );
};

export default CalendarDays;
