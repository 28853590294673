import React from "react";
import { Col, Row } from "react-bootstrap";
import { Panel, PanelGroup } from "rsuite";
import { useTranslation } from "react-i18next";
import img from '../../assets/icones/undraw_pending_approval_xuu9.svg'

const Faq = () => {
  const { t } = useTranslation();

  const faqData = t("faq_data", { returnObjects: true });


  faqData.sort(() => Math.random() - 0.5);

  const selectedFaqItems = faqData.slice(0, 4);

  return (
    <div className="text-dark py-5">
      <Row className="justify-content-between align-items-center">

        <Col xl="7">
          <div className="fs-4 fw-medium text-blue">{t("frequently_asked_questions")}</div>
          <p className="fs-6 my-3 fw-light">{t('faq_intro')}</p>
          <PanelGroup accordion>
            {selectedFaqItems.map((item, index) => (
              <div key={index} className="border-top my-3">
                <Panel header={<div className="fs-5 fw-bold">{item.title}</div>}>
                  <p className="fs-6 fw-light">{item.content}</p>
                </Panel>
              </div>
            ))}
          </PanelGroup>
        </Col>
        <Col xl="4">
          <img
            src={img}
            alt="illustration faq"
            width="100%"
            height="100%"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Faq;
