import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CalendarCheck2, CalendarX2 } from "lucide-react";
import { DatePicker } from "rsuite";
import { useTranslation } from "react-i18next";

const DateYear = () => {
  const { t } = useTranslation();
  const today = new Date();
  const currentYear = today.getFullYear();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  const disabledDate = (date) => {
    return date.getFullYear() !== currentYear;
  };

  return (
    <Row>
      <Col md="6" className="my-3">
        <DatePicker className="border w-100 p-3" oneTap placeholder={t('from')} onChange={handleStartDate} value={startDate} disabledDate={disabledDate} caretAs={CalendarCheck2} />
      </Col>
      <Col md="6" className="my-3">
        <DatePicker className="border w-100 p-3" oneTap placeholder={t('to')} onChange={handleEndDate} value={endDate} disabledDate={disabledDate} caretAs={CalendarX2} />
      </Col>
    </Row>
  );
};

export default DateYear;
