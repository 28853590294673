import { useEffect, useState } from "react";
import HousePrivacy from './components/Privacy'
import Color from './components/Color'
import Services from './components/Services'
import HotelPrivacy from "./hotelComponents/Privacy";
import Host from "./components/Host";

const Additional = ({ t }) => {

  const [product, setproduct] = useState("");

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setproduct(savedType);
    }
  }, []);

  const configurations = {
    Restaurant: [
      { content: <Services t={t} /> },
    ],

    Maison: [
      { content: <HousePrivacy /> },
    ],

    Activité: [
      { content: <Host /> },
    ],

    Transport: [
      { content: <Host /> },
    ],

    Voiture: [
      { content: <Color t={t} /> },
    ],

    Package: [
      { content: <Host /> },
    ],

    Hotel: [
      { content: <HotelPrivacy t={t} /> },
    ],
  };

  const data = configurations[product] || [];


  return (
    <>
      {data.map((choice, index) => (
        <div key={index}>{choice.content}</div>
      ))}
    </>
  );
};

export default Additional;
