import React from 'react'
import ToolTip from '../Tooltip'

const CheckInput = ({ label, onChange, id, disabled,checked,info, value }) => {
    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center'>
                <input id={id} type="checkbox" onChange={onChange} disabled={disabled} checked={checked} value={value} />
                <label htmlFor={id} className='mx-2'>{label}</label>
            </div>
            {info && (<ToolTip body={info} placement="top"/>) }
        </div>
    )
}

export default CheckInput