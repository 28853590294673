import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Users from './Users'
import Messages from './Messages'

const Chat = ({ t }) => {

  const data = [
    { name: "John doe", date: "16h34", booking: true, message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", gender: "m" },
    { name: "Jane wilder", date: "09h15", booking: false, message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", gender: "w" },
    { name: "Harry joshua", date: "14h45", booking: true, message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", gender: "m" }
  ]

  return (
    <div className='my-3'>
      <Row>
        <Col xl="4" className='p-0'>
          <Users t={t} data={data} />
        </Col>
        <Col xl="8" className='ps-0'>
          <Messages t={t} data={data} />
        </Col>
      </Row>
    </div>
  )
}

export default Chat
