import { Row, Col } from "react-bootstrap";
import { Bed, Book, Briefcase, Building2, Bus, CakeSlice, Car, CarFront, ChefHat, Coffee, Hotel, Map, Pizza, Plane, Sandwich, Ship, Soup, SprayCan, Ticket, TrainFront, Truck, User2, Waves, HeartHandshake, Mountain, Construction, Leaf, Baby, CarTaxiFront, Users2, Building, Bike, BusFront, Sailboat } from "lucide-react";
import { useEffect, useState } from "react";
import { RadioTile, RadioTileGroup } from "rsuite";
import Icon from "../../assets/icones/icones";

const Type = ({ t }) => {

  const [product, setproduct] = useState("");

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setproduct(savedType);
    }
  }, []);

  const [infos, setInfos] = useState({
    choice: "",
  });

  const handleChoice = (value) => {
    setInfos({ ...infos, choice: value });
  };

  const configurations = {
    Restaurant: [
      { title: t('restaurants'), icon: <ChefHat strokeWidth={1.3} size={30} />, value: "Restaurants", content: t('Restau1p'), },
      { title: t('fast_food'), icon: <Sandwich strokeWidth={1.3} size={30} />, value: "Fast-food", content: t('Restau2p'), },
      { title: t('pizzeria'), icon: <Pizza strokeWidth={1.3} size={30} />, value: "Pizzeria", content: t('Restau3p'), },
      { title: t('bistros'), icon: <Soup strokeWidth={1.3} size={30} />, value: "Bistros", content: t('Restau4p'), },
      { title: t('Restau5'), icon: <CakeSlice strokeWidth={1.3} size={30} />, value: "Crêperies", content: t('Restau5p'), },
      { title: t('Restau6'), icon: <Coffee strokeWidth={1.3} size={30} />, value: "Cafétéria", content: t('Restau6p'), },
      { title: t('Restau7'), icon: <Truck strokeWidth={1.3} size={30} />, value: "Marchands ambulants", content: t('Restau6p'), },
    ],

    Maison: [
      { title: t('house'), value: "Maison", icon: <Icon name="maison" /> },
      { title: t('appart'), value: "Appartement", icon: <Icon name="appart" /> },
      { title: t('barn'), value: "Grange", icon: <Icon name="grange" /> },
      { title: t('host_room'), value: "Chambre d'hotes", icon: <Icon name="host_room" /> },
      { title: t('boat'), value: "Bateau", icon: <Icon name="boat" /> },
      { title: "Cabane", value: "Cabane", icon: <Icon name="caban" /> },
      { title: t('camping_car'), value: "Camping car", icon: <Icon name="caravan" /> },
      { title: t('special_house'), value: "Casa particular", icon: <Icon name="casa" /> },
      { title: t('castle'), value: "Château", icon: <Icon name="castle" /> },
      { title: t('troglodyte_house'), value: "Maison troglodyte", icon: <Icon name="troglodyte" /> },
      { title: t('container'), value: "Conteneur", icon: <Icon name="container" /> },
      { title: t('cycladique_house'), value: "Maison cycladique", icon: <Icon name="cycladique" /> },
      { title: t('dammuso'), value: "Dammuso", icon: <Icon name="dammuso" /> },
      { title: "Dôme", value: "Dome", icon: <Icon name="dome" /> },
      { title: t('organic_house'), value: "Maison organique", icon: <Icon name="organique" /> },
      { title: t('farm'), value: "Ferme", icon: <Icon name="farm" /> },
      { title: t('host_houses'), value: "MaisonHotes", icon: <Icon name="host_home" /> },
      { title: t('water_house'), value: "Logement sur l'eau", icon: <Icon name="water_edge" /> },
      { title: "Kezhan", value: "Kezhan", icon: <Icon name="kezhan" /> },
      { title: "Minsu", value: "Minsu", icon: <Icon name="minsu" /> },
      { title: "Riad", value: "Riad", icon: <Icon name="riad" /> },
      { title: "Ryokan", value: "Ryokan", icon: <Icon name="ryokan" /> },
      { title: t('hut_house'), value: "Cabane de berger", icon: <Icon name="shepard" /> },
      { title: t('tent'), value: "Tente", icon: <Icon name="tent" /> },
      { title: t('tiny_house'), value: "Tiny house", icon: <Icon name="tiny_house" /> },
      { title: t('towel'), value: "Tour", icon: <Icon name="towel" /> },
      { title: t('tree_hosue'), value: "Cabane perchée", icon: <Icon name="cabin_perch" /> },
      { title: "Trullo", value: "Trullo", icon: <Icon name="trullo" /> },
      { title: t('windmill'), value: "Moulin à vent", icon: <Icon name="windmill" /> },
      { title: "Yourte", value: "Yourte", icon: <Icon name="yourte" /> },
    ],

    Activité: [
      { title: t('entrace_tickets'), icon: <Ticket strokeWidth={1.3} size={25} />, value: "Billets d'entrée", content: t('entrace_tickets_caption') },
      { title: t('guided_tours'), icon: <User2 strokeWidth={1.3} size={25} />, value: "Visites guidées", content: t('guided_tours_caption') },
      { title: t('day_trips'), icon: <Map strokeWidth={1.3} size={25} />, value: "Excursions d'une journée", content: t('day_trips_caption') },
      { title: t('water_activities'), icon: <Waves strokeWidth={1.2} size={25} />, value: "Activités aquatiques", content: t('water_activities_caption') },
      { title: t('workshops'), icon: <Book strokeWidth={1.3} size={25} />, value: "Ateliers et cours", content: t('workshops_caption') },
      { title: t('days_trip'), icon: <Plane strokeWidth={1.3} size={25} />, value: "Excursions de plusieurs jours", content: t('days_trip_caption') },
    ],

    Transport: [
      { title: t('taxi'), icon: <CarTaxiFront strokeWidth={1.3} size={25} />, value: "Taxi", content: t('tax_caption') },
      { title: t('transfer'), icon: <Car strokeWidth={1.3} size={25} />, value: "Tranferts", content: t('transfer_caption') },
      { title: t('bus'), icon: <Bus strokeWidth={1.3} size={25} />, value: "Bus", content: t('bus_caption') },
      { title: t('train'), icon: <TrainFront strokeWidth={1.3} size={25} />, value: "Train", content: t('train_caption') },
      { title: t('plane'), icon: <Plane strokeWidth={1.3} size={25} />, value: "Avion", content: t('plane_caption') },
      { title: t('boat'), icon: <Ship strokeWidth={1.3} size={25} />, value: "Bateau", content: t('boat_caption') },
    ],

    Voiture: [
      { title: t('compact'), icon: <CarFront strokeWidth={1.3} size={25} />, value: "Compacte", content: t('compact_catpion') },
      { title: t('berlines'), icon: <CarFront strokeWidth={1.3} size={25} />, value: "Berline", content: t('berlines_caption') },
      { title: t('suv'), icon: <Car strokeWidth={1.3} size={25} />, value: "SUV", content: t('suv_caption') },
      { title: t('utility'), icon: <Truck strokeWidth={1.3} size={25} />, value: "Utilitaire", content: t('utility_caption') },
      { title: t('monospace'), icon: <Bus strokeWidth={1.3} size={25} />, value: "Monospace", content: t('monospace_caption') },
      { title: t('vans'), icon: <BusFront strokeWidth={1.3} size={25} />, value: "Van", content: t('van_caption') },
      { title: t('moto'), icon: <Bike strokeWidth={1.3} size={25} />, value: "Moto", content: t('moto_caption') },
      { title: t('ship'), icon: <Sailboat strokeWidth={1.3} size={25} />, value: "Bateau", content: t('ship_caption') },
      { title: t('yacht'), icon: <Ship strokeWidth={1.3} size={25} />, value: "Yacht", content: t('yacht_caption') },
    ],

    Hotel: [
      { title: t('lux'), icon: <Building2 strokeWidth={1.3} size={25} />, value: "Hotel de luxe", content: t('lux_text') },
      { title: t('business'), icon: <Briefcase strokeWidth={1.3} size={25} />, value: "Hôtels d’affaires ", content: t('business_text') },
      { title: t('economic'), icon: <Building strokeWidth={1.3} size={25} />, value: "Hotel économique", content: t('eco_text') },
      { title: t('family_hotel'), icon: <Users2 strokeWidth={1.3} size={25} />, value: "Familial", content: t('family_hotel_caption') },
      { title: t('health_and_care'), icon: <SprayCan strokeWidth={1.3} size={25} />, value: "Hôtels de santé et bien-être", content: t('health_care_text') },
      { title: t('boutique'), icon: <Hotel strokeWidth={1.3} size={25} />, value: "Boutique", content: t('boutique_caption') },
      { title: t('hostel'), icon: <Bed strokeWidth={1.3} size={25} />, value: "Auberges de jeunesse", content: t('hostel_text') },
    ],

    Package: [
      { title: t('cultural'), icon: <HeartHandshake strokeWidth={1.5} size={35} />, value: "Culturel", content: t('cultural_text') },
      { title: t('adventure'), icon: <Mountain strokeWidth={1.5} size={35} />, value: "Aventure", content: t('adventure_text') },
      { title: t('relaxation'), icon: <SprayCan strokeWidth={1.5} size={35} />, value: "Détente", content: t('relaxation_text') },
      { title: t('urban'), icon: <Construction strokeWidth={1.5} size={35} />, value: "Urbain", content: t('urban_text') },
      { title: t('nature'), icon: <Leaf strokeWidth={1.5} size={35} />, value: "Nature", content: t('nature_text') },
      { title: t('familial'), icon: <Baby strokeWidth={1.5} size={35} />, value: "Famillial", content: t('familial_text') },
      { title: t('cruise'), icon: <Ship strokeWidth={1.5} size={35} />, value: "Croisiére", content: t('cruise_text') }
    ],
  };

  const data = configurations[product] || [];


  return (
    <section className="container form-transition">
      <Row className="justify-content-center">
        <Col lg="7" xl="6" className="my-3">
          <>
            <h2 className="fs-3 lh-base fw-medium text-dark">{t('product_type_title')}</h2>
            <p className="fs-5 fw-light">{t('product_type_text')}</p>

            <div className="my-3">
              {product === "Maison"

                ?

                <RadioTileGroup>
                  <Row>
                    {data.map((choice, index) => (
                      <Col key={index} sm="6" md="4" className="my-2">
                        <RadioTile value={choice.value} onChange={handleChoice} className={`h-100 rounded-4 py-4 p-3 ${infos.choice === choice.value ? "border-dark" : "border"}`}>
                          <div className="text-dark fs-6 fw-mesium p-2">
                            <div>{choice.icon}</div>
                            <div className="mt-1 fw-light">{choice.title}</div>
                          </div>
                        </RadioTile>
                      </Col>
                    ))}
                  </Row>
                </RadioTileGroup>
                :
                <RadioTileGroup>
                  <Row>
                    {data.map((choice, index) => (
                      <Col key={index} xs="12" className="my-2">
                        <RadioTile onChange={handleChoice} value={choice.value} className={`h-100 rounded-4 py-4 p-3 ${infos.choice === choice.value ? "border-dark" : "border"}`}>
                          <div className="text-dark d-flex">
                            {choice.icon}
                            <span className="px-3">
                              <div className="fs-6 fw-medium">{choice.title}</div>
                              <p className="fw-light">{choice.content}</p>
                            </span>
                          </div>
                        </RadioTile>
                      </Col>
                    ))}
                  </Row>
                </RadioTileGroup>}
            </div>
          </>
        </Col>
      </Row>
    </section>
  );
};

export default Type;
