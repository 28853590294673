import { useState } from "react";
import Location from './Location'
import PaymentOption from "./PaymentOption";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsSki = ({ checked }) => {
    const { t } = useTranslation();
    const [checkedItems, setCheckedItems] = useState({});
    const [infos, setInfos] = useState({
        ski: "",
    });

    const handleSki = (value) => {
        setInfos({ ...infos, beach: value });
    };

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const pistes = [
        { title: t('ski_area_nearby'), value: "Domaine-skiable" },
        { title: t('ski_lifts_nearby'), value: "Remontées-mécaniques" },
        { title: t('ski_tracks_nearby'), value: "Pistes-proximité" },
    ]

    return (

        <div className="my-3 fs-6">
            {checked === 'Pistes-ski' && (
                <>
                    {pistes.map((item, indexOf) => (
                        <div key={indexOf} className='px-3 my-3'>
                            <>

                                <div className='d-flex align-items-center'>
                                    <CheckInput id={item.value} label={item.title} checked={checkedItems[item.value] || false} onChange={() => handleCheckboxChange(item.value)} />
                                </div>
                            </>

                        </div>
                    ))}
                </>
            )}

            {checked === 'NavetteSki' && (
                <PaymentOption />
            )}

            {['Snowboard', 'SkiFond', 'CoursDeSki', 'LocationSki', 'Ski-alpin'].includes(checked) && (
                <Location onChange={handleSki} />
            )}

        </div>

    )
}

export default DetailsSki