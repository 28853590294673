import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Info } from 'lucide-react';
import React, { useState, useRef } from 'react';
import RatesOption from "./components/RatesOption";

const Pricing = ({ lg, xl }) => {

  const { t } = useTranslation();

  const inputRef = useRef(null);
  const [price, setPrice] = useState(10);

  const handleChange = (event) => {
    const value = parseFloat(event.target.value) || 0;
    setPrice(value);
    sessionStorage.setItem('price', value);
  };

  const purcentClient = 9
  const service = (price * purcentClient) / 100;
  const total = price + service;

  const purcentProvider = 3
  const host = (price * purcentProvider) / 100;
  const benefit = price - host;


  return (
    <section className="bg-white form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg={`${lg ? lg : "7"}`} xl={`${xl ? xl : "7"}`} className="my-3">
            <h2 className="fs-3 lh-base fw-medium text-dark">{t('pricing_title')}</h2>
            <p className="fs-5 fw-light">{t('pricing_caption')}</p>

            <div className="my-3">
              <div className="fw-bold text-dark d-flex" style={{ fontSize: '800%' }}>
                <label>€</label>
                <input
                  ref={inputRef}
                  type="number"
                  className="border-0"
                  value={price}
                  max={10000}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="my-4">
              <RatesOption price={price} t={t} />
            </div>

            <div className="rounded-4 p-3 border-dark text-dark my-4">
              <div className="d-flex justify-content-between fs-5 fw-light">
                <span>{t('base_amount')}</span>
                <span>{price.toFixed(2)}€</span>
              </div>
              <div className="d-flex justify-content-between fs-5 my-3 fw-light">
                <span>{t('traveler_service_fees')} <span role="button" title={t('service_fees_caption')} className='text-blue'><Info size={20} /></span></span>
                <span>{service.toFixed(2)}€</span>
              </div>
              <hr className="border-secondary my-3" />
              <div className="d-flex justify-content-between fw-medium fs-5 my-3">
                <span>{t('amount_for_traveler')}</span>
                <span>{total.toFixed(2)}€</span>
              </div>
            </div>

            <div className="rounded-4 p-3 border-dark text-dark my-4">
              <div className="d-flex justify-content-between fs-5 fw-light">
                <span>{t('base_amount')}</span>
                <span>{price.toFixed(2)}€</span>
              </div>
              <div className="d-flex justify-content-between fs-5 my-3 fw-light">
                <span>{t('host_fees')} <span role="button" title={t('host_fees_caption')} className='text-blue'><Info size={20} /></span></span>
                <span>{host.toFixed(2)}€</span>
              </div>
              <hr className="border-secondary my-3" />
              <div className="d-flex justify-content-between text-blue fw-bold fs-5 my-3">
                <span>{t('you_earn')}</span>
                <span>{benefit.toFixed(2)}€</span>
              </div>
            </div>


          </Col>
        </Row>

      </div>
    </section>
  );
};

export default Pricing;

