import { Facebook, Instagram, Linkedin, Twitter } from 'lucide-react'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import LangChanger from './LangChanger'
import { useLocation } from "react-router-dom";

const Footer = ({t}) => {

    const location = useLocation();

    return (
        <div className='bg-dark text-white py-4 w-100'>
            <div className='container'>
                <Row className='align-items-center'>
                    <Col xl="9">
                        <div className='fs-5 fw-light'>Copyright © 2023 <span className='text-blue'>TravelPartner</span> | All rights réserved</div>
                    </Col>
                    <Col xl="1">
                       <div className='px-2'> <LangChanger location={location} t={t}/></div>
                    </Col>
                    <Col xl="2">
                        <div className='d-flex justify-content-between'>
                            <span><Facebook/></span>
                            <span><Instagram/></span>
                            <span><Linkedin/></span>
                            <span><Twitter/></span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Footer
