import React, { useState } from "react";
import { Table } from "rsuite";
import { Trash2, EyeIcon, CalendarCheck2, AlarmClock, Pause } from "lucide-react";
import { Link } from "react-router-dom";

const TableItem = (props) => {
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const ImageCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 10 }}>
      <img src={rowData.avatar} width="100%" height="100%" alt={rowData.title} />
    </Cell>
  );

  const { Column, HeaderCell, Cell } = Table;


  const handleSelectAll = () => {
    const newCheckedItems = {};
    props.bookings.forEach((booking, index) => {
      newCheckedItems[index] = !selectAll;
    });
    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (index) => {
    const newCheckedItems = { ...checkedItems, [index]: !checkedItems[index] };
    setCheckedItems(newCheckedItems);

    const allChecked = Object.values(newCheckedItems).every((value) => value);
    setSelectAll(allChecked);
  };

  return (
    <Table
      data={props.getData}
      sortColumn={props.sortColumn}
      sortType={props.sortType}
      onSortColumn={props.handleSortColumn}
      loading={props.loading}
      autoHeight
      cellBordered={true}
      className="p-1 rounded"
    >
      <Column width={50}>
        <HeaderCell>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
          />
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => (
            <input
              type="checkbox"
              checked={checkedItems[rowIndex]}
              onChange={() => handleCheckboxChange(rowIndex)}
            />
          )}
        </Cell>
      </Column>
      <Column width={60} sortable>
        <HeaderCell>No</HeaderCell>
        <Cell className="text-capitalize" dataKey="id" />
      </Column>
      <Column width={160}>
        <HeaderCell>{props.t('invoice')}</HeaderCell>
        <Cell className="text-capitalize" dataKey="number" />
      </Column>
      <Column width={180}>
        <HeaderCell>{props.t('custumers')}</HeaderCell>
        <Cell className="text-capitalize" dataKey="client" />
      </Column>
      <Column width={60} sortable>
        <HeaderCell>{props.t('age')}</HeaderCell>
        <Cell dataKey="age" />
      </Column>
      <Column width={120} sortable>
        <HeaderCell>{props.t('city')}</HeaderCell>
        <Cell dataKey="location" />
      </Column>
      <Column width={80} sortable>
        <HeaderCell>{props.t('status')}</HeaderCell>
        <Cell dataKey="status">
          {(rowData) => (
            <div className="d-flex justify-content-center">
              <div
                title={rowData.status}
                className="p-1 rounded text-center text-capitalize w-50"
                style={{ marginTop: "-5px", color: getStatusStyles(rowData.status).color, backgroundColor: getStatusStyles(rowData.status).backgroundColor }}
              >
                {getStatusStyles(rowData.status).icon}
              </div>
            </div>
          )}
        </Cell>
      </Column>
      <Column width={140} sortable>
        <HeaderCell>{props.t('phone')}</HeaderCell>
        <Cell className="text-center" dataKey="phone" />
      </Column>
      <Column width={240} sortable>
        <HeaderCell>{props.t('email')}</HeaderCell>
        <Cell dataKey="mail" />
      </Column>
      <Column width={120} sortable>
        <HeaderCell>{props.t('date')}</HeaderCell>
        <Cell dataKey="date" />
      </Column>
      <Column width={90} fixed="right">
        <HeaderCell>{props.t('actions')}</HeaderCell>
        <Cell>
          {(rowData) => (
            <div className="d-flex align-items-center">
              <Link
                to={rowData.type === "hotel" ? "/myhotel" : `/myactivity/${rowData.id}`}
                className="text-blue mx-1"
              >
                <EyeIcon size={20} strokeWidth={1.3} />
              </Link>
            </div>
          )}
        </Cell>
      </Column>
    </Table>
  )
}

export default TableItem

function getStatusStyles(status) {
  switch (status) {
    case "confirmed":
      return { color: "#36CB78", backgroundColor: "#DDF6E8", icon: <CalendarCheck2 size={20} strokeWidth={1.3} /> };
    case "waiting":
      return { color: "#FFC353", backgroundColor: "#FFF7E7", icon: <AlarmClock size={20} strokeWidth={1.3} /> };
    case "cancelled":
      return { color: "#FE6A49", backgroundColor: "#FFF3F0", icon: <Pause size={20} strokeWidth={1.3} /> };
    default:
      return { color: "#f3f6f9", backgroundColor: "#f3f6f9" };
  }
}