import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import CheckInput from '../../components/forms/CheckInput'
import RadioInput from '../../components/forms/RadioInput'
import Choice from '../../components/Choice'
import Pricing from './components/Pricing'
import Extcharges from './components/Extcharges'
import Annulation from './components/Annulation'
import Canbook from './components/Canbook'
import Daysnum from './components/Daysnum'
import Minight from './components/Minight'
import { useTranslation } from "react-i18next";
import Select from '../../components/forms/Select'

const Newrate = () => {

    const { t } = useTranslation();

    const [selectedOption, setSelectedOption] = useState(null);


    const [charges, setCharges] = useState({

        charges: "no"
    });
    const handleExtcharges = (value) => {
        setCharges({ ...charges, charges: value });
    };
    const handleRadioChange = (value) => {
        setSelectedOption(value);
    };
    const [infos, setInfo] = useState({

        pricing: "no"
    });
    const handlePricing = (value) => {
        setInfo({ ...infos, pricing: value });
    };


    const data = [
        {title:t('select_option'),value:""},
        {title:t('early_booking'),value:"early"},
        {title:t('all_included'),value:"all included"},
        {title:t('ho_act_best'),value:"best rate"},
        {title:t('ho_act_Opt_Tarif_full'),value:"full board"},
        {title:t('ho_act_Opt_demi'),value:"half board"},
        {title:t('free_wifi'),value:"free wifi"},
        {title:t('ho_act_Opt_Tarif_inc'),value:"breakfast"}
    ]

    return (
        <section>
            <div className='container justify-center'>
                <Row>
                    <Col className='my-3'>
                        <form action='#'>
                            <fieldset className='my-3 bg-white p-5'>
                                <>
                                    <span className='fs-4 fw-bold text-dark'>{t('ho_act_newrate')}</span>
                                    <p className='fs-6 mb-4'>{t('ho_act_newrateP')}</p>
                                </>
                                <Row className='d-flex flex-column'>
                                    <Col md="12" xl="12" className='mb-3'>
                                        <span className='fs-4 fw-bold text-dark'>1. {t('ho_act_newratePrice')}</span>
                                        <p className='fs-6'>{t('ho_act_newratetxt')}</p>
                                        <div className='my-3'>
                                        <CheckInput id="roomtype" label="Single room" />
                                        </div>
                                        <div  className='my-3'>
                                        <CheckInput id="roomtype1" label="Standard double room" />
                                        </div>
                                        <div  className='my-3'>
                                        <CheckInput id="roomtype2" label="Suite" />
                                        </div>
                                    </Col>
                                    <Col md="12" xl="12" className='mb-3'>
                                        <span className='fs-4 fw-bold text-dark'>{t('type')}</span>
                                        <p className='fs-6'>{t('ho_act_newratetxt')}</p>

                                        <RadioInput id="hotelalone" label={t('ho_act_newrate_hotel')} name="RateType" checked={selectedOption === "hotelalone"} onChange={() => handleRadioChange("hotelalone")} />
                                        <RadioInput id="Formula" label={t('formule')} name="RateType" checked={selectedOption === "Formula"} onChange={() => handleRadioChange("Formula")} />
                                        <RadioInput id="Company" label={t('society')} name="RateType" checked={selectedOption === "Company"} onChange={() => handleRadioChange("Company")} />
                                    </Col>
                                    <Col md="12" xl="12" className='mb-3 '>
                                        <span className='fs-4 fw-bold text-dark'>{t('ho_act_newrate_yours')}</span>
                                        <p className='fs-6 mb-4'>{t('ho_act_newrate_yoursP')}</p>
                                        <div>
                                            <Select data={data}/>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='my-4 bg-white '>
                                            <span className='fs-4 fw-bold text-dark'>2. {t('ho_act_newrate2')}</span>
                                            <Choice text={t('ho_act_newrate2p')} onChange={handlePricing} selectedChoice={infos.pricing} />

                                            {infos.pricing === 'yes' && (
                                                <Pricing t={t} />
                                            )}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className=' bg-white p-3'>
                                            <span className='fs-4 fw-bold text-dark'>{t('ho_act_newrate_stayExtra')}</span>
                                            <Choice text={t('ho_act_newrate_stayExtraP')} onChange={handleExtcharges} selectedChoice={charges.charges} />

                                            {charges.charges === 'yes' && (
                                                <Extcharges t={t} />
                                            )}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='my-4 bg-white '>
                                            <Annulation />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='my-4 bg-white '>
                                            <Canbook t={t} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='bg-white '>
                                            <Minight t={t} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className=' bg-white '>
                                            <Daysnum t={t} />
                                        </div>
                                    </Col>
                                </Row>
                                <button type='submit' className='btn-blue p-3 px-5 rounded-3 text-white'>{t('submit')}</button>
                            </fieldset>
                        </form>
                    </Col>
                </Row>
            </div>
        </section>
    )
}
export default Newrate;
