import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const TabContent = ({ message, t }) => (
  <>
    <div className="text-center border-bottom py-3">{message}</div>
    <div className="my-3">
      <Link to="#" className="text-blue fw-light">
        {t('afficher_new_reservation')}
      </Link>
    </div>
  </>
);

function Skoop({ t }) {
  const [activeButton, setActiveButton] = useState("booking");

  const handleActiveButton = (value) => {
    setActiveButton(value);
  };

  const tabs = [
    {
      eventKey: "booking",
      title: t('bookings'),
      message: t('bookings_message'),
    },
    {
      eventKey: "arrival",
      title: t('arrivals'),
      message: t('arrivals_message'),
    },
    {
      eventKey: "departure",
      title: t('departures'),
      message:t('departures_message'),
    },
    {
      eventKey: "cancelled",
      title: t('cancelled'),
      message: t('cancellations_message'),
    },
  ];

  return (
    <>
      <Row className="border-bottom justify-content-between my-3">
        {tabs.map((button) => (
          <Col xl="3" key={button.eventKey}>
            <button
              onClick={() => handleActiveButton(button.eventKey)}
              className={`w-100 p-3 border border-bottom-0 ${activeButton === button.eventKey
                  ? "bg-blue text-white"
                  : "bg-white text-dark"
                }`}
            >
              {button.title}
            </button>
          </Col>
        ))}
      </Row>

      <div className="py-3">
        {tabs.map((button) =>
          button.eventKey === activeButton && <TabContent t={t} key={button.eventKey} message={button.message} />
        )}
      </div>
    </>
  );
}

export default Skoop;
