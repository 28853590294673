import React, { useState } from 'react'
import { CalendarCheck2 } from 'lucide-react'
import DateInput from '../../components/forms/DateInput'
import Form from 'react-bootstrap/Form'
import { Row, Col, Modal } from 'react-bootstrap'
import { Button } from 'rsuite'
import Draggable from 'react-draggable'
import { AiOutlineCheckCircle } from "react-icons/ai"
import { Table } from 'react-bootstrap'
import ManageHeader from "../../components/ManageHeader";
import Input from '../../components/forms/Input'
import Select from '../../components/forms/Select'

const Availability = ({ t }) => {
    const [showModal, setShowModal] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleDeactivateClick = (event) => {
        event.preventDefault();
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleConfirmDeactivate = () => {
        setShowModal(false);
        handleShowSuccessMessage();
    };

    const handleCloseSuccessMessage = () => {
        setShowSuccessMessage(false);
    };

    const handleShowSuccessMessage = () => {
        setShowSuccessMessage(true);
    };

    const data = ["Chambre Double Standard", "Suite standard", "Chambre classique avec lits jumeaux", "Chambre triple standard", "Chambre Simple", "Suite Junior"]
    const actions = [
        { title: t('ho_act_segmentActiona1'), value: "" },
        { title: t('closed'), value: "Fermé" },
        { title: t('opened'), value: "Ouvert" },
    ]
    return (
        <section>
            <ManageHeader
                title={t('ho_act_tarif_rest')}
                content={t('ho_act_tarif_rest_txt')}
                icon={<CalendarCheck2 size={25} strokeWidth={1.5} />}
            />

            <div className='container my-4'>
                <Row className='m-auto mx-1 p-5 d-flex flex-column bg-white rounded'>
                    <Col className=''>
                        <span className='fs-4 fw-bold text-dark'>{t('ho_act_select')}</span>
                        <p className='fs-8 my-2'>{t('ho_act_segment_selectp')}<br /><b>{t('exemple')}: </b>{t('ho_act_segmentExp')}</p>
                    </Col>
                    <Col className='d-flex flex-row align-items-center'>
                        <Col sm="4" md="4" className='d-flex align-items-start mt-3 py-2'>
                            <DateInput rightLabel={t('departure_date')} leftLabel="End date" />
                        </Col>
                        <Col sm="4" md="4" className='d-flex flex-row mt-3 ms-5 py-2'>
                            <span className='d-flex flex-row me-2'>
                                <Form.Check type="checkbox" className='me-1' />
                                <span className='mt-1' >{t('mon')}</span>
                            </span>
                            <span className='d-flex flex-row me-2'>
                                <Form.Check type="checkbox" className='me-1' />
                                <span className='mt-1' >{t('thur')}</span>
                            </span>
                            <span className='d-flex flex-row me-2'>
                                <Form.Check type="checkbox" className='me-1' />
                                <span className='mt-1' >{t('wed')}</span>
                            </span>
                            <span className='d-flex flex-row me-2'>
                                <Form.Check type="checkbox" className='me-1' />
                                <span className='mt-1' >{t('thu')}</span>
                            </span>
                            <span className='d-flex flex-row me-2'>
                                <Form.Check type="checkbox" className='me-1' />
                                <span className='mt-1' >{t('fri')}</span>
                            </span>
                            <span className='d-flex flex-row me-2'>
                                <Form.Check type="checkbox" className='me-1' />
                                <span className='mt-1' >{t('sat')}</span>
                            </span>
                            <span className='d-flex flex-row me-2'>
                                <Form.Check type="checkbox" className='me-1' />
                                <span className='mt-1' >{t('sun')}</span>
                            </span>
                        </Col>
                    </Col>
                </Row>
            </div>
            <div className='container my-4 d-flex flex-column'>
                <span className='fs-4 fw-bold text-dark'>{t('ho_act_segmentMAJ')}</span>
                <p xl="8" className='fs-8 mt-2 mb-4'>{t('ho_act_segmentMAJp')}</p>
                <Row className='m-auto mt-3 mx-1 px-3 py-5 d-flex flex-row bg-white rounded border' style={{ boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)' }}>
                    <Table className='rounded custom-hover' hover>
                        <thead>
                            <tr>
                                <th className='fs-6'>{t('ho_act_segmentType')}</th>
                                <th className='fs-6'>{t('ho_act_segmentDispo')}</th>
                                <th className='fs-6'>{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item}</td>
                                    <td>
                                        <Input type="text" placeholder="" />
                                    </td>
                                    <td>
                                        <Select data={actions} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </Table>
                    <Button className='px-5 w-25 mt-2' appearance="primary" color="cyan" onClick={handleDeactivateClick}>
                        {t('confirm')}
                    </Button>
                </Row>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" className='rounded-2  p-2'>
                <Draggable>
                    <div className=''>
                        <Modal.Header className='bg-white' closeButton>
                            <Modal.Title className="text-modal">{t('ho_act_segmentConfirmU')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='bg-white text-modal d-flex flex-column'>
                            {t('ho_act_segmentcConfirmp')}
                        </Modal.Body>
                        <Modal.Footer className='bg-white'>
                            <Button variant="warning" onClick={handleCloseModal}>
                                {t('cancel')}
                            </Button>
                            <Button variant="primary" onClick={handleConfirmDeactivate}>
                                {t('confirm')}
                            </Button>
                        </Modal.Footer>
                    </div>
                </Draggable>
            </Modal>
            <Modal show={showSuccessMessage} onHide={handleCloseSuccessMessage} backdrop="static" className='rounded-top p-2'>
                <div>
                    <Modal.Body className='bg-white text-white d-flex flex-column'>
                        <Row className='py-3'>
                            <Col className='d-flex flex-row'>
                                <AiOutlineCheckCircle style={{ color: '#008000', fontSize: '28px' }} />
                                <span className='fs-5 fw-medium ps-3' style={{ color: '#008000' }}>{t('ho_act_segmentcDone')}</span>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className='bg-white'>
                        <Button variant="light" onClick={handleCloseSuccessMessage}>
                            {t('close')}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </section>
    );
};

export default Availability;
