import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Select from '../../../components/forms/Select';
import Input from '../../../components/forms/Input';
import RadioInput from '../../../components/forms/RadioInput'
import { useTranslation } from "react-i18next";

const ParkingFees = () => {
    const { t } = useTranslation();
    const [groupId] = useState(uuidv4());
    const [parkingExpense, setParkingExpense] = useState("gratuit")

    const handleParkingExpense = (event) => {
        setParkingExpense(event.target.value)
    }

    const data = [
        { title: t('per_stay'), value: "sejour" },
        { title: t('pey_night'), value: "nuit" },
        { title: t('per_day'), value: "jour" },
        { title: t('per_week'), value: "semaine" },
    ]

    return (
        <>
            <Row className='fs-6 align-items-center'>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <span className='fw-medium text-dark'>{t('parking_acess_fees')}</span>
                </Col>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <div className='d-flex align-items-center mx-3'>
                        <RadioInput id={`Gratuit_${groupId}`} label={t('free')} name={`parking_${groupId}`} value="Gratuit" onChange={handleParkingExpense} />
                    </div>

                    <div className='d-flex align-items-center mx-3'>
                        <RadioInput id={`Supplément_${groupId}`} label={t('supplement')} name={`parking_${groupId}`} value="Supplément" onChange={handleParkingExpense} />
                    </div>
                </Col>
            </Row>

            {
                parkingExpense === "Supplément" && (
                    <Row>
                        <Col md="6" className='my-3'>
                            <Input placeholder={t('amount_per_vehicle')} type="number" />
                        </Col>
                        <Col md="6" className='my-3'>
                            <Select data={data} />
                        </Col>
                    </Row>
                )
            }
        </>
    )
}

export default ParkingFees