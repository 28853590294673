import React from "react";
import { Col, Row } from "react-bootstrap";
import TagInput from "../../../components/forms/TagInput";
import { MinusCircle, PlusCircle } from "lucide-react";
import { useState } from "react";
import Input from "../../../components/forms/Input";

const Card = ({ t }) => {

  const [entries, setEntries] = useState([{ amount: "" }]);

  const handleAddEntry = () => {
    setEntries([...entries, { amount: "" }]);
  };

  const handleRemoveEntry = () => {
    if (entries.length > 1) {
      const newEntries = [...entries];
      newEntries.pop();
      setEntries(newEntries);
    }
  };

  const handleAmountChange = (index, amount) => {
    const newEntries = [...entries];
    newEntries[index].amount = amount;
    setEntries(newEntries);
  };

  const data = [
    { title: "Pizza", value: "Pizza" },
    { title: "Cheese", value: "Cheese" },
  ];

  return (
    <>
      {entries.map((entry, index) => (
        <div key={index}>
          <Row className="fs-6 align-items-center">
            <Col sm="6" className="my-3">
              <span className="text-blue">{t('RestauFixerTarif2')}</span>
              <div className="my-2 fw-medium">
                <TagInput list="RestaurantMeals" data={data} />
              </div>
            </Col>
            <Col sm="6" className="my-3">
              <span className="text-blue">{t('client_pay')}</span>
              <div className="my-2 fw-medium">
                <Input type="number" value={entry.amount} handleChange={(e) => handleAmountChange(index, parseFloat(e.target.value) || "")} placeholder={t('amount')} />
              </div>
            </Col>
          </Row>
        </div>
      ))}
      <div className="fs-5 fw-light">
        <button className="bg-transparent text-blue" type="button" onClick={handleAddEntry}>
          <PlusCircle strokeWidth={1.5} /> {t('add')}
        </button>
        <button className="bg-transparent text-danger mx-3" type="button" onClick={handleRemoveEntry} disabled={entries.length < 2}>
          <MinusCircle strokeWidth={1.5} /> {t('remove')}
        </button>
      </div>
    </>
  );
};

export default Card;
