import React from 'react'
import { Col, Row } from 'react-bootstrap'
import img1 from '../../assets/icones/5.svg'
import img2 from '../../assets/icones/6.svg'
import img3 from '../../assets/icones/7.svg'

const Reasons = ({ t }) => {

  const data = [
    { id: 1, title: t('visibility'), content: t('visibility_caption'), img: img1 },
    { id: 2, title: t('easy_and_fast'), content: t('easy_and_fast_caption'), img: img2 },
    { id: 3, title: t('support'), content: t('support_caption'), img: img3 }
  ]

  return (
    <Row className='py-5'>
      {data.map(item => (
        <Col sm="6" lg="4" key={item.id} className='d-flex flex-column align-items-center my-3'>
          <img src={item.img} width={80} height={80} alt={`Illustration d'une icone de ${item.title}`} />
          <div className='fs-5 fw-medium text-dark my-2'>{item.title}</div>
          <p className='fs-6 fw-light px-5 text-center'>{item.content}</p>
        </Col>
      ))}
    </Row>
  )
}

export default Reasons
