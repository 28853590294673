import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";

const ChartItem = () => {

    const { t } = useTranslation();


    var options = {
        series: [
            {
                name: t('earnings'),
                data: [31, 40, 28, 51, 42, 109, 100],
            },
            {
                name: t('sales'),
                data: [11, 32, 45, 32, 34, 52, 41],
            },
            {
                name: t('products'),
                data: [8, 45, 3, 2, 19, 52, 56],
            },
        ],

        chart: {
            height: 350,
            type: "area",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
        },
        xaxis: {
            categories: [t('sat'), t('sun'), t('mon'), t('tue'), t('wed'), t('thur'), t('fri')]
        },
        colors: ["#36CB78", "#8077ec", "#f8d448"],
    };


    return (
        <div className="bg-white border rounded h-100 d-flex flex-column justify-content-between" style={{ overflow: "hidden" }}>
            <div className="p-3">
                <span className="fs-5 fw-medium text-dark">{t("overview")}</span>
                <p className="fs-6">{t('weekly_earnings')}</p>
                <div className="mt-1">
                    <Chart options={options} series={options.series} type="line" height={390} />
                </div>
            </div>

        </div>
    )
}

export default ChartItem
