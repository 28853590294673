import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import RadioInput from '../../../components/forms/RadioInput';
import { useTranslation } from "react-i18next";

const PaymentOption = () => {
  const { t } = useTranslation();
  const [groupId] = useState(uuidv4());
  
  const radioData = [
    { id: `free_${groupId}`, label: t('free') },
    { id: `suplement_${groupId}`, label: t('supplement') },
  ];

  return (
    <Row className='fs-6 align-items-center'>
      <Col md="6" className='d-flex align-items-center my-3'>
        <span className='fw-medium text-dark'>{t('cost')}</span>
      </Col>
      <Col md="6" className='d-flex align-items-center my-3'>
        {radioData.map((radio) => (
          <div className='mx-3'>
            <RadioInput name={`amount_${groupId}`} key={radio.id} id={radio.id} label={radio.label} />
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default PaymentOption;
