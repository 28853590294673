import React, { useState } from "react"
import { Row, Col, Button, DatePicker, Table } from "rsuite"
import Head from "../../components/Head"
import { FiDownloadCloud } from "react-icons/fi"
import { LuPrinter } from "react-icons/lu"
import { MdEdit } from "react-icons/md"
import { FiEye } from "react-icons/fi"
import { Modal, Form } from "react-bootstrap"
import { FaHotel } from "react-icons/fa6"
import { FaRegCalendarCheck } from "react-icons/fa6"
import { IoPricetagSharp } from "react-icons/io5"
import { Link } from 'react-router-dom'
import DateInput from "../../components/forms/DateInput"
import { useTranslation } from "react-i18next";

const { Column, HeaderCell, Cell } = Table;

const BookingHotels = () => {

    const { t } = useTranslation();

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleModifierClick = () => {
        setShowSecondModal(false);
        setShowModal(true);
    }
    const [showThirdModal, setShowThirdModal] = useState(false);

    const handleOpenThirdModal = () => {
        setShowSecondModal(false);
        setShowThirdModal(true);
    };

    const handleOpenDatePicker = () => {
        setShowDatePicker(true);
    };

    const handleCloseDatePicker = () => {
        setShowDatePicker(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        handleCloseDatePicker();
    };

    const [showModal, setShowModal] = useState(false);

    const handleConfirmDeactivate = () => {
        setShowModal(false);
        handleShowSuccessMessage();
    };

    const handleShowSuccessMessage = () => {
        setShowSuccessMessage(true);
    };

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleEditButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [bookings, setBookings] = useState([
        { id: 1, idHotel: "11771007", hotelName: "DAR EL IKRAM HOTEL", hotelAddress: "22 Rue Hocine BELADJEL, Alger Centre", clientName: "DEVRIM GECEGEZER", arrival: "2024-05-20", departure: "2024-05-21", accommodations: "Chambre Triple", reservedOn: "2024-05-15", status: "Confirmé", rate: "$100", commission: "$10", reservationNumber: "4953455341" },
        { id: 2, idHotel: "45933282", hotelName: "BELVEDERE", hotelAddress: "Boulevard Colonel Amirouche, Béjaïa", clientName: "Sam Escoffier", arrival: "2024-05-21", departure: "2024-05-23", accommodations: "Chambre Simple", reservedOn: "2024-05-16", status: "En attente", rate: "$120", commission: "$12", reservationNumber: "8573455341" },
        { id: 3, idHotel: "58565858", hotelName: "LES ZIBAN", hotelAddress: "32RUE MOULOUD BELHOUCHET,, Alger", clientName: "Dalida Arbi", arrival: "2024-05-22", departure: "2024-05-24", accommodations: "Suite", reservedOn: "2024-05-17", status: "Confirmé", rate: "$200", commission: "$20", reservationNumber: " 5847455341" },
        { id: 4, idHotel: "12548759", hotelName: "Oasis Hotel", hotelAddress: "Rue du 8 Mars, Biskra-Algérie., Biskra", clientName: "Kashier Kash", arrival: "2024-05-20", departure: "2024-05-21", accommodations: "Chambre Double", reservedOn: "2024-05-15", status: "Annulé", rate: "$100", commission: "$10", reservationNumber: "4953455341" },
        { id: 5, idHotel: "85248652", hotelName: "HOTEL DU NORD", hotelAddress: "22 Rue Hocine BELADJEL, Alger Centre", clientName: "Eminem Dall", arrival: "2024-05-21", departure: "2024-05-23", accommodations: " Chambre Lits Jumeaux", reservedOn: "2024-05-16", status: "En attente", rate: "$120", commission: "$12", reservationNumber: "8573455341" },
        { id: 6, idHotel: "12548755", hotelName: "BELVEDERE", hotelAddress: "Boulevard Colonel Amirouche, Béjaïa", clientName: "Rozy Rosa", arrival: "2024-05-22", departure: "2024-05-24", accommodations: "Suite", reservedOn: "2024-05-17", status: "Confirmé", rate: "$200", commission: "$20", reservationNumber: " 5847455341" },
        { id: 7, idHotel: "55566699", hotelName: "Oasis Hotel", hotelAddress: "Rue du 8 Mars, Biskra-Algérie., Biskra", clientName: "DEVRIM GECEGEZER", arrival: "2024-05-20", departure: "2024-05-21", accommodations: "Single Room", reservedOn: "2024-05-15", status: "Confirmé", rate: "$100", commission: "$10", reservationNumber: "4953455341" },
        { id: 8, idHotel: "96458752", hotelName: "BELVEDERE", hotelAddress: "Boulevard Colonel Amirouche, Béjaïa", clientName: "Sam Escoffier", arrival: "2024-05-21", departure: "2024-05-23", accommodations: "Chambre Double", reservedOn: "2024-05-16", status: "En attente", rate: "$120", commission: "$12", reservationNumber: "8573455341" },
        { id: 9, idHotel: "54821358", hotelName: "HOTEL DU NORD", hotelAddress: "Rue du 8 Mars, Biskra-Algérie., Biskra", clientName: "Dalida Arbi", arrival: "2024-05-22", departure: "2024-05-24", accommodations: "Suite", reservedOn: "2024-05-17", status: "Confirmé", rate: "$200", commission: "$20", reservationNumber: " 5847455341" },
        { id: 10, idHotel: "95844575", hotelName: "Oasis Hotel", hotelAddress: "22 Rue Hocine BELADJEL, Alger Centre", clientName: "Kashier Kash", arrival: "2024-05-20", departure: "2024-05-21", accommodations: "Chambre Double", reservedOn: "2024-05-15", status: "Annulé", rate: "$100", commission: "$10", reservationNumber: "4953455341" },
        { id: 11, idHotel: "32584547", hotelName: "BELVEDERE", hotelAddress: "22 Rue Hocine BELADJEL, Alger Centre", clientName: "Eminem Dall", arrival: "2024-05-21", departure: "2024-05-23", accommodations: "Chambre Triple", reservedOn: "2024-05-16", status: "En attente", rate: "$120", commission: "$12", reservationNumber: "8573455341" },
        { id: 12, idHotel: "96548724", hotelName: "DAR EL IKRAM HOTEL", hotelAddress: "Rue du 8 Mars, Biskra-Algérie., Biskra", clientName: "Rozy Rosa", arrival: "2024-05-22", departure: "2024-05-24", accommodations: "Suite", reservedOn: "2024-05-17", status: "Confirmé", rate: "$200", commission: "$20", reservationNumber: " 5847455341" }
    ]);

    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleSelectAll = () => {
        const newCheckedItems = {};
        bookings.forEach((booking, index) => {
            newCheckedItems[index] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (index) => {
        const newCheckedItems = { ...checkedItems, [index]: !checkedItems[index] };
        setCheckedItems(newCheckedItems);

        const allChecked = Object.values(newCheckedItems).every((value) => value);
        setSelectAll(allChecked);
    };

    const handleSearch = () => {
        const filteredBookings = bookings.filter((booking) => {
            if (!startDate || !endDate) return true;
            const bookingArrivalDate = new Date(booking.arrival);
            const bookingDepartureDate = new Date(booking.departure);
            return bookingArrivalDate >= startDate && bookingDepartureDate <= endDate;
        });
        return filteredBookings;
    };

    const [showSecondModal, setShowSecondModal] = useState(false);
    const handleOpenSecondModal = () => {
        setShowModal(false);
        setShowSecondModal(true);
    };

    const handleCloseSecondModal = () => {
        setShowSecondModal(false);
    };

    return (
        <section className="mb-4">
            <Head />
            <div lg="5" xl="8" sm="12" className="px-5 mb-4 py-4 d-flex flex-column">
                <Row className="d-flex flex-row justify-content-between">
                    <Col xl="auto" className="text-dark fs-4 pt-2 fw-bold">
                        {t('bookings')}
                    </Col>
                    <Col className="d-flex justify-content-end p-2 ms-auto">
                        <Button color="blue" appearance="primary" className="me-2">
                            <FiDownloadCloud className="me-1" />
                            {t('download')}
                        </Button>
                        <Button color="cyan" appearance="primary">
                            <LuPrinter className="me-1" />
                            {t('print')}
                        </Button>
                    </Col>
                </Row>

                <div className="d-flex mt-2 my-1">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                            checked
                            onChange={() => { }}
                        />
                        <label className="form-check-label me-3" htmlFor="exampleRadios1">
                            {t('arrival')}
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios2"
                            value="option2"
                            onChange={() => { }}
                        />
                        <label className="form-check-label me-3" htmlFor="exampleRadios2">
                            {t('departure')}
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios3"
                            value="option3"
                            onChange={() => { }}
                        />
                        <label className="form-check-label me-3" htmlFor="exampleRadios3">
                            {t('booking_date')}
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios4"
                            value="option4"
                            onChange={() => { }}
                        />
                        <label className="form-check-label" htmlFor="exampleRadios4">
                            {t('cancel_date')}
                        </label>
                    </div>
                </div>
                <Row>
                    <Col xl="6" className="my-3">
                        <DateInput leftLabel="Du" rightLabel="Au" />
                    </Col>
                    <Col xl="6" className="my-3">
                        <button onClick={handleSearch} className="btn-blue w-100 p-4 h-100">
                            {t('filtrer')}
                        </button>
                    </Col>
                </Row>
                <div className="my-2">
                    <Table height={400} data={bookings} autoHeight>
                        <Column width={40}>
                            <HeaderCell>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            </HeaderCell>
                            <Cell>
                                {(rowData, rowIndex) => (
                                    <input
                                        type="checkbox"
                                        checked={checkedItems[rowIndex]}
                                        onChange={() => handleCheckboxChange(rowIndex)}
                                    />
                                )}
                            </Cell>
                        </Column>
                        <Column width={90} >
                            <HeaderCell className="fs-6 fw-bold text-dark">Id</HeaderCell>
                            <Cell dataKey="idHotel" />
                        </Column>
                        <Column >
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('hotel_name')}</HeaderCell>
                            <Cell dataKey="hotelName" />
                        </Column>
                        <Column >
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('hotel_adress')}</HeaderCell>
                            <Cell dataKey="hotelAddress" />
                        </Column>
                        <Column width={120}>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('custumers')}</HeaderCell>
                            <Cell dataKey="clientName" />
                        </Column>
                        <Column flexGrow={1} sortable>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('arrival')}</HeaderCell>
                            <Cell dataKey="arrival" />
                        </Column>
                        <Column flexGrow={1} sortable>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('departure')}</HeaderCell>
                            <Cell dataKey="departure" />
                        </Column>
                        <Column width={110}>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('bedroom')}</HeaderCell>
                            <Cell dataKey="accommodations" />
                        </Column>
                        <Column flexGrow={1} sortable>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('booked_the')}</HeaderCell>
                            <Cell dataKey="reservedOn" />
                        </Column>
                        <Column flexGrow={1} sortable>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('status')}</HeaderCell>
                            <Cell dataKey="status">
                                {(rowData) => {
                                    let backgroundColor = '';
                                    let color = '';

                                    switch (rowData.status) {
                                        case 'Confirmé':
                                            backgroundColor = '#e8f9ee';
                                            color = '#29c763';
                                            break;
                                        case 'Annulé':
                                            backgroundColor = '#fef0e7';
                                            color = '#f97316';
                                            break;
                                        case 'En attente':
                                            backgroundColor = '#f2f0fe';
                                            color = '#8b74f9';
                                            break;
                                        default:
                                            backgroundColor = '#f3f6f9';
                                            color = '#000000';
                                    }

                                    return (
                                        <div className="p-1 rounded-4 text-center" style={{ marginTop: '-5px', backgroundColor, color }}>
                                            {rowData.status}
                                        </div>
                                    );
                                }}
                            </Cell>
                        </Column>
                        <Column width={60}>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('rates')}</HeaderCell>
                            <Cell dataKey="rate" />
                        </Column>
                        <Column width={60}>
                            <HeaderCell className="fs-6 fw-bold text-dark text-center">%</HeaderCell>
                            <Cell dataKey="commission" className="text-center" />
                        </Column>
                        <Column>
                            <HeaderCell className="fs-6 fw-bold text-dark h-100">N° {t('booking')}</HeaderCell>
                            <Cell dataKey="reservationNumber" />
                        </Column>
                        <Column width={100}>
                            <HeaderCell className="fs-6 fw-bold text-dark">{t('actions')}</HeaderCell>
                            <Cell>
                                {(rowData) => (
                                    <div className="d-flex flex-row  align-items-center" style={{ marginTop: '-5px' }} >

                                        <Button className="border me-1 rounded" onClick={handleEditButtonClick}>
                                            <MdEdit />
                                        </Button>
                                        <Link to="/myhotel/bookingdetails" >
                                            <Button className="border  me-1 rounded">
                                                <FiEye />
                                            </Button>
                                        </Link>
                                    </div>
                                )}
                            </Cell>
                        </Column>
                    </Table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" className='rounded-2 p-2'>
                <Modal.Header className='bg-white' closeButton>
                    <Modal.Title className="text-modal fw-bold fs-5 ms-2"> Modifier les dates et les tarifs </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white text-modal d-flex flex-column'>
                    <div className='p-2'>
                        <FaHotel className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">Hébergements</span>
                        <div className='d-flex flex-column ms-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <Form.Check className="me-2 ms-3" /> <span>Chambre Lits Jumeaux</span>
                            </div>
                            <div className='d-flex align-items-center mt-2'>
                                <Form.Check className="me-2 ms-3" /><span>Chambre Triple</span>
                            </div>
                        </div>
                    </div>
                    <div className='p-2 my-3'>
                        <FaRegCalendarCheck className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">Sélectionnez d'autres dates</span>
                        <div className='d-flex flex-row ms-4'>
                            <div className='d-flex flex-column mt-3 me-4 ms-3'>
                                <span className="mb-2">Date d’arrivée</span>
                                <DatePicker placeholder='Sélectionner une date' format='DD/MM/YYYY' />
                            </div>
                            <div className='d-flex flex-column mt-3 ms-3'>
                                <span className="mb-2">Date de départ</span>
                                <DatePicker placeholder='Sélectionner une date' format='DD/MM/YYYY' />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='bg-white'>
                    <Button appearance="primary" color="blue" onClick={handleOpenSecondModal}>
                        Voir les disponibilités et changer les prix
                    </Button>
                    <Button appearance="primary" color="cyan" onClick={handleConfirmDeactivate}>
                        Annuler
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSecondModal} onHide={handleCloseSecondModal} size="lg" className='rounded-2 p-2'>
                <Modal.Header className='bg-white' closeButton>
                    <Modal.Title className="text-modal fw-bold fs-5 ms-2">Modifier les dates et les tarifs</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white text-modal d-flex flex-column'>
                    <div className="p-2 my-2">
                        <span className=" fs-7">Avant de sauvegarder les modifications, veuillez vous assurer que vous avez configuré des disponibilités pour les dates demandées.</span>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <div className='p-2'>
                            <FaRegCalendarCheck className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">Les dates</span>
                            <div className='d-flex flex-column ms-4'>
                                <div className='d-flex flex-column  mt-3'>
                                    <span className="fs-8 my-1 text-red"><s>sam. 4 mai 2024 — lun. 6 mai 2024</s></span>
                                    <span className="fs-5 fw-bold text-dark my-1">dim. 26 mai 2024 — mer. 29 mai 2024</span>
                                    <Button className="px-5 mt-2 mb-2 w-50" onClick={handleModifierClick}>
                                        Modifier
                                    </Button>

                                </div>
                            </div>
                        </div>
                        <div className='p-2 '>
                            <IoPricetagSharp className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">Les tarifs</span>
                            <div className='d-flex flex-column ms-4'>
                                <div className='d-flex flex-column  mt-3'>
                                    <span className="my-1">Tarif total net de l'hébergement (hors taxes et frais)</span>
                                    <span className="fs-7 my-2 "><s>DZD 46 000.00 </s></span>
                                    <input type="text" placeholder="DZD 96 000.00" className="form-control w-50 mt-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className='bg-white'>
                    <span className="text-modal p-2">Si vous modifiez le tarif d'une réservation comprenant plusieurs hébergements, ce nouveau tarif sera divisé et appliqué de façon égale aux différents hébergements. Pour modifier le tarif par hébergement, sélectionnez-les séparément à l'étape précédente.</span>
                    <Button appearance="primary" color="blue" onClick={handleOpenThirdModal}>
                        Suivant
                    </Button>
                    <Button appearance="primary" color="cyan" onClick={handleCloseSecondModal}>
                        Annuler
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showThirdModal} onHide={() => setShowThirdModal(false)} size="lg" className='rounded-2 p-1'>
                <Modal.Header className='bg-white' closeButton>
                    <Modal.Title className="text-modal fw-bold fs-5 ms-2">Modifier les dates et les tarifs</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white text-modal d-flex flex-column'>
                    <div className="p-2 my-2">
                        <span className=" fs-7">Avant de sauvegarder les modifications, veuillez vous assurer que vous avez configuré des disponibilités pour les dates demandées.</span>
                    </div>
                    <Row className="d-flex flex-row justify-content-around">
                        <Col className=''>
                            <FaHotel className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">Hébergements</span>
                            <div className='d-flex flex-column ms-4'>
                                <div className='d-flex flex-column mt-3'>
                                    <span className="my-2">Chambre Lits Jumeaux</span>
                                    <span className="my-2">Chambre Triple</span>
                                </div>
                            </div>
                        </Col>
                        <Col className=''>
                            <FaRegCalendarCheck className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">Les dates</span>
                            <div className='d-flex flex-column ms-4'>
                                <div className='d-flex flex-column  mt-3'>
                                    <span className="fs-8 my-2 text-red"><s>sam. 4 mai 2024 — lun. 6 mai 2024</s></span>
                                    <span className="fs-8 fw-bold text-dark my-2">dim. 26 mai 2024 — mer. 29 mai 2024</span>
                                    <Button className="px-4 my-2 w-50" onClick={handleModifierClick}>
                                        Modifier
                                    </Button>

                                </div>
                            </div>
                        </Col>
                        <Col className=''>
                            <IoPricetagSharp className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">Les tarifs</span>
                            <div className='d-flex flex-column ms-4'>
                                <div className='d-flex flex-column  mt-3'>
                                    <span className="fs-8 my-2 "><s>DZD 46 000.00 </s></span>
                                    <span className="fs-8 my-2 fw-bold">DZD 96 000.00</span>
                                    <Button className="px-5 my-2 w-50" onClick={() => { setShowThirdModal(false); setShowSecondModal(true); }}>
                                        Modifier
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='bg-white'>

                    <Button appearance="primary" color="blue" onClick={() => setShowThirdModal(false)}>
                        Sauvegarder
                    </Button>
                    <Button appearance="primary" color="cyan" onClick={() => setShowThirdModal(false)}>
                        Annuler
                    </Button>
                </Modal.Footer>

            </Modal>
        </section>
    );
};

export default BookingHotels;
