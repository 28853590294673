import { Col, Row } from 'react-bootstrap';
import { Loader, Progress } from "rsuite";
import React, { useEffect, useState } from 'react';
import { RefreshCcw } from 'lucide-react';
import { useTranslation } from "react-i18next";

const ActiveProducts = ({ products }) => {
    const [randomElements, setRandomElements] = useState([]);
    const [averagePercentage, setAveragePercentage] = useState(0);
    const [loading, setLoading] = useState(false);

    const refreshData = () => {
        setLoading(true);
        const filteredData = products.filter(item => item.status !== "En marche");
        const randomElems = getRandomElements(filteredData);
        const avgPercentage = calculateAverage(randomElems);

        setRandomElements(randomElems);
        setAveragePercentage(avgPercentage);

        setTimeout(() => {
            setLoading(prevLoading => false);
        }, 1000);

    };

    useEffect(() => {
        refreshData();
    }, []);


    const getRandomElements = (data) => {
        const shuffled = data.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 5);
    };

    const calculateAverage = (elements) => {
        if (elements.length === 0) return 0;

        const sum = elements.reduce((acc, item) => acc + item.progress, 0);
        const average = sum / elements.length;

        return average;
    };

    const { t } = useTranslation();

    return (
        <>
            {!loading ?
                <div className="rounded px-3 border h-100">
                    <div className='my-3 d-flex justify-content-between align-items-center'>
                        <div>
                            <div className="text-dark fw-medium fs-5">{t('current_products')}</div>
                            <p className='fs-6 fw-light'>{t('average')} {averagePercentage}% {t('finished')}</p>
                        </div>
                        <button className="btn-blue p-2 rounded" onClick={refreshData}><RefreshCcw size={20} /></button>
                    </div>
                    <div className='mt-4'>
                        <>
                            {randomElements.map(item => (
                                <Row key={item.id} className='my-3'>
                                    <Col xs="2" md="1" lg="2">
                                        <img src={item.avatar} width="45" height="45" alt={`Illustration du produit ${item.title}`} />
                                    </Col>
                                    <Col xs="3" md="2" lg="4">
                                        <div className='fs-6 fw-medium text-dark' title={item.title}>{item.title.slice(0, 10)}...</div>
                                        <div className='fs-6 fw-light'>{item.type}</div>
                                    </Col>
                                    <Col sm="7" md="9" lg="6">
                                        <Progress.Line percent={item.progress} strokeColor={item.progress <= 35 ? "#f43f3f" : (item.progress > 35 && item.progress <= 70) ? '#f8d448' : "#2cae29"} />
                                    </Col>
                                </Row>
                            ))}</>
                    </div>
                </div> : <div className='d-flex justify-content-center align-items-center h-100 border rounded'><Loader className="text-blue" size="md" /></div>}
        </>

    );
};

export default ActiveProducts;
