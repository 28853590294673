import Transport from './Transport'
import Food from './Food'
import { Col, Row } from 'react-bootstrap'

const FoodAndTransport = ({ t }) => {
    return (
        <section className="container form-transition">
            <Row className="justify-content-center">
                <Col lg="8" className="my-3">
                    <h2 className="fs-3 lh-base fw-medium text-dark">{t('food_and_transport')}</h2>
                    <p className="fs-5 fw-light">{t('food_and_transport_caption')}</p>
                    <div className='my-4'>
                        <Food />
                    </div>
                    <div className='my-4'>
                        <Transport />
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default FoodAndTransport
