import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import RadioInput from '../../../components/forms/RadioInput';
import { useTranslation } from "react-i18next";


const Location = ({ onChange }) => {
  const { t } = useTranslation();
  const [groupId] = useState(uuidv4());

  return (
    <Row className='fs-6 align-items-center'>
      <Col md="6" className='d-flex align-items-center my-1'>
        <span className='fw-medium text-dark'>{t('location')}</span>
      </Col>
      <Col md="6" className='d-flex align-items-center my-1'>

        <div className='mx-3'>
          <RadioInput name={`location_${groupId}`} id={`inPlace_${groupId}`} label={t('onsite')} value="SurPlace" onChange={() => onChange('SurPlace')} />
        </div>

        <div className='mx-3'>
          <RadioInput name={`location_${groupId}`} id={`Aproximite_${groupId}`} label={t('nearby')} value="Aproximite" onChange={() => onChange('Aproximite')} />
        </div>

      </Col>
    </Row>
  );
};

export default Location;
