import { useEffect, useState } from "react";
import FoodAndTransport from './components/FoodAndTransport'
import RestaurantAtt from './components/RestaurantAtt'
import CarAtt from './components/CarAtt'
import RentalAtt from './components/RentalAtt'
import HotelAtt from './components/HotelAtt'
import TransportAtt from './components/TransportAtt'
import Destinations from "./components/Destinations";

const Attributes = ({ t }) => {

  const [product, setproduct] = useState("");

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setproduct(savedType);
    }
  }, []);


  const configurations = {
    Restaurant: [
      { content: <RestaurantAtt t={t} /> },
    ],

    Maison: [
      { content: <RentalAtt /> },
    ],

    Activité: [
      { content: <FoodAndTransport t={t} /> },
    ],

    Transport: [
      { content: <TransportAtt t={t} /> },
    ],

    Voiture: [
      { content: <CarAtt t={t} /> },
    ],

    Package: [
      { content: <Destinations t={t}  /> },
    ],

    Hotel: [
      { content: <HotelAtt /> },
    ],
  };

  const data = configurations[product] || [];


  return (
    <>
      {data.map((choice, index) => (
        <div key={index}>{choice.content}</div>
      ))}
    </>
  );
};

export default Attributes;
