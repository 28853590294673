import { Wrench } from "lucide-react";
import ManageHeader from "../../components/ManageHeader";

const Administration = () => {
    
  return (
    <section>
        <ManageHeader
                title="Administration de l'hébergement"
                content="Gérez les informations fiscales, juridiques et réglementaires de votre hébergement."
                icon={<Wrench size={25} strokeWidth={1.5} />}
            />
        <div className="container my-4">
            Administration
        </div>
    </section>
  )
}

export default Administration
