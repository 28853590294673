import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import L from 'leaflet';
import Input from '../../../components/forms/Input';



const App = ({ t, address, setAddress }) => {

    const defaultIcon = new L.Icon({
        iconUrl: 'https://unpkg.com/leaflet/dist/images/marker-icon.png',
        iconRetinaUrl: 'https://unpkg.com/leaflet/dist/images/marker-icon-2x.png',
        shadowUrl: 'https://unpkg.com/leaflet/dist/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    const defaultLocation = {
        lat: 48.8566,
        lon: 2.3522,
    };

    const ChangeMapView = ({ location }) => {
        const map = useMap();
        useEffect(() => {
            if (location) {
                map.setView([location.lat, location.lon], 13);
            }
        }, [location, map]);

        return null;
    };

    const MapEvents = ({ onMapClick }) => {
        useMapEvents({
            click(e) {
                console.log('Map clicked at:', e.latlng);
                onMapClick(e.latlng);
            },
        });

        return null;
    };

    const [location, setLocation] = useState(defaultLocation);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchLocation('');
    }, []);

    const fetchLocation = async (address) => {
        if (!address.trim()) {
            setLocation(defaultLocation);
            return;
        }
        const geoUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}&limit=1`;

        try {
            const response = await axios.get(geoUrl);
            const result = response.data[0];
            if (result) {
                setLocation({
                    lat: parseFloat(result.lat),
                    lon: parseFloat(result.lon),
                });
                setError('');
            } else {
                setError('Adresse non trouvée');
                setLocation(defaultLocation);
            }
        } catch (err) {
            console.error('Erreur de géocodage:', err);
            setError('Erreur de géocodage');
            setLocation(defaultLocation);
        }
    };

    const fetchAddress = async (latlng) => {
        const reverseGeoUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latlng.lat}&lon=${latlng.lng}&addressdetails=1`;

        try {
            const response = await axios.get(reverseGeoUrl);
            const address = response.data.display_name;
            setAddress(address);
            setLocation({
                lat: latlng.lat,
                lon: latlng.lng,
            });
        } catch (err) {
            console.error('Erreur lors de la récupération de l\'adresse:', err);
            setError('Erreur lors de la récupération de l\'adresse');
        }
    };

    const handleChange = (e) => {
        const newAddress = e.target.value;
        setAddress(newAddress);
        fetchLocation(newAddress);
    };

    const handleMapClick = (latlng) => {
        console.log('Fetching address for:', latlng);
        fetchAddress(latlng);
    };

    return (
        <>
            <div className="fs-6 fw-medium text-dark">{t('complete_adress')}</div>
            <div className='my-1'>
                <Input type="text" maxlength="50" value={address} handleChange={handleChange} placeholder="Entrez une adresse" />
            </div>

            {error && <p className='my-3 text-red'>{error}</p>}

            <div className='border w-100 my-3 rounded-4' style={{ overflow: "hidden" }}>
                <MapContainer className='w-100' center={[location.lat, location.lon]} zoom={13} style={{ height: '400px' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <ChangeMapView location={location} />
                    <MapEvents onMapClick={handleMapClick} />
                    <Marker position={[location.lat, location.lon]} icon={defaultIcon}>
                        <Popup>{address || 'Localisation par défaut'}</Popup>
                    </Marker>
                </MapContainer>
            </div>
        </>
    );
};

export default App;
