import React from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import { useState } from "react";
import ExtraCharge from "../../../components/ExtraCharge";
import Bedtype from "./Bedtype";
import { Plus, Trash2 } from "lucide-react";
import Choice from "../../../components/Choice";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const Bed = () => {
  const [infos, setInfos] = useState({
    babiesBeds: false,
    extraBeds: false,
    babiescost: "",
    bedscost: "",
    bedsAdd: "no",
  });

  const { t } = useTranslation();

  const handleBabiesBeds = () => {
    setInfos({ ...infos, babiesBeds: !infos.babiesBeds });
  };

  const handleExtraBeds = () => {
    setInfos({ ...infos, extraBeds: !infos.extraBeds });
  };

  const handleBedsAdd = (value) => {
    setInfos({ ...infos, bedsAdd: value });
  };

  const handleBedsCost = (value) => {
    setInfos({ ...infos, bedscost: value });
  };

  const handleBabiescost = (value) => {
    setInfos({ ...infos, babiescost: value });
  };

  const BabiesbedsData = Array.from({ length: 20 }, (_, index) => ({
    title: (index + 1).toString(),
    value: index + 1,
  }));

  const [beds, setBeds] = useState([{ id: 1, value: <Bedtype /> }]);

  const addBeds = () => {
    if (beds.length === 0) {
      setBeds([{ id: 1, value: "" }]);
    } else if (beds.length < 10) {
      const newId = beds[beds.length - 1].id + 1;
      setBeds([...beds, { id: newId, value: "" }]);
    }
  };

  const removeBeds = (id) => {
    const updatedBeds = beds.filter((bed) => bed.id !== id);
    setBeds(updatedBeds);
  };

  const cost = [
    { title: t('free'), value: "Gratuit" },
    { title: t('additional'), value: "Supplément" },
  ];

  return (
    <div className="fs-6">
      <div className="my-3">
        {beds.map((bed) => (
          <div className="my-3 d-flex flex-column" key={bed.id}>
            <Bedtype />
            {beds.length > 1 && (
              <div role="button" className="align-self-end mt-2 border text-danger p-2 rounded-5" onClick={() => removeBeds(bed.id)}>
                <Trash2 size={20} /> <span className="text-dark">{t('delete')}</span>
              </div>
            )}
          </div>
        ))}
        {beds.length < 10 && (
          <span role="button" className="border rounded-5 text-dark text-center p-2 px-3" onClick={addBeds}>
            <Plus size={20} /> {t('add')}
          </span>
        )}
      </div>

      <Choice text={t('add_babies_beds')} selectedChoice={infos.bedsAdd} onChange={handleBedsAdd} />

      {infos.bedsAdd === "yes" && (
        <>
          <div className="my-3">
            <CheckInput id="LitsBébé" label={t('baby_beds')} value={infos.babiesBeds} onChange={handleBabiesBeds} />
          </div>
          {infos.babiesBeds && (
            <>
              <Row className="align-items-center">
                <Col sm="6" xl="3" className="my-3">
                  <Select data={BabiesbedsData} />
                </Col>

                <Col sm="6" xl="3" className="my-3">
                  <Select data={cost} handleChange={(e) => handleBabiescost(e.target.value)} />
                </Col>

                {infos.babiescost === "Supplément" && (
                  <Col xl="6" className="my-3">
                    <ExtraCharge />
                  </Col>
                )}
              </Row>
            </>
          )}

          <div className="my-3">
            <CheckInput id="LitsPliantsSup" label={t('extra_beds')} value={infos.extraBeds} onChange={handleExtraBeds} />
          </div>

          {infos.extraBeds && (
            <>
              <Row className="align-items-center">
                <Col xl="9">
                  <Bedtype />
                </Col>
                <Col xl="3" className="my-2">
                  <Select handleChange={(e) => handleBedsCost(e.target.value)} data={cost} />
                </Col>

                {infos.bedscost === "Supplément" && (
                  <Col xl="12">
                    <ExtraCharge />
                  </Col>
                )}
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Bed;
