import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Row, Col } from "react-bootstrap";
import Select from "../../components/forms/Select";
import Input from "../../components/forms/Input";
import TableItem from "./TableItem";
import { useTranslation } from "react-i18next";

const Products = ({ products, setDeleteProductModal }) => {

  const [bookings] = useState(products);

  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const [filterStatus, setFilterStatus] = useState("Tous");
  const [searchName, setSearchName] = useState("");

  const { t } = useTranslation();



  const getData = () => {
    let filteredData = bookings;

    if (filterStatus !== "Tous") {
      filteredData = filteredData.filter(item => item.status === filterStatus);
    }

    if (searchName !== "") {
      filteredData = filteredData.filter(item =>
        item.title.toLowerCase().includes(searchName.toLowerCase())
      );
    }

    if (sortColumn && sortType) {
      filteredData = filteredData.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = filteredData.slice(startIndex, endIndex);

    return paginatedData;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const status = [
    { title: t('per_status'), value: "Tous" },
    { title: t('working'), value: "En marche" },
    { title: t('waiting'), value: "En attente" },
    { title: t('suspended'), value: "Suspendu" }
  ];

  const handlePageChange = (page) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setCurrentPage(page);
    }, 500);
  };

  const filteredData = bookings.filter(item =>
    filterStatus === "Tous" || item.status === filterStatus
  ).filter(item =>
    searchName === "" || item.title.toLowerCase().includes(searchName.toLowerCase())
  ).sort((a, b) => {
    if (sortColumn && sortType) {
      let x = a[sortColumn];
      let y = b[sortColumn];
      if (typeof x === 'string') {
        x = x.charCodeAt();
      }
      if (typeof y === 'string') {
        y = y.charCodeAt();
      }
      if (sortType === 'asc') {
        return x - y;
      } else {
        return y - x;
      }
    } else {
      return 0;
    }
  });

  const pageCount = Math.ceil(filteredData.length / pageSize);

  return (
    <>
      <div className="border rounded">
        <Row className="px-3 align-items-center">
          <Col xl="4" className="my-3">
            <div className="fs-5 fw-medium text-blue">{t('my_products')}</div>
          </Col>
          <Col xl="4" className="my-3">
            <Input placeholder={t('search_per_name')} data={status} value={searchName} handleChange={(e) => setSearchName(e.target.value)} type="text" />
          </Col>
          <Col xl="4" className="my-3">
            <Select data={status} value={filterStatus} handleChange={(e) => setFilterStatus(e.target.value)} />
          </Col>
        </Row>
        <hr className="m-0 border-secondary" />
        <TableItem bookings={bookings} getData={getData()} sortColumn={sortColumn} sortType={sortType} handleSortColumn={handleSortColumn}
          loading={loading} setDeleteProductModal={setDeleteProductModal} />
        <div className="d-flex align-items-center justify-content-between border-top p-3 mt-3">
          <span className="fs-6 fw-light">
            {t('displaying')} 1 {t('à')} 5 {t('to')} {bookings.length} {t('entries')}
          </span>
          <span className="d-flex align-items-center">
            <button className="bg-white text-blue p-2 mx-3" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}><ChevronLeft size={20} /></button>

            {Array.from({ length: pageCount }, (_, index) => (
              <button key={index} className={`bg-white fs-5 mx-3 ${currentPage === index + 1 ? 'text-blue' : 'text-secondary'}`} onClick={() => handlePageChange(index + 1)} disabled={currentPage === index + 1}>
                {index + 1}
              </button>
            ))}

            <button className="bg-white text-blue p-2 mx-3" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}><ChevronRight size={20} /></button>
          </span>
        </div>
      </div>
    </>
  );
};

export default Products;

