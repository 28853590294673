import { Row, Col } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import Input from "../../../components/forms/Input";
import RadioInput from "../../../components/forms/RadioInput";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ValidityAndDuration = () => {

    const { t } = useTranslation();

    const [infos, setInfos] = useState({
        validity: ""
    });

    const handleValidity = (value) => {
        setInfos({ ...infos, validity: value });
    };

    const time = [
        { title: "Minute(s)", value: "Minute(s)" },
        { title: "Heure(s)", value: "Heure(s)" },
        { title: "Jour(s)", value: "Jour(s)" },
    ];

    const validty = [
        { title: t('valid_for_period_after_booking'), value: "Valable pour une période de temps à compter de la date de réservation" },
        { title: t('valid_for_period_after_activation') },
        { title: t('valid_for_booking_date'), value: "Valable à la date réservée" },
    ];

    return (
        <>
            <div className="my-4">
                <div className="fs-5 fw-medium text-dark">{t('activityduree')}</div>
                <p className="fs-6 fw-light mt-1">{t('activitydureeP')}</p>
                <>
                    <div className="my-3 fs-6">
                        <RadioInput name="ActivityValidity" id="DuréeDetérminée" label={t('activitydureeP1')} onChange={() => handleValidity("DuréeDetérminée")} />
                    </div>

                    {infos.validity === "DuréeDetérminée" && (
                        <Row className="fs-6">
                            <Col sm="6" className="my-3">
                                <Input type="number" placeholder={t('duration')} />
                            </Col>
                            <Col sm="6" className="my-3">
                                <Select data={time} />
                            </Col>
                        </Row>
                    )}

                    <div className="my-3 fs-6">
                        <RadioInput name="ActivityValidity" id="AtoutMoment" label={t('activitydureeP2')} onChange={() => handleValidity("AtoutMoment")} />
                    </div>

                    {infos.validity === "AtoutMoment" && (
                        <div className="my-3 fs-6">
                            <Select data={validty} />
                        </div>
                    )}
                </>
            </div>
            <div className="my-4">
                <>
                    <div className="fs-5 fw-medium text-dark">{t('limited_hour')}</div>
                    <p className="fs-6">{t('limited_hour_caption')}</p>
                    <Row className="fs-6">
                        <Col sm="6" className="my-3">
                            <Input type="number" placeholder={t('duration')} />
                        </Col>
                        <Col sm="6" className="my-3">
                            <Select data={time} />
                        </Col>
                    </Row>
                </>
            </div>
        </>
    )
}

export default ValidityAndDuration
