import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;


const DateInput = ({ rightLabel, leftLabel }) => {

  return (
      <RangePicker className='input-blue w-100 p-4 border rounded-0' placeholder={[leftLabel,rightLabel]} />
  );
};

export default DateInput;
