import { X } from 'lucide-react';
import React, { useState } from 'react';
import { t } from 'i18next';

const AddedValue = () => {

    const items = [
        { title: t('rate_item1'), value: "01CarSafariParNuit" },
        { title: t('rate_item2'), value: "02SafarisEnVoitureParNuit" },
        { title: t('rate_item3'), value: "AccesBoitesNuitFileVIP" },
        { title: t('rate_item4'), value: "AccesWifiGratuit" },
        { title: t('rate_item5'), value: "AccesAuSpa" },
        { title: t('rate_item6'), value: "TransfertAeroportGratuit" },
        { title: t('rate_item7'), value: "TousRepasInclus" },
        { title: t('rate_item8'), value: "ServiceVoiturierGratuit" },
        { title: t('rate_item9'), value: "MassageGratuitInclus" },
        { title: t('rate_item10'), value: "ToutCompris" },
        { title: t('rate_item11'), value: "ServiceDepartTardif" },
        { title: t('rate_item12'), value: "MinibarGratuit" },
        { title: t('rate_item13'), value: "EntreeGratuiteParcAquatic" },
    ];

    const [infos, setInfos] = useState({
        elements: [],
        options: items,
        selected: "",
    });

    const handleSelectChange = (event) => {
        const value = event.target.value;

        if (!infos.elements.map(opt => opt.value).includes(value)) {
            const selectedOption = infos.options.find(option => option.value === value);
            setInfos(prevState => ({
                ...prevState,
                elements: [...prevState.elements, selectedOption],
                options: prevState.options.filter(option => option.value !== value)
            }));
        }
    };

    const handleDelete = (value) => {
        setInfos(prevState => ({
            ...prevState,
            elements: prevState.elements.filter(item => item.value !== value.value),
            options: [...prevState.options, value]
        }));
    };

    return (
        <>
            <span className='fs-6 text-blue'>{t('items_included_in_price')} :</span>
            <select value={infos.selected} onChange={handleSelectChange} className='input-blue w-100 p-4 border my-2'>
                <option value="">-- {t('select_option')} --</option>
                {infos.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.title}
                    </option>
                ))}
            </select>

            <div className='my-3'>
                <div className='d-flex flex-wrap'>
                    {infos.elements.map((item) => (
                        <div key={item.value} className='d-flex align-items-center m-2 border p-3'>
                            <span>{item.title}</span>
                            <span role='button' onClick={() => handleDelete(item)} className='text-danger mx-2'><X /></span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default AddedValue;