import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import RoomOption from './RoomOption'
import CheckInput from '../../../components/forms/CheckInput';
import { useTranslation } from "react-i18next";

const DetailsBedding = () => {
    const { t } = useTranslation();
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };


    const bedding = [
        { title: t('hypo_bedding_available'), value: "Hypoallergénique" },
        { title: t('premium_bedding'), value: "LiterieSupérieure" },
        { title: t('high_end_mattress'), value: "MatelasHautDeGamme" },
        { title: t('choice_of_pillows'), value: "ChoixOreillers" }
    ]

    const mattress = [
        { title: t('memory_mattress'), value: "MatelasAmémoireDeForme" },
        { title: t('mattress_topper'), value: "Surmatelas" },
        { title: t('tumpur_pedic_mattress'), value: "MatelasTempurPedic" },
        { title: t('sleep_number_mattress'), value: "MatelasSleepNumber" }
    ]

    return (
        <div className='my-3 fs-6'>
            <>

                {bedding.map((check, index) => (
                    <div key={index} className='my-3'>
                        <>
                            <CheckInput id={check.value} label={check.title} value={check.value} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                        </>

                        {checkedItems[check.value] && (
                            <>

                                {['Hypoallergénique', 'ChoixOreillers', 'CouetteEnPlumes'].includes(check.value) && (
                                    <RoomOption />
                                )}

                                {check.value === 'LiterieSupérieure' && (
                                    <>
                                        <RoomOption />
                                        <div className='my-3 px-3'>
                                            <CheckInput id="DrapsGautGamme" label={t('premium_linens')} />
                                        </div>
                                    </>
                                )}

                                {check.value === 'MatelasHautDeGamme' && (
                                    <>
                                        <RoomOption />
                                        <Row className='align-items-center'>
                                            <Col md="6" className='my-2'>
                                                <span className='fs-6 fw-medium text-dark'>{t('mattress_type')}</span>
                                            </Col>
                                            <Col md="6" className='my-2'>
                                                <Select data={mattress} />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        )}


                    </div>
                ))}
            </>
        </div>
    )
}

export default DetailsBedding