import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid';
import RadioInput from '../../../components/forms/RadioInput'
import { useTranslation } from "react-i18next";

const RoomOption = () => {
  const { t } = useTranslation();
  const [groupId] = useState(uuidv4());
  const [selectedRoom, setSelectedRoom] = useState(null);

  const handleRoomChange = (event) => {
    setSelectedRoom(event.target.id);
  };

  useEffect(() => {

    setSelectedRoom(null);
  }, []);

  return (

    <Row className='fs-6 align-items-center'>
      <Col md="12" className='d-flex align-items-center'>
        <div className='d-flex align-items-center mx-3'>
          <RadioInput id={`everyroom_${groupId}`} label={t('in_every_room')} name={`roomType_${groupId}`} checked={selectedRoom === `everyroom_${groupId}`} onChange={handleRoomChange} />
        </div>

        <div className='d-flex align-items-center mx-3'>
          <RadioInput id={`thisroom_${groupId}`} label={t('in_this_room')} name={`roomType_${groupId}`} checked={selectedRoom === `thisroom_${groupId}`} onChange={handleRoomChange} />
        </div>
      </Col>
    </Row>
  )
}

export default RoomOption