import { Row, Col } from "react-bootstrap";
import Faq from "../../components/home/Faq";
import { useTranslation } from "react-i18next";
import { AppWindow, Coins } from "lucide-react";
import Steps from "../../components/home/Steps";
import Member from "../../components/home/Member";
import logo from "../../assets/Travel partener2.svg";
import { useState } from "react";
import Register from "./Register";
import Reasons from "../../components/home/Reasons";
import Footer from "../../layout/Footer";

const Landing = ({ handleLogin }) => {

  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }

  return (
    <section>
      <div className="container fs-6 text-dark">
        <Row className="justify-content-between py-3">
          <Col xs="4" sm="3" md="2">
            <img alt="Logo de idhtours - Site de réservations de voyages en ligne" width={60} height={60} src={logo} />
          </Col>
          <Col xl="4">
            <Row>
              <Col xl="6" className="my-3">
                <button className="btn-outline-blue border-blue p-3 w-100" onClick={() => handleShow(true)}>
                  {t('log_in')}
                </button>
              </Col>
              <Col xl="6" className="my-3">
                <button className="btn-blue border-blue p-3 w-100" onClick={() => handleShow(true)}>
                  {t('register')}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center pt-5">
          <Col lg="6">
            <div>
              <h1 className="fs-0" dangerouslySetInnerHTML={{ __html: t("main_title") }}></h1>
              <div className="fs-3 fw-medium my-3">{t("subtitle")}</div>
              <p className="fs-6 fw-light my-4">{t("intro")}</p>

              <div className="my-5 fs-6 d-flex align-items-start">
                <span className="p-3 rounded-circle bg-secondary-blue text-blue">
                  <AppWindow strokeWidth={1.5} />
                </span>
                <span className="mx-3">
                  <div className="fw-medium fs-5">{t("create_product")}</div>
                  <p className="fw-light mt-2">{t("create_product_text")}</p>
                </span>
              </div>
              <div className="my-5 fs-6 d-flex align-items-start">
                <span className="p-3 rounded-circle bg-secondary-blue text-blue">
                  <Coins strokeWidth={1.5} />
                </span>
                <span className="mx-3">
                  <div className="fw-medium fs-5">{t("make_benefits")}</div>
                  <p className="fw-light mt-2">{t("make_benefits_text")}</p>
                </span>
              </div>
            </div>
          </Col>
          <Col lg="6">
            <Row>
              <Col xl="6" className="my-2">
                <img className="rounded" width="100%" height={500} alt="Illusatration de logement" src="https://creativelayers.net/themes/gotrip-html/img/masthead/2/1.png" />
              </Col>

              <Col xl="6">
                <Row>
                  <Col xl="12" className="my-2 d-none d-xl-block">
                    <img className="rounded" width="100%" height={240} alt="Illusatration de logement" src="https://dtkudil.wpenginepowered.com/wp-content/uploads/2014/01/gallery1-640x480.jpg" />
                  </Col>
                  <Col xl="12" className="my-2 d-none d-xl-block">
                    <img className="rounded" width="100%" height={240} alt="Illusatration de logement" src="https://creativelayers.net/themes/gotrip-html/img/masthead/2/3.png" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Reasons t={t} />
        <Steps t={t} />
        <Member t={t} show={show} setShow={setShow} />
        <Faq />
        <Register show={show} setShow={setShow} handleLogin={handleLogin} />

      </div>
      <Footer t={t} />
    </section>
  );
};

export default Landing;
