import React from 'react'

const RadioInput = ({id,name,label,value,onChange}) => {
    
    return (
        <div className='d-flex align-items-center my-3'>
            <input type="radio" name={name} id={id} value={value} onChange={onChange} />
            <label className='mx-2' htmlFor={id}>{label}</label>
        </div>
    )
}

export default RadioInput