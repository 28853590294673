import { Col, Modal, Row } from "react-bootstrap";
import Chart from "./ChartItem";
import Products from "./Products";
import BestProduct from "./BestProduct";
import Stats from "./Stats";
import SalesCountires from "./SalesCountires";
import products from '../../data/products.json'
import ActiveProducts from "./ActiveProducts";
import EventItem from "./EventItem";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Dashboard = () => {

  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const { t } = useTranslation();
  
  return (
    <div className="container py-5">
      <Stats products={products.products} />
      <Row>
        <Col lg="6" xl="8" className="my-3">
          <Chart />
        </Col>
        <Col lg="6" xl="4" className="my-3">
          <BestProduct products={products.products}/>
        </Col>
        <Col xl="12" className="my-3">
          <Products products={products.products} setDeleteProductModal={() => (setDeleteProductModal(true))} />
        </Col>
        <Col lg="6" xl="4" className="my-3">
          <SalesCountires />
        </Col>
        <Col lg="6" xl="4" className="my-3">
          <EventItem />
        </Col>
        <Col lg="6" xl="4" className="my-3">
          <ActiveProducts products={products.products} />
        </Col>
      </Row>

      <Modal show={deleteProductModal} onHide={() => (setDeleteProductModal(false))}>
        <Modal.Body className="text-dark"><p className="text-dark fs-5 fw-light py-4">{t('delete_product')}</p></Modal.Body>
        <Modal.Footer>
          <button className="p-3 btn-outline-blue" onClick={() => (setDeleteProductModal(false))}>
            {t('cancel')}
          </button>
          <button className="p-3 btn-blue" onClick={() => (setDeleteProductModal(false))}>
          {t('confirm')}
          </button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Dashboard;
