import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsLaundry = ({ checked }) => {
  
  const { t } = useTranslation();

  const installations = [
    { title: t('ballroom'), value: "SalleDeBal" },
    { title: t('banquet_hall'), value: "SalleDeBanquet" },
    { title: t('reception_room'), value: "SalleDeRéception" },
  ];

  return (
    <div className="my-3 fs-6">
      {checked === "Laverie" && (
        <div className="px-3 my-3">
          <CheckInput id="LaverieAvecJetons" label={t('laundry_with_tokens')} />
        </div>
      )}

      {checked === "InstallationsRéception" && (
        <>
          {installations.map((check, index) => (
            <div key={index} className="px-3 my-3">
              <CheckInput id={check.value} label={check.title} />
            </div>
          ))}
        </>
      )}

    </div>
  );
};

export default DetailsLaundry;
