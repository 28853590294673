import TopBanner from "../../components/TopBanner";
import HandleSteps from "../../components/HandleSteps";
import { useState } from "react";
import StepComponent from "../../components/StepComponent";
import { useTranslation } from "react-i18next";
import Title from './Title'
import Description from './Description'
import Photos from './Photos'
import Structure from './Structure'
import Informations from './Informations'
import Type from './Type'
import Attributes from "./Attributes";
import Additional from "./Additional";
import Details from "./Details";
import Process from "./Process";
import Pricing from "./Pricing";
import Review from "./Review";
import Booking from "./Booking";
import Discount from "./Discount";
import Location from "./components/Location";
import Epilogue from "./Epilogue";

const Operations = () => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(0);

    const handleStart = () => {
        setIsLoading(true);
        setTimeout(() => {
            setStep(1);
            window.scrollTo(0, 0);
            setIsLoading(false);
        }, 1000);
    };

    const handleNextStep = () => {
        setIsLoading(true);
        setTimeout(() => {
            setStep(step + 1);
            window.scrollTo(0, 0);
            setIsLoading(false);
        }, 1000);
    };

    const handlePrevStep = () => {
        setStep(step - 1);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <TopBanner step={step} />
            <section className="position-relative py-5 mb-5 h-100">
                <form action="/">

                    <>
                        {step === 0 && <Structure onStart={handleStart} t={t} isLoading={isLoading} />}

                        {step === 1 && <StepComponent step={1} />}

                        {step === 2 && <Title />}
                        {step === 3 && <Type t={t} />}
                        {step === 4 && <Location t={t} />}
                        {step === 5 && <Description />}

                        {step === 6 && <StepComponent step={2} />}

                        {step === 7 && <Informations />}
                        {step === 8 && <Additional t={t} />}
                        {step === 9 && <Attributes t={t} />}
                        {step === 10 && <Details t={t} />}
                        {step === 11 && <Photos />}

                        {step === 12 && <StepComponent step={3} />}

                        {step === 13 && <Process t={t} />}
                        {step === 14 && <Booking t={t} />}
                        {step === 15 && <Pricing t={t} />}
                        {step === 16 && <Discount t={t} />}
                        {step === 17 && <Review t={t} />}

                        {step === 18 && <Epilogue />}

                        <HandleSteps isLoading={isLoading} totalSteps={18} step={step} prev={handlePrevStep} next={handleNextStep} sepratorOne={5} SeparatorTwo={11} />
                    </>

                </form>
            </section>
        </>
    );
};

export default Operations;
