import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FiAlertCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const TabContent = ({ message }) => (
  <>
    <div className="py-5 text-center border-bottom me-2">
      <FiAlertCircle /> {message}
    </div>
  </>
);

function Bookingdetailsroom() {
  const { t } = useTranslation();

  const tabs = [
    { eventKey: "Prepaiement", title: t('prepaiement'), message: t('prepaiement_caption') },
    { eventKey: "Dommages", title: t('damages'), message: t('damages_caption') },
    { eventKey: "Internet", title: t('internet'), message: t('internet_caption') },
    { eventKey: "Parking", title: t('parking'), message: t('parking_caption') },
    { eventKey: "Groupes", title: t('groups'), message: t('groups_caption') },
    { eventKey: "Animaux", title: t('Animaux de compagnie'), message: t('pets_caption') },
  ];

  return (
    <div className="my-2">
      <style>{`
        .skoop-tabs {
          background-color: #f8f9fa;
          border-radius: 10px;
        }
        
        .skoop-tabs .nav-link {
          color: #000000;
          font-weight: bold;
          transition: background-color 0.3s ease;
        }
        
        .skoop-tabs .nav-link.active {
          background-color: black;
          color: #ffffff;
        }
      `}</style>
      <Tabs defaultActiveKey="Prepaiement" id="booking-details-tabs" className="skoop-tabs" justify>
        {tabs.map((tab) => (
          <Tab eventKey={tab.eventKey} title={tab.title} key={tab.eventKey}>
            <TabContent message={tab.message} />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}

export default Bookingdetailsroom;
