import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Chart from './components/Chart'
import ManageHeader from '../../components/ManageHeader'
import { BarChart } from 'lucide-react'
import Stats from './components/Stats'
import Bookings from './components/Bookings'

const Performances = ({t}) => {
  return (
    <section>
      <ManageHeader title={t('bookings')} content={t('performance_caption')} icon={<BarChart size={25} strokeWidth={1.5} />} />

      <div className="container py-4">
        <Row>
          <Col lg="6" className="my-3">
            <Chart t={t}/>
          </Col>
          <Col lg="6" className="my-3">
            <Stats t={t}/>
          </Col>
        </Row>
        <Bookings t={t}/>
      </div>


    </section>
  )
}

export default Performances
