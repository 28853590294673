import { Mail } from "lucide-react";
import ManageHeader from "../../components/ManageHeader";
import Chat from "./components/Chat";

const Messages = ({t}) => {
  return (
    <section>
      <ManageHeader
        title={t('messages')}
        content={t('messages_caption')}
        icon={<Mail size={25} strokeWidth={1.5}/>}
      />

      <div className="container p-4">
        <Chat t={t}/>
      </div>
    </section>
  )
}

export default Messages
