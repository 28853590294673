import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import WifiFees from "./WifiFees";
import Restrictions from "./Restrictions";
import { useTranslation } from "react-i18next";

const DetailsWifi = ({ checked }) => {
  const { t } = useTranslation();

  const debit = [
    { title: `${t('more_than')} 25 Mb/s`, value: "25mb" },
    { title: `${t('more_than')} 50 Mb/s`, value: "50mb" },
    { title: `${t('more_than')} 100 Mb/s`, value: "100mb" },
    { title: `${t('more_than')} 250 Mb/s`, value: "250mb" },
    { title: `${t('more_than')} 500 Mb/s`, value: "500mb" },
  ];

  return (
    <div className="my-3 fs-6">
      <>
        {checked === "DansLesChambres" && (
          <>
            <WifiFees />
            <Row className="fs-6 align-items-center">
              <Col md="6" className="d-flex align-items-center my-3">
                <span className="fw-medium text-dark">{t('wifi_min_debit')}</span>
              </Col>
              <Col md="6" className="d-flex align-items-center my-3">
                <Select data={debit} />
              </Col>
            </Row>
            <Restrictions />
          </>
        )}

        {checked === "PartiesCommunes" && (
          <>
            <WifiFees />
            <Restrictions />
          </>
        )}

        {["ParCableDansLesChambres", "ParCablePartiesCommunes", "ParModem"].includes(checked) && <WifiFees />}
      </>
    </div>
  );
};

export default DetailsWifi;
