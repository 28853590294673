import React, { useState } from 'react'
import Textarea from '../../../components/forms/Textarea'
import { Info } from 'lucide-react';

const Title = ({t}) => {

  const [title, setTitle] = useState("");

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  return (
    <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('marketing_modal_titre')}</h2>
              <div className="mt-5">
                <Textarea value={title} handleChange={handleTitle} rows={8} maxlength={32} />
              </div>
              <div className="text-secondary fw-bold">{title.length}/32</div>
              {title.length > 31 && (
                <div className="mt-3 text-danger d-flex align-items-center">
                  <Info size={20} />
                  <p className="mx-2">32 {t('max_chars')}</p>
                </div>
              )}
            </>
  )
}

export default Title
