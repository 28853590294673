import { useState } from 'react';
import RoomOption from './RoomOption'
import ExtraCharge from '../../../components/ExtraCharge';
import CheckInput from '../../../components/forms/CheckInput';
import DateYear from '../../../components/DateYear'
import { useTranslation } from "react-i18next";

const DetailsConditioning = () => {

    const { t } = useTranslation();

    const [checkedItems, setCheckedItems] = useState({});

    const [infos, setInfos] = useState({
        restricted: false,
        fees: false
    });

    const handleRestricted = () => {
        setInfos({ ...infos, restricted: !infos.restricted });
    };

    const handleFees = () => {
        setInfos({ ...infos, fees: !infos.fees });
    };


    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const conditioning = [
        { title: t('restauAtt18'), value: "Climatiseur" },
        { title: t('fan'), value: "Ventilateur" },
        { title: t('heating'), value: "Chauffage" }
    ]

    const fan = [
        { title: t('ceiling_fan'), value: "VentilateurPlafond" },
        { title: t('portable_fan'), value: "VentilateurPortable" },
        { title: t('fan'), value: "VentilateurSimple" }
    ]

    return (
        <div className='my-3 fs-6'>
            <>

                {conditioning.map((check, index) => (
                    <div key={index} className='my-3'>
                        <>
                            <CheckInput id={check.value} label={check.title} value={check.value} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                        </>

                        {checkedItems[check.value] && (
                            <>

                                {check.value === 'Climatiseur' && (
                                    <>
                                        <RoomOption />
                                        <div className='px-3'>
                                            <div className='my-3'>
                                                <CheckInput id="UtilisationRestreinte" label={t('air_choice1')} value={infos.restricted} onChange={handleRestricted} />
                                            </div>

                                            {infos.restricted &&
                                                <DateYear />
                                            }

                                            <div className='my-3'>
                                                <CheckInput id="FraisClimatisation" label={t('air_choice2')} value={infos.fees} onChange={handleFees} />
                                            </div>

                                            {infos.fees &&
                                                <ExtraCharge />
                                            }

                                        </div>
                                    </>
                                )}

                                {check.value === 'Ventilateur' && (
                                    <>
                                        <RoomOption />
                                        {fan.map((check, index) => (
                                            <div key={index} className='my-3 px-3'>
                                                <CheckInput id={check.value} label={check.title} />
                                            </div>
                                        ))}
                                    </>
                                )}

                                {check.value === 'Chauffage' && (
                                    <>
                                        <RoomOption />
                                    </>
                                )}

                            </>
                        )}

                    </div>
                ))}
            </>
        </div>
    )
}

export default DetailsConditioning