import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import Calendar from "./Calendar";
import RadioInput from "../../../components/forms/RadioInput";
import Choice from "../../../components/Choice";

const RatesPerAge = ({ t,content }) => {
  const [filters, setFilters] = useState({
    babies: { title: t('babies'), checked: false, min: "", max: "", amount: "", tutor: true },
    childs: { title: t('childs'), checked: false, min: "", max: "", amount: "", tutor: true },

    adults: { title: t('adults'), checked: false, min: "", max: "", amount: "", tutor: false },
    seniors: { title: t('seniors'), checked: false, min: "", max: "", amount: "", tutor: false },
  });

  const [infos, setInfos] = useState({
    pricing: "MemePrixPourTous",
    elements: [],
    options: filters,
    allowed: "no",
    fees: "no",
    ticket: "no",
    tutorNeeded: "no"
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: { ...prevFilters[name], checked }
    }));
  };

  const handleInputChange = (event, groupName, type) => {
    const { value } = event.target;
    
    const validValue = type === 'min' || type === 'max' ? value.slice(0, 2) : value;
  
    setFilters(prevFilters => ({
      ...prevFilters,
      [groupName]: {
        ...prevFilters[groupName],
        [type]: validValue
      }
    }));
  };
  
  const handlePricing = (value) => {
    setInfos(prevInfos => ({ ...prevInfos, pricing: value }));
  };

  const handleChange = (field, value, groupName) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [groupName]: {
        ...prevFilters[groupName],
        [field]: value
      }
    }));
  };

  return (
    <>
      <div className="fs-5 lh-base fw-medium text-dark">{t('more_about_your_prices')}</div>
      <p className="fs-6 fw-light">{t('more_about_your_prices_caption')}</p>

      <div className="my-3 fs-6">
        <RadioInput
          name="ActivityPrix"
          id="MemePrixPourTous"
          label={t('same_price_for_everyone')}
          value={infos.pricing}
          onChange={() => handlePricing("MemePrixPourTous")}
        />
      </div>
      <div className="my-3 fs-6">
        <RadioInput
          name="ActivityPrix"
          id="PrixDependDeLage"
          label={t('price_depend_on_age')}
          value={infos.pricing}
          onChange={() => handlePricing("PrixDependDeLage")}
        />
      </div>

      {infos.pricing === "PrixDependDeLage" && (
        <div className="fs-6 my-3">
          <Row>
            {Object.keys(filters).map((group, index) => (
              <Col xl="12" key={group}>
                <div className="d-flex align-items-center my-3 w-100">
                  <input
                    id={`age${index}`}
                    type="checkbox"
                    name={group}
                    checked={filters[group].checked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={`age${index}`} className="mx-2">
                    {filters[group].title}
                  </label>
                </div>

                {filters[group].checked && (
                  <>
                    <Calendar t={t} group={group} filters={filters} handleInputChange={handleInputChange} />
                    <>
                      <Choice
                        text={t('category_allowed')}
                        selectedChoice={filters[group].allowed}
                        onChange={(value) => handleChange('allowed', value, group)}
                      />

                      {filters[group].allowed === "yes" && (
                        <>
                          <Choice
                            text={t('category_free')}
                            selectedChoice={filters[group].fees}
                            onChange={(value) => handleChange('fees', value, group)}
                          />

                          {filters[group].tutor && (
                            <Choice
                              text={t('adult_necessary')}
                              selectedChoice={filters[group].tutorNeeded}
                              onChange={(value) => handleChange('tutorNeeded', value, group)}
                            />
                          )}
                        </>
                      )}
                    </>
                  </>
                )}
              </Col>
            ))}
          </Row>
        </div>
      )}
      <div className="my-3">
        {content}
      </div>
    </>
  );
};

export default RatesPerAge;
