import React, { useState } from 'react';
import { Input, Button } from 'rsuite';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        if (username === 'idh' && password === 'idh2024') {
            onLogin();
        } else {
            alert('Identifiant ou mot de passe incorrect');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="login-bg">
            <div className="login-container w-50 p-5">
                <h2 className="fs-4 text-center mb-5">WELCOME TO IDH! PLEASE SIGN IN</h2>
                <Input
                    placeholder="Nom d'utilisateur"
                    value={username}
                    onChange={(value) => setUsername(value)}
                    className="mb-3"
                />
                <Input
                    type="password"
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(value) => setPassword(value)}
                    onKeyPress={handleKeyPress}
                    className="mb-3"
                />
                <Button
                    appearance="primary"
                    block
                    onClick={handleLogin}
                    className={`login-button ${password === 'idh2024' && username === 'idh' ? 'orange' : ''}`}
                >
                    Connexion
                </Button>
            </div>
        </div>
    );
}

export default Login;
