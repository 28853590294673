import { Row, Col } from "react-bootstrap";
import { RadioTile, RadioTileGroup } from "rsuite";
import { useState } from "react";

const Color = ({ t }) => {
  const [infos, setInfos] = useState({
    color: "",
  });

  const handleColor = (value) => {
    setInfos({ ...infos, color: value });
  };

  const data = [
    { title: t('white'), value: "white" },
    { title: t('black'), value: "black" },
    { title: t('grey'), value: "#E8E9E8" },
    { title: t('blue'), value: "#3B71FE" },
    { title: t('red'), value: "#D22B2B" },
    { title: t('yellow'), value: "#FFEA00" },
    { title:t('green'), value: "#023020" },
    { title: t('orange'), value: "#F8D448" },
    { title: t('purple'), value: "purple" },
    { title: t('brown'), value: "#6E260E" },
    { title: t('beige'), value: "beige" },
    { title: t('dark_grey'), value: "#3A3B3C" },
    { title:t('midnight_blue'), value: "#00008B" },
    { title: t('pink'), value: "pink" },
    { title: t('skyblue'), value: "#7DF9FF" },
    { title: t('light_green'), value: "lightgreen" },
    { title: t('silver'), value: "linear-gradient(to right,#6f6f73, #ffffff, #b4b4b9, #727176)" },
    { title: t('golden'), value: "linear-gradient(to right,#AE8625, #F9F295, #d2ac47, #F9F295)" },
  ];
  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="6" className="my-3">
            <h2 className="fs-3 lh-base fw-medium text-dark">{t('CarsCol')}</h2>
            <p className="fs-5 fw-light">{t('CarsColp')}</p>
            <div className="my-4">
              <RadioTileGroup>
                <Row>
                  {data.map((choice, index) => (
                    <Col key={index} xs="3" lg="4" className="my-2">
                      <RadioTile value={choice.value} onChange={handleColor} className={`p-4 ${infos.color === choice.value ? "border-blue" : "border"}`} style={{ background: choice.value }}>
                        <div className={`${choice.value === "black" || choice.value === "#3B71FE" || choice.value === "#D22B2B" || choice.value === "#023020" || choice.value === "#6E260E" || choice.value === "#3A3B3C" || choice.value === "#00008B" || choice.value === "purple" ? "text-white" : "text-dark"}`}>{choice.title}</div>
                      </RadioTile>
                    </Col>
                  ))}
                </Row>
              </RadioTileGroup>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Color;
