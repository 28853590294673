import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import img from '../../assets/icones/undraw_fireworks_re_2xi7.svg';

const Epilogue = () => {

    const { t } = useTranslation();

  return (
    <section className="position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col xl="10">
            <Row className="align-items-center">
              <Col lg="6" className="my-3">
                <div className="py-5 px-sm-5 text-dark">
                  <div className="fs-5 fw-bold text-blue">
                    {t('congrats')}
                  </div>
                  <h2 className="fs-1 lh-base fw-bold text-dark my-1">{t('step4_title')}</h2>
                  <p className="fs-5 fw-light my-3">{t('step4_text')}</p>
                </div>
              </Col>
              <Col lg="6">
                <div className="w-100">
                  <img
                    alt={`illustration de la création d'un produit`}
                    width="100%"
                    height="100%"
                    src={img}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Epilogue
