import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Select from '../../../components/forms/Select'
import { useTranslation } from "react-i18next";

const Bedtype = () => {
    const { t } = useTranslation();

    const beds = [
        { title: t('single_bed'), value: "TwinBed" },
        { title: t('large_single_bed'), value: "TwinXLBed" },
        { title: t('double_bed'), value: "FullBed" },
        { title: t('queen_bed'), value: "QueenBed" },
        { title: t('king_bed'), value: "KingBed" },
        { title: t('water_bed'), value: "WaterBed" },
        { title: t('bunk_bed'), value: "BunkBed" },
        { title: t('sofa'), value: "SofaBed" },
        { title: t('futon'), value: "Futon" },
    ]

    const bedsLength = Array.from({ length: 20 }, (_, index) => ({
        title: (index + 1).toString(),
        value: index + 1
    }));

    return (
        <Row className='fs-6 align-items-center'>
            <Col xl="9" className='my-3'>
                <Select data={beds} />
            </Col>
            <Col xl="3" className='my-3'>
                <Select data={bedsLength} />
            </Col>
        </Row>
    )
}

export default Bedtype