import { Row, Col } from "react-bootstrap";
import { RadioTile, RadioTileGroup } from "rsuite";
import { CalendarCheck2, CalendarClock, CalendarMinus } from "lucide-react";
import { useState } from "react";
import Cancel from "./Cancel";

const Availability = ({ t }) => {
  const [infos, setInfos] = useState({
    choice: "",
  });

  const handleChoice = (value) => {
    setInfos({ ...infos, choice: value });
  };

  const data = [
    {
      title: t('CarsDetailsWeek1'),
      icon: <CalendarCheck2 strokeWidth={1.2} size={30} />,
      value: "TousLesWeekEnds",
      content: t('CarsDetailsWeek1p'),
    },
    {
      title: t('CarsDetailsWeek2'),
      icon: <CalendarClock strokeWidth={1.2} size={30} />,
      value: "2/4Week-endEnMoyenne",
      content: t('CarsDetailsWeek2p'),
    },
    {
      title: t('CarsDetailsWeek3'),
      icon: <CalendarMinus strokeWidth={1.2} size={30} />,
      value: "0/1WeekEndMoyenne",
      content: t('CarsDetailsWeek3p'),
    },
  ];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="9" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('availability')}</h2>
              <p className="fs-5 fw-light">{t('CarsDetailsWeek')}</p>
              <RadioTileGroup>
                <Row className="mt-3">
                  {data.map((choice, index) => (
                    <Col key={index} xs="12" className="my-2">
                      <RadioTile onChange={handleChoice} value={choice.value} className={`h-100 rounded-4 py-4 p-3 ${infos.choice === choice.value ? "border-dark" : "border"}`}>
                        <div className="text-dark d-flex">
                          {choice.icon}
                          <span className="px-3">
                            <div className="fs-5 ">{choice.title}</div>
                            <p className="fs-6 fw-light">{choice.content}</p>
                          </span>
                        </div>
                      </RadioTile>
                    </Col>
                  ))}
                </Row>
              </RadioTileGroup>
              <Cancel/>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Availability;
