import { Row, Col } from 'react-bootstrap'
import RadioInput from '../../../components/forms/RadioInput'
import { useState } from 'react'
import Input from '../../../components/forms/Input'
import Form from 'react-bootstrap/Form'
import DaysCalendar from './DaysCalendar'


const Restrictionupdate = ({ t }) => {
    const [restrictionsupdates, setInfo] = useState({
        restrictionsupdate: ""
    });

    const handlerestrictions = (value) => {
        setInfo({ ...restrictionsupdates, restrictionsupdate: value });
    };

    const renderRestrictions = () => (
        <Row className='d-flex flex-column'>
            <Col>
                <span className='fw-bolder fs-6 text-blue'>{t('saturday')}</span>
                <DaysCalendar t={t} />
            </Col>
            <Col>
                <span className='fw-bolder fs-6 text-blue'>{t('monday')}</span>
                <DaysCalendar t={t} />
            </Col>
            <Col>
                <span className='fw-bolder fs-6 text-blue'>{t('tuesday')}</span>
                <DaysCalendar t={t} />
            </Col>
            <Col>
                <span className='fw-bolder fs-6 text-blue'>{t('wednesday')}</span>
                <DaysCalendar t={t} />
            </Col>
            <Col>
                <span className='fw-bolder fs-6 text-blue'>{t('thursday')}</span>
                <DaysCalendar t={t} />
            </Col>
            <Col>
                <span className='fw-bolder fs-6 text-blue'>{t('friday')}</span>
                <DaysCalendar t={t} />
            </Col>
            <Col>
                <span className='fw-bolder fs-6 text-blue'>{t('saturday')}</span>
                <DaysCalendar t={t} />
            </Col>
        </Row>
    );
    return (
        <div className='bg-white p-3'>
            <span className='fs-4 fw-medium text-dark'>{t('ho_act_tarif_rest_restp')}</span>
            <p className='fs-6 my-3'>{t('ho_act_tarif_rest_majpp')}</p>

            <div className='my-3 mt-4'>
                <RadioInput id="for_everyday" name="restrictionsupdate" label={t('for_every_day')} value="for_everyday" onChange={() => handlerestrictions("for_everyday")} />
                <RadioInput id="day_week" name="restrictionsupdate" label={t('ho_act_tarif_rest_choice2')} value="day_week" onChange={() => handlerestrictions("day_week")} />

                {restrictionsupdates.restrictionsupdate === "for_everyday" && (
                    <Row>
                        <Col md='3' className='d-flex flex-column my-2'>
                            <span className='mb-2 fw-bolder'>{t('rate')}</span>
                            <Input placeholder="EUR" type="number" />
                        </Col>
                        <Col md='3' className='d-flex flex-column my-2'>
                            <span className='mb-2 fw-bolder'>{t('ho_act_tarif_rest_fer')}</span>
                            <Form.Select aria-label="Default select example" className=' p-2 h-100'>
                                <option >Without changes </option>
                                <option value="1">Activated</option>
                                <option value="2">Deactivated</option>
                            </Form.Select>
                        </Col>
                        <Col md='3' className='d-flex flex-column my-2'>
                            <span className='mb-2 fw-bolder'>{t('ho_act_tarif_rest_fer1')}</span>
                            <Form.Select aria-label="Default select example" className=' p-2 h-100'>
                                <option >Without changes </option>
                                <option value="1">Activated</option>
                                <option value="2">Deactivated</option>
                            </Form.Select>
                        </Col>
                        <Col md='3' className='d-flex flex-column my-2'>
                            <span className='mb-2 fw-bolder'>{t('availability')}</span>
                            <Form.Select aria-label="Default select example" className=' p-2 h-100'>
                                <option >Without changes </option>
                                <option value="1">Activated</option>
                                <option value="2">Deactivated</option>
                            </Form.Select>
                        </Col>
                        <Col md='3' className='d-flex flex-column my-2'>
                            <span className='mb-2 fw-bolder'>{t('ho_act_tarif_rest_min')}</span>
                            <Input placeholder="1-50" type="number" />
                        </Col>
                        <Col md='3' className='d-flex flex-column my-2'>
                            <span className='mb-2 fw-bolder'>{t('ho_act_tarif_rest_max')}</span>
                            <Input placeholder="1-50" type="number" />
                        </Col>
                    </Row>
                )}
                {restrictionsupdates.restrictionsupdate === "day_week" && (
                    <p>{renderRestrictions()}</p>
                )}
            </div>
        </div>
    );
};

export default Restrictionupdate;
