import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select'
import { useState } from 'react';
import Input from '../../../components/forms/Input'
import Radio from '../../../components/forms/RadioInput'
import { v4 as uuidv4 } from 'uuid';
import Schedules from '../../../components/Schedules'
import { useTranslation } from "react-i18next";

const DepartFees = ({ text }) => {
    const { t } = useTranslation();
    const [groupId] = useState(uuidv4());

    const [infos, setInfos] = useState({
        expense: "Gratuit",
        extra: "Montant"
    });

    const handleExpense = (value) => {
        setInfos({ ...infos, expense: value });
    };

    const handleExtra = (value) => {
        setInfos({ ...infos, extra: value });
    };

    const extraTypeData = [
        { title: t('amount'), value: "Montant" },
        { title: t('percentage'), value: "Pourcentage" },
        { title: t('variable_fees'), value: "Frais variables" }
    ]

    return (
        <>
            <Row className='fs-6 align-items-center'>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <span className='fw-medium text-dark'>{text}</span>
                </Col>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <div className='mx-3'>
                        <Radio id={`Gratuit_${groupId}`} label={t('free')} name={`reception_${groupId}`} value="Gratuit" onChange={() => handleExpense('Gratuit')} />
                    </div>

                    <div className='mx-3'>
                        <Radio id={`Supplément_${groupId}`} label={t('supplement')} name={`reception_${groupId}`} value="Supplément" onChange={() => handleExpense('Supplément')} />
                    </div>
                </Col>
            </Row>

            {infos.expense === "Supplément" &&

                <>
                    <Row>
                        <Col sm="6" className='my-2'>
                            <Select data={extraTypeData} value={infos.extra} handleChange={(e) => handleExtra(e.target.value)} />
                        </Col>
                        {infos.extra !== "Frais variables" && (
                            <Col sm="6" className='my-2'>
                                <Input type="number" placeholder={`${infos.extra === "Montant" ? "$" : '%'}`} />
                            </Col>
                        )}
                    </Row>

                    <div className='my-3'>
                        <span className='fs-6 fw-medium text-dark'>{t('when_apply_extra_charges')}</span>
                        <Schedules/>
                    </div>


                </>
            }
        </>
    )
}

export default DepartFees