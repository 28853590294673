import React, { useRef } from "react"
import { useState } from "react"
import html2pdf from "html2pdf.js"
import { Row, Col, Button, Table } from "rsuite"
import { LuPrinter } from "react-icons/lu"
import { Link } from 'react-router-dom'
import { FiEye } from "react-icons/fi"
import { LuDownloadCloud } from "react-icons/lu"
import { FaFileAlt } from "react-icons/fa"
import { FaFileCircleCheck } from "react-icons/fa6"
import { RiMoneyDollarCircleLine } from "react-icons/ri"
import { RiMoneyDollarCircleFill } from "react-icons/ri"
import { useTranslation } from "react-i18next";

const { Column, HeaderCell, Cell } = Table;

const Facturedetails = () => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleModifierClick = () => {
    setShowSecondModal(false);
    setShowModal(true);}
    const [showThirdModal, setShowThirdModal] = useState(false);

    const handleOpenThirdModal = () => {
        setShowSecondModal(false); 
        setShowThirdModal(true); 
    };

    const handleOpenDatePicker = () => {
        setShowDatePicker(true);
    };

    const handleCloseDatePicker = () => {
        setShowDatePicker(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        handleCloseDatePicker();
    };

    const [showModal, setShowModal] = useState(false);

    const handleConfirmDeactivate = () => {
        setShowModal(false);
        handleShowSuccessMessage();
    };

    const handleShowSuccessMessage = () => {
        setShowSuccessMessage(true);
    };

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleEditButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [bookings, setBookings] = useState([
        { id: 1, clientName: "DEVRIM GECEGEZER", arrival: "2024-05-20", departure: "2024-05-21", accommodations: "Single Room", reservedOn: "2024-05-15", status: "Séjournée", rate: "DZD 31 200", frate:"DZD 31 200", commission: "18", reservationNumber: "4953455341" },
        { id: 2, clientName: "Sam Escoffier", arrival: "2024-05-21", departure: "2024-05-23", accommodations: "Double Room", reservedOn: "2024-05-16", status: "En attente",  rate: "DZD 17 400", frate:"DZD 17 400",commission: "18", reservationNumber: "8573455341" },
        { id: 3, clientName: "Dalida Arbi", arrival: "2024-05-22", departure: "2024-05-24", accommodations: "Suite", reservedOn: "2024-05-17", status: "Séjournée",  rate: "DZD 31 200", frate:"DZD 31 200", commission: "18", reservationNumber: " 5847455341" },
        { id: 4, clientName: "Kashier Kash", arrival: "2024-05-20", departure: "2024-05-21", accommodations: "Single Room", reservedOn: "2024-05-15", status: "Annulé",  rate: "DZD 31 200", frate:"DZD 0", commission: "0", reservationNumber: "4953455341" },
        { id: 5, clientName: "Eminem Dall", arrival: "2024-05-21", departure: "2024-05-23", accommodations: "Double Room", reservedOn: "2024-05-16", status: "Annulé",  rate: "DZD 8 700", frate:"DZD 8 700", commission: "18", reservationNumber: "8573455341" },
    ]);

    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleSelectAll = () => {
        const newCheckedItems = {};
        bookings.forEach((booking, index) => {
            newCheckedItems[index] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (index) => {
        const newCheckedItems = { ...checkedItems, [index]: !checkedItems[index] };
        setCheckedItems(newCheckedItems);

        const allChecked = Object.values(newCheckedItems).every((value) => value);
        setSelectAll(allChecked);
    };

    const handleSearch = () => {
        const filteredBookings = bookings.filter((booking) => {
            if (!startDate || !endDate) return true;
            const bookingArrivalDate = new Date(booking.arrival);
            const bookingDepartureDate = new Date(booking.departure);
            return bookingArrivalDate >= startDate && bookingDepartureDate <= endDate;
        });
        return filteredBookings;
    };

    const [showSecondModal, setShowSecondModal] = useState(false);
    const handleOpenSecondModal = () => {
        setShowModal(false); 
        setShowSecondModal(true); 
    };
    
    const calculateTotal = (bookings) => {
        
        const total = bookings.reduce((acc, booking) => {
          
            const frateValue = parseFloat(booking.frate.replace("DZD ", "").replace(/\s/g, ""));
            
            return !isNaN(frateValue) ? acc + frateValue : acc;
        }, 0);
        
       
        return total.toFixed(2);
    };

    const generatePDF = () => {
        const options = {
            margin: 0.5,
            filename: 'facture.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf().from(pdfRef.current).set(options).save();
    };

    const pdfRef = useRef();

    const handlePrintButtonClick = () => {
        window.print();
    };

    const { t } = useTranslation();

    return (
        <div className='container-fluid'>
            <div className='px-5 pt-1'>
            <Row className='my-3'>
                <Col className='my-2 p-1' style={{width:'25%'}}>
                    <div className='bg-white border rounded p-2'>
                        <Row className='d-flex flex-row'>
                            <Col className='mt-2 d-flex justify-content-center align-items-center' style={{ height: '3vw', width: '5vw'}}>
                                <span className='d-flex justify-content-center align-items-center rounded-circle' style={{ backgroundColor: '#f3f6f9', width: '100%', height: '100%'}}>
                                    <RiMoneyDollarCircleLine   style={{ fontSize: '1.5vw', color:'#8b74f9' }}/>
                                </span>
                            </Col>
                            <Col className='d-flex flex-column'>
                                <div className='fs-6 text-uppercase' style={{color:'#878a99'}}> {t('bookings')} </div>
                                <div className='text-dark fs-5 fw-bold'>5</div>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-end">
                                <span className='px-1 rounded' style={{backgroundColor:'#29c763' , color:'#fff'}}>{t('payed_invoice')}</span> 
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col className='my-2 p-1' style={{width:'25%'}}>
                    <div className='bg-white border rounded p-2'>
                        <Row className='d-flex flex-row justify-content-between'>
                            <Col className='mt-2 d-flex justify-content-center align-items-center' style={{ height: '3vw', width: '5vw'}}>
                                <span className='d-flex justify-content-center align-items-center rounded-circle' style={{ backgroundColor: '#f3f6f9', width: '100%', height: '100%'}}>
                                    <FaFileAlt style={{ fontSize: '1.4vw', color:'#8b74f9' }}/>
                                </span>
                            </Col>
                            <Col className='d-flex flex-column'>
                                <div className='fs-6 text-uppercase' style={{color:'#878a99'}}> {t('ho_act_facturation_num')} </div>
                                <div className='text-dark fs-5 fw-bold'>4953455341</div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col className='my-2 p-1' style={{width:'25%'}} >
                    <div className='bg-white border rounded p-2'>
                        <Row className='d-flex flex-row justify-content-between'>
                            <Col className='mt-2 d-flex justify-content-center align-items-center' style={{ height: '3vw', width: '5vw'}}>
                                <span className='d-flex justify-content-center align-items-center rounded-circle' style={{ backgroundColor: '#f3f6f9', width: '100%', height: '100%'}}>
                                    <FaFileCircleCheck  style={{ fontSize: '1.5vw', color:'#25a0e2' }}/>
                                </span>
                            </Col>
                            <Col className='d-flex flex-column '>
                                <div className='fs-6 text-uppercase' style={{color:'#878a99'}}> {t('ho_act_paiement_datepayment')} </div>
                                <div className='text-dark fs-5 fw-bold'>2024-05-20</div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col className='my-2 p-1' style={{width:'25%'}}>
                    <div className='bg-white border rounded p-2'>
                        <Row className='d-flex flex-row justify-content-between'>
                            <Col className='mt-2 d-flex justify-content-center align-items-center' style={{ height: '3vw', width: '5vw'}}>
                                <span className='d-flex justify-content-center align-items-center rounded-circle' style={{ backgroundColor: '#f3f6f9', width: '100%', height: '100%'}}>
                                    <RiMoneyDollarCircleFill style={{ fontSize: '1.5vw', color:'#29c763' }} />
                                </span>
                            </Col>
                            <Col className='d-flex flex-column'>
                                <div className='fs-6 text-uppercase' style={{color:'#878a99'}}> {t('amount')}</div>
                                <div className='text-dark fs-5 fw-bold'>DZD 88500.00</div>
                            </Col>
                           
                        </Row>
                    </div>
                </Col>
            </Row>

            </div>
            <div ref={pdfRef}>
                <div  lg="12" xl="8" sm="12" md="12" className="px-5 mb-4 d-flex flex-column">
                    <div className="">
                        <Row className="d-flex flex-row justify-content-between">
                            <Col className="d-flex justify-content-end ms-auto">
                                <Button color="blue" appearance="primary" className="me-2" onClick={generatePDF}>
                                    <LuDownloadCloud  className="me-1" />
                                    {t('ho_act_invoicedetails_btn')}
                                </Button>
                                <Button color="cyan" appearance="primary" onClick={handlePrintButtonClick}>
                                    <LuPrinter className="me-1" />
                                   {t('ho_act_reservation_details_print')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="my-4 p-0" id="prin">
                    <Row className="border rounded p-2">
                        <Col className="justify-content-enter col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <Table className="mx-auto" height={400}  data={bookings} autoHeight>
                                <Column width={40}>
                                    <HeaderCell>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </HeaderCell>
                                    <Cell>
                                        {(rowData, rowIndex) => (
                                            <input
                                                type="checkbox"
                                                checked={checkedItems[rowIndex]}
                                                onChange={() => handleCheckboxChange(rowIndex)}
                                            />
                                        )}
                                    </Cell>
                                </Column>
                                <Column width={120}>
                                    <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_reservation_det_nom_client')}</HeaderCell>
                                    <Cell dataKey="clientName" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">{t('arrival')}</HeaderCell>
                                    <Cell dataKey="arrival" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">{t('departure')}</HeaderCell>
                                    <Cell dataKey="departure" />
                                </Column>
                                <Column >
                                    <HeaderCell className="fs-6 fw-bold text-dark">{t('bedroom')}</HeaderCell>
                                    <Cell dataKey="accommodations" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">{t('booked_the')}</HeaderCell>
                                    <Cell dataKey="reservedOn" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">{t('status')}</HeaderCell>
                                    <Cell dataKey="status">
                                        {(rowData) => {
                                            let backgroundColor = '';
                                            let color = '';

                                            switch (rowData.status) {
                                                case 'Séjournée':
                                                    backgroundColor = '#e8f9ee';
                                                    color = '#29c763';
                                                    break;
                                                case 'Annulé':
                                                    backgroundColor = '#fef0e7';
                                                    color = '#f97316';
                                                    break;
                                                case 'En attente':
                                                    backgroundColor = '#f2f0fe';
                                                    color = '#8b74f9';
                                                    break;
                                                default:
                                                    backgroundColor = '#f3f6f9';
                                                    color = '#000000';
                                            }

                                            return (
                                                <div className="p-1 rounded-4 text-center" style={{ marginTop: '-5px', backgroundColor, color }}>
                                                    {rowData.status}
                                                </div>
                                            );
                                        }}
                                    </Cell>
                                </Column>
                                <Column width={125}>
                                    <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_invoicedetailsMontant')}</HeaderCell>
                                    <Cell dataKey="rate" className="text-center" />
                                </Column>
                                <Column width={50}>
                                    <HeaderCell className="fs-6 fw-bold text-dark text-center">%</HeaderCell>
                                    <Cell dataKey="commission" className="text-center" />
                                </Column>
                                <Column width={125}>
                                    <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_invoicedetailsMontantF')}</HeaderCell>
                                    <Cell dataKey="frate" className="text-center" />
                                </Column>
                                <Column width={140}>
                                    <HeaderCell className="fs-6 fw-bold text-dark ">N° {t('booking')}</HeaderCell>
                                    <Cell dataKey="reservationNumber" />
                                </Column>
                                <Column width={100}>
                                    <HeaderCell className="fs-6 fw-bold text-dark">{t('actions')}</HeaderCell>
                                    <Cell>
                                        {(rowData) => (
                                            <div className="d-flex flex-row  align-items-center " style={{ marginTop: '-5px' }} >

                                                
                                                <Link to="/myhotel/bookingdetails1" className="w-100 align-items-center"  >
                                                    <Button className="border w-100 align-items-center me-1 rounded">
                                                        <FiEye  />
                                                    </Button>
                                                </Link>
                                            </div>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                            <div className="fs-6 fw-bold text-dark text-end mt-3">{t('total')} : DZD {calculateTotal(bookings)} </div>
                        </Col>
                    </Row>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Facturedetails;
