import React, { useState } from "react";
import CheckInput from "../../../components/forms/CheckInput";
import TagInput from "../../../components/forms/TagInput";
import Select from "../../../components/forms/Select";
import Input from "../../../components/forms/Input";

const Target = ({ t }) => {

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const data = [
    { title: t('marketing_1'), value: "MembersOnly" },
    { title: t('marketing_2'), value: "SomeCountries" },
    { title: t('marketing_3'), value: "MinDuration", },
    { title: t('marketing_4'), value: "FirstBooking" },
  ];

  const promo = [
    { title: t('no_included_these_countries'), value: "ExcludeThisCountries" },
    { title: t('these_countries_only'), value: "ThoseCountriesOnly" },
  ]

  const countries = [
    { title: "", value: "" },
  ];

  return (
    <>
      <div className="fs-4 fw-bold">{t('target')}</div>
      <p className="fs-6 fw-light">
        {t('marketing_modal_cible_txt')}
      </p>
      <div className="my-4">
        {data.map((check, index) => (
          <div key={index} className="my-3">
            <div className="fs-6">
              <CheckInput
                id={check.value}
                label={check.title}
                checked={checkedItems[check.value] || false}
                onChange={() => handleCheckboxChange(check.value)}
              />
            </div>

            {checkedItems[check.value] && (
              <>
                {check.value === 'SomeCountries' && (
                  <div className="fs-6">

                    <div className="my-3">
                      <Select data={promo} />
                    </div>

                    <div className="my-3">
                      <TagInput data={countries} list="PromoCountries" />
                    </div>
                  </div>
                )}

                {check.value === 'MinDuration' && (
                  <div className="fs-6 my-3">
                    <Input placeholder={t('minimum_nights_length')} type="number" />
                  </div>
                )}
              </>

            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Target;
