import { Row, Col } from "react-bootstrap";
import Input from "../../../components/forms/Input";
import Select from "../../../components/forms/Select";
import countries from "../../../data/countries.json";
import { useState } from "react";
import Map from "./Map";

const Location = ({ t }) => {
  const [selectedCountry, setSelectedCountry] = useState("fr");

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const selectedCountryData = countries.countries.find((item) => item.value === selectedCountry);

  const [address, setAddress] = useState('');

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="7" xl="6" className="my-3">
            <>
              <>
                <h2 className="fs-3 lh-base fw-medium text-dark">{t('rental_location')}</h2>
                <p className="fs-5 fw-light">{t('location_text')}</p>

                <Row className="fs-6 my-4">
                  <Col md="6" className="my-3">
                    <div className="fw-medium text-dark">{t('country')}</div>
                    <div className="my-1">
                      <Select data={countries.countries} />
                    </div>
                  </Col>
                  <Col md="6" className="my-3">
                    <div className="fw-medium text-dark">{t('city')}</div>
                    <div className="my-1">
                      <Input type="text" maxlength="50" />
                    </div>
                  </Col>
                </Row>

                <div className="my-4 fs-6">
                  <div className="fw-medium text-dark">{t('phone')}</div>
                  <p className="my-1">{t('RestauDesTelP')}</p>
                  <div className="fs-6">
                    <Row className="align-items-center">
                      <Col xl="6" className="my-3">
                        <div className="d-flex input-blue border">
                          <div className="p-4 border-end">
                            <img width={20} height={13} alt={`${selectedCountry}`} src={require(`../../../assets/flags/${selectedCountry}.svg`)} />
                          </div>

                          <select className="p-3 border-0 w-100" onChange={handleCountryChange} value={selectedCountry}>
                            {countries.countries.map((item) => (
                              <option value={item.value} className="text-uppercase">{item.title}</option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col xl="6" className="my-3">
                        <Input type="number" placeholder={selectedCountryData?.dial_code || ''} />
                      </Col>
                    </Row>
                  </div>
                </div>

                <Map t={t} address={address} setAddress={setAddress} />

              </>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Location;
