import { Row, Col } from 'react-bootstrap'
import Input from '../../../components/forms/Input'
import Form from 'react-bootstrap/Form'

const DaysCalendar = ({ t }) => {
    return (
        <div className='bg-white p-3'>
            <Row>
                <Col md='3' className='d-flex flex-column my-2'>
                    <span className='mb-2 fw-bolder'>{t('rate')}</span>
                    <Input placeholder="EUR" type="number" />
                </Col>
                <Col md='3' className='d-flex flex-column my-2'>
                    <span className='mb-2 fw-bolder'>{t('ho_act_tarif_rest_fer')}</span>
                    <Form.Select aria-label="Default select example" className=' p-2 h-100'>
                        <option >Without changes </option>
                        <option value="1">Activated</option>
                        <option value="2">Deactivated</option>
                    </Form.Select>
                </Col>
                <Col md='3' className='d-flex flex-column my-2'>
                    <span className='mb-2 fw-bolder'>{t('ho_act_tarif_rest_fer1')}</span>
                    <Form.Select aria-label="Default select example" className=' p-2 h-100'>
                        <option >Without changes </option>
                        <option value="1">Activated</option>
                        <option value="2">Deactivated</option>
                    </Form.Select>
                </Col>
                <Col md='3' className='d-flex flex-column my-2'>
                    <span className='mb-2 fw-bolder'>{t('availability')}</span>
                    <Form.Select aria-label="Default select example" className=' p-2 h-100'>
                        <option >Without changes </option>
                        <option value="1">Activated</option>
                        <option value="2">Deactivated</option>
                    </Form.Select>
                </Col>
                <Col md='3' className='d-flex flex-column my-2'>
                    <span className='mb-2 fw-bolder'>{t('ho_act_tarif_rest_min')}</span>
                    <Input placeholder="1-50" type="number" />
                </Col>
                <Col md='3' className='d-flex flex-column my-2'>
                    <span className='mb-2 fw-bolder'>{t('ho_act_tarif_rest_max')}</span>
                    <Input placeholder="1-50" type="number" />
                </Col>
            </Row>

        </div>
    );
};

export default DaysCalendar;
