import React from "react";
import Select from "../../../components/forms/Select";
import { Col, Row } from "react-bootstrap";
import RoomOption from "./RoomOption";
import ExtraCharge from "../../../components/ExtraCharge";
import { useState } from "react";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const KitchenFeatures = () => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState({
    fridgeExtra: false,
    microExtra: false,
  });

  const handleFridge = () => {
    setInfos({ ...infos, fridgeExtra: !infos.fridgeExtra });
  };

  const handleMicro = () => {
    setInfos({ ...infos, microExtra: !infos.microExtra });
  };

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const data = [
    { title: t("cookware_dishes_utensils"), value: "BatterieCuisine" },
    { title: t("dishwasher"), value: "LaveVaisselle" },
    { title: t("fridge"), value: "Réfrigérateur" },
    { title: t("kitchen_island"), value: "IlotCuisine" },
    { title: t("oven"), value: "Four" },
    { title: t("microwave"), value: "MicroOndes" },
    { title: t("rice_cooker"), value: "CuiseurRiz" },
    { title: t("hob"), value: "PlaqueCuisson" },
    { title: t("freezer"), value: "Congélateur" },
    { title: t("ice_disturber"), value: "DistributeurGlaçons" },
    { title: t("blender"), value: "Blender" },
    { title: t("coffee_grinder"), value: "MoulinCafé" },
    { title: t("mixer"), value: "Mixeur" },
    { title: t("spicies"), value: "Epices" },
    { title: t("toaster"), value: "GrillePain" },
    { title: t("wiffle_iron"), value: "Gauffrier" },
    { title: t("cleaning_kit"), value: "NécessaireNettoyage" },
    { title: t("paper_napkins"), value: "ServiettesPapier" },
  ];

  const fridge = [
    { title: t("fridge_freezer"), value: "RéfrigérateurSpacieux" },
    { title: t("mini_fridge"), value: "MiniRéfrigérateur" },
    { title: t("fridge"), value: "Réfrigérateur" },
    { title: t("fridge_onrequest"), value: "RéfrigérateurSurDemande" },
  ];

  return (
    <div className="fs-6">
      {data.map((check, index) => (
        <div key={index} className="my-3">
          <>
            <CheckInput
              id={check.value}
              label={check.title}
              value={check.value}
              checked={checkedItems[check.value] || false}
              onChange={() => handleCheckboxChange(check.value)}
            />

            {checkedItems[check.value] && <RoomOption />}
          </>

          {checkedItems[check.value] && (
            <>
              {check.value === "Réfrigérateur" && (
                <>
                  <Row className="align-items-center">
                    <Col md="6" className="my-3">
                      <span className="fs-6 fw-medium text-dark">
                        {t('fridge_type')}
                      </span>
                    </Col>
                    <Col md="6" className="my-3">
                      <Select data={fridge} />
                    </Col>

                    <Col md="12" className="my-3">
                      <div className="px-3">
                        <CheckInput
                          id={t('fridge_extra')}
                          label={t('fridge_extra')}
                          value={infos.fridgeExtra}
                          onChange={handleFridge}
                        />

                        {infos.fridgeExtra && <ExtraCharge />}
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              {check.value === "MicroOndes" && (
                <>
                  <Row className="align-items-center">
                    <Col md="12" className="my-3 px-3">
                      <CheckInput
                        id={t("extra_for_microwaves")}
                        label={t("extra_for_microwaves")}
                        value={infos.microExtra}
                        onChange={handleMicro}
                      />

                      {infos.microExtra && <ExtraCharge />}
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default KitchenFeatures;
