import { Coins, Eye, Receipt, ShieldAlert, ShoppingBagIcon, TrendingDown } from 'lucide-react'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const Stats = ({ t }) => {
    return (
        <div className='d-flex flex-column justify-content-between h-100'>
            <Row>
                <Col sm="6">
                    <div className='bg-blue p-4 text-white rounded'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <span>
                                <div className='fs-6'>{t('sales')}</div>
                                <div className='fs-3 fw-medium'>81,020€</div>
                            </span>
                            <span className='bg-secondary-blue text-blue p-2'><ShoppingBagIcon strokeWidth={1.5} /></span>

                        </div>
                        <p className='mt-3'>{t('yearly_performances')}</p>
                    </div>
                </Col>
                <Col sm="6">
                    <div className='bg-white p-4 text-dark rounded'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <span>
                                <div className='fs-6'>{t('earnings')}</div>
                                <div className='fs-3 fw-medium'>1380.99€</div>
                            </span>
                            <span className='text-green p-2'><Coins strokeWidth={1.5} /></span>

                        </div>
                        <p className='mt-3'><span className='text-green'>+8.26%</span> {t('than_usual')} <br /> {t('this_month')}.</p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col sm="6">
                    <div className='bg-white p-4 text-dark rounded'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <span>
                                <div className='fs-6'>{t('bookings')}</div>
                                <div className='fs-3 fw-medium'>102</div>
                            </span>
                            <span className='text-dark p-2'><Receipt strokeWidth={1.5} /></span>

                        </div>
                        <p className='mt-3'><span className='text-red'>-4%</span> {t('than_usual')} <br /> {t('this_month')}.</p>
                    </div>
                </Col>
                <Col sm="6">
                    <div className='bg-white p-4 text-dark rounded'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <span>
                                <div className='fs-6'>{t('seen')}</div>
                                <div className='fs-3 fw-medium'>26</div>
                            </span>
                            <span className='text-blue p-2'><Eye strokeWidth={1.5} /></span>

                        </div>
                        <p className='mt-3'><span className='text-green'>+3.06%</span> {t('than_usual')} <br /> {t('this_month')}.</p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col sm="6">
                    <div className='bg-white p-4 text-dark rounded'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <span>
                                <div className='fs-6'>{t('cancellations')}</div>
                                <div className='fs-3 fw-medium'>4</div>
                            </span>
                            <span className='text-orange p-2'><ShieldAlert strokeWidth={1.5} /></span>

                        </div>
                        <p className='mt-3'><span className='text-green'>-1%</span> {t('than_usual')} <br /> {t('this_month')}.</p>
                    </div>
                </Col>
                <Col sm="6">
                    <div className='bg-white p-4 text-dark rounded'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <span>
                                <div className='fs-6'>{t('losses')}</div>
                                <div className='fs-3 fw-medium'>112€</div>
                            </span>
                            <span className='text-red p-2'><TrendingDown strokeWidth={1.5} /></span>

                        </div>
                        <p className='mt-3'><span className='text-green'>-2.18%</span> {t('than_usual')} <br /> {t('this_month')}.</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Stats
