import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Input from '../../../components/forms/Input';
import Textarea from '../../../components/forms/Textarea';
import { Plus, Trash2 } from 'lucide-react';

const Destinations = ({ t }) => {
  // État initial avec un seul formulaire
  const [destinations, setDestinations] = useState([{ day: 1, destination: "", description: "" }]);

  // Ajouter un formulaire
  const addDestination = () => {
    setDestinations(prevDestinations => {
      const newDay = prevDestinations.length + 1;
      return [...prevDestinations, { day: newDay, destination: "", description: "" }];
    });
  };

  // Supprimer un formulaire
  const removeDestination = (day) => {
    setDestinations(prevDestinations => {
      const updatedDestinations = prevDestinations.filter(dest => dest.day !== day);
      // Réorganiser les jours pour qu'ils soient consécutifs
      return updatedDestinations.map((dest, index) => ({ ...dest, day: index + 1 }));
    });
  };

  // Mettre à jour la valeur du champ
  const handleChange = (day, field, value) => {
    setDestinations(prevDestinations =>
      prevDestinations.map(dest =>
        dest.day === day ? { ...dest, [field]: value } : dest
      )
    );
  };

  return (
    <section className="bg-white form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="7" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('where_will_customers_go')}</h2>
              <p className="fs-5 fw-light">{t('where_will_customers_go_caption')}</p>
              <div className="mt-5 fs-6 text-dark">
                {destinations.map((item) => (
                  <Row key={item.day} className="mb-4">
                    <Col xs="2" md="1" className="d-flex flex-column">
                      <div className="fw-medium text-center">{t('day')}</div>
                      <div className="my-3 text-center fs-4 fw-bold">{item.day}</div>
                    </Col>

                    <Col md="10">
                      <div className='d-flex flex-column'>
                        <div className="fw-medium text-dark">{t('destination')}</div>
                        <div className="my-3">
                          <Input
                            placeholder="e.x. Paris, France"
                            value={item.destination}
                            onChange={(e) => handleChange(item.day, 'destination', e.target.value)}
                          />
                        </div>
                        <div className="my-3">
                          <Textarea
                            placeholder={t('destination_description')}
                            rows={5}
                            value={item.description}
                            onChange={(e) => handleChange(item.day, 'description', e.target.value)}
                          />
                        </div>
                        <div
                          role="button"
                          className={`align-self-end mt-2 border text-danger p-2 rounded-5 ${item.day === 1 ? "d-none" : "d-block"}`}
                          onClick={() => removeDestination(item.day)}
                        >
                          <Trash2 size={20} /> <span className="text-dark">{t('delete')}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ))}

                {destinations.length <= 9 && (
                  <span role="button" className="border rounded-5 text-dark text-center p-2 px-3 my-3" onClick={addDestination}>
                    <Plus size={20} /> {t('add')}
                  </span>
                )}
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Destinations;
