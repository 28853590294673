import React from 'react'
import { Badge } from 'rsuite'

const TopBanner = ({ data, dynamicData, dynamicData1, dd, dd1 }) => {
    return (
        <div className="topbanner px-4 pt-5 pb-4 rounded">
            <div className='mt-3'><span className='text-dark fs-5 fw-medium'>{data}</span></div>
            <div className="mb-3 mt-1">
                <span className='fs-7' style={{}}>
                    <b className='text-dark fw-medium me-1'>{dynamicData1} </b> <Badge color="cyan" className='p-1' content={dd1} />
                </span>
                <span className='fs-7 p-2 rounded-4 ' style={{}}>
                    <b className='text-dark fw-medium me-1'>{dynamicData} </b> <Badge className='p-1' color="blue" content={dd} />
                </span>
            </div>
        </div>
    )
}

export default TopBanner;
