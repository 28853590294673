import { Button, DatePicker } from "rsuite"
import { Modal, Form } from "react-bootstrap"
import { FaHotel } from "react-icons/fa6"
import { FaRegCalendarCheck } from "react-icons/fa6"

const UpdateDateRates = (props) => {
    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal} size="lg" className='rounded-2 p-2'>
            <Modal.Header className='bg-white' closeButton>
                <Modal.Title className="text-modal fw-bold fs-5 ms-2"> {props.t('ho_act_reservation_maj')} </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-white text-modal d-flex flex-column'>
                <div className='p-2'>
                    <FaHotel className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">{props.t('accommodation')}</span>
                    <div className='d-flex flex-column ms-4'>
                        <div className='d-flex align-items-center mt-3'>
                            <Form.Check className="me-2 ms-3" /> <span>Chambre Lits Jumeaux</span>
                        </div>
                        <div className='d-flex align-items-center mt-2'>
                            <Form.Check className="me-2 ms-3" /><span>Chambre Triple</span>
                        </div>
                    </div>
                </div>
                <div className='p-2 my-3'>
                    <FaRegCalendarCheck className="fs-4 me-3 mb-1" /><span className="fw-bold fs-6">{props.t('select_other_dates')}</span>
                    <div className='d-flex flex-row ms-4'>
                        <div className='d-flex flex-column mt-3 me-4 ms-3'>
                            <span className="mb-2">{props.t('arrival_date')}</span>
                            <DatePicker placeholder={props.t('select_date')} format='DD/MM/YYYY' />
                        </div>
                        <div className='d-flex flex-column mt-3 ms-3'>
                            <span className="mb-2">{props.t('departure_date')}</span>
                            <DatePicker placeholder={props.t('select_date')} format='DD/MM/YYYY' />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-white'>
                <Button appearance="primary" color="blue" onClick={props.handleOpenSecondModal}>
                   {props.t('see_availability_and_edit_rates')}
                </Button>
                <Button appearance="primary" color="cyan" onClick={props.handleConfirmDeactivate}>
                    {props.t('cancel')}
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default UpdateDateRates
