import { Camera } from "lucide-react";
import ManageHeader from "../../components/ManageHeader";

const Photos = () => {
    
  return (
    <section>
        <ManageHeader
                title="Photos"
                content="Aidez les voyageurs à imaginer leur séjour dans votre hébergement."
                icon={<Camera size={25} strokeWidth={1.5} />}
            />
        <div className="container my-4">
          Photos
        </div>
    </section>
  )
}

export default Photos
