import { Row, Col } from 'react-bootstrap'
import { Badge } from 'rsuite'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Top = ({ t }) => {
    return (
        <section className='mb-2' style={{ backgroundColor: '#f9f9f9' }}>
            <div className='container-fluid'>
                <Row className='d-flex flex-row'>
                    <Col className='my-2'>
                        <div className='d-flex flex-row p-2 mx-5' style={{ marginTop: '3vw' }}>

                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-top">{t('last2_days_bookings')}</Tooltip>}
                            >
                                <Link to="/myhotel/bookinghotels" style={{ textDecoration: 'none', color: 'black' }}>
                                    <span className='fs-6 p-2 rounded-4 me-5' style={{ backgroundColor: '#119e911f' }}>
                                        {t('my_bookings')}
                                        <Badge color='blue' className='ms-2 px-2 py-1' content='15' />
                                    </span>
                                </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="tooltip-bottom">{t('amount_to_idh')}</Tooltip>}
                            >
                                <Link to="/myhotel/paymentglobal" style={{ textDecoration: 'none', color: 'black' }}>
                                    <span className='fs-6 p-2 rounded-4 me-5' style={{ backgroundColor: '#119e911f' }}>
                                        {t('to_pay')}
                                        <Badge color='cyan' className='ms-2 px-2 py-1' content='€ 1806.94' />
                                    </span>
                                </Link>
                            </OverlayTrigger>
                        </div>
                        <div className='mx-5 mt-2 mb-4'>
                            <span className='fw-bold text-black' style={{ fontSize: '500%' }} dangerouslySetInnerHTML={{ __html: t('optimize_your_hotels') }}>

                            </span>
                        </div>
                        <div className='mx-5'>
                            <span className='fs-6 fw-semibold'>{t('optimize_your_hotels_caption')}</span>
                        </div>
                    </Col>
                    <Col xl='5' className=' d-flex justify-content-end align-items-center'>
                        <img className='' alt='myhotel' src="https://afriqpnb.com/static/media/large_img.a8fa9ca8.png" width="100%" style={{ borderTopLeftRadius: '150px', height: '400px' }} />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Top;
