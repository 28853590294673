import React, { useState } from "react";
import CheckInput from "../../../components/forms/CheckInput";
import { Col, Row } from "react-bootstrap";
import Checkbox from "../../../components/forms/Checkbox";

const Seasons = ({ t }) => {
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, title: t('spring'), isChecked: false, value: "Printemps" },
    { id: 2, title: t('summer'), isChecked: false, value: "été " },
    { id: 3, title: t('autumn'), isChecked: false, value: "Automne" },
    { id: 4, title: t('winter'), isChecked: false, value: "Hiver" },
  ]);

  const checkAllChecked = () => {
    return checkboxes.every((checkbox) => checkbox.isChecked);
  };

  const handleCheckAll = () => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      isChecked: !checkAllChecked(),
    }));
    setCheckboxes(updatedCheckboxes);
  };

  const toggleCheckbox = (id) => {
    setCheckboxes((prevState) => prevState.map((checkbox) => (checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox)));
  };

  return (
    <div className="fs-6 my-4 text-dark">
      <div className="fs-5 fw-medium">{t('PackagesDesSaisons')}</div>
      <div className="my-3">
        <CheckInput id="ToutesLesSaisons" label={t('all_seasons')} checked={checkAllChecked()} onChange={handleCheckAll} />
      </div>
      <Row>
        {checkboxes.map((checkbox, index) => (
          <Col key={index} xs="6" sm="4" md="4" xl="3" className="my-2">
            <Checkbox key={checkbox.id} id={checkbox.id} isChecked={checkbox.isChecked} value={checkbox.value} onChange={toggleCheckbox} title={checkbox.title} icon={checkbox.icon} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Seasons;
