import React, { useState } from "react"
import { Row, Col, Button, Table } from "rsuite"
import { BsBuildingFillCheck } from "react-icons/bs"
import { LuPrinter } from "react-icons/lu"
import { MdEdit } from "react-icons/md"
import { BsBuildingCheck } from "react-icons/bs"
import { MdOutlineFreeBreakfast } from "react-icons/md"
import Bookingdetailsroom from "./components/Bookingdetailsroom"
import { FiAlertOctagon } from "react-icons/fi"
import { useTranslation } from "react-i18next";
import UpdateDateRates from "./components/UpdateDateRates"
import BeforeSaveRates from "./components/BeforeSaveRates"
import SaveDateRates from "./components/SaveDateRates"

const { Column, HeaderCell, Cell } = Table;

const Bookingdetails = () => {

    const { t } = useTranslation();

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleModifierClick = () => {
        setShowSecondModal(false);
        setShowModal(true);
    }
    const [showThirdModal, setShowThirdModal] = useState(false);

    const handleOpenThirdModal = () => {
        setShowSecondModal(false);
        setShowThirdModal(true);
    };

    const handleOpenDatePicker = () => {
        setShowDatePicker(true);
    };

    const handleCloseDatePicker = () => {
        setShowDatePicker(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        handleCloseDatePicker();
    };

    const [showModal, setShowModal] = useState(false);

    const handleConfirmDeactivate = () => {
        setShowModal(false);
        handleShowSuccessMessage();
    };

    const handleShowSuccessMessage = () => {
        setShowSuccessMessage(true);
    };

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleEditButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [showSecondModal, setShowSecondModal] = useState(false);
    const handleOpenSecondModal = () => {
        setShowModal(false);
        setShowSecondModal(true);
    };

    const handleCloseSecondModal = () => {
        setShowSecondModal(false);
    };

    const handlePrintButtonClick = () => {
        window.print();
    };

    return (
        <section>
            <div lg="5" xl="5" sm="12" className="container mb-2 py-4 d-flex flex-column">
                <Row className="d-flex ">
                    <Col xl="auto" className="text-dark fs-4 pt-2 fw-bold">
                        {t('ho_act_reservation_details')}
                    </Col>
                </Row>
            </div>
            <div className="container">
                <Row className="d-flex flex-row justify-content-between">
                    <Col>
                        <Row className="d-flex flex-column">
                            <Col className="d-flex flex-row">
                                <span className="text-dark fs-6 py-1 fw-bold me-3">{t('ho_act_reservation_num')} : 4829709529</span>
                                <span className="py-1 px-4 rounded-4 fs-6" style={{ backgroundColor: '#f2f0fe', color: '#8b74f9' }}>{t('waiting')}</span>
                            </Col>
                            <Col><span>{t('ho_act_reservation_recu')} : vendredi. 19 avr. 2024. 09:42pm</span></Col>
                        </Row>
                    </Col>
                    <Col className="d-flex justify-content-end px-2 py-3 ms-auto">
                        <Button color="blue" appearance="primary" className="me-2" onClick={handleEditButtonClick}>
                            <MdEdit className="me-1" />
                            {t('ho_act_reservation_maj')}
                        </Button>
                        <Button color="cyan" appearance="primary" onClick={handlePrintButtonClick}>
                            <LuPrinter className="me-2" />
                            {t('ho_act_reservation_details_print')}
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="container bg-white text-start p-4 my-2 border rounded-3">
                <Row className="">
                    <Col className="d-flex flex-column text-start col-md-4 col-12">
                        <span className="fs-5 fw-bold mb-2 border-bottom pb-3 text-dark" style={{ float: 'left' }}>{t('the_dates')}</span>
                        <span className="mt-2">{t('ho_act_reservation_det_date_arrive')} :</span>
                        <span className="fs-5 fw-bold mt-1 text-dark">sam. 4 mai 2024</span>
                        <span className="mt-3">{t('ho_act_reservation_det_date_dep')} :</span>
                        <span className="fs-5 fw-bold mt-1 text-dark">lun. 6 mai 2024</span>
                        <span className="mt-3">{t('ho_act_reservation_det_sejour')} :</span>
                        <span className="mt-1 text-dark">2 {t('nights')}</span>
                        <span className="mt-3">{t('ho_act_reservation_det_nbr_per')} :</span>
                        <span className="mt-1 text-dark">2</span>
                        <span className="mt-3">{t('number_of_housings')} :</span>
                        <span className="mt-1 text-dark">2 </span>
                    </Col>
                    <Col className="d-flex flex-column text-start col-md-4 col-12">
                        <span className="fs-5 fw-bold mb-2 border-bottom pb-3 text-dark">{t('ho_act_reservation_details_client')}</span>
                        <span className="mt-2">{t('ho_act_reservation_det_nom_client')} :</span>
                        <span className="fs-5 fw-bold mt-1 text-dark">REMY STEPHANE KUBLER</span>
                        <span className="mt-3">{t('email')} :</span>
                        <span className="mt-1 text-dark">rkuble.262061@guest.booking.com</span>
                        <span className="mt-3">{t('phone')} :</span>
                        <span className="mt-1 text-dark">+33 6 95 69 02 25</span>
                        <span className="mt-3">{t('nationality')} :</span>
                        <span className="mt-1 text-dark">française</span>
                        <span className="mt-3">{t('ho_act_reservation_det_langue')} :</span>
                        <span className="mt-1 text-dark">français</span>
                    </Col>
                    <Col className="d-flex flex-column text-start col-md-4 col-12">
                        <span className="fs-5 fw-bold mb-2 border-bottom pb-3 text-dark">{t('the_payment')}</span>
                        <span className="mt-2">{t('ho_act_reservation_det_mont')} :</span>
                        <span className="fs-5 fw-bold mt-1 text-dark">DZD 50 000</span>
                        <span className="mt-3">{t('ho_act_reservation_det_soumis')} :</span>
                        <span className="mt-1 text-dark">DZD 46 000</span>
                        <span className="mt-3">{t('comission')} :</span>
                        <span className="mt-1 text-dark">DZD 7 820</span>
                        <span className="mt-3">{t('ho_act_reservation_det_iata')} :</span>
                        <span className="mt-1 text-dark">PC029090</span>
                    </Col>
                </Row>
            </div>
            <div className="container bg-white text-start p-4 my-4 border rounded-3">
                <Row className=" d-flex flex-column">
                    <Col className="d-flex flex-row justify-content-between border-bottom ">
                        <span className="fs-5 fw-bold mb-2 pb-1 text-dark">1. Chambre Lits Jumeaux</span>
                        <span className="fs-5 fw-bold mb-2 text-dark">DZD 22 000</span>
                    </Col>
                    <Col className="mt-3 fw-bold">
                        <BsBuildingCheck /><span className="me-5">  sam. 4 mai 2024 </span>
                        <BsBuildingFillCheck /><span className="me-5">  lun. 6 mai 2024 </span>
                        <MdOutlineFreeBreakfast /><span className="me-5">  Petit-déjeuner </span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-3 col-12 d-flex flex-column mt-3">
                        <span className="mt-3">{t('ho_act_reservation_det_nom_client')}</span>
                        <span className="mt-1 text-dark fw-medium">REMY STEPHANE KUBLER</span>
                    </Col>
                    <Col className="col-md-3 col-12 d-flex flex-column mt-3">
                        <span className="mt-3">{t('ho_act_reservation_Repas')}</span>
                        <span className="mt-1 text-dark fw-medium">Petit-déjeuner ({t('included_in_price')})</span>
                    </Col>
                    <Col className="col-md-3 col-12 d-flex flex-column mt-3">
                        <span className="mt-3">{t('ho_act_reservation_occupation')}</span>
                        <span className="mt-1 text-dark fw-medium">2 {t('adults')}</span>
                    </Col>
                    <Col className="col-md-3 col-12 d-flex flex-column mt-3">
                        <span className="mt-3">{t('ho_act_reservation_occupationmax')}</span>
                        <span className="mt-1 text-dark fw-medium">2 {t('adults')}, 1 {t('childs')} (2 personnes max.)</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-10 col-10 mt-3">
                        <Table striped hover className="100  p-2"
                            height={350}
                            data={[
                                {
                                    date: '04 - 05 mai',
                                    tarif: 'Standard Rate',
                                    tarifParNuit: 'DZD 10 200'
                                },
                                {
                                    date: '05 - 06 mai',
                                    tarif: 'Standard Rate',
                                    tarifParNuit: 'DZD 10 200'
                                },
                                {
                                    date: 'Sous-total',
                                    tarif: '',
                                    tarifParNuit: 'DZD 20 400'
                                },
                                {
                                    date: 'Taxe de séjour',
                                    tarif: 'DZD 400.00 par personne et par nuit',
                                    tarifParNuit: 'DZD 1 600'
                                },
                                {
                                    date: 'Tarif total de l\'hébergement',
                                    tarif: '',
                                    tarifParNuit: 'DZD 22 000'
                                },
                                {
                                    date: 'Le tarif comprend',
                                    tarif: '',
                                    tarifParNuit: '17 % de TVA'
                                }
                            ]}
                            rowClassName="table-row"
                        >

                            <Column flexGrow={1}>
                                <HeaderCell className="fw-bold fs-6 text-dark">{t('date')}</HeaderCell>
                                <Cell dataKey="date" />
                            </Column>

                            <Column flexGrow={1}>
                                <HeaderCell className="fw-bold fs-6 text-dark">{t('rate')}</HeaderCell>
                                <Cell dataKey="tarif" />
                            </Column>

                            <Column flexGrow={1}>
                                <HeaderCell className="fw-bold fs-6 text-dark">{t('ho_act_reservation_tarif_nuit')}</HeaderCell>
                                <Cell dataKey="tarifParNuit" />
                            </Column>
                        </Table>
                    </Col>
                </Row>

            </div>
            <div className="container bg-white text-start p-4 my-4 border rounded-3">
                <Row className=" d-flex flex-column">
                    <Col className="d-flex flex-row justify-content-between border-bottom ">
                        <span className="fs-5 fw-bold mb-2 pb-1 text-dark">2. Chambre Triple</span>
                        <span className="fs-5 fw-bold mb-2 text-dark">DZD 28 000</span>
                    </Col>
                    <Col className="mt-3 fw-bold">
                        <BsBuildingCheck /><span className="me-5">  sam. 4 mai 2024 </span>
                        <BsBuildingFillCheck /><span className="me-5">  lun. 6 mai 2024 </span>
                        <MdOutlineFreeBreakfast /><span className="me-5">  Petit-déjeuner </span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-3 col-12 d-flex flex-column mt-3">
                        <span className="mt-3">{t('ho_act_reservation_det_nom_client')}</span>
                        <span className="mt-1 text-dark fw-medium">Francesco Or</span>
                    </Col>
                    <Col className="col-md-3 col-12 d-flex flex-column mt-3">
                        <span className="mt-3">Repas</span>
                        <span className="mt-1 text-dark fw-medium">Petit-déjeuner ({t('included_in_price')})</span>
                    </Col>

                    <Col className="col-md-3 col-12 d-flex flex-column mt-3">
                        <span className="mt-3">{t('ho_act_reservation_occupation')}</span>
                        <span className="mt-1 text-dark fw-medium">3 {t('adults')}</span>
                    </Col>
                    <Col className="col-md-3 col-12 d-flex flex-column mt-3">
                        <span className="mt-3">{t('ho_act_reservation_occupationmax')}</span>
                        <span className="mt-1 text-dark fw-medium">3{t('adults')}, 2 {t('childs')} (3 personnes max.)</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-10 mt-3">
                        <Table hover className="px-1  py-2"
                            height={350}
                            data={[
                                {
                                    date: '04 - 05 mai',
                                    tarif: 'Standard Rate',
                                    tarifParNuit: 'DZD 12 800'
                                },
                                {
                                    date: '05 - 06 mai',
                                    tarif: 'Standard Rate',
                                    tarifParNuit: 'DZD 12 800'
                                },
                                {
                                    date: 'Sous-total',
                                    tarif: '',
                                    tarifParNuit: 'DZD 25 600'
                                },
                                {
                                    date: 'Taxe de séjour',
                                    tarif: 'DZD 400.00 par personne et par nuit',
                                    tarifParNuit: 'DZD 2 400'
                                },
                                {
                                    date: 'Tarif total de l\'hébergement',
                                    tarif: '',
                                    tarifParNuit: 'DZD 28 000'
                                },
                                {
                                    date: 'Le tarif comprend',
                                    tarif: '',
                                    tarifParNuit: '17 % de TVA'
                                }
                            ]}
                            rowClassName="table-row"
                        >

                            <Column flexGrow={1}>
                                <HeaderCell className="fw-bold fs-6 text-dark">{t('date')}</HeaderCell>
                                <Cell dataKey="date" />
                            </Column>

                            <Column flexGrow={1}>
                                <HeaderCell className="fw-bold fs-6 text-dark">{t('rate')}</HeaderCell>
                                <Cell dataKey="tarif" />
                            </Column>

                            <Column flexGrow={1}>
                                <HeaderCell className="fw-bold fs-6 text-dark">{t('ho_act_reservation_tarif_nuit')}</HeaderCell>
                                <Cell dataKey="tarifParNuit" />
                            </Column>
                        </Table>
                    </Col>
                </Row>

            </div>
            <div className="container bg-white text-start p-4 my-4 border rounded-3">
                <Row className=" d-flex flex-column">
                    <Col className="d-flex flex-row justify-content-between border-bottom ">
                        <span className="fs-5 fw-bold mb-2 pb-1 text-dark">Chambre Lits Jumeaux</span>
                        <span className="fs-5 fw-bold mb-2 text-dark"><FiAlertOctagon /></span>
                    </Col>
                    <Col className="mb-3 mt-4 fw-bold d-flex flex-column">
                        <Col className="d-flex flex-row">
                            <span className="text-dark fs-7 py-1 fw-bold me-3">{t('ho_act_reservation_conditions')} :</span>
                            <span className="py-1 px-4 rounded-4 fs-7" style={{ backgroundColor: '#fef0e7', color: '#f97316' }}>{t('non_refundable_booking')}</span>
                        </Col>
                        <Col>
                            <span className="fs-8 fw-medium">{t('non_refundable_booking_txt')}</span>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-12 col-12 mt-3">
                        <Bookingdetailsroom t={t} />
                    </Col>
                </Row>
            </div>
            <div className="container bg-white text-start p-4 my-4 border rounded-3">
                <Row className=" d-flex flex-column">
                    <Col className="d-flex flex-row justify-content-between border-bottom ">
                        <span className="fs-5 fw-bold mb-2 pb-1 text-dark">Chambre Triple</span>
                        <span className="fs-5 fw-bold mb-2 text-dark"><FiAlertOctagon /></span>
                    </Col>
                    <Col className="mb-3 mt-4 fw-bold d-flex flex-column">
                        <Col className="d-flex flex-row">
                            <span className="text-dark fs-7 py-1 fw-bold me-3">{t('ho_act_reservation_conditions')} :</span>
                            <span className="py-1 px-4 rounded-4 fs-7" style={{ backgroundColor: '#e8f9ee', color: '#29c763' }}>{t('refundable_booking')}</span>
                        </Col>
                        <Col>
                            <span className="fs-8 fw-medium">{t('refundable_booking_txt')}</span>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-12 col-12 mt-3">
                        <Bookingdetailsroom />
                    </Col>
                </Row>
            </div>

            <UpdateDateRates t={t} showModal={showModal} handleCloseModal={handleCloseModal} handleOpenSecondModal={handleOpenSecondModal} handleConfirmDeactivate={handleConfirmDeactivate} />

            <BeforeSaveRates t={t} showSecondModal={showSecondModal} handleCloseSecondModal={handleCloseSecondModal} handleModifierClick={handleModifierClick} handleOpenThirdModal={handleOpenThirdModal} />

            <SaveDateRates t={t} showThirdModal={showThirdModal} setShowThirdModal={setShowThirdModal} handleModifierClick={handleModifierClick} setShowSecondModal={setShowSecondModal} />
        </section>
    );
};

export default Bookingdetails;
