import { Menu, Trash2 } from "lucide-react";
import React from "react";
import { Col, Row } from "react-bootstrap";

const Messages = ({ data, t }) => {
  return (
    <div className="border border-start-0">
      <div className="d-flex justify-content-between align-items-center p-3">
        <div
          className={`bg-dark fs-5 fw-medium rounded-circle d-flex justify-content-center align-items-center text-white`}
          style={{ width: "40px", height: "40px" }}
        >
          J
        </div>
        <span className="d-flex text-dark">
          <span
            role="button"
            title={t('delete')}
            className="fs-5 fw-medium border rounded-circle d-flex justify-content-center align-items-center mx-1"
            style={{ width: "40px", height: "40px" }}
          >
            <Trash2 size={15} />
          </span>

          <span
            role="button"
            title={t('more')}
            className="fs-5 fw-medium border rounded-circle d-flex justify-content-center align-items-center mx-1"
            style={{ width: "40px", height: "40px" }}
          >
            <Menu size={15} />
          </span>
        </span>
      </div>
      <hr className="border-secondary m-0" />
      <div className="p-3">
        <div className="text-center fs-6">Aujourd'hui, jan 30</div>
        <Row className="flex-column">
          <Col xl="7">
            <div className="my-4 d-flex">
              <span
                className={`bg-dark text-white fs-5 fw-medium rounded-circle d-flex justify-content-center align-items-center`}
                style={{ width: "40px", height: "40px", minHeight: "40px", minWidth: "40px" }}
              >
                J
              </span>

              <span className="mx-3">
                <div>
                  <span className="fs-5 fw-medium text-dark">John doe</span>
                  <span className="mx-3 fw-light">16h34</span>
                </div>
                <div
                  className="my-3 bg-blue text-white p-3"
                  style={{ borderRadius: "0 20px 20px 20px" }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing elit
                </div>

              </span>
            </div>
          </Col>

          <Col xl="7" className="align-self-end">
            <div className="my-3 d-flex">


              <span className="mx-3 d-flex flex-column align-items-end">
                <div>
                  <span className="fs-5 fw-medium text-dark">Moi</span>
                  <span className="mx-3 fw-light">16h34</span>
                </div>
                <div
                  className="my-3 bg-secondary-blue text-dark p-3"
                  style={{ borderRadius: "20px 0 20px 20px" }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing elit
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing elit
                </div>

              </span>
              <span
                className={`bg-dark fs-5 fw-medium rounded-circle d-flex justify-content-center align-items-center`}
                style={{ width: "40px", height: "40px", minHeight: "40px", minWidth: "40px" }}
              >
                J
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Messages;
