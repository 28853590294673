import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import RoomOption from './RoomOption'
import CheckInput from '../../../components/forms/CheckInput';
import { useTranslation } from "react-i18next";

const DetailsDrinks = () => {

    const { t } = useTranslation();

    const [checkedItems, setCheckedItems] = useState({});

    const [infos, setInfos] = useState({
        tea: false,
        machine: false,
        bags: false
    });

    const handleTea = () => {
        setInfos({ ...infos, tea: !infos.tea });
    };

    const handleMachine = () => {
        setInfos({ ...infos, machine: !infos.machine });
    };

    const handleBags = () => {
        setInfos({ ...infos, bags: !infos.bags });
    };

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const drinks = [
        { title: t('drinks_title1'), value: "BouteilleGratuite" },
        { title: t('drinks_title2'), value: "Cafetière" },
        { title: t('drinks_title3'), value: "Minibar" },
        { title: t('drinks_title4'), value: "BarAvecRobinet" }
    ]

    const minibar = [
        { title: t('minibar_title1'), value: "MinibarApprovisionné" },
        { title: t('minibar_title2'), value: "MinibarCertainsGratuits" },
        { title: t('minibar_title3'), value: "MiniBar" }
    ]

    return (
        <div className='my-3 fs-6'>
            <>

                {drinks.map((check, index) => (
                    <div key={index} className='my-3'>
                        <>
                            <CheckInput id={check.value} label={check.title} value={check.value} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                        </>

                        {checkedItems[check.value] && (
                            <>

                                {check.value === 'Minibar' && (
                                    <>
                                        <RoomOption />
                                        <Row className='align-items-center'>
                                            <Col md="6" className='my-2'>
                                                <span className='fs-6 fw-medium text-dark'>{t('minibar_type')}</span>
                                            </Col>
                                            <Col md="6" className='my-2'>
                                                <Select data={minibar} />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {['BouteilleGratuite', 'BarAvecRobinet'].includes(check.value) && (
                                    <RoomOption />
                                )}

                                {check.value === 'Cafetière' && (
                                    <>
                                        <RoomOption />

                                        <div className='px-3'>
                                            <div className='my-3'>
                                                <CheckInput id="PlateauCafé" label={t("minibar_choice1")} value={infos.tea} onChange={handleTea} disabled={infos.bags} />
                                            </div>

                                            <div className='my-3'>
                                                <CheckInput id="MachineAexpresso" label={t('minibar_choice2')} value={infos.machine} onChange={handleMachine} disabled={infos.bags} />
                                            </div>

                                            <div className='my-3'>
                                                <CheckInput id="Bouilloire0Electrique" label={t('minibar_choice3')} />
                                            </div>

                                            <div className='my-3'>
                                                <CheckInput id="SachetsThéGratuits" label={t('minibar_choice4')} value={infos.bags} onChange={handleBags} disabled={infos.tea || infos.machine} />
                                            </div>
                                        </div>
                                    </>
                                )}


                            </>
                        )}


                    </div>
                ))}
            </>
        </div>
    )
}

export default DetailsDrinks