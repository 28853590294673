import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Choice from "../../../components/Choice";
import Select from "../../../components/forms/Select";
import CheckInput from "../../../components/forms/CheckInput";
import { Toggle } from "rsuite";
import { useTranslation } from "react-i18next";
import { MinusCircle, PlusCircle } from "lucide-react";

const Food = (props) => {

  const { t } = useTranslation();

  const [infos, setInfos] = useState({
    food: "yes",
    restrictions: false,
    elements: [],
    options: [],
    selected: "",
  });

  const [checkedItems, setCheckedItems] = useState({});
  const [groups, setGroups] = useState([{ meal: "", format: "" }]);

  const meal = [
    { title: "Petit déjeuner", value: "PetitDéjeuner" },
    { title: "Déjeuner", value: "Déjeuner" },
    { title: "Dîner", value: "Dîner" },
  ];

  const format = [
    { title: "Repas complet", value: "Repas complet" },
    { title: "Dégustation", value: "Dégustation" },
    { title: "Cours de cuisine", value: "Cours de cuisine" },
    { title: "Collation", value: "Collation" },
    { title: "Pique-nique", value: "Pique-nique" },
    { title: "à emporter", value: "à emporter" },
    { title: "Barbecue", value: "Barbecue" },
  ];

  const addGroup = () => {
    setGroups([...groups, { meal: "", format: "" }]);
  };

  const removeGroup = (index) => {
    setGroups(groups.filter((_, i) => i !== index));
  };

  const handleMealChange = (index, value) => {
    const newGroups = [...groups];
    newGroups[index].meal = value;
    setGroups(newGroups);
  };

  const handleFormatChange = (index, value) => {
    const newGroups = [...groups];
    newGroups[index].format = value;
    setGroups(newGroups);
  };

  const handleFood = (value) => {
    setInfos({ ...infos, food: value });
  };

  const handleRestrictions = () => {
    setInfos({ ...infos, restrictions: !infos.restrictions });
  };

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const restrictions = [
    { title: t("diabetic"), value: "Diabétique" },
    { title: t('gluten_free'), value: "SansGluten" },
    { title: t('lactose_free'), value: "SansLactose" },
    { title: t("nut_free"), value: "SansNoix" },
    { title: t("sea_food"), value: "SansFruitsDeMer" },
    { title: t("vegetarian"), value: "Végétarien" },
    { title: t("without_eggs"), value: "SansOeufs" },
    { title: t("hallah"), value: "Hallal" },
    { title: t("kosher"), value: "Kascher" },
    { title: t("low_carbs"), value: "FaibleGlucides" },
    { title: t("vegan"), value: "Vegan" },
  ];

  return (
    <>
      <Choice text={<div className="fs-5 lh-base fw-medium text-dark">{t('food_title')}</div>} selectedChoice={infos.food} onChange={handleFood} />
      {infos.food === "yes" && (
        <>
          {groups.map((group, index) => (
            <Row className="align-items-center" key={index}>
              <Col md="6" className="d-flex align-items-center my-3">
                <div className="d-flex flex-column w-100">
                  <span className="fw-medium">{t('meals_type')}</span>
                  <div className="mt-2 fs-6">
                    <Select
                      id={`meal-${index}`}
                      name="meal"
                      value={group.meal}
                      handleChange={(e) => handleMealChange(index, e.target.value)}
                      data={meal}
                    />
                  </div>
                </div>
              </Col>

              <Col md="6" className="d-flex align-items-center my-3">
                <div className="d-flex flex-column w-100">
                  <span className="fw-medium">{t('format')}</span>
                  <div className="mt-2 fs-6">
                    <Select
                      id={`format-${index}`}
                      name="format"
                      value={group.format}
                      handleChange={(e) => handleFormatChange(index, e.target.value)}
                      data={format}
                    />
                  </div>
                </div>
              </Col>

              <Col md="12" className="d-flex justify-content-end">

                <button className="bg-transparent text-danger mx-3" type="button" onClick={() => removeGroup(index)} disabled={groups.length <= 1}>
                  <MinusCircle strokeWidth={1.5} /> {t('remove')}
                </button>
              </Col>
            </Row>
          ))}


          <button className="bg-transparent text-blue" type="button" onClick={addGroup} disabled={groups.length >= 5}>
            <PlusCircle strokeWidth={1.5} /> {t('add')}
          </button>

          <div className="fs-6 mt-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="my-3">
                <CheckInput id="BoissonsIncluses" label={t('drinks_included')} />
              </div>
              <div className="my-3">
                <span className="mx-2">{t('food_restrictions')} ({Object.keys(checkedItems).length})</span>
                <Toggle onChange={handleRestrictions} size="md" checked={infos.restrictions} />
              </div>
            </div>

            {infos.restrictions && (
              <div className="fs-6 mt-5">
                <div className="fs-6 lh-base fw-medium text-dark">{t('accepted_food')}</div>
                {restrictions.map((check, index) => (
                  <div key={index} className="my-3">
                    <CheckInput id={check.value} label={check.title} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </>

  );
};

export default Food;
