import React, { useState } from 'react'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

function Togglesw() {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div>
      <Toggle
        defaultChecked={isChecked}
        onChange={handleToggle}
      />
    </div>
  );
}

export default Togglesw;
