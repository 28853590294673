import React from "react";
import { Modal } from "react-bootstrap";
import { Toggle } from "rsuite";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";
import Input from "../../../components/forms/Input";

const StandardPromo = ({ show, handleClose, active, handleActive, data,t }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-dark">Ajouter une promotion standard</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-dark py-4 px-3">
            <div className="d-flex justify-content-between align-items-center">
              {active ? (
                <span className="text-success">Opérationnelle</span>
              ) : (
                <span className="text-danger">Interrompue</span>
              )}{" "}
              <Toggle
                checked={active}
                onChange={handleActive}
                checkedChildren={<CheckIcon />}
                unCheckedChildren={<CloseIcon />}
              />
            </div>

            {active && (
              <>
                <div className="my-4">
                  <div className="fs-5">
                    De quelle remise supplémentaire les voyageurs profitent-ils
                    ?
                  </div>
                  <div className="mt-3">
                    <Input type="text" placeholder="Remise %" />
                  </div>
                </div>

                <div className="my-4">
                  <div className="fs-5">
                    Quelle promotion est concernée par cette remise ?
                  </div>
                  <div className="mt-3">
                    <select className="input-blue w-100 p-4 border">
                      {data.map((option) => (
                        <option>{option.title}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-blue p-3 px-4 rounded-5" onClick={handleClose}>Enregistrer</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StandardPromo;
