import Schedules from '../../../components/Schedules'
import DateYear from '../../../components/DateYear'

const BookingDate = ({t}) => {
  return (
    <>
        <div className="fs-4 fw-bold">{t('booking_date')}</div>
        <p className="fs-6 fw-light">
          {t('booking_date_caption')}
        </p>
        <div className='my-4'>
          <DateYear />
          <Schedules/>
        </div>
    </>
  )
}

export default BookingDate
