import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Chart = ({t}) => {
  const [series, setSeries] = useState([
    {
      name: t('sales'),
      group: 'sales',
      data: [440, 550, 410, 800, 220, 430]
    },
    {
      name: t('earnings'),
      group: 'sales',
      data: [130, 260, 200, 400, 65, 270]
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 280,
      stacked: true,
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    dataLabels: {
      formatter: (val) => {
        return val + '€'
      }
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    xaxis: {
      categories: [
        'Jan',
        'Fev',
        'Mar',
        'Avr',
        'Mai',
        'Jui'
      ]
    },
    fill: {
      opacity: 1
    },
    colors: ['#8077ec', '#2cae29'],
    yaxis: {
      labels: {
        formatter: (val) => {
          return val + '€'
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    }
  });

  return (
    <div className="border rounded p-3">
      <ReactApexChart options={options} series={series} type="bar" height={460} />
    </div>
  );
}

export default Chart;
