import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select'
import { useState } from 'react';
import Input from '../../../components/forms/Input'
import CheckInput from '../../../components/forms/CheckInput'
import { useTranslation } from "react-i18next";

const Communication = () => {
    const { t } = useTranslation();
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const contact = [
        { title: t('28h_before_arrival'), value: "28 heures" },
        { title: t('48h_before_arrival'), value: "48 heures" },
        { title: t('72h_before_arrival'), value: "72 heures" }
    ]

    const email = [
        { title: t('28h_before_arrival'), value: "28 heures" },
        { title: t('48h_before_arrival'), value: "48 heures" },
        { title: t('72h_before_arrival'), value: "72 heures" },

        { title: t('5d_before_arrival'), value: "5 jours" },
        { title: t('7d_before_arrival'), value: "7 jours" },
        { title: t('10d_before_arrival'), value: "10 jours" },
        { title: t('2w_before_arrival'), value: "2 semaines" },
    ]

    const communication = [
        { title: t('tavelers_must_contact_hotel'), value: "ContacterHebergement"},
        { title: t('hotel_will_send_email'), value: "EmailVoyageurs"},
        { title: t('travelers_will_gve_their_id'), value: "PieceIdentite" },
        { title: t('register_in_different_place'), value: "LieuDifferent" },
    ]

    const document = [
        { title: t('id_card_with_photo'), value: "Carte d’identité" },
        { title: t('passport'), value: "Passeport" }
    ]

    return (
        <div className='my-3 fs-6'>
            <>
                <span className='fw-medium text-dark'>{t('communication_before_arrival')}</span>
                {communication.map((check, index) => (
                    <div key={index} className='my-3'>
                        <>

                            <CheckInput id={check.value} label={check.title} value={check.value} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                        </>

                        {checkedItems[check.value] && (
                            <>

                                {check.value === 'ContacterHebergement' && (
                                    <Row className='align-items-center'>
                                        <Col md="6" className='my-2'>
                                            <span className='fs-6 fw-medium text-dark'>{t('make_contact_before_arrival')}</span>
                                        </Col>
                                        <Col md="6" className='my-2'>
                                            <Select data={contact} />
                                        </Col>
                                    </Row>
                                )}

                                {check.value === 'EmailVoyageurs' && (
                                    <Row className='align-items-center'>
                                        <Col md="6" className='my-2'>
                                            <span className='fs-6 fw-medium text-dark'>{t('make_contact_before_arrival')}</span>
                                        </Col>
                                        <Col md="6" className='my-2'>
                                            <Select data={email} />
                                        </Col>
                                    </Row>
                                )}

                                {check.value === 'PieceIdentite' && (
                                    <Row className='align-items-center'>
                                        <Col md="6" className='my-2'>
                                            <span className='fs-6 fw-medium text-dark'>{t('id_card_type')}</span>
                                        </Col>
                                        <Col md="6" className='my-2'>
                                            <Select data={document} />
                                        </Col>
                                    </Row>
                                )}

                                {check.value === 'LieuDifferent' && (
                                    <Row className='align-items-center'>
                                        <Col md="6" className='my-2'>
                                            <span className='fs-6 fw-medium text-dark'>{t('alternativ_address')}</span>
                                        </Col>
                                        <Col md="6" className='my-2'>
                                            <Input placeholder={t('enter_an_address')} type="text" />
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}

                    </div>
                ))}
            </>
        </div>
    )
}


export default Communication