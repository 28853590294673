import { useState } from "react";
import CheckInput from '../../../components/forms/CheckInput'
import { useTranslation } from "react-i18next";
import DetailsWifi from "./DetailsWifi";
import DetailsParking from "./DetailsParking";
import DetailsPool from "./DetailsPool";
import DetailsCasino from "./DetailsCasino";
import DetailsSki from "./DetailsSki";
import DetailsGolf from "./DetailsGolf";
import DetailsSpring from "./DetailsSpring";
import DetailsSpa from "./DetailsSpa";
import DetailsBeach from "./DetailsBeach";
import DetailsBusiness from "./DetailsBusiness";
import DetailsFamily from "./DetailsFamily";
import DetailsCity from "./DetailsCity";
import DetailsNature from "./DetailsNature";
import DetailsAccessibilityServices from "./DetailsAccessibilityServices";
import AccessibilityOptions from "./AccessibilityOptions";
import DetailsAnimals from "./DetailsAnimals";
import AnimalsOptions from "./AnimalsOptions";
import DetailsLaundry from "./DetailsLaundry";
import DetailsFacilities from "./DetailsFacilities";

const Services = ({ serviceKey }) => {

    const { t } = useTranslation();
    const [checkedItems, setCheckedItems] = useState({});
    const [showAll, setShowAll] = useState(false);

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const data = {
        wifi: [
            { title: t('wifi_in_bedrooms'), value: "DansLesChambres" },
            { title: t('wifi_in_common_parts'), value: "PartiesCommunes" },
            { title: t('internet_per_cable_in_bedrooms'), value: "ParCableDansLesChambres" },
            { title: t('internet_per_cable_in_common_rooms'), value: "ParCablePartiesCommunes" },
            { title: t('internet_via_modem_in_rooms'), value: "ParModem" },
            { title: t('internet_via_modem_in_common_parts'), value: "ParModemPartiesCommunes" },
        ],
        parking: [
            { title: t('self_parking'), value: "SansVoiturier" },
            { title: t('valet_parking'), value: "AvecVoiturier" },
            { title: t('prolonged'), value: "Prolongé" },
            { title: t('offsite'), value: "Dehors" },
            { title: t('offstreet'), value: "HorsRue" },
            { title: t('truck_parking'), value: "Camion" },
            { title: t('parking_limited_spaces'), value: "EmplacementsLimités" },
            { title: t('parking_restrictions'), value: "RestrictionsDeHauteur" },
            { title: t('onsite_parking'), value: "AlaDemande" },
            { title: t('electric_charge_station'), value: "BorneDeRecharge" },
            { title: t('parking_moto_only'), value: "ParkingMotoUniquement" }
        ],
        pool: [
            { title: t('pool_open_all_year'), value: "OuverteTouteLannee" },
            { title: t('pool_open_in_season'), value: "OuverteEnSaison" },
            { title: t('pool_open'), value: "Couverte" },
            { title: t('pool_for_childs'), value: "PourEnfants" },
            { title: t('opening_hours_for_pool'), value: "Horraires" },
            { title: t('access_nearby_indoor_pool'), value: "CouverteVoisine" },
            { title: t('access_nearby_extern_pool'), value: "ExterieurVoisine" },
            { title: t('poolside_bar'), value: "BarEnBord" },
            { title: t('poolside'), value: "Bar" },
            { title: t('water_park'), value: "Parc" },
            { title: t('water_slider'), value: "Toboggan" },
            { title: t('artificial_river'), value: "Rivière" },
            { title: t('hammam'), value: "Hammam" },
            { title: t('hot_tub'), value: "BainARemous" },
            { title: t('lifeguard'), value: "Maître-nageur" },
            { title: t('sauna'), value: "Sauna" },
            { title: t('pool_with_barreirs'), value: "PiscineBarrieres" },
            { title: t('umbrellas_at_the_pool'), value: "ParasolsPiscine" },
            { title: t('pool_loungers'), value: "Transats" },
            { title: t('pool_cabins'), value: "Cabines" },
        ],
        animals: [
            { title: t('restrictions'), value: "Restrictions" },
            { title: t('deposit_animal'), value: "CautionAnimal", info: t('travelers_should_pay_deposit_for_damages') },
            { title: t('animals_clean_fees'), value: "FraisDeMenage", info: t('travelers_should_pay_animals_clean_fess') },
            { title: t('bowls_for_water_and_food'), value: "Gamelles" },
            { title: t('litter_box'), value: "Bac" },
            { title: t('dog_exercice_area'), value: "ZoneExercice" },
            { title: t('animals_in_few_rooms_only'), value: "AnimauxAdmisDansCertainesChambres" },
            { title: t('pet_care_services'), value: "ServicesDeGarde" },
            { title: t('grooming_services'), value: "ServicesDeToilettage" },
        ],
        accessibility: [
            { title: t('well_lit_access'), value: "AccèsEclairé" },
            { title: t('stepsless_access'), value: "AccèsSansMarches" },
            { title: t('need_to_take_stairs_to_reach_entrance'), value: "EscaliersPourEntrée" },
            { title: t('acces_ramp'), value: "Rampe" },
            { title: t('hearing_devices'), value: "AppareilsAuditive" },
            { title: t('meeting_rooms_with_hearing_devices'), value: "SallesDeRéunion" },
            { title: t('braille_signage'), value: "Signalétique" },
            { title: t('hallways_ramp'), value: "RampesDansLesCouloirs" },
            { title: t('handrails_on_stairs'), value: "RampesDansLesEscaliers" },
            { title: t('hospital_bed'), value: "LitHôpital" },
            { title: t('patient_lift_available'), value: "LèvePersonneDisponible" },
            { title: t('pool_lift'), value: "LèvePersonnePiscine" },
            { title: t('pool_ramp'), value: "RampePiscine" },
            { title: t('staff_practicing_sign_language'), value: "LangageDesSignes" },
            { title: t('visual_alarms'), value: "AlarmesVisuelles" },
            { title: t('wheelchairs_available_onsite'), value: "FauteuilsRoulants" },
        ],
        laundry: [
            { title: t('luggage_storage'), value: "ConsigneAbagages" },
            { title: t('isnctructions_available'), value: "ConsigneAdisposition" },
            { title: t('laundry'), value: "Laverie" },
            { title: t('laundry_services'), value: "Blanchisserie" },
            { title: t('ticket_assistance'), value: "Assistance" },
            { title: t('concierge_services'), value: "Concierge" },
            { title: t('newspapper_in_the_lobby'), value: "Journaux" },
            { title: t('safe_at_reception'), value: "Coffre-fort" },
            { title: t('atm_banking_services'), value: "Distributeur" },
            { title: t('computer_station'), value: "PosteInformatique" },
            { title: t('fireplace_in_the_hall'), value: "CheminéeDansLeHall" },
            { title: t('common_living_room'), value: "SalleCommune" },
            { title: t('free_delivery_services'), value: "ServiceDeLivraison" },
            { title: t('grocery_store'), value: "Épicerie" },
            { title: t('hair_salon'), value: "SalonDeCoiffure" },
            { title: t('library'), value: "Bibliothèque" },
            { title: t('multilangual_staff'), value: "PersonnelPolyglotte" },
            { title: t('luggage_handler'), value: "Bagagiste" },
            { title: t('reception_facilities'), value: "InstallationsRéception" },
            { title: t('shared_microwave'), value: "MicroOndesPartagé" },
            { title: t('shared_fridge'), value: "RéfrigérateurPartagé" },
            { title: t('shops_onsite'), value: "Boutiques" },
            { title: t('tv_in_common_areas'), value: "TélévisionCommuns" },
            { title: t('water_dispenser'), value: "DistributeurDeau" },
            { title: t('weeding_services'), value: "ServicesMariage" },
            { title: t('vending_machine'), value: "DistributeurAutomatique" },
        ],
        facilities: [
            { title: t("local_maps"), value: "CartesLocales" },
            { title: t("guides_or_recommandations"), value: "Recommandations" },
            { title: t("restaurant_guide"), value: "GuideRestaurants" },
            { title: t("chef"), value: "ChefCuisinier" },
            { title: t("meal_delivery_service"), value: "LivraisonRepas" },
            { title: t("inroom_massages"), value: "MassagesChambre" },
            { title: t("slippers"), value: "Pantoufles" },
            { title: t("slippers_for_childrens"), value: "PantouflesEnfants" },
            { title: t("turndown_service"), value: "ServiceCouverture" },
            { title: t("iron_board"), value: "FerArepasser" },
            { title: t("washing_machine_inroom"), value: "MachineALaver" },
            { title: t("washing"), value: "Lessive" },
            { title: t("blackout_curtains"), value: "RideauxOpaques" },
            { title: t("mosquito_nets"), value: "Moustiquaires" },
            { title: t("sound_proofed_rooms"), value: "ChambresInsonorisées" },
            { title: t("noise_free_rooms"), value: "ChambresSansBruit" },
            { title: t("inroom_safe"), value: "CoffreFort" },
            { title: t("mobil_key_entry"), value: "CléMobile" },
            { title: t("free_newspaper"), value: "JournalGratuit" },
            { title: t("phone"), value: "Téléphone" },
            { title: t("smartphone"), value: "Smartphone" },
            { title: t("electrical_adapters"), value: "Adaptateurs" },
            { title: t("computer_screen"), value: "ÉcranOrdinateur" },
            { title: t("printer"), value: "Imprimante" },
        ],
        aventure: [
            { title: t('bikes'), value: "Vélos" },
            { title: t('hiking'), value: "Randonnée" },
            { title: t('horse_riding'), value: "Équitation/centre équestre" },
            { title: t('archery'), value: "Tir à l’arc" },
            { title: t('potholing'), value: "Spéléologie" },
            { title: t('ecotourism'), value: "Écotourisme" },
            { title: t('plane_tours'), value: "Visites en hélicoptère/avion sur place" },
            { title: t('hunting'), value: "Chasse" },
            { title: t('mountaineering'), value: "Alpinisme" },
            { title: t('tree_climbing'), value: "Accrobranche" },
            { title: t('climbing'), value: "Escalade" },
            { title: t('scooter'), value: "Scooter ou mobylette" },
            { title: t('segways'), value: "Segways" },
            { title: t('skydiving'), value: "Parachutisme" },
            { title: t('zipline'), value: "Tyrolienne" },
        ],
        casino: [
            { title: t('casino'), value: "Casino" },
            { title: t('bingo'), value: "Bingo" },
            { title: t('pachinko'), value: "Pachinko" }
        ],
        golf: [
            { title: t('golf_field'), value: "Terrain de golf" },
            { title: t('golf_practice'), value: "Pratique de golf" },
        ],
        ski: [
            { title: t('direct_access_to_tracks'), value: "Accès-direct" },
            { title: t('ski_tracks_nearby'), value: "Pistes-ski" },
            { title: t('snowboard'), value: "Snowboard" },
            { title: t('cross_country'), value: "SkiFond" },
            { title: t('ski_en_option'), value: "PrivilègesDeSki" },
            { title: t('ski_passes_available'), value: "ForfaitsDeSki" },
            { title: t('ski_storage'), value: "LocalSkis" },
            { title: t('ski_lessons'), value: "CoursDeSki" },
            { title: t('lift_privileges'), value: "PrivilègesRemontée" },
            { title: t('ski_equipment_rental'), value: "LocationSki" },
            { title: t('alpine_ski'), value: "Ski-alpin" },
            { title: t('ski_shuttle'), value: "NavetteSki" },
        ],
        spring: [
            { title: t('hot_springs_nearby'), value: "SourcesProximite" },
            { title: t('hot_springs_onsite'), value: "SouresSurPlace" },
        ],
        spa: [
            { title: t("massages"), value: "MassagesSpa" },
            { title: t("spa_treatment_room"), value: "Salle-spa" },
            { title: t("spa_booking_advance"), value: "Réservations-a-lavance" },
            { title: t('mud_bath'), value: "BainDeBoueoue" },
            { title: t('sauna_in_the_spa'), value: "SaunaSpa" },
            { title: t('hot_tub_in_spa'), value: "BainRemousSpa" },
            { title: t('hot_springs_in_spa'), value: "SourcesSpa" },
            { title: t('adult_supervision_required'), value: "SurveillanceAdulte" },
            { title: t('age_restrictions_apply'), value: "RestrictionSpa" }
        ],
        beach: [
            { title: t('beach_access'), value: "Accès" },
            { title: t('beach_bars'), value: "Bars" },
            { title: t('beach_club'), value: "Club" },
            { title: t('beach_shuttle'), value: "Navette" },
            { title: t('beach_huts'), value: "Cabines" },
            { title: t('beach_loungers'), value: "Transats de plage" },
            { title: t('beach_towels'), value: "Serviettes" },
            { title: t('parasols'), value: "Parasols" },
            { title: t('beach_volley'), value: "Beach-volley" },
            { title: t('beach_yoga'), value: "Yoga de plage" },
            { title: t('beach_massage'), value: "Massages" }
        ],
        business: [
            { title: t("business_center"), value: "Centre" },
            { title: t("meetingrooms"), value: "Salles" },
            { title: t("meeting_rooms_count"), value: "NombreDeSalles" },
            { title: t("workspaces"), value: "Espaces" },
            { title: t("conference_installations"), value: "Installations" },
        ],
        shop: [
            { title: t('designer_shopping'), value: "BoutiquesCréateur" },
            { title: t('shopping_center'), value: "CentreCommercial" },
            { title: t('art_gallery'), value: "Galerie" }
        ],
        family: [
            { title: t('inroom_babysitting_service'), value: "Service de garde" },
            { title: t('baby_sitting_onsite'), value: "Baby-sitting", info: t('baby_sitting_services') },
            { title: t('child_care_service'), value: "Halte-garderie", info: t('child_care_services') },
            { title: t('kids_club'), value: "ClubPourEnfants", info: t('kids_club_info') },
            { title: t('children_tableware'), value: "VaissellePourEnfants" },
            { title: t('high_chair'), value: "ChaiseHaute" },
            { title: t('stroller'), value: "Poussette", info: t('stroller_availability') },
            { title: t('baby_bath'), value: "BaignoirePourBebe" },
            { title: t('babyphone'), value: "Babyphone" },
            { title: t('changing_table'), value: "TableLanger" },
            { title: t('art_supplies'), value: "FournituresArt" },
            { title: t('childs_books'), value: "LivresEnfants" },
            { title: t('games_for_children'), value: "JeuxEnfants" },
            { title: t('toys'), value: "Jouets" },
            { title: t('musical_instruments'), value: "Instruments" },
            { title: t('trampoline'), value: "Trampoline" },
            { title: t('shared_playground_onsite'), value: "Terrain", info: t('common_area_for_games') },
            { title: t('bed_rails'), value: "Barrières" },
            { title: t('cabinet_locks'), value: "SerruresArmoires" },
            { title: t('corner_protections'), value: "ProtectionsAngle" },
            { title: t('chimney_protection'), value: "ProtectionCheminée" },
            { title: t('stair_gate'), value: "BarrièreEscalier" },
            { title: t('socket_cover'), value: "Cache-prise" },
            { title: t('window_barriers'), value: "BarrièresFenêtre" }
        ],
        romantic: [
            { title: t('private_dining'), value: "RestaurationPrivé" },
            { title: t('picnics'), value: "Pique-niques" },
            { title: t('champagne_service'), value: "Service-champagne" },
            { title: t('proposal_packages_available'), value: "Formules-romantiques" },
        ],
        wine: [
            { title: t("adjoining_winery"), value: "Exploitation" },
            { title: t("vineyard"), value: "Vignoble" },
            { title: t("tasting_room"), value: "SalleDégustation" },
            { title: t("wine_launch_events"), value: "Événements" },
            { title: t("private_vineyard_tours"), value: "VisitesPriveesVignoble" },
            { title: t("public_vineyard_tours"), value: "VisitesPubliquesVignoble" },
        ],
        city: [
            { title: t("location_of_accommodation"), value: "LieuHébergement" },
            { title: t("connection_accommodation"), value: "ConnexionHébergement" },
            { title: t("nearby_accommodation"), value: "ProcheHébergement" },
        ],
        nature: [
            { title: t("location_of_accommodation"), value: "LieuNature" },
            { title: t("connection_accommodation"), value: "ConnexionNature" },
            { title: t("nearby_accommodation"), value: "ProcheNature" },
        ]
    };

    const itemsToShow = showAll ? data[serviceKey] : data[serviceKey].slice(0, 5);

    return (
        <div className='my-3 fs-6'>
            <>

                {serviceKey === "accessibility" && <AccessibilityOptions />}
                {serviceKey === "animals" && <AnimalsOptions />}

                {itemsToShow.map((check, index) => (
                    <div key={index} className='my-3'>

                        <div className='d-flex align-items-center'>
                            <CheckInput id={check.value} label={check.title} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} info={check.info} />
                        </div>

                        {checkedItems[check.value] && (
                            <>
                                <DetailsWifi checked={check.value} />
                                <DetailsParking checkedItems={checkedItems} checked={check.value} />
                                <DetailsPool checked={check.value} />
                                <DetailsAnimals checked={check.value} />
                                <DetailsAccessibilityServices checked={check.value} />
                                <DetailsLaundry checked={check.value} />
                                <DetailsFacilities checked={check.value} />
                                <DetailsCasino checked={check.value} />
                                <DetailsGolf checked={check.value} />
                                <DetailsSki checked={check.value} />
                                <DetailsSpring checked={check.value} />
                                <DetailsSpa checked={check.value} />
                                <DetailsBeach checked={check.value} />
                                <DetailsBusiness checked={check.value} />
                                <DetailsFamily checked={check.value} />
                                <DetailsCity checked={check.value} />
                                <DetailsNature checked={check.value} />

                            </>
                        )}
                    </div>
                ))}
            </>
            {data[serviceKey].length > 5 && <button type="button" className="bg-white text-blue fs-6 fw-light" onClick={() => setShowAll(!showAll)}>
                {showAll ? t('show_less') : t('show_more')}
            </button>}
        </div>
    )
}

export default Services