import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select'
import { useState } from 'react';
import Input from '../../../components/forms/Input'
import supplierData from '../../../data/globaldata.json'
import DepartFees from './DepartFees'
import CheckInput from '../../../components/forms/CheckInput';
import { useTranslation } from "react-i18next";

const LateArrival = () => {
    const { t } = useTranslation();
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const LateArrival = [
        { title: t('alert_late_arrival'), value: "avertir" },
        { title: t('late_arrivals_are_treated_elsewhere'), value: "autre" }
    ]

    return (
        <>
            <DepartFees text={t('late_arrival_fees')} />

            <div className='my-3'>
                <span className='fs-6 fw-medium text-dark'>{t('late_arrival_requirements')}</span>
                {LateArrival.map((check, index) => (
                    <div key={index} className='my-3'>

                        <CheckInput id={check.value} label={check.title} value={check.value} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />


                        {checkedItems[check.value] && (
                            <>

                                {check.value === 'avertir' && (
                                    <Row className='align-items-center'>
                                        <Col md="6" className='my-2'>
                                            <span className='fs-6 fw-medium text-dark'>{t('warn_after')}</span>
                                        </Col>
                                        <Col md="6" className='my-2'>
                                            <Select data={supplierData.schedules} />
                                        </Col>
                                    </Row>
                                )}

                                {check.value === 'autre' && (
                                    <Row className='align-items-center'>
                                        <Col md="6" className='my-2'>
                                            <span className='fs-6 fw-medium text-dark'>{t('late_arrival_address')}</span>
                                        </Col>
                                        <Col md="6" className='my-2'>
                                            <Input placeholder={t('enter_an_address')} type="text" />
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}

                    </div>
                ))}
            </div>
        </>
    )
}

export default LateArrival