import React from 'react'
import Select from '../../../components/forms/Select'
import Input from '../../../components/forms/Input'
import { Col, Row } from 'react-bootstrap'
import { useState } from 'react'
import Choice from '../../../components/Choice'

const Pricing = ({ t }) => {


    const [result, setResult] = useState({
        result: "no"
    });
    const handleResult = (value) => {
        setResult({ ...result, result: value });
    };

    const pricingData = [
        { title: "1 personne", value: "1personne" },
        { title: "5 personnes", value: "5personnes" },
        { title: "10 personnes", value: "10personnes" },
        { title: "15 personnes", value: "15personnes" },
        { title: "20 personnes", value: "20personnes" },
    ]
    return (
        <>
            <Row className='d-flex flex-column'>
                <Col md="10" className='my-3'>
                    <p className='fs-6 mb-3'>{t('ho_act_newrate2txt')}</p>
                    <Select data={pricingData} />
                </Col>
                <Col md="10" className='' style={{ backgroundColor: '#d1eaff' }}>
                    <p className='fs-6 mb-3'>{t('ho_act_newrate_ps')}</p>

                </Col>
                <Col md="5" className='my-3'>
                    <p className='fs-6 mb-3'>{t('ho_act_newrate_stayL')}</p>
                    <Input placeholder="" type="number" />
                </Col>
                <Col md="5" className='mt-3'>
                    <p className='fs-6 '>{t('ho_act_newrate_stayB')}</p>
                    <Input placeholder="" type="number" />
                </Col>
                <Col className='my-4 bg-white p-3 d-flex flex-column'>

                    <span>
                        <Choice text={t('ho_act_newrate_staysure')} onChange={handleResult} selectedChoice={result.result} />
                    </span>
                    {result.result === 'yes' && (
                        <p className='fs-6 my-3' style={{ color: '#008000' }}>{t('ho_act_newrate_stayCon')}</p>
                    )}

                </Col>
            </Row>
        </>
    )
}

export default Pricing