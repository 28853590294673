import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Input from './forms/Input'
import Select from './forms/Select'
import { useTranslation } from "react-i18next";

const ExtraCharge = () => {
    const { t } = useTranslation();

    const feesData = [
        {title: t('per_day'),value: "ParJour"},
        {title: t('per_night'),value: "ParNuit"},
        {title: t('per_week'),value: "ParSemaine"},
        {title: t('per_stay'),value: "ParSéjour"},
    ]

    return (
        <Row className='align-items-center'>
            <Col md="8" className='my-3'>
                <Input placeholder={t('amount_and_taxes')} type="number" />
            </Col>
            <Col md="4" className='my-3'>
                <Select data={feesData} />
            </Col>

        </Row>
    )
}

export default ExtraCharge