import { Panel, PanelGroup } from "rsuite";
import CheckInput from "../../../components/forms/CheckInput";

function Selectrate() {
  return (
    <PanelGroup accordion defaultActiveKey={1} bordered>
      <Panel header="Standard double room" eventKey={1}>
        <div className="my-3">
          <CheckInput id="standard-double-breakfast-p" label="Breakfast Included (P)" />
        </div>
        <div className="my-3">
          <CheckInput id="standard-double-breakfast-s" label="Breakfast Included (S)" />
        </div>
        <div className="my-3">
          <CheckInput id="standard-double-preferred-c" label="Egencia Preferred (C)" />
        </div>
        <div className="my-3">
          <CheckInput id="standard-double-full-board-p" label="Full Board (P)" />
        </div>
        <div className="my-3">
          <CheckInput id="standard-double-full-board-s" label="Full Board (S)" />
        </div>
        <div className="my-3">
          <CheckInput id="standard-double-half-board-p" label="Half Board (P)" />
        </div>
        <div className="my-3">
          <CheckInput id="standard-double-half-board-s" label="Half Board (S)" />
        </div>
      </Panel>
      <Panel header="Single room" eventKey={2}>
        <div className="my-3">
          <CheckInput id="single-room-breakfast-p" label="Breakfast Included (P)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room-breakfast-s" label="Breakfast Included (S)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room-preferred-c" label="Egencia Preferred (C)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room-full-board-p" label="Full Board (P)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room-full-board-s" label="Full Board (S)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room-half-board-p" label="Half Board (P)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room-half-board-s" label="Half Board (S)" />
        </div>
      </Panel>
      <Panel header="Single room" eventKey={3}>
        <div className="my-3">
          <CheckInput id="single-room2-breakfast-p" label="Breakfast Included (P)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room2-breakfast-s" label="Breakfast Included (S)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room2-preferred-c" label="Egencia Preferred (C)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room2-full-board-p" label="Full Board (P)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room2-full-board-s" label="Full Board (S)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room2-half-board-p" label="Half Board (P)" />
        </div>
        <div className="my-3">
          <CheckInput id="single-room2-half-board-s" label="Half Board (S)" />
        </div>
      </Panel>
    </PanelGroup>
  );
}

export default Selectrate;
