import { Snowflake, Gauge, Locate, Baby, Luggage, Bike, Link, FileAudio, Bluetooth, Accessibility, Video, Cast, SmartphoneNfc, Usb, Sun, PawPrint, Radio, CircleDot } from "lucide-react";
import Icons from "../../../assets/icones/icones";
import { Row, Col } from "react-bootstrap";
import Checkbox from "../../../components/forms/Checkbox";
import { useState } from "react";

const Attributes = ({ t }) => {
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, title: t('air_conditioning'), isChecked: false, value: "Climatisation", icon: <Snowflake strokeWidth={1.2} size={35} /> },
    { id: 2, title: t('radio'), isChecked: false, value: "Radio", icon: <Radio strokeWidth={1.2} size={35} /> },
    { id: 3, title: t('gps'), isChecked: false, value: "Gps", icon: <Locate strokeWidth={1.2} size={35} /> },
    { id: 4, title: t('screen'), isChecked: false, value: "Ecran", icon: <Cast strokeWidth={1.2} size={35} /> },
    { id: 5, title: t('screen_audio'), isChecked: false, value: "Entrée audio / iPod", icon: <FileAudio strokeWidth={1.2} size={35} /> },
    { id: 6, title: t('bluetooth'), isChecked: false, value: "Bluetooth", icon: <Bluetooth strokeWidth={1.2} size={35} /> },
    { id: 7, title: t('usb'), isChecked: false, value: "Port usb", icon: <Usb strokeWidth={1.2} size={35} /> },
    { id: 8, title: t('dashcam'), isChecked: false, value: "Dashcam", icon: <Video strokeWidth={1.2} size={35} /> },

    { id: 9, title: t('reversing_cameras'), isChecked: false, value: "Caméra de reculs", icon: <SmartphoneNfc strokeWidth={1.2} size={35} /> },
    { id: 10, title: t('open_roof'), isChecked: false, value: "Toit ouvrant", icon: <Sun strokeWidth={1.2} size={35} /> },
    { id: 11, title: t('all_wheels'), isChecked: false, value: "Traction intégrale", icon: <CircleDot color="white" fill="#232323" strokeWidth={1.2} size={35} /> },
    { id: 12, title: t('speed_regulator'), isChecked: false, value: "Régulateur de vitesse", icon: <Gauge strokeWidth={1.2} size={35} /> },

    { id: 13, title: t('baby_seat'), isChecked: false, value: "Siége bébé", icon: <Baby strokeWidth={1.2} size={35} /> },
    { id: 14, title: t('roof_box'), isChecked: false, value: "Coffre de toit", icon: <Luggage strokeWidth={1.2} size={35} /> },
    { id: 15, title: t('bike_rack'), isChecked: false, value: "Porte vélos", icon: <Bike strokeWidth={1.2} size={35} /> },
    { id: 16, title: t('chains'), isChecked: false, value: "Chaines", icon: <Link strokeWidth={1.2} size={35} /> },

    { id: 17, title: t('coupling'), isChecked: false, value: "Attelage", icon: <Icons name="route" strokeWidth={1.2} size={35} /> },
    { id: 18, title: t('wheelchair_accessible'), isChecked: false, value: "Accessible fauteuil roulant", icon: <Accessibility strokeWidth={1.2} size={35} /> },

    { id: 19, title: t('animals_accepted'), isChecked: false, value: "Animaux acceptés", icon: <PawPrint strokeWidth={1.2} size={35} /> },
  ]);

  const toggleCheckbox = (id) => {
    setCheckboxes((prevState) => prevState.map((checkbox) => (checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox)));
  };

  // const getCheckedValues = () => {
  //   return checkboxes.filter((checkbox) => checkbox.isChecked).map((checkbox) => checkbox.value);
  // };

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('CarsAddInd')}</h2>
              <p className="fs-5 fw-light">{t('CarsAddIndP')}</p>

              <div className="my-4">
                <Row>
                  {checkboxes.map((checkbox, index) => (
                    <Col key={index} xs="6" sm="4" md="4" className="my-2">
                      <Checkbox key={checkbox.id} id={checkbox.id} isChecked={checkbox.isChecked} value={checkbox.value} onChange={toggleCheckbox} title={checkbox.title} icon={checkbox.icon} />
                    </Col>
                  ))}
                </Row>
                {/* <div>
                  {getCheckedValues().map((value) => (
                    <p key={value}>{value}</p>
                  ))}
                </div> */}
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Attributes;
