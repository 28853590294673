import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Counter from "../../components/Counter";
import { useTranslation } from "react-i18next";


const Informations = () => {

  const [product, setproduct] = useState("");

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setproduct(savedType);
    }
  }, []);

  const { t } = useTranslation();

  const [infos, setInfos] = useState({
    travellers: 1,
    rooms: 1,
    beds: 0,
    baths: 0,
    tables: 6,
    capacity: 10,
    levels: 0,
    activityMax: 1,
    activityMin: 1,
    transportTravellers: 1,
    transportBookings: 1,
    transportSeats: 1,
    packageMax: 1,
    packageMin: 1,
    PackageAge: 0,
    PackageDuration: 3,
    CarDoors: 2,
    CarSeats: 2,
    CarHorses: 2,
    HotelRooms: 10,
    HotelCapacity: 10,
    HotelPools: 0,
    HotelLevels: 1,
    HotelRestaurants: 0,
  });

  const handleIncrement = (key, operation) => {
    setInfos((prevInfos) => {
      const newValue = operation === "increment" ? prevInfos[key] + 1 : operation === "decrement" ? prevInfos[key] - 1 : prevInfos[key];
      return { ...prevInfos, [key]: newValue };
    });
  };

  const handleChange = (key, value) => {
    setInfos((prevInfos) => ({
      ...prevInfos,
      [key]: parseInt(value) || 0,
    }));
  };

  const configurations = {
    Maison: [
      { label: t('travelers'), value: infos.travellers, min: 1, max: 20, key: "travellers", content: t('travelers_content') },
      { label: t('rooms'), value: infos.rooms, min: 1, max: 50, key: "rooms", content: t('rooms_content') },
      { label: t('beds'), value: infos.beds, min: 0, max: 1000, key: "beds", content: t('beds_content') },
      { label: t('baths'), value: infos.baths, min: 0, max: 1000, key: "baths", content: t('baths_content') },
    ],
    Restaurant: [
      { label: t('tables'), value: infos.tables, min: 6, max: 50, key: "tables", content: t('tables_content') },
      { label: t('welcome_capacity'), value: infos.capacity, min: 10, max: 50, key: "capacity", content: t('welcome_capacity_content') },
      { label: t('levels'), value: infos.levels, min: 0, max: 5, key: "levels", content: t('levels_content') },
    ],
    Activité: [
      { label: t('min_number_of_participants'), value: infos.activityMin, min: 2, max: 10000, key: "activityMin" },
      { label: t('max_number_of_participants'), value: infos.activityMax, min: infos.activityMin + 1, max: 10000, key: "activityMax" },
    ],

    Transport: [
      { label: t('max_number_of_participants'), value: infos.transportTravellers, min: 2, max: 49, key: "transportTravellers" },
      { label: t('seats'), value: infos.transportSeats, min: 1, max: 49, key: "transportSeats" },
    ],

    Package: [
      { label: t('min_number_of_participants'), value: infos.packageMin, min: 2, max: 10000, key: "packageMin" },
      { label: t('max_number_of_participants'), value: infos.packageMax, min: infos.packageMin + 1, max: 10000, key: "packageMax" },
    ],

    Voiture: [
      { label: t('doors'), value: infos.CarDoors, min: 2, max: 8, key: "CarDoors", content: "Le nombre de porte que votre voiture posséde." },
      { label: t('seats'), value: infos.CarSeats, min: 2, max: 50, key: "seats", content: "Le nombre de siéges que votre voiture posséde." },
      { label: t('horses'), value: infos.CarHorses, min: 2, max: 1000, key: "horses", content: "Le nombre de chevaux que votre voiture posséde." },
    ],

    Hotel: [
      { label: t('rooms'), value: infos.HotelRooms, min: 10, max: 10000, key: "HotelRooms", content: t('rooms_caption') },
      { label: t('pools'), value: infos.HotelPools, min: 0, max: 5, key: "HotelPools", content: t('pools_caption') },
      { label: t('restaurants'), value: infos.HotelRestaurants, min: 0, max: 10, key: "HotelRestaurants", content: t('restaurants_caption') },
      { label: t('levels'), value: infos.HotelLevels, min: 1, max: 60, key: "HotelLevels", content: t('level_caption') },

    ]
  };

  const data = configurations[product] || [];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="9" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('give_main_infos')}</h2>
              <p className="fs-5 fw-light">{t('main_infos_text')}</p>
              <Counter data={data} handleIncrement={handleIncrement} handleChange={handleChange} />
              {(infos.activityMin > infos.activityMax || infos.packageMin > infos.packageMax) && (
                <div className="fs-6 text-danger my-3">
                  {t('number_must_be_higher_than')} {infos.activityMin}
                </div>
              )}

            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Informations;

