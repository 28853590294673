import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Selectfees from './components/Selectfees'
import { Button } from 'rsuite'
import { useTranslation } from "react-i18next";
import Input from '../../components/forms/Input'
import Select from '../../components/forms/Select'

const Newfees = () => {

    const { t } = useTranslation();

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleShowSuccessMessage = () => {
        setShowSuccessMessage(true);
    };

    const handleSaveButtonClick = () => {
        handleShowSuccessMessage();
    };

    const [activeKey, setActiveKey] = useState(null);
    const handleSelect = (eventKey, event) => {
        event.preventDefault();
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    const rooms = [
        { title: "Deluxe Room", value: "Deluxe Room" },
        { title: "Standard Single Room", value: "Standard Single Room" },
        { title: "Triple room", value: "Triple Room" },
        { title: "Superior Double", value: "Superior Double" },

    ]

    return (
        <section>
            <div className='container justify-center'>
                <Row>
                    <Col className='my-3'>
                        <form action='#'>
                            <fieldset className='bg-white p-5'>
                                <>
                                    <span className='fs-2 fw-bold text-dark'>{t('ho_act_newFees')}</span>
                                    <p className='fs-6 mb-4 mt-1'>{t('ho_act_newfeesTxt')}</p>
                                </>
                                <Row className='d-flex flex-column'>
                                    <Col className='d-flex flex-column w-75 my-2'>
                                        <span className='fs-5 fw-bold text-dark'>{t('ho_act_newFees_nommer')}</span>
                                        <div className='my-1'>
                                            <Input type="text" />
                                        </div>
                                    </Col>
                                    <Col className='my-3 bg-white'>
                                        <span className='fs-5 fw-bold text-dark'>{t('ho_act_newFees_age')}</span>
                                        <p className='fs-8 mb-4'>{t('ho_act_newFees_age_txt')}</p>
                                        <>
                                            <Col md="12" xl="12" className='d-flex flex-column bg-white mt-3 mb-5 w-50'>
                                                <span className='my-2'>{t('ho_act_newFees_select')}</span>

                                                <Select data={rooms} />

                                            </Col>
                                            <Col className='d-flex flex-row w-100 my-2'>
                                                <span className='my-2 fs-6 fw-bold ms-2 me-5 text-dark'>{t('adult')} :</span>
                                                <Col xl="5" className='d-flex flex-row'>
                                                    <span className='my-2 me-2'>{t('ho_act_min')}</span>
                                                    <input type="text" className="ms-2 form-control inpcss" />
                                                </Col>
                                                <Col xl="5" className='d-flex flex-row'>
                                                    <span className='my-2 me-2'>{t('ho_act_max')}</span>
                                                    <input type="text" className="ms-2 form-control inpcss" />
                                                </Col>
                                            </Col>
                                            <Col className='d-flex flex-row w-100 my-2'>
                                                <span className='my-2 fs-6 fw-bold ms-2 me-5 text-dark'>{t('child')} :</span>
                                                <Col xl="5" className='d-flex flex-row'>
                                                    <span className='my-2 me-2'>{t('ho_act_min')}</span>
                                                    <input type="text" className="ms-2 form-control inpcss" />
                                                </Col>
                                                <Col xl="5" className='d-flex flex-row'>
                                                    <span className='my-2 me-2'>{t('ho_act_max')}</span>
                                                    <input type="text" className="ms-2 form-control inpcss" />
                                                </Col>
                                            </Col>
                                            <Col className='d-flex flex-row w-100 my-2'>
                                                <span className='my-2 fs-6 fw-bold ms-2 me-5 text-dark'>{t('baby')} :</span>
                                                <Col xl="5" className='d-flex flex-row'>
                                                    <span className='my-2 me-2'>{t('ho_act_min')}</span>
                                                    <input type="text" className="ms-2 form-control inpcss" />
                                                </Col>
                                                <Col xl="5" className='d-flex flex-row'>
                                                    <span className='my-2 me-2'>{t('ho_act_max')}</span>
                                                    <input type="text" className="ms-2 form-control inpcss" />
                                                </Col>
                                            </Col>

                                            <div className="d-flex justify-content-end mt-5">
                                                <Button appearance="primary" color='cyan' className="me-2 px-4" onClick={handleSaveButtonClick}>{t('save')}</Button>
                                                <Button color="orange" appearance="primary" className='px-4'>{t('cancel')}</Button>
                                            </div>

                                        </>
                                    </Col>
                                    <Col md="12" xl="12" className='my-3'>
                                        <p className='fs-6 mb-4'>{t('ho_act_add')}</p>
                                        <Selectfees t={t} />
                                    </Col>
                                </Row>
                                <Button appearance="primary" className="p-3 mt-2 px-5 rounded-3 text-white">{t('submit')}</Button>
                            </fieldset>
                        </form>
                    </Col>
                </Row>
            </div>
        </section>
    )
}
export default Newfees;
