import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Button } from 'rsuite'
import { Badge } from 'rsuite'
import { GoPersonFill } from "react-icons/go"
import { MdBedroomParent } from "react-icons/md"
import { MdPersonAddAlt1 } from "react-icons/md"
import ManageHeader from "../../components/ManageHeader";
import { UserPlusIcon } from 'lucide-react'

const PersonOccu = ({ t }) => {
    const [showDefaultMessage, setShowDefaultMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleUndefinedClick = (event) => {
        event.preventDefault();
        setShowDefaultMessage(false);
    };

    const handleByDefaultClick = (event) => {
        event.preventDefault();
        setShowDefaultMessage(true);
    };

    const handleCloseSuccessMessage = () => {
        setShowSuccessMessage(false);
    };

    const handleShowSuccessMessage = () => {
        setShowSuccessMessage(true);
    };

    const handleSaveButtonClick = () => {
        handleShowSuccessMessage();
    };

    const [activeKey, setActiveKey] = useState(null);
    const handleSelect = (eventKey, event) => {
        event.preventDefault();
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };
    const [selectedRoom, setSelectedRoom] = useState('');
    const [niveauxTarifaires, setNiveauxTarifaires] = useState({
        'Ibis Classic Twin Room': 9,
        'Ibis Deluxe Double Room': 12,
        'Suite Standard Ibis Tarif': 3,
        'Junior Suite Ibis Tarif': 6
    });

    const fraisTypes = {
        'Ibis Classic Twin Room': 'Frais par personne',
        'Ibis Deluxe Double Room': 'Frais par chambre',
        'Suite Standard Ibis Tarif': 'Frais par chambre',
        'Junior Suite Ibis Tarif': 'Personne supplémentaire'
    };

    const handleParDefautClick = (roomName) => {
        setSelectedRoom(roomName);
    };
    return (
        <section className='mb-4'>
            <ManageHeader
                title={t('ho_act_frais_ind')}
                content={t('ho_act_frais_ind_txt')}
                icon={<UserPlusIcon size={25} strokeWidth={1.5} />}
            />

            <div className='container my-4'>
                <Row className='bgperson'>
                    <Col xl='6'>
                        <div className='d-flex flex-row'>
                            <div className='content ms-2 mt-4'>
                                <div className='fs-2 fw-bold text-dark my-4'>{t('ho_act_frais_ind_chambre')}</div>
                                <div><span className='' style={{ color: '#333333' }}>{t('ho_act_frais_ind_chambre_txt')}</span></div>
                                <div className='mt-4'>
                                    <Button appearance="primary" color="cyan" className='px-5 fw-medium'>
                                        <Link to="../newfees" className="text-white">{t('ho_act_frais_ind_btw')}</Link>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='container'>
                <Row className='my-3'>
                    <Col md="6" lg="4" className='my-2 p-1'>
                        <div className='bg-white border rounded p-3 h-100'>
                            <div className='d-flex justify-content-between'>
                                <div className=''>
                                    <div className='text-dark fs-4 fw-bold mb-4 py-2'>{t('ho_act_frais_parPerson')}</div>
                                    <div className='fs-7 text-dark my-2'>{t('ho_act_frais_parPersonTxt')}</div>

                                </div>
                                <div>
                                    <GoPersonFill className='text-orange' style={{ width: '80px', height: '80px', color: 'lightblue' }} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="4" className='my-2 p-1'>
                        <div className='bg-white border rounded p-3 h-100'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div className='text-dark fs-4 fw-bold mb-4 py-2'>{t('ho_act_frais_ind_room')}</div>
                                    <div className='fs-7 text-dark my-2'>{t('ho_act_frais_ind_room_txt')}</div>
                                </div>
                                <div>
                                    <MdBedroomParent className='text-green' style={{ width: '80px', height: '80px' }} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="4" className='my-2 p-1'>
                        <div className='bg-white border rounded p-3 h-100'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div className='fs-4 fw-bold text-dark'>{t('ho_act_frais_ind_fraisPlus')}</div>
                                    <div className='fs-7 text-dark my-2'>{t('ho_act_frais_ind_fraisPlusTxt')}</div>
                                </div>
                                <div>
                                    <MdPersonAddAlt1 className='text-blue' style={{ width: '80px', height: '80px', color: 'orange' }} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='container my-2 p-3'>
                <Row className='p-2 border rounded'>
                    <Col xl='8'>
                        <div className='d-flex flex-row'>
                            <div className='content ms-2 mt-4'>
                                <div>
                                    <span className='fs-5 text-dark  me-2'>{t('ho_act_frais_ind_default')} :</span>
                                    <span className='fs-5 fw-bold me-3' style={{ color: '#333333' }}>{selectedRoom || 'Ibis tarif'}</span>
                                    <Badge className='p-1 me-3' color='yellow' content={`${niveauxTarifaires[selectedRoom] || 12} niveaux tarifaires`}></Badge>
                                </div>
                                <div className='mt-4'>
                                    <Button appearance="primary" color="cyan" className='px-5 fw-medium'>
                                        <Link to="../newfees" className="text-white">{t('edit')}</Link>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='bgpersonss'>
                    </Col>
                </Row>
            </div>
            <div className='container mt-2'>{t('ho_act_frais_parPerson')}
                <span className='text-capitalize text-dark fs-4 fw-bold mx-2'>Activé</span>
                <div className='d-flex align-items-center mt-2'>
                    <p className='fs-8 w-75'>{t('ho_act_frais_ind_ActivéTXT')}</p>
                </div>
            </div>
            <div className='container my-2 p-2'>
                <Row className='my-3'>
                    {Object.entries(niveauxTarifaires).map(([roomName, numberOfTariffs]) => (
                        <Col md="6" lg="6" className='my-2' key={roomName}>
                            <div className='bg-white border rounded p-3 h-100'>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div className='fs-5 fw-bold text-dark'>{roomName}</div>
                                        <div className='d-flex flex-row'>
                                            <div className='fs-7 text-dark my-2 d-flex flex-column'>
                                                <span>{numberOfTariffs} {t('ho_act_detailscancelTarif')}</span>
                                                <span>{fraisTypes[roomName]}</span>
                                            </div>
                                            <div className="d-flex justify-content-center mt-5 ms-5 ps-5">
                                                <Link to="../newfees" className="me-3 text-cyan">{t('edit')}</Link>
                                                <Link to="#" className="text-blue" onClick={() => handleParDefautClick(roomName)}>{t('by_default')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {roomName === 'Ibis Classic Twin Room' && <GoPersonFill style={{ width: '80px', height: '80px', color: 'lightblue' }} />}
                                        {roomName === 'Ibis Deluxe Double Room' && <MdBedroomParent style={{ width: '80px', height: '80px', color: 'lightblue' }} />}
                                        {roomName === 'Suite Standard Ibis Tarif' && <MdBedroomParent style={{ width: '80px', height: '80px', color: 'lightblue' }} />}
                                        {roomName === 'Junior Suite Ibis Tarif' && <MdPersonAddAlt1 className='text-blue' style={{ width: '80px', height: '80px', color: 'lightblue' }} />}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </section>
    )
}

export default PersonOccu;
