import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import ExtraCharge from "../../../components/ExtraCharge";
import Input from "../../../components/forms/Input";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";


const DetailsAnimals = ({ checked }) => {
  const { t } = useTranslation();

  const [animalsItems, setanimalsItems] = useState({});

  const handleanimalsItems = (value) => {
    setanimalsItems((prevanimalsItems) => ({
      ...prevanimalsItems,
      [value]: !prevanimalsItems[value],
    }));
  };

  const weight = [
    { title: t('kilograms'), value: "Kilogrames" },
    { title: t('pounds'), value: "Livres" },
  ];

  const restrictions = [
    { title: t('weight_limit_per_animal'), value: "LimiteDePoids" },
    { title: t('short_animals_only'), value: "AnimauxPetits" },
    { title: t('animals_in_smoking_rooms_only'), value: "AnimauxChambresFumeurs" },
    { title: t('animals_in_few_rooms_only'), value: "AnimauxCertainesChambres" },
    { title: t('animals_without_watch'), value: "AnimauxSansSurveillance" },
    { title: t('restrictions_applicable_for_animals'), value: "AnimauxRestrictions" },
  ];

  const animals_length = Array.from({ length: 5 }, (_, index) => ({
    title: `${index + 1}`,
    value: index + 1,
  }));

  return (
    <div className="my-3 fs-6">
      {checked === "Restrictions" && (
        <div className="px-3">
          {restrictions.map((check, index) => (
            <div key={index}>
              <div className="my-3">
                <CheckInput id={check.value} label={check.title} checked={animalsItems[check.value] || false} onChange={() => handleanimalsItems(check.value)} />
              </div>

              {animalsItems[check.value] && (
                <>
                  {check.value === "LimiteDePoids" && (
                    <Row>
                      <Col md="6" className="my-3">
                        <Input placeholder="poid de l'animal" type="number" />
                      </Col>
                      <Col md="6" className="d-flex align-items-center my-3">
                        <Select data={weight} />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          ))}

          <Row className="fs-6 align-items-center">
            <Col md="6" className="d-flex align-items-center my-3">
              <span className="fw-medium text-dark">{t('max_per_room')}</span>
            </Col>
            <Col md="6" className="d-flex align-items-center my-3">
              <Select data={animals_length} />
            </Col>
          </Row>
        </div>
      )}

      {["CautionAnimal", "FraisDeMenage"].includes(checked) && <ExtraCharge />}
    </div>
  );
};

export default DetailsAnimals;
