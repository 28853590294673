import { MonitorSmartphone } from 'lucide-react';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import products from '../../../data/products.json';
import BookingsList from './BookingsList';

const BookingActivities = ({t}) => {
    const [filtreStatut, setFiltreStatut] = useState("all");
    const buttons = [
        {title:t('all'),value:"all"},
        {title:t('confirmed'),value:"En marche"},
        {title:t('waiting'),value:"En attente"},
        {title:t('suspended'),value:"Suspendu"},
        {title:t('cancelled'),value:"annulée"},
    ];
    
    const filteredData = filtreStatut === "all" ? products.products : products.products.filter(item => item.status === filtreStatut);

    return (
        <div className="my-4">
            <div className="fs-4 fw-medium text-dark">{t('your_bookings')}</div>
            <Row>
                <Col xl="11">
                    <Row>
                        {buttons.map((item, index) => (
                            <Col key={index} xl="2" className="my-3">
                                <div
                                    role="button"
                                    className={`border rounded p-3 w-100 text-center ${filtreStatut === item.value ? "border-dark" : ""}`}
                                    onClick={() => setFiltreStatut(item.value)}
                                >
                                    {item.title}
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col>
                            {filteredData.length === 0 ? (
                                <div className="p-5 text-dark">
                                    <div className="d-flex flex-column align-items-center">
                                        <MonitorSmartphone size={60} strokeWidth={1.3} />
                                        <p className="fs-5 fw-light my-2">{t('no_available_bookings')}.</p>
                                    </div>
                                </div>
                            ) : (
                                <BookingsList data={filteredData} />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default BookingActivities;
