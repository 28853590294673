import { Row, Col } from "react-bootstrap";
import { RadioTile, RadioTileGroup } from "rsuite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsHouses, BsHouseDoor } from "react-icons/bs";
import Counter from '../../../components/Counter'
import Rating from "./Rating";

const Privacy = () => {

    const { t } = useTranslation();
    const [infos, setInfos] = useState({
        choice: "",
        properties: 1
    });

    const handleChoice = (value) => {
        setInfos({ ...infos, choice: value });
    };

    const data = [
        {
            title: t('single_hotel'),
            icon: <BsHouseDoor size={20} />,
            value: "Hôtel unique",
            content: t('single_hotel_caption'),
        },
        {
            title: t('multiples_hotels'),
            icon: <BsHouses size={20} />,
            value: "HotelsMultiples",
            content: t('multiples_hotels_caption'),
        },
    ];

    const count = [
        { label: t('property_length'), value: infos.properties, min: 1, max: 20, key: "properties", content: t('property_length_content') },
    ]

    const handleIncrement = (key, operation) => {
        setInfos((prevInfos) => {
            const newValue = operation === "increment" ? prevInfos[key] + 1 : operation === "decrement" ? prevInfos[key] - 1 : prevInfos[key];
            return { ...prevInfos, [key]: newValue };
        });
    };

    const handleChange = (key, value) => {
        setInfos((prevInfos) => ({
            ...prevInfos,
            [key]: parseInt(value) || 0,
        }));
    };


    return (
        <section className="bg-white position-relative form-transition">
            <div className="container">
                <Row className="justify-content-center">
                    <Col lg="9" xl="6" className="my-3">
                        <>
                            <h2 className="fs-3 lh-base fw-medium text-dark">{t('hotel_privacy_title')}</h2>
                            <p className="fs-5 fw-light">{t('hotel_privacy_caption')}</p>

                            <RadioTileGroup>
                                <Row className="mt-3">
                                    {data.map((choice, index) => (
                                        <Col key={index} xs="12" className="my-2">
                                            <RadioTile onChange={handleChoice} value={choice.value} className={`h-100 rounded-4  p-3 ${infos.choice === choice.value ? "border-dark" : "border"}`}>
                                                <div className="text-dark d-flex">
                                                    {choice.icon}
                                                    <span className="px-3">
                                                        <div className="fs-6 fw-medium">{choice.title}</div>
                                                        <p className="fw-light">{choice.content}</p>
                                                    </span>
                                                </div>
                                            </RadioTile>
                                        </Col>
                                    ))}
                                </Row>
                            </RadioTileGroup>

                            {infos.choice === "HotelsMultiples" &&
                                <Counter data={count} handleChange={handleChange} handleIncrement={handleIncrement} />
                            }
                            <div className="my-5">
                                <Rating t={t} />
                            </div>
                        </>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Privacy;
