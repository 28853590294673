import ManageHeader from '../../components/ManageHeader'
import { CalendarCheck } from 'lucide-react'
import Calendar from './components/Calendar'

const Availability = ({t}) => {

    return (
        <section>
            <ManageHeader
                title={t('availability')}
                content={t('availability_caption')}
                icon={<CalendarCheck size={25} strokeWidth={1.5} />}
            />

            <div className="container p-4">
                <Calendar t={t} />
            </div>
        </section>
    )
}

export default Availability
