import Home from "./Home";
import Settings from "./Settings";

function Main(props) {
  const composite = {
    home: <Home />,
    settings: <Settings />,
  };

  return <>{eval("composite." + props.component)}</>;
}

export default Main;
