import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Choice from "../../../components/Choice";
import CheckInput from "../../../components/forms/CheckInput";

const Events = ({ t }) => {

  const [infos, setInfos] = useState({
    events: "yes",
  });

  const HnaleEvent = (value) => {
    setInfos({ ...infos, events: value });
  };

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const data = [
    { title: t('RestauInfosOrigine1'), value: "Anniversaires" },
    { title: t('RestauInfosOrigine2'), value: "Mariages et fiançailles" },
    { title: t('RestauInfosOrigine3'), value: "Fêtes de remise des diplômes" },
    { title: t('RestauInfosOrigine4'), value: "Réunions de famille" },
    { title: t('RestauInfosOrigine5'), value: "Événements d'entreprise" },
    { title: t('RestauInfosOrigine6'), value: "Soirées à thème" },
    { title: t('RestauInfosOrigine7'), value: "Célébrations religieuses" },
    { title: t('RestauInfosOrigine8'), value: "Célébrations culturelles" },
  ];

  return (
    <section className="container form-transition">
      <Row className="justify-content-center">
        <Col lg="7" xl="8" className="my-3">
          <>
            <Choice text={<span className="fs-3 lh-base fw-medium text-dark">{t('RestauInfosOrigine')}</span>} selectedChoice={infos.events} onChange={HnaleEvent} />
            {infos.events === "yes" && (
              <div className="fs-6 mt-5">
                {data.map((check, index) => (
                  <div key={index} className="my-3">
                    <CheckInput id={check.value} label={check.title} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                  </div>
                ))}
              </div>
            )}
          </>
        </Col>
      </Row>
    </section>
  );
};

export default Events;
