import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import ExtraCharges from '../../../components/ExtraCharge'
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsFamily = ({ checked }) => {
    const { t } = useTranslation();

    const [chair, setChair] = useState(false);

    const HandleChair = () => {
        setChair(!chair)
    }


    const cost = [
        { title: t('free'), value: "Gratuit" },
        { title: t('supplement'), value: "En supplément" },
    ]

    const toys = [
        { title: t('toys_for_babies'), value: "JouetsPourBébé" },
        { title: t('toys_for_children'), value: "JouetsEnfants" },
        { title: t('beach_toys'), value: "JouetsDePlage" },
        { title: t('pool_games'), value: "JeuxPourLaPiscine" },
    ]

    return (
        <div className='my-3 fs-6'>

            {checked === 'ChaiseHaute' && (
                <>
                    <div className="my-3 px-3">
                        <CheckInput id="SupplémentChaise" label={t('high_chair_extra')} value={chair} onChange={HandleChair} />
                    </div>

                    {chair && (
                        <ExtraCharges />
                    )}
                </>
            )}

            {['Baby-sitting', 'Halte-garderie', 'ClubPourEnfants'].includes(checked) && (
                <Row className='fs-6 align-items-center'>
                    <Col md="6" className='d-flex align-items-center my-3'>
                        <span className='fw-medium text-dark'>{t('cost')}</span>
                    </Col>
                    <Col md="6" className='d-flex align-items-center my-3'>
                        <Select data={cost} />
                    </Col>
                </Row>
            )}
            {checked === 'Jouets' && (
                <>
                    {toys.map((toy, indexOf) => (
                        <div key={indexOf} className='my-3 px-3'>

                            <CheckInput id={toy.value} label={toy.title} />

                        </div>
                    ))}
                </>
            )}

        </div>

    )
}

export default DetailsFamily