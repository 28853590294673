import { Row, Col } from "react-bootstrap";
import { useEffect, useReducer } from "react";
import { RadioTile, RadioTileGroup } from "rsuite";
import { CableCar, Car, Home, Hotel, Ship, TrainFront, Utensils } from "lucide-react";

const initialState = {
  product: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TYPE":
      localStorage.setItem("product", action.payload);
      return { ...state, product: action.payload };
    default:
      return state;
  }
};

const Structure = ({ t, onStart, isLoading }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      dispatch({ type: "SET_TYPE", payload: savedType });
    }
  }, []);

  const handleRadioChange = (value) => {
    dispatch({ type: "SET_TYPE", payload: value });
  };

  const data = [
    { title: t('rental'), value: "Maison", icon: <Home size={20} strokeWidth={1.5} />, content: t('rental_caption') },
    { title: t('hotel'), value: "Hotel", icon: <Hotel size={20} strokeWidth={1.5} />, content: t('hotel_caption') },
    { title: t('activity'), value: "Activité", icon: <CableCar size={20} strokeWidth={1.5} />, content: t('activity_caption') },
    { title: t('transport'), value: "Transport", icon: <TrainFront size={20} strokeWidth={1.5} />, content: t('transport_caption') },
    { title: t('package'), value: "Package", icon: <Ship size={20} strokeWidth={1.5} />, content: t('package_caption') },
    { title: t('car'), value: "Voiture", icon: <Car size={20} strokeWidth={1.5} />, content: t('car_caption') },
    { title: t('restaurant'), value: "Restaurant", icon: <Utensils size={20} strokeWidth={1.5} />, content: t('restaurant_caption') },
  ];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="8" className="my-3">
            <div className="bg-white">
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('product_type_question')}</h2>
              <p className="fs-5 fw-light">{t('product_type_question_caption')}</p>

              <div className="my-3">
                <RadioTileGroup value={state.product} onChange={handleRadioChange}>
                  <Row>
                    {data.map((choice) => (
                      <Col key={choice.value} sm="12" md="12" className="my-2">
                        <RadioTile
                          value={choice.value}
                          className={`p-2 h-100 ${state.product === choice.value ? "border-dark" : "border"}`}
                        >
                          <div className="p-2 text-dark d-flex">
                            <div>{choice.icon}</div>
                            <div className="mx-3">
                              <div className="fw-medium fs-6">{choice.title}</div>
                              <p className="mt-2 fw-light">{choice.content}</p>
                            </div>
                          </div>
                        </RadioTile>
                      </Col>
                    ))}
                  </Row>
                </RadioTileGroup>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Structure;
