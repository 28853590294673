import { useState } from "react";
import Location from './Location'
import CheckInput from '../../../components/forms/CheckInput'
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import { useTranslation } from "react-i18next";

const DetailsGolf = ({ checked }) => {

    const { t } = useTranslation();

    const [checkedItems, setCheckedItems] = useState({});
    const [infos, setInfos] = useState({
        golf: "",
        classes: ""
    });

    const handleGolf = (value) => {
        setInfos({ ...infos, golf: value });
    };

    const handleClasses = (value) => {
        setInfos({ ...infos, classes: value });
    };


    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const type = [
        { title: t('golf_caddy'), value: "Caddy" },
        { title: t('golf_cart'), value: "Voiturette" },
        { title: t('Réservations à l’avance'), value: "RéservationsLavance" },
    ]

    const length = [
        { title: "9", value: 9 },
        { title: "18", value: 18 },
        { title: "27", value: 27 },
        { title: "36", value: 36 },
    ]

    const practice = [
        { title: t('golf_lessons'), value: "Leçons de golf" },
        { title: t('golf_store'), value: "Boutique Pro" },
        { title: t('golf_clubs'), value: "Clubs de golf" },
        { title: t('club_house'), value: "Club-house" },
        { title: t('golf_privileges'), value: "Privilèges" }
    ]


    return (
        <div className="my-3 fs-6">
            {checked === "Terrain de golf" && <>
                <Location onChange={handleGolf} />
                <Row className='fs-6 align-items-center'>
                    <Col md="6" className='d-flex align-items-center my-3'>
                        <span className='fw-medium text-dark'>{t('holes_count')}</span>
                    </Col>
                    <Col md="6" className='d-flex align-items-center my-3'>
                        <Select data={length} />
                    </Col>
                </Row>

                {type.map((item, indexOf) => (
                    <div key={indexOf} className='px-3 my-3'>
                        <div className='d-flex align-items-center'>
                            <CheckInput id={item.value} label={item.title} checked={checkedItems[item.value] || false} onChange={() => handleCheckboxChange(item.value)} />
                        </div>
                    </div>

                ))}

            </>}

            {checked === "Pratique de golf" && <>
                {practice.map((item, indexOf) => (
                    <div key={indexOf} className='my-3 px-3'>
                        <>

                            <div className='d-flex align-items-center'>
                                <CheckInput id={item.value} label={item.title} checked={checkedItems[item.value] || false} onChange={() => handleCheckboxChange(item.value)} />
                            </div>
                        </>
                        {checkedItems[item.value] && (
                            <>
                                {item.value === 'LeçonsGolf' && (
                                    <>
                                        <Location onChange={handleClasses} />
                                        <div className='px-3'>
                                            <CheckInput id="CoursGolfProfessionnel" label={t('pro_golf_lessons')} />
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>

                ))}
            </>}

        </div>
    )
}

export default DetailsGolf
