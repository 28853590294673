import React from 'react'
import Input from '../../../components/forms/Input'
import { Col, Row } from 'react-bootstrap'
import Annulation from './Annulation'

const Cancelpolicy = ({ t }) => {

    return (
        <>
            <Row className='d-flex flex-column'>
                <Col className='my-3'>
                    <p className='fs-6 fw-semibold'>{t('policy_config')}</p>
                    <Input placeholder={t('political_name')} type="politicalnumber" />
                </Col>
                <Col>
                    <div className='bg-white '>
                        <Annulation t={t} />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Cancelpolicy