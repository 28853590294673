import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import RoomOption from './RoomOption'
import ExtraCharge from '../../../components/ExtraCharge';
import CheckInput from '../../../components/forms/CheckInput';
import { useTranslation } from "react-i18next";

const Detailskitchen = () => {
    const { t } = useTranslation();
    const [infos, setInfos] = useState({
        kitchen: false,
        fees: false
    });

    const handleKitchen = () => {
        setInfos({ ...infos, kitchen: !infos.kitchen });
    };

    const handleFees = () => {
        setInfos({ ...infos, fees: !infos.fees });
    };


    const data = [
        { title: t('kitchen'), value: "Cuisine" },
        { title: t('kitchen_corner'), value: "CoinCuisine" },
        { title: t('shared_kitchen'), value: "CuisineCommune" },
    ]

    return (
        <div className='fs-6'>

            <div className='my-3'>
                <CheckInput id="kitchen" label={t('kitchen')} value={infos.kitchen} onChange={handleKitchen} />
            </div>

            {infos.kitchen && (
                <>
                    <RoomOption />
                    <Row className='align-items-center'>
                        <Col md="6" className='my-2'>
                            <span className='fw-medium text-dark'>{t('type_of_kitchen')}</span>
                        </Col>
                        <Col md="6" className='my-2'>
                            <Select data={data} />
                        </Col>
                    </Row>
                </>
            )}

            <div className='my-3'>
                <CheckInput id="kitchenfees" label={t('kitchen_fees')} value={infos.fees} onChange={handleFees} />
            </div>

            {infos.fees && (
                <ExtraCharge />
            )}
        </div>
    )
}

export default Detailskitchen