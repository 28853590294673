import React, { useState } from 'react'
import AmountValue from '../../../components/AmountValue'
import CheckInput from '../../../components/forms/CheckInput';

const Offer = ({t}) => {

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const data = [
    { title: "Chambre simple", value: "SingleRoom" },
    { title: "Chambre double", value: "DoubleRoom" },
    { title: "Suite", value: "Suite", },
  ];


  return (
    <>
      <div className="fs-4 fw-bold">{t('discount')}</div>
      <p className="fs-6 fw-light">
        {t('marketing_remise_txt')}
      </p>

      <div className='my-3'>
        <AmountValue t={t} />
      </div>


<div className='fs-5 fw-medium'>{t('marketing_remise_txt1')}</div>

        {data.map((check, index) => (
          <div key={index} className="my-3 fs-6">
              <CheckInput
                id={check.value}
                label={check.title}
                checked={checkedItems[check.value] || false}
                onChange={() => handleCheckboxChange(check.value)}
              />
          </div>
        ))}

    </>
  )
}

export default Offer
