import Inclusions from './Inclusions'
import Restrictions from './Restrictions'
import { Col, Row } from 'react-bootstrap'

const InclusionsAndRestrictions = ({ t }) => {
    return (
        <section className="container form-transition">
            <Row className="justify-content-center">
                <Col lg="8" className="my-3">
                    <h2 className="fs-3 lh-base fw-medium text-dark">{t('inclusions_and_restrcitions')}</h2>
                    <p className="fs-5 fw-light">{t('inclusions_and_restrcitions_caption')}</p>
                    <div className='my-4'>
                        <Inclusions />
                    </div>
                    <div className='my-4'>
                        <Restrictions />
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default InclusionsAndRestrictions
