import ManageHeader from "../../components/ManageHeader";
import { Megaphone } from "lucide-react";
import { Col, Row } from "react-bootstrap";
import Textarea from "../../components/forms/Textarea";
import DateOneInput from "../../components/forms/DateOneInput";
import React, { useState } from 'react';
import CheckInput from "../../components/forms/CheckInput";
import Select from "../../components/forms/Select";

const Promotions = ({ t }) => {
  const daysOfWeek = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedDays, setCheckedDays] = useState(daysOfWeek.reduce((acc, day) => {
    acc[day] = false;
    return acc;
  }, {}));

  const handleCheckboxChange = (event, day) => {
    const { checked } = event.target;

    if (day === 'Tout') {
      setIsCheckedAll(checked);
      const newCheckedDays = {};
      daysOfWeek.forEach(day => {
        newCheckedDays[day] = checked;
      });
      setCheckedDays(newCheckedDays);
    } else {
      const newCheckedDays = {
        ...checkedDays,
        [day]: checked
      };
      setCheckedDays(newCheckedDays);
      const allChecked = daysOfWeek.every(day => newCheckedDays[day]);
      setIsCheckedAll(allChecked);
    }
  };

  const data = [];

  for (let i = 5; i <= 50; i += 5) {
    data.push({ title: `${i}%`, value: i });
  }

  console.log(data);

  return (
    <section>
      <ManageHeader
        title={t('promotions')}
        content={t('promotions_caption')}
        icon={<Megaphone size={25} strokeWidth={1.5} />}
      />

      <div className="my-4 container">
        <Row className='justify-content-center'>
          <Col lg="10" xl="8">
            <div className='my-3'>
              <div className='fs-4 text-dark'>{t('special_offer_name')}</div>
              <p className='fs-6'>{t('special_offer_name_caption')}</p>
              <div className='my-3'>
                <Textarea rows={8} placeholder={t('special_offer_name_ex')} />
              </div>
            </div>

            <div className='my-3'>
              <div className='fs-4 text-dark'>{t('booking_date')}</div>
              <p className='fs-6'>{t('booking_date_caption')}</p>
              <div className='my-3'>
                <Row>
                  <Col md="6">
                    <DateOneInput placeholder={t('from')} />
                  </Col>
                  <Col md="6">
                    <DateOneInput placeholder={t('to')} />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="my-3">
              <div className='fs-4 text-dark'>{t('calendar')}</div>
              <p className='fs-6'>{t('calendar_caption')}</p>
              <div className="my-3">
                <div className="my-2">
                  <CheckInput checked={isCheckedAll} onChange={(e) => handleCheckboxChange(e, 'Tout')} label={t('all')} id="Tout" />
                </div>
                {daysOfWeek.map(day => (
                  <div className="my-2" key={day}>
                    <CheckInput
                      label={day}
                      id={day}
                      checked={checkedDays[day]}
                      onChange={(e) => handleCheckboxChange(e, day)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <Row className="align-items-end">
              <Col xl="8" className="my-3">
                <div className='fs-4 text-dark'>{t('discount')}</div>
                <p className='fs-6'>{t('discount_caption')}</p>
                <div className="mt-3">
                  <Select data={data} />
                </div>
              </Col>
              <Col xl="4" className="my-3">
                <button className="btn-blue p-4 w-100">{t('Create')}</button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Promotions;
