import { Microwave, Tv, Wifi, AirVent, Car, Dumbbell, Refrigerator, LampDesk, Bath, Webcam, Flame, RockingChair, Palmtree, ShowerHead, Utensils, Leaf, Mountain, Dices, SprayCan, Library, Gamepad2 } from "lucide-react";
import Icons from "../../../assets/icones/icones";
import { Row, Col } from "react-bootstrap";
import Checkbox from "../../../components/forms/Checkbox";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TbIroning3 } from "react-icons/tb";
import { PiCoatHangerLight, PiFanLight, PiThermometerHotLight, PiSwimmingPoolLight, PiFirstAidKitLight, PiCookingPotLight } from "react-icons/pi";

const Attributes = () => {

  const { t } = useTranslation();

  const [checkboxes, setCheckboxes] = useState([
    { id: 1, title: t('wifi'), isChecked: false, value: "Wifi", icon: <Wifi strokeWidth={1.2} size={25} /> },
    { id: 2, title: t('television'), isChecked: false, value: "Télévision", icon: <Tv strokeWidth={1.2} size={25} /> },
    { id: 3, title: t('microwave'), isChecked: false, value: "Micro ondes", icon: <Microwave strokeWidth={1.2} size={25} /> },
    { id: 4, title: t('washing_machine'), isChecked: false, value: "Lave-linge", icon: <Icons name="washing" strokeWidth={1.2} size={25} /> },
    { id: 5, title: t('parking'), isChecked: false, value: "Parking", icon: <Car strokeWidth={1.2} size={25} /> },
    { id: 6, title: t('air_conditioning'), isChecked: false, value: "Climatisation", icon: <AirVent strokeWidth={1.2} size={25} /> },
    { id: 7, title: t('workspace'), isChecked: false, value: "Espace de travail", icon: <LampDesk strokeWidth={1.2} size={25} /> },
    { id: 8, title: t('pool'), isChecked: false, value: "Piscine", icon: <PiSwimmingPoolLight strokeWidth={1.2} size={25} /> },
    { id: 9, title: t('jacuzzi'), isChecked: false, value: "Jacuzzi", icon: <Bath strokeWidth={1.2} size={25} /> },
    { id: 10, title: t('fridge'), isChecked: false, value: "Réfrigérateur", icon: <Refrigerator strokeWidth={1.2} size={25} /> },
    { id: 11, title: t('barbecue'), isChecked: false, value: "Barbecue", icon: <Flame strokeWidth={1.2} size={25} /> },
    { id: 12, title: t('air_meal'), isChecked: false, value: "Repas en plein-air", icon: <RockingChair strokeWidth={1.2} size={25} /> },
    { id: 13, title: t('piano'), isChecked: false, value: "Piano", icon: <Icons name="piano" strokeWidth={1.2} size={25} /> },
    { id: 14, title: t('sport_equip'), isChecked: false, value: "Appareils de sport", icon: <Dumbbell strokeWidth={1.2} size={25} /> },
    { id: 15, title: t('lake_access'), isChecked: false, value: "Accés au lac", icon: <Icons name="tent_tree" strokeWidth={1.2} size={25} /> },
    { id: 16, title: t('beach_access'), isChecked: false, value: "Accés à la plage", icon: <Palmtree strokeWidth={1.2} size={25} /> },
    { id: 17, title: t('ski_access'), isChecked: false, value: "Accessible au ski", icon: <Icons name="ski" strokeWidth={1.2} size={25} /> },
    { id: 18, title: t('bath_ext'), isChecked: false, value: "Douche extérieur", icon: <ShowerHead strokeWidth={1.2} size={25} /> },
    { id: 19, title: t('smoke_detector'), isChecked: false, value: "Détécteur de fumée", icon: <Icons name="alarm_smoke" strokeWidth={1.2} size={25} /> },
    { id: 20, title: t('health_kit'), isChecked: false, value: "Kit de premiers secours", icon: <PiFirstAidKitLight strokeWidth={1.2} size={25} /> },
    { id: 21, title: t('extinguisher'), isChecked: false, value: "Extincteur", icon: <Icons name="extinguisher" strokeWidth={1.2} size={25} /> },
    { id: 22, title: t('security_cameras'), isChecked: false, value: "Caméras de sécurité", icon: <Webcam strokeWidth={1.2} size={25} /> },
    { id: 23, title: t('hanger'), isChecked: false, value: "Cintres", icon: <PiCoatHangerLight strokeWidth={1.2} size={25} /> },
    { id: 24, title: t('iron'), isChecked: false, value: "Fer à repasser", icon: <TbIroning3 strokeWidth={1.2} size={25} /> },
    { id: 25, title: t('fan'), isChecked: false, value: "Ventilateur", icon: <PiFanLight strokeWidth={1.2} size={25} /> },
    { id: 26, title: t('heating'), isChecked: false, value: "Chauffage", icon: <PiThermometerHotLight strokeWidth={1.2} size={25} /> },
    { id: 27, title: t('kitchen'), isChecked: false, value: "Cuisine", icon: <PiCookingPotLight strokeWidth={1.2} size={25} /> },
    { id: 28, title: t('kithen_dishes'), isChecked: false, value: "Vaisselle", icon: <Utensils strokeWidth={1.2} size={25} /> },
    { id: 29, title: t('garden'), isChecked: false, value: "Jardin", icon: <Leaf strokeWidth={1.2} size={25} /> },
    { id: 30, title: t('mountain_view'), isChecked: false, value: "Vue sur la montagne", icon: <Mountain strokeWidth={1.2} size={25} /> },
    { id: 31, title: t('society_games'), isChecked: false, value: "Jeux de societés", icon: <Dices strokeWidth={1.2} size={25} /> },
    { id: 32, title: t('care_products'), isChecked: false, value: "Produits de soins personnels", icon: <SprayCan strokeWidth={1.2} size={25} /> },
    { id: 34, title: t('books'), isChecked: false, value: "Livres", icon: <Library strokeWidth={1.2} size={25} /> },
    { id: 35, title: t('entr_title6'), isChecked: false, value: "COnsole de jeux", icon: <Gamepad2 strokeWidth={1.2} size={25} /> },
  ]);

  const toggleCheckbox = (id) => {
    setCheckboxes((prevState) => prevState.map((checkbox) => (checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox)));
  };

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('give_product_equip')}</h2>
              <p className="fs-5 fw-light">{t('product_equip_text')}</p>

              <div className="my-3">
                <Row>
                  {checkboxes.map((checkbox, index) => (
                    <Col key={index} xs="6" sm="4" md="4" className="my-2">
                      <Checkbox key={checkbox.id} id={checkbox.id} isChecked={checkbox.isChecked} value={checkbox.value} onChange={toggleCheckbox} title={checkbox.title} icon={checkbox.icon} />
                    </Col>
                  ))}
                </Row>
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Attributes;
