import React from "react";
import Input from "../../../components/forms/Input";
import { Col, Row } from "react-bootstrap";
import { Check, ListFilter, Pen, X } from "lucide-react";

const Users = ({ data, t }) => {
  return (
    <div className="border">
      <div className="d-flex justify-content-between align-items-center p-3">
        <span className="fs-5 fw-medium text-blue">{t('messages')}</span>
        <span className="d-flex text-dark">
          <span
            role="button"
            title={t('filtrer')}
            className="fs-5 fw-medium border rounded-circle d-flex justify-content-center align-items-center mx-1"
            style={{ width: "40px", height: "40px" }}
          >
            <ListFilter size={15} />
          </span>

          <span
            role="button"
            title={t('manage')}
            className="fs-5 fw-medium  border rounded-circle d-flex justify-content-center align-items-center mx-1"
            style={{ width: "40px", height: "40px" }}
          >
            <Pen size={15} />
          </span>
        </span>
      </div>
      <hr className="border-secondary m-0" />
      <div className="p-3">
        <Input type="text" placeholder={t('name_or_booking_number')} />
      </div>
      <hr className="border-secondary m-0" />
      <div className="p-3">
        {data.map((user) => (
          <Row className="my-4">
            <Col xl="3">
              <div
                className={`bg-dark fs-5 fw-medium rounded-circle d-flex justify-content-center align-items-center ${user.gender === "m" ? "bg-primary-subtle" : "bg-danger-subtle"
                  }`}
                style={{ width: "60px", height: "60px" }}
              >
                {user.name.slice(0, 1)}
              </div>
            </Col>
            <Col xl="6">
              <div className="fs-5 fw-medium text-dark ">{user.name}</div>
              <p className="fw-light">{user.message.slice(0, 20)} ...</p>
            </Col>

            <Col xl="3">
              <div className="w-100 d-flex flex-column align-items-end">
                <div className="fs-6">{user.date}</div>
                {user.booking ? (
                  <div
                    className="mt-1 d-flex justify-content-center align-items-center bg-green text-white rounded-circle"
                    style={{ width: "20px", height: "20px" }}
                  >
                    <Check size={10} />
                  </div>
                ) : (
                  <div
                    className="mt-1 d-flex justify-content-center align-items-center bg-red text-white rounded-circle"
                    style={{ width: "20px", height: "20px" }}
                  >
                    <X size={10} />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default Users;
