import React from 'react'
import { useState } from 'react';
import RadioInput from '../../../components/forms/RadioInput'
import DateYear from '../../../components/DateYear'
import { useTranslation } from "react-i18next";

const Period = () => {
    const { t } = useTranslation();
    const [infos, setInfos] = useState({
        period: "Allyear"
    });

    const handlePeriod = (value) => {
        setInfos({ ...infos, period: value });
    };

    return (
        <div className="my-3">
            <div className="d-flex fs-6">
                <div className="mx-2">
                    <RadioInput id="Allyear" name="DepositPeriod" value="Allyear" label={t('all_year')} onChange={() => handlePeriod("Allyear")} />
                </div>

                <div className="mx-2">
                    <RadioInput id="period" name="DepositPeriod" value="period" label={t('period')} onChange={() => handlePeriod("period")} />
                </div>
            </div>

            {infos.period === "period" && (
                <DateYear />
            )}
        </div>
    )
}

export default Period