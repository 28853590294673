import { Row, Col } from "react-bootstrap";
import { RadioTile, RadioTileGroup } from "rsuite";
import { Bed, Home, Users2 } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Privacy = () => {

  const { t } = useTranslation();
  const [infos, setInfos] = useState({
    choice: "",
  });

  const handleChoice = (value) => {
    setInfos({ ...infos, choice: value });
  };

  const data = [
    {
      title: t('entire_rental'),
      icon: <Home strokeWidth={1.3} size={30} />,
      value: "LogementEniter",
      content: t('entire_rental_text'),
    },
    {
      title: t('single_room'),
      icon: <Bed strokeWidth={1.3} size={30} />,
      value: "UneChambre",
      content: t('signle_room_text'),
    },
    {
      title: t('shared_room'),
      icon: <Users2 strokeWidth={1.3} size={30} />,
      value: "ChambrePartagée",
      content: t('shared_room_text'),
    },
  ];
  

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="9" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('rental_type_for_travelers')}</h2>
              <p className="fs-5 fw-light">{t('rental_type_for_travelers_caption')}</p>
              <RadioTileGroup>
                <Row className="mt-3">
                  {data.map((choice, index) => (
                    <Col key={index} xs="12" className="my-2">
                      <RadioTile onChange={handleChoice} value={choice.value} className={`h-100 rounded-4  p-3 ${infos.choice === choice.value ? "border-dark" : "border"}`}>
                        <div className="text-dark d-flex">
                          {choice.icon}
                          <span className="px-3">
                            <div className="fs-6 fw-medium">{choice.title}</div>
                            <p className="fw-light">{choice.content}</p>
                          </span>
                        </div>
                      </RadioTile>
                    </Col>
                  ))}
                </Row>
              </RadioTileGroup>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Privacy;
