import React from 'react'
import { Col, Row } from 'react-bootstrap'
import TagIcon from '@rsuite/icons/Tag';
import GrowthIcon from '@rsuite/icons/Growth';
import EditIcon from '@rsuite/icons/Edit';

const Steps = ({t}) => {
  return (
    <div className='py-5'>
      <Row>
        <Col lg="7" xl="6" className='pe-5 d-flex flex-column justify-content-between my-1'>
            <div className='fs-1 fw-bold'>{t('start_process')}</div>
            <p className='fs-5 fw-light'>{t('process_caption')}</p>
            <div className='mt-3'>
                <img src='https://images.pexels.com/photos/4467748/pexels-photo-4467748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt="Figure de fléshes pour étapes" width="100%" height="100%" />
            </div>
           
        </Col>
        <Col lg="5" xl="6" className='my-1'>
            <Row className='justify-content-between align-items-center'>
                <Col xs="6" className='my-2'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-blue'><EditIcon width={40} height={40} /></span> <span className='fw-bold' style={{fontSize:"450%",color:"white",WebkitTextStroke:"1px #212529"}}>01</span>
                    </div>
                    <div className='fs-5 fw-medium'>{t('register')}</div>
                    <p className='fs-6 fw-light'>{t('register_caption')}</p>
                </Col>
                <Col xs="3" className='my-2'>
                    <img src='https://st.ourhtmldemo.com/new/Udrive/assets/images/shape/shape-20.png' alt="Figure de fléshes pour étapes" width="100%" height="100%" />
                </Col>
            </Row>

            <Row className='justify-content-between align-items-center'>
                <Col xs="3" className='my-2'>
                    <img src='https://st.ourhtmldemo.com/new/Udrive/assets/images/shape/shape-21.png' alt="Figure de fléshes pour étapes" width="100%" height="100%" />
                </Col>
                <Col xs="6" className='my-2'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-blue'><GrowthIcon width={40} height={40} /></span> <span className='fw-bold' style={{fontSize:"450%",color:"white",WebkitTextStroke:"1px #212529"}}>02</span>
                    </div>
                    <div className='fs-5 fw-medium'>{t('create')}</div>
                    <p className='fs-6 fw-light'>{t('create_caption')}</p>
                </Col>
            </Row>

            <Row className='justify-content-between align-items-center'>
                <Col xs="6" className='my-2'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-blue'><TagIcon width={40} height={40} /></span> <span className='fw-bold' style={{fontSize:"450%",color:"white",WebkitTextStroke:"1px #212529"}}>03</span>
                    </div>
                    <div className='fs-5 fw-medium'>{t('publish')}</div>
                    <p className='fs-6 fw-light'>{t('publish_caption')}</p>
                </Col>
                <Col xs="3" className='my-2'>
                    <img src='https://st.ourhtmldemo.com/new/Udrive/assets/images/shape/shape-20.png' alt="Figure de fléshes pour étapes" width="100%" height="100%" />
                </Col>
            </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Steps
