import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import DateYear from "../../../components/DateYear";
import Schedules from "../../../components/Schedules";
import RadioInput from "../../../components/forms/RadioInput";
import { useTranslation } from "react-i18next";

const DetailsPool = ({ checked }) => {
  const { t } = useTranslation();

  const [infos, setInfos] = useState({
    period: "",
    pool: "no",
    schedules: "",
  });

  const handlePeriod = (value) => {
    setInfos({ ...infos, period: value });
  };


  const handleSchedules = (value) => {
    setInfos({ ...infos, schedules: value });
  };


  const pools_length = Array.from({ length: 15 }, (_, index) => ({
    title: `${index + 1}`,
    value: index + 1,
  }));


  const amount = [
    { title: t('free'), value: "Gratuit" },
    { title: t('supplement'), value: "En supplément" },
  ];

  const months = [
    { title: t('january'), value: "Janvier" },
    { title: t('february'), value: "Février" },
    { title: t('march'), value: "Mars" },
    { title: t('april'), value: "Avril" },
    { title: t('may'), value: "Mai" },
    { title: t('june'), value: "Juin" },
    { title: t('july'), value: "Juillet" },
    { title: t('august'), value: "Août" },
    { title: t('september'), value: "Septembre" },
    { title: t('october'), value: "Octobre" },
    { title: t('november'), value: "Novembre" },
    { title: t('december'), value: "Décembre" }
  ];

  return (
    <div className="my-3 fs-6">
      {checked === "OuverteEnSaison" && (
        <>
          <div className="d-flex align-items-center fs-6">
            <div className="mx-3">
              <RadioInput name="PoolPeriod" id="Périod" label={t('period')} value="Périod" onChange={() => handlePeriod("Périod")} />
            </div>
            <div className="mx-3">
              <RadioInput name="PoolPeriod" id="Mois" label={t('month')} value="Mois" onChange={() => handlePeriod("Mois")} />
            </div>
          </div>

          {infos.period === "Périod" ? (
            <DateYear />
          ) : (
            <Row className="align-items-center">
              <Col md="6" className="d-flex align-items-center my-3">
                <div className="d-flex flex-column w-100">
                  <span className="fw-medium">{t('start_month')}</span>
                  <Select data={months} />
                </div>
              </Col>

              <Col md="6" className="d-flex align-items-center my-3">
                <div className="d-flex flex-column w-100">
                  <span className="fw-medium">{t('end_month')}</span>
                  <Select data={months} />
                </div>
              </Col>
            </Row>
          )}
        </>
      )}

      {checked === "Horraires" && (
        <>
          <div className="d-flex align-items-center fs-6">
            <div className="mx-3">
              <RadioInput name="PoolSchedules" id="24Heures" label={t('24h')} value="24Heures" onChange={() => handleSchedules("24Heures")} />
            </div>
            <div className="mx-3">
              <RadioInput name="PoolSchedules" id="CréneauxHoraires" label={t('time_slots')} value="CréneauxHoraires" onChange={() => handleSchedules("CréneauxHoraires")} />
            </div>
          </div>

          {infos.schedules === "CréneauxHoraires" && <Schedules />}
        </>
      )}

      {["OuverteTouteLannee", "Couverte", "BarEnBord", "Bar", "BainARemous"].includes(checked) && (
        <Row className="fs-6 align-items-center">
          <Col md="6" className="d-flex align-items-center my-3">
            <span className="fw-medium text-dark">{t('how_many')}</span>
          </Col>
          <Col md="6" className="d-flex align-items-center my-3">
            <Select data={pools_length} />
          </Col>
        </Row>
      )}

      {["Parc", "Cabines"].includes(checked) && (
        <Row className="fs-6 align-items-center">
          <Col md="6" className="d-flex align-items-center my-3">
            <span className="fw-medium text-dark">{t('cost')}</span>
          </Col>
          <Col md="6" className="d-flex align-items-center my-3">
            <Select data={amount} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DetailsPool;
