import React, { useState } from "react"
import { Row, Col, Button, DatePicker, Table } from "rsuite"
import { Form } from "react-bootstrap"
import { FaArrowTrendUp } from "react-icons/fa6"
import { RiMoneyDollarCircleFill } from "react-icons/ri"
import { RiMoneyDollarCircleLine } from "react-icons/ri"
import { Link } from 'react-router-dom'
import { FiEye } from "react-icons/fi"
import { LuDownloadCloud } from "react-icons/lu"
import MAFacture from "../../assets/slide/facture.pdf"
import ManageHeader from "../../components/ManageHeader"
import { Banknote } from "lucide-react"
import Input from "../../components/forms/Input"
import DateInput from "../../components/forms/DateInput"
import Select from "../../components/forms/Select"

const { Column, HeaderCell, Cell } = Table;

const Facture = ({ t }) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleModifierClick = () => {
        setShowSecondModal(false);
        setShowModal(true);
    }
    const [showThirdModal, setShowThirdModal] = useState(false);

    const handleOpenThirdModal = () => {
        setShowSecondModal(false);
        setShowThirdModal(true);
    };

    const handleOpenDatePicker = () => {
        setShowDatePicker(true);
    };

    const handleCloseDatePicker = () => {
        setShowDatePicker(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        handleCloseDatePicker();
    };

    const [showModal, setShowModal] = useState(false);

    const handleConfirmDeactivate = () => {
        setShowModal(false);
        handleShowSuccessMessage();
    };

    const handleShowSuccessMessage = () => {
        setShowSuccessMessage(true);
    };

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleEditButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [bookings, setBookings] = useState([
        { id: 1, numerofacture: "4953455341", nomclient: "DEVRIM GECEGEZER", datecreation: "2024-05-15", montant: "EUR 57.49", status: "payé", date: "2024-05-20", typefacture: "23834017" },
        { id: 2, numerofacture: "8573455341", nomclient: "Sam Escoffier", datecreation: "2024-05-16", montant: "EUR 25.55", status: "En attente", date: "2024-05-21", typefacture: "98524017" },
        { id: 3, numerofacture: "5847455341", nomclient: "Dalida Arbi", datecreation: "2024-05-17", montant: "EUR 65.15", status: "payé", date: "2024-05-22", typefacture: "23834017" },
        { id: 4, numerofacture: "4953455341", nomclient: "Kashier Kash", datecreation: "2024-05-15", montant: "EUR 1.24", status: "Annulé", date: "2024-05-20", typefacture: "23834017" },
        { id: 5, numerofacture: "8573455341", nomclient: "Eminem Dall", datecreation: "2024-05-16", montant: "EUR 158.47", status: "En attente", date: "2024-05-21", typefacture: "98754017" },
        { id: 6, numerofacture: "5847455341", nomclient: "Rozy Rosa", datecreation: "2024-05-17", montant: "EUR 48.87", status: "payé", date: "2024-05-22", typefacture: "96584017" },
        { id: 7, numerofacture: "4953455341", nomclient: "DEVRIM GECEGEZER", datecreation: "2024-05-15", montant: "EUR 57.9", status: "payé", date: "2024-05-20", typefacture: "11134017" },
        { id: 8, numerofacture: "8573455341", nomclient: "Sam Escoffier", datecreation: "2024-05-16", montant: "EUR 12.58", status: "En attente", date: "2024-05-21", typefacture: "23834017" },
        { id: 9, numerofacture: "5847455341", nomclient: "Dalida Arbi", datecreation: "2024-05-17", montant: "EUR 5.49", status: "payé", date: "2024-05-22", typefacture: "85834017" },
        { id: 10, numerofacture: "4953455341", nomclient: "Kashier Kash", datecreation: "2024-05-15", montant: "EUR 7.49", status: "Annulé", date: "2024-05-20", typefacture: "23834017" },
        { id: 11, numerofacture: "8573455341", nomclient: "Eminem Dall", datecreation: "2024-05-16", montant: "EUR EUR 9.68", status: "En attente", date: "2024-05-21", typefacture: "23834087" },
        { id: 12, numerofacture: "5847455341", nomclient: "Rozy Rosa", datecreation: "2024-05-17", montant: "33.83", status: "payé", date: "2024-05-22", typefacture: "23834017" }
    ]);

    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleSelectAll = () => {
        const newCheckedItems = {};
        bookings.forEach((booking, index) => {
            newCheckedItems[index] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (index) => {
        const newCheckedItems = { ...checkedItems, [index]: !checkedItems[index] };
        setCheckedItems(newCheckedItems);

        const allChecked = Object.values(newCheckedItems).every((value) => value);
        setSelectAll(allChecked);
    };

    const handleSearch = () => {
        const filteredBookings = bookings.filter((booking) => {
            if (!startDate || !endDate) return true;
            const bookingArrivalDate = new Date(booking.arrival);
            const bookingDepartureDate = new Date(booking.departure);
            return bookingArrivalDate >= startDate && bookingDepartureDate <= endDate;
        });
        return filteredBookings;
    };

    const [showSecondModal, setShowSecondModal] = useState(false);
    const handleOpenSecondModal = () => {
        setShowModal(false);
        setShowSecondModal(true);
    };

    const handleCloseSecondModal = () => {
        setShowSecondModal(false);
    };
    const downloadPDF = () => {
        const link = document.createElement('a');
        link.href = MAFacture;
        link.download = 'facture.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const data = [
        { title: "Payée", value: "payed" },
        { title: "En attente", value: "pounding" },
        { title: "Annulée", value: "cancelled" },
    ]

    return (
        <section className="mb-4">
            <ManageHeader
                title={t('ho_act_paiement')}
                content={t('ho_act_paiement_txt')}
                icon={<Banknote size={25} strokeWidth={1.5} />}
            />
            <div className="container p-4">


                <Row className="d-flex flex-row">
                    <Col className="col-4">
                        <div className="p-4 d-flex flex-column border-end">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="fw-bolder fs-6">{t('ho_act_paiement_revenus')}</span>
                                <span className="rounded fs-4" style={{ color: '#8b74f9' }}><RiMoneyDollarCircleLine /></span>
                            </div>

                            <div className="mb-1 fw-bolder fs-4" style={{ color: '#8b74f9' }}>325 EUR</div>
                            <div className="mb-1 fw-medium fs-7" style={{ color: '#8b74f9' }}>+2.25 <FaArrowTrendUp /></div>
                            <div className=" fw-medium "><span>{t('ho_act_paiement_year')}</span></div>
                        </div>
                    </Col>
                    <Col className="col-4">
                        <div className="p-4 d-flex flex-column border-end">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="fw-bolder fs-6">{t('ho_act_paiement_revenus')}</span>
                                <span className="rounded fs-4" style={{ color: '#29c763' }}><RiMoneyDollarCircleFill /></span>
                            </div>

                            <div className="mb-1 fw-bolder fs-4" style={{ color: '#29c763' }}>932 EUR</div>
                            <div className="mb-1 fw-medium fs-7" style={{ color: '#29c763' }}>+7.5 <FaArrowTrendUp /></div>
                            <div className=" fw-medium "><span>{t('ho_act_paiement_Epoque')}</span></div>
                        </div>
                    </Col>
                    <Col className="col-4">
                        <div className="p-4 d-flex flex-column rounded">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="fw-bolder fs-6">{t('ho_act_paiement_tarifs')}</span>
                                <span className="rounded fs-4" style={{ color: '#f97316' }}><RiMoneyDollarCircleLine /></span>
                            </div>

                            <div className="mb-1 fw-bolder fs-4" style={{ color: '#f97316' }}>83 EUR </div>
                            <div className="mb-1 fw-medium fs-7" style={{ color: '#f97316' }}>+6.0 <FaArrowTrendUp /></div>
                            <div className=" fw-medium "><span>{t('ho_act_paiement_12')}</span></div>
                        </div>
                    </Col>
                </Row>

                <>
                    <div className="my-2"><span className="fs-5 p-2 fw-bolder">{t('ho_act_paiement_watch')}</span></div>
                    <Row className="p-1 d-flex flex-row">
                        <Col className="col-8">
                            <div style={{ backgroundColor: '#f2f0fe' }} className="p-4 d-flex flex-column rounded">
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="fw-bolder fs-6">{t('ho_act_paiement_ledernier')}</span>
                                    <span className="p-1 rounded text-white" style={{ backgroundColor: '#8b74f9' }}></span>
                                </div>
                                <div className="mb-3 fw-bolder fs-5" style={{ color: '#8b74f9' }}>497,84 EUR</div>
                                <Row className="d-flex flex-row justify-content-between">
                                    <Col className="col-6">
                                        <div className="mb fw-medium fs-7" >{t('ho_act_paiement_datepayment')}</div>
                                        <div className=" fw-Bolder text-dark"><span>06 Juin 2024</span></div>
                                    </Col>
                                    <Col className="col-6 justify-content-end">
                                        <div className="mb fw-medium fs-7" >{t('ho_act_paiement_compte')}</div>
                                        <div className=" fw-Bolder text-dark"><span>{t('ho_act_paiement_compte_txt')}</span></div>
                                    </Col>

                                </Row>
                            </div>
                        </Col>
                        <Col className="col-4">
                            <Link to="/myhotel/Demanderpayment" >
                                <div style={{ backgroundColor: '#e8f9ee' }} className="p-4 d-flex flex-column rounded">
                                    <div className="d-flex flex-row justify-content-between">
                                        <span className="fw-bolder fs-6">{t('ho_act_paiement_demander')}</span>
                                        <span className="p-1 rounded text-white" style={{ backgroundColor: '#29c763' }}></span>
                                    </div>

                                    <div className="mb-1 fw-bolder fs-4" style={{ color: '#29c763' }}>$ 165.21k </div>
                                    <div className="mb-1 fw-medium fs-7" style={{ color: '#29c763' }}>+8.5 <FaArrowTrendUp /></div>
                                    <div className=" fw-medium "><span>{t('ho_act_paiement_attente')}</span></div>
                                </div>
                            </Link>
                        </Col>

                    </Row>
                </>

                <Row className="my-3">
                    <Col className="my-3 col-xl-4">
                        <Input placeholder={t('ho_act_facturation_num')} type="text" />
                    </Col>
                    <Col className="my-3 col-xl-3">
                        <DateInput leftLabel={t('from')} rightLabel={t('to')} />
                    </Col>
                    <Col className="my-3  col-xl-3">
                        <Select data={data} />
                    </Col>
                    <Col className="my-3  col-xl-2">
                        <button className="btn-blue w-100 p-4" onClick={handleSearch}>{t('filtrer')}</button>
                    </Col>
                </Row>



                <>


                    <div className="my-1">
                        <Table height={400} data={bookings} autoHeight className="w-100">
                            <Column width={40}>
                                <HeaderCell>
                                    <input
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                </HeaderCell>
                                <Cell>
                                    {(rowData, rowIndex) => (
                                        <input
                                            type="checkbox"
                                            checked={checkedItems[rowIndex]}
                                            onChange={() => handleCheckboxChange(rowIndex)}
                                        />
                                    )}
                                </Cell>
                            </Column>
                            <Column width={120} >
                                <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_facturation_num')}</HeaderCell>
                                <Cell dataKey="numerofacture" />
                            </Column>
                            <Column flexGrow={1} sortable>
                                <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_reservation_det_nom_client')}</HeaderCell>
                                <Cell dataKey="nomclient" />
                            </Column>
                            <Column width={125}>
                                <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_paiement_date_demande')}</HeaderCell>
                                <Cell dataKey="datecreation" />
                            </Column>
                            <Column >
                                <HeaderCell className="fs-6 fw-bold text-dark">{t('amount')}</HeaderCell>
                                <Cell dataKey="montant" />
                            </Column>

                            <Column width={160}>
                                <HeaderCell className="fs-6 fw-bold text-dark text-center">{t('status')}</HeaderCell>
                                <Cell dataKey="status">
                                    {(rowData) => {
                                        let backgroundColor = '';
                                        let color = '';

                                        switch (rowData.status) {
                                            case 'payé':
                                                backgroundColor = '#e8f9ee';
                                                color = '#29c763';
                                                break;
                                            case 'Annulé':
                                                backgroundColor = '#fef0e7';
                                                color = '#f97316';
                                                break;
                                            case 'En attente':
                                                backgroundColor = '#f2f0fe';
                                                color = '#8b74f9';
                                                break;
                                            default:
                                                backgroundColor = '#f3f6f9';
                                                color = '#000000';
                                        }

                                        return (
                                            <div className="p-1 rounded-4 text-center" style={{ marginTop: '-5px', backgroundColor, color }}>
                                                {rowData.status}
                                            </div>
                                        );
                                    }}
                                </Cell>
                            </Column>
                            <Column width={125} >
                                <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_paiement_date_demande')}</HeaderCell>
                                <Cell dataKey="date" className="" />
                            </Column>

                            <Column width={125} >
                                <HeaderCell className="fs-6 fw-bold text-dark">{t('ho_act_paiement_suivipa')}</HeaderCell>
                                <Cell dataKey="typefacture" className="" />
                            </Column>

                            <Column width={120}  >
                                <HeaderCell className="fs-6 fw-bold text-dark">{t('actions')}</HeaderCell>
                                <Cell>
                                    {(rowData) => (
                                        <div className="d-flex flex-row  align-items-center" style={{ marginTop: '-5px' }} >

                                            <Link to="/myhotel/Facturedetails" >
                                                <Button className="border px-3 me-1 rounded">
                                                    <FiEye />
                                                </Button>
                                            </Link>

                                            <Button className="border px-3 me-1 rounded" onClick={downloadPDF}>
                                                <LuDownloadCloud />
                                            </Button>

                                        </div>
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                    </div>
                </>
            </div>


        </section>
    );
};

export default Facture;
