import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsFacilities = ({ checked }) => {

  const { t } = useTranslation();
  
  const [infos, setInfos] = useState({
    phoneCalls: false,
    equipments: "no",
  });

  const handlePhoneCalls = () => {
    setInfos({ ...infos, phoneCalls: !infos.phoneCalls });
  };

  const washing = [
    { title: t("washer_dryer"), value: "washer" },
    { title: t("washing_machine"), value: "washing" },
    { title: t("dryer"), value: "dryer" },
    { title: t("2_in_1_washing_machine_and_dryer"), value: "washerAndDryer" },
  ];

  const phone = [
    { title: t("free_international_calls"), value: "AppelsInternationaux" },
    { title: t("free_local_calls"), value: "AppelsLocaux" },
    { title: t("free_long_distance_calls"), value: "AppelsLongueDistance" },
  ];

  const speed = [
    { title: t("3g"), value: "3G" },
    { title: t("4g"), value: "4G" },
    { title: t("4g_lte"), value: "4G LTE" },
    { title: t("5g"), value: "5G" },
  ];

  const safe = [
    { title: t("inroom_safe"), value: "CoffreFortDansLaChambre" },
    { title: t("safe_for_laptops"), value: "CoffreFortAdaptéAuxordinateurs" },
    { title: t("extra_safe"), value: "CoffreFortEnSupplément" },
  ];

  const use = [
    { title: t("limited"), value: "Limitée" },
    { title: t("unlimited"), value: "Illimitée" },
  ];

  return (
    <div className="my-3 fs-6">
      {checked === "MachineALaver" && (
        <Row className="fs-6 align-items-center">
          <Col md="6" className="d-flex align-items-center my-3">
            <span className="fw-medium text-dark">
              {t("availability")}
            </span>
          </Col>
          <Col md="6" className="d-flex align-items-center my-3">
            <Select data={washing} />
          </Col>
        </Row>
      )}

      {checked === "CoffreFort" && (
        <Row className="fs-6 align-items-center">
          <Col md="6" className="d-flex align-items-center my-3">
            <span className="fw-medium text-dark">
              {t("availability")}
            </span>
          </Col>
          <Col md="6" className="d-flex align-items-center my-3">
            <Select data={safe} />
          </Col>
        </Row>
      )}

      {checked === "Téléphone" && (
        <div className="px-3">
          {phone.map((item, index) => (
            <div className="my-3">
              <CheckInput
                key={index}
                id={item.value}
                label={item.title}
              />
            </div>
          ))}
        </div>
      )}

      {checked === "Smartphone" && (
        <>
          <Row className="fs-6 align-items-center">
            <Col
              md="6"
              className="d-flex align-items-center my-3"
            >
              <div className="d-flex flex-column w-100">
                <span className="fw-medium">
                  {t("data_speed")}
                </span>
                <Select data={speed} />
              </div>
            </Col>
            <Col
              md="6"
              className="d-flex align-items-center my-3"
            >
              <div className="d-flex flex-column w-100">
                <span className="fw-medium">
                  {t("use_of_data")}
                </span>
                <Select data={use} />
              </div>
            </Col>
          </Row>
          <div className="px-3">
            <CheckInput
              id="Appels"
              value={infos.phoneCalls}
              label={t("phone_calls")}
              onChange={handlePhoneCalls}
            />

            {infos.phoneCalls && (
              <div className="my-3">
                <Select data={phone} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DetailsFacilities;
