import { useState, useEffect } from "react";
import { ArrowLeft, ArrowRight, Dot } from "lucide-react";
import { useTranslation } from "react-i18next";

const ImageItem = () => {

  const { t } = useTranslation();

  const images = [
    { id: 1, title: t('best_way_to_handle'), img: "https://images.pexels.com/photos/4467687/pexels-photo-4467687.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" },
    { id: 2, title: t('trips_deserve_to_be_seen'), img: "https://images.pexels.com/photos/4342493/pexels-photo-4342493.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" },
    { id: 3, title: t('boost_you_sales'), img: "https://images.pexels.com/photos/4343451/pexels-photo-4343451.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(goToNextSlide, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const slideStyles = {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    height: "600px",
    maxHeight: "600px",
    minHeight: "600px",
  };

  const slideContainerStyles = {
    display: "flex",
    transition: "transform 0.5s ease",
    transform: `translateX(-${currentIndex * 100}%)`,
  };

  const imageStyles = {
    maxWidth: "100%",
    height: "auto",
    filter: "brightness(70%)",
  };

  return (
    <div style={slideStyles}>
      <div style={slideContainerStyles}>
        {images.map((image) => (
          <div key={image.id} style={{ width: "100%", flexShrink: 0 }} className="position-relative">
            <img width="100%" height="100%" src={image.img} alt={image.title} style={imageStyles} />
            <div className="position-absolute top-0 left-0 d-flex justify-content-center w-100 h-100">
              <p className="fs-1 p-5">{image.title}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between align-items-center w-100" style={{ position: "absolute", bottom: "0" }}>
        <div className="bg-white p-3 rounded-top-4">
          <button className="text-blue p-3 rounded-4 px-4" onClick={goToPrevSlide}>
            <ArrowLeft />
          </button>
        </div>
        <div className="d-flex justify-content-around align-items-center">
          {images.map((_, index) => (
            <Dot key={index} size={`${currentIndex === index ? 60 : 30}`} />
          ))}
        </div>
        <div className="bg-white p-3 rounded-top-4">
          <button className="bg-blue p-3 rounded-4 px-5" onClick={goToNextSlide}>
            <ArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageItem;
