import { AlarmClock, CalendarDays } from 'lucide-react';
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const EventItem = () => {

    const { t } = useTranslation();

    const today = new Date();
    const formattedDate = today.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: 'short',
        year: '2-digit'
    });


    return (
        <div className="rounded p-3 border h-100">
            <Row className='justify-content-center'>
                <Col xs="11" className='my-2'>
                    <Row className='justify-content-center'>
                        <Col xs="5" lg="4" xl="5">
                            <img width="100%" height="100%" src='https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/girl-with-laptop.png' alt="Illustration d'un office manager" />
                        </Col>
                    </Row>
                </Col>
                <Col xl="11" className='my-2'>
                    <div className='fs-5 fw-medium text-dark'>
                        {t('add_your_product')}
                    </div>
                    <p className='fs-6'>{t('add_text')}</p>
                </Col>
                <Col xl="11" className='my-2'>
                    <Row>
                        <Col xs="6">
                            <div className='d-flex align-items-start'>
                                <span className='bg-secondary-blue p-2 rounded text-blue'><CalendarDays /></span>
                                <span className='mx-3'>
                                    <div className='fs-6 fw-medium text-dark'>{formattedDate}</div>
                                    <p>{t('date')}</p>
                                </span>
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className='d-flex align-items-start'>
                                <span className='bg-secondary-blue p-2 rounded text-blue'><AlarmClock /></span>
                                <span className='mx-3'>
                                    <div className='fs-6 fw-medium text-dark'>30 {t('minutes')}</div>
                                    <p>{t('duration')}</p>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xl="11" className='my-2'>
                    <div className='mt-3 d-flex align-items-center justify-content-center'>
                        <a href='/product' className='btn-blue p-3 px-5 mt-3 w-75 rounded text-white text-decoration-none'>{t('add')}</a>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default EventItem
