import { useState } from 'react'
import RadioInput from '../../../components/forms/RadioInput'
import Togglesw from './Togglesw'

const Canbook = ({t}) => {
    const [infos, setInfo] = useState({
        cancel: ""
    });

    const [displaySpecificContent, setDisplaySpecificContent] = useState(false);

    const handlebook = (value) => {
        setInfo({ ...infos, cancel: value });
        setDisplaySpecificContent(value === "specific"); 
    };

    const renderbook = (data) => (
        <>
            <ul >
                <div className='px-4 py-3 d-flex flex-row justify-content-between '> 
                    <p>{t('ho_act_newrate_stay1')}</p>  
                    <span className='d-flex justify-content-end'><Togglesw /></span>
                </div>
                <div className='px-4 py-3 d-flex flex-row justify-content-between'> 
                    <p>{t('ho_act_newrate_stay2')}</p>  
                    <span className='d-flex justify-content-end'><Togglesw /></span>
                </div>
                <div className='px-4 py-3 d-flex flex-row justify-content-between'> 
                    <p>{t('ho_act_newrate_stay3')}</p>  
                    <span className=' d-flex justify-content-end'><Togglesw /></span>
                </div>
                
                
            </ul>
        </>
    );

    const bookdata = [
        { title: t('everyone'), value: "everyone" },
        { title: t('ho_act_newrate_customers'), value: "specific" }
    ];

    return (
        <div className='my-4 bg-white'>
            <span className='fs-4 fw-medium text-dark'>3. {t('ho_act_newrate_who')}</span>
            <p className='fs-6 my-3'>{t('ho_act_newrate_whoP')}</p>
       
            <div xl="6" className='my-3 mt-4 '>
                {bookdata.map((policy, index) => (
                    <div key={index} className='my-3 fs-6 mx-4'>
                        <RadioInput id={policy.value} name="bookPolicy" label={policy.title} value={policy.value} onChange={() => handlebook(policy.value)} />
                    </div>
                ))}
                {displaySpecificContent && infos.cancel === "specific" && (
                    <div className='my-3 fs-6 mx-4'>
                        {renderbook("")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Canbook;
