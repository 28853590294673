import { Row, Col } from 'react-bootstrap'
import RadioInput from '../../../components/forms/RadioInput'
import { useState } from 'react'
import Input from '../../../components/forms/Input'

const Ratesupdate = ({ t }) => {
    const [ratesupdates, setInfo] = useState({
        ratesupdate: ""
    });

    const handleratesupdate = (value) => {
        setInfo({ ...ratesupdates, ratesupdate: value });
    };

    const renderrates = () => (
        <Row className='d-flex flex-row'>
            <Col md='3' className='d-flex flex-column my-2'>
                <span className="fw-bolder my-2">{t('sunday')}</span>
                <Input placeholder={t('rate')} type="number" />
            </Col>
            <Col md='3' className='d-flex flex-column my-2'>
                <span className="fw-bolder my-2">{t('sunday')}</span>
                <Input placeholder={t('rate')} type="number" />
            </Col>
            <Col md='3' className='d-flex flex-column my-2'>
                <span className="fw-bolder my-2">{t('tuesday')}</span>
                <Input placeholder={t('rate')} type="number" />
            </Col>
            <Col md='3' className='d-flex flex-column my-2'>
                <span className="fw-bolder my-2">{t('wednesday')}</span>
                <Input placeholder={t('rate')} type="number" />
            </Col>
            <Col md='3' className='d-flex flex-column my-2'>
                <span className="fw-bolder my-2">{t('thursday')}</span>
                <Input placeholder={t('rate')} type="number" />
            </Col>
            <Col md='3' className='d-flex flex-column my-2'>
                <span className="fw-bolder my-2">{t('friday')}</span>
                <Input placeholder={t('rate')} type="number" />
            </Col>
            <Col md='3' className='d-flex flex-column my-2'>
                <span className="fw-bolder my-2">{t('saturday')}</span>
                <Input placeholder={t('rate')} type="number" />
            </Col>
        </Row>
    );

    return (
        <div className='bg-white p-3'>
            <span className='fs-4 fw-medium text-dark'>{t('ho_act_tarif_rest_majpP')}</span>
            <p className='fs-6 my-3'>{t('ho_act_tarif_rest_majpp')}</p>

            <div className='my-3 mt-4'>
                <RadioInput id="for_everyday" name="ratesupdate" label={t('for_every_day')} value="for_everyday" onChange={() => handleratesupdate("for_everyday")} />
                <RadioInput id="day_week" name="ratesupdate" label={t('ho_act_tarif_rest_choice2')} value="day_week" onChange={() => handleratesupdate("day_week")} />

                {ratesupdates.ratesupdate === "for_everyday" && (
                    <Col md='3' className='d-flex flex-column my-2'>
                        <span className="fw-bolder my-2">{t('rate')}</span>
                        <Input placeholder="EUR" type="number" />
                    </Col>
                )}
                {ratesupdates.ratesupdate === "day_week" && (
                    <p>{renderrates()}</p>
                )}
            </div>
        </div>
    );
};

export default Ratesupdate;
