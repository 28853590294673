import { useTranslation } from "react-i18next";
import Prologue from "./Prologue";

const Home = () => {

  const { t } = useTranslation();

  return (
    <>
      <Prologue t={t} />
    </>
  );
};

export default Home;
