import { Row, Col } from "react-bootstrap";
import InputRadio from "../../../components/forms/RadioInput";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";
import Cancel from "./Cancel";

const Legal = () => {

  const { t } = useTranslation();

  const services = [
    {
      id: 1,
      title: t('ext_cameras'),
      value: "CamérasDeSurveillance",
      info: t('cameras_prohibited'),
    },
    { id: 2, title: t('weapons'), value: "Armes" },
    { id: 3, title: t('dangerous_animals'), value: "AnimauxDangereux" },
  ];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="7" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('activityperso')}</h2>
              <p className="fs-5 fw-light">{t('activitypersoP')}</p>

              <div className="my-5">
                <div className="my-4">
                  <div className="fw-medium fs-5 text-dark">{t('type_activity')}</div>
                  <div className="fs-6">
                    <InputRadio name="RentalHost" label={t('host_as_particular')} id="HoteParticulier" />
                    <InputRadio name="RentalHost" label={t('host_as_pro')} id="HoteProfessionnel" />
                  </div>
                </div>

                <div className="my-4">
                  <div className="fw-medium fs-5 text-dark">{t('rental_elements')}</div>
                  <div className="fs-6">
                    {services.map((check) => (
                      <div key={check.id} className="my-3">
                        <CheckInput id={check.value} label={check.title} value={check.value} info={check.info} />
                      </div>
                    ))}
                  </div>
                </div>

                <Cancel/>
              </div>

              <hr className="border-secondary" />

              <div className="my-4">
                <div className="fw-medium fs-5 text-dark">{t('important_to_know')}</div>
                <p className="fs-6 my-3" dangerouslySetInnerHTML={{ __html: t('important_to_know_text') }}>
                </p>
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Legal;
