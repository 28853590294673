import { useEffect, useState } from "react";
import PolicyTransport from './components/PolicyTransport'
import PolicyPackage from './components/PolicyPackage'
import Legal from './components/Legal'
import Availability from './components/Availability'
import WorkingHours from './components/WorkingHours'
import OptionSetup from './components/OptionSetup'
import HotelPolicy from './hotelComponents/HotelPolicy'

const Process = ({ t }) => {

  const [product, setproduct] = useState("");

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setproduct(savedType);
    }
  }, []);


  const configurations = {
    Restaurant: [
      { content: <WorkingHours t={t} /> },
    ],

    Maison: [
      { content: <Legal /> },
    ],

    Activité: [
      { content: <OptionSetup /> },
    ],

    Transport: [
      { content: <PolicyTransport t={t} /> },
    ],

    Voiture: [
      { content: <Availability t={t} /> },
    ],

    Package: [
      { content: <PolicyPackage t={t} /> },
    ],

    Hotel: [
      { content: <HotelPolicy /> },
    ],
  };

  const data = configurations[product] || [];


  return (
    <>
      {data.map((choice, index) => (
        <div key={index}>{choice.content}</div>
      ))}
    </>
  );
};

export default Process;
