import { useEffect, useState } from "react";
import DetailsCar from './components/DetailsCar'
import Visibility from './components/Visibility'
import Events from './components/Events'
import Rooming from "./hotelComponents/Rooming";
import Theme from './components/Theme'
import InclusionsAndRestrictions from './components/InclusionsAndRestrictions'

const Details = ({ t }) => {

  const [product, setproduct] = useState("");

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setproduct(savedType);
    }
  }, []);

  const configurations = {
    Restaurant: [
      { content: <Events t={t} /> },
    ],

    Maison: [
      { content: <Visibility /> },
    ],

    Activité: [
      { content: <InclusionsAndRestrictions t={t} /> },
    ],

    Transport: [
      { content: <Theme /> },
    ],

    Voiture: [
      { content: <DetailsCar t={t} /> },
    ],

    Package: [
      { content:<InclusionsAndRestrictions t={t} /> },
    ],

    Hotel: [
      { content: <Rooming /> },
    ],
  };

  const data = configurations[product] || [];

  return (
    <>
      {data.map((choice, index) => (
        <div key={index}>{choice.content}</div>
      ))}
    </>
  );
};

export default Details;
