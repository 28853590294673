import { useState } from "react";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsNature = ({ checked }) => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedPlaces, setCheckedPlaces] = useState({});

  const handlePlaces = (value) => {
    setCheckedPlaces((prevPlaces) => ({
      ...prevPlaces,
      [value]: !prevPlaces[value],
    }));
  };

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const connexion = [
    { title: t("in_a_bay"), value: "DansUneBaie" },
    { title: t("on_the_promenade"), value: "SurPromenade" },
    { title: t("by_lake"), value: "AuBordLac" },
    { title: t("on_a_bank_of_river"), value: "AuBordRivière" },
    { title: t("at_waters_edge"), value: "AuBordEau" },
    { title: t("by_the_ocean"), value: "AuBordOcéan" },
    { title: t("by_the_sea"), value: "AuBordMer" },
  ];

  const places = [
    { title: t("in_the_mountains"), value: "EnMontagne" },
    { title: t("in_national_park"), value: "parc-national" },
    { title: t("private_island"), value: "Île-privée" },
    { title: t("provincial_park"), value: "parc-provincial" },
    { title: t("regional_park"), value: "parc-régional" },
    { title: t("in_countryside"), value: "LaCampagne" },
    { title: t("in_a_state_park"), value: "parc-etat" },
    { title: t("in_suburbs"), value: "EnBanlieue" },
    { title: t("village"), value: "Village" },
  ];

  const near = [
    { title: t("near_a_bay"), value: "BaieProximité" },
    { title: t("walk_nearby"), value: "PromenadeProximité" },
    { title: t("nature_reserve_nearby"), value: "RéserveProximité" },
    { title: t("hospital_nearby"), value: "HopitalProximité" },
    { title: t("whale_watching_nearby"), value: "ObservationBaleines" },
  ];

  return (
    <div className="my-3 fs-6">
      {checked === "LieuNature" && (
        <>
          <div className="text-blue mt-3">
            {t("select_two_elements")}suivants :
          </div>
          {places.map((item, indexOf) => (
            <div key={indexOf} className="px-3 my-3">
              <div className="d-flex align-items-center">
                <CheckInput
                  id={item.value}
                  label={item.title}
                  checked={checkedPlaces[item.value] || false}
                  onChange={() => handlePlaces(item.value)}
                  disabled={Object.keys(checkedPlaces).length > 1}
                />
              </div>
            </div>
          ))}
        </>
      )}

      {checked === "ConnexionNature" && (
        <>
          {connexion.map((item, indexOf) => (
            <div key={indexOf} className="px-3 my-3">
              <div className="d-flex align-items-center">
                <CheckInput
                  id={item.value}
                  label={item.title}
                  checked={checkedItems[item.value] || false}
                  onChange={() => handleCheckboxChange(item.value)}
                />
              </div>
            </div>
          ))}
        </>
      )}

      {checked === "ProcheNature" && (
        <>
          {near.map((item, indexOf) => (
            <div key={indexOf} className="px-3 my-3">
              <div className="d-flex align-items-center">
                <CheckInput
                  id={item.value}
                  label={item.title}
                  checked={checkedItems[item.value] || false}
                  onChange={() => handleCheckboxChange(item.value)}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default DetailsNature;
