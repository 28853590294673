import React, { useState } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import Draggable from 'react-draggable'
import { Pencil, ShieldClose } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Button } from 'rsuite'
import ManageHeader from "../../components/ManageHeader";

const Cancel = ({ t }) => {
    const [showModal, setShowModal] = useState(false);
    const [defaultPolicy, setDefaultPolicy] = useState(null);

    const handleDeactivateClick = (event) => {
        event.preventDefault();
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleConfirmDeactivate = () => {
        setShowModal(false);
    };

    return (
        <section>
            <ManageHeader
                title={t('ho_act_cancellation')}
                content={t('ho_act_cancellation_txt1')}
                icon={<ShieldClose size={25} strokeWidth={1.5} />}
            />
            <div className='container my-4'>
                <Row className='border rounded backimgcon'>
                    <Col md="6" lg="12" className='pb-3'>
                        <div className='p-2'>
                            <div className='backimgcontent'>
                                <div className='fs-4 fw-bold text-dark mt-2 mb-3'>{t('ho_act_cancellation')}</div>
                                <div>
                                    <span className='' style={{ color: '#333333' }}>

                                    </span>
                                </div>
                                <div className='d-flex justify-content-between mt-3'>
                                    <span className='fs-8 fw-bold text-dark mt-2'>{t('ho_act_cancellationtxt2')} {t('ho_act_cancellation_titre')}</span>
                                    <div>
                                        <Button color="cyan" appearance="primary" className='px-5'>{t('edit')}</Button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='container my-4'>
                <Row className=''>
                    <Col xl='5'>
                        <div className='d-flex flex-row'>
                            <div className='content ms-2 mt-4'>
                                <div className='fs-2 fw-bold text-dark my-4'>{t('ho_act_cancellation_titre')}</div>
                                <div><span className='' style={{ color: '#333333' }}>{t('ho_act_cancellation_txt3')}</span></div>
                                <div className='mt-4'>
                                    <Button appearance="primary" color="cyan" className='px-5 fw-medium'>
                                        <Link to="../adjustedrate" className="text-white">{t('ho_act_cancellation_new')}</Link>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='bgpersons me-5 mt-4'>
                    </Col>
                </Row>
            </div>
            <div sm="12" className='container py-4 '>
                <Pencil strokeWidth={1} size={22} className='text-orange mb-2' />
                <span className='text-capitalize text-dark fs-4 fw-bold mx-2'>{t('ho_act_cancellation_titre1')}</span>
                <div className='d-flex align-items-center'>
                    <p className='fs-8 w-75'>{t('ho_act_cancellation_txt4')}</p>
                </div>
            </div>
            <div className='container'>
                <Row className='d-flex flex-row justify-content-between'>
                    <Col className='my-3 mx-1  p-4 rounded-4 bg-white'>
                        <div xl="6" sm="12" className='d-flex flex-column'>
                            <span className='fs-4 fw-bold text-dark mx-2 my-2'>{t('ho_act_cancellation_titre3')}</span>
                            <span className='fs-8 fw-bold text-dark mx-2 my-2'>{t('ho_act_cancellation_base')}</span>
                            <ul>
                                <li className='my-2'>{t('ho_act_cancellation_li1')}</li>
                                <li className=''>{t('ho_act_cancellation_li2')}</li>
                            </ul>
                            <span className='fs-8 fw-bold text-blue mx-2 my-2'>10 {t('ho_act_cancellation_nivtar')}</span>
                            <div className='d-flex flex-row'>
                                <div className='d-flex justify-content-center align-items-center rounded-2 border mt-3 p-2 '>
                                    <Link to="../updatecancel" className="link-style">
                                        <span className="p-2">{t('ho_act_cancellation_edit')}</span>
                                    </Link>
                                </div>
                                <div className='d-flex justify-content-center align-items-center rounded-2 border mt-3 mx-2 p-2' style={{ backgroundColor: defaultPolicy === '48hours' ? 'orange' : '', color: defaultPolicy === '48hours' ? 'white' : '' }} onClick={(e) => { e.preventDefault(); setDefaultPolicy('48hours') }}>
                                    <span className='p-2' >
                                        {defaultPolicy === '48hours' ? t('ho_act_cancellation_bydefault') : t('ho_act_cancellation_li4')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='my-3 mx-1  p-4 rounded-4 bg-white'>
                        <div xl="6" sm="12" className='d-flex flex-column'>
                            <span className='fs-4 fw-bold text-dark mx-2 my-2' style={{ color: defaultPolicy === '24hours' ? '#008000' : '' }} onClick={(e) => { e.preventDefault(); setDefaultPolicy('24hours') }}>
                                {defaultPolicy === '24hours' ? 'Annulation ou Modification sous 24h' : 'Annulation ou Modification sous 24h'}
                            </span>
                            <span className='fs-8 fw-bold text-dark mx-2 my-2'>{t('ho_act_cancellation_base1')}</span>
                            <ul>
                                <li className='my-2'>{t('ho_act_cancellation_li3')}</li>
                                <li className=''>{t('ho_act_cancellation_defaut')}</li>
                            </ul>
                            <span className='fs-8 fw-bold text-blue mx-2 my-2'>5 {t('ho_act_cancellation_nivtar')}</span>
                            <div className='d-flex flex-row'>
                                <div className='d-flex justify-content-center align-items-center rounded-2 border mt-3 p-2 mx-2 '>
                                    <Link to="../updatecancel" className="link-style">
                                        <span className="p-2">{t('ho_act_cancellation_edit')}</span>
                                    </Link>
                                </div>
                                <div className='d-flex justify-content-center align-items-center rounded-2 border mt-3 p-2 mx-2' style={{ backgroundColor: defaultPolicy === '24hours' ? 'orange' : '', color: defaultPolicy === '24hours' ? 'white' : '' }} onClick={(e) => { e.preventDefault(); setDefaultPolicy('24hours') }}>
                                    <span className='p-2' >
                                        {defaultPolicy === '24hours' ? t('ho_act_cancellation_bydefault') : t('ho_act_cancellation_li4')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" className='rounded-2 border p-2'>
                <Draggable>
                    <div className=''>
                        <Modal.Header className='bg-white' closeButton>
                            <Modal.Title className="text-modal">Delete cancellation policy</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='bg-white text-modal d-flex flex-column'>
                            Do you really want to delete this cancellation policy?
                        </Modal.Body>
                        <Modal.Footer className='bg-white'>
                            <Button variant="warning" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleConfirmDeactivate}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </div>
                </Draggable>
            </Modal>
        </section>
    )
}

export default Cancel;
