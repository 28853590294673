import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import i18n from "../lang/i18n";
import data from '../data/questions.json';

const Questions = ({ t, step, show, handleClose }) => {
    const currentLanguage = i18n.language;
    const [product, setProduct] = useState("");

    useEffect(() => {
        const savedProduct = localStorage.getItem("product");
        console.log('Current Language:', currentLanguage);
        console.log('Product:', product);
        console.log('Step:', step);
        if (savedProduct) {
            setProduct(savedProduct);
        }
    }, [currentLanguage, product, step]);



    const currentData = data.questions[0]?.[step]?.[product]?.[currentLanguage];

    return (
        <>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('your_questions')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='my-3 px-3'>
                        {currentData ? (
                            <>
                                <div className='fs-5 fw-bold'>{currentData.question}</div>
                                <p className='mt-3 fs-6 text-secondary' dangerouslySetInnerHTML={{ __html: currentData.answer }}></p>
                            </>
                        ) : (
                            <p>{t('no_data_available')}</p>
                        )}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Questions;
