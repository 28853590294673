import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import ManageHeader from "../../components/ManageHeader";
import { ListChecks } from 'lucide-react';
import RoomsAVailability from './components/RoomsAVailability';
import DateOneInput from '../../components/forms/DateOneInput'

const Rates = ({t}) => {
    const today = new Date();

    const [selectedDate, setSelectedDate] = useState(today);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const realFormatteDate = new Date(selectedDate).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });


    const [showModal, setShowModal] = useState(false);

    const handleSaveChanges = () => {
        console.log("Changements sauvegardés !");
        setShowModal(false);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <section>
            <ManageHeader
                title={t('ho_act_Opt_Tarif')}
                content={t('ho_act_Opt_Tarif_txt')}
                icon={<ListChecks size={25} strokeWidth={1.5} />}
            />
            <div className='container my-4'>
                <Row className='align-items-center'>
                    <Col xl="3" className='pb-3 d-flex flex-row'>
                        <span className='fs-5 fw-bold text-dark mt-2 ms-3 me-4 '>1. {t('select_date')}</span>
                    </Col>
                    <Col xl="4" className='pb-3 d-flex flex-row'>
                        <DateOneInput onChange={handleDateChange} placeholder={today} />
                    </Col>
                </Row>

                <RoomsAVailability t={t} selectedDate={realFormatteDate} openModal={openModal} />
            </div>
            <style>
                {`
                    .rectangle {
                        width: 100%;
                        height: 34px;
                        border: 1px solid #ced4da;
                        border-radius: 4px;
                        cursor: pointer;
                    }

                    .rectangle.selected {
                        background-color: #008009;
                    }

                    .disabled-input {
                        pointer-events: none;
                        background-color: #f8f9fa;
                    }

                    .board-options {
                        width: 100%;
                        display: flex;
                    }

                    .board-option {
                        flex: 1;
                        text-align: center;
                    }

                    .board-option.selected {
                        background-color: #008009;
                    }
                `}
            </style>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title><div className='text-dark'>{t('ho_act_Opt_Tarifbtn')}</div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-dark'>
                       {t('ho_act_segmentcConfirmp')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn-outline-blue p-3" onClick={closeModal}>
                        {t('cancel')}
                    </button>

                    <button className="btn-blue p-3" onClick={handleSaveChanges}>
                        {t('save')}
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default Rates;
