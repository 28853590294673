import React from 'react'
import { useState } from 'react';
import RoomOption from './RoomOption'
import CheckInput from '../../../components/forms/CheckInput';
import Height from '../../../components/Height'
import { useTranslation } from "react-i18next";

const DetailsAccessibility = () => {

    const { t } = useTranslation();

    const [infos, setInfos] = useState({
        bar: false,
        toilet: false,
        seat: false
    });

    const handleGrabBar = () => {
        setInfos({ ...infos, bar: !infos.bar });
    };

    const handleToilet = () => {
        setInfos({ ...infos, toilet: !infos.toilet });
    };

    const handleSeat = () => {
        setInfos({ ...infos, seat: !infos.seat });
    };

    const [roomsItems, setRoomsItems] = useState({});
    const [bathsItems, setBathsItems] = useState({});
    const [showersItems, setShowersItems] = useState({});


    const handleRoomsItems = (value) => {
        setRoomsItems((prevRoomsItems) => ({
            ...prevRoomsItems,
            [value]: !prevRoomsItems[value],
        }));
    };

    const handleBathsItems = (value) => {
        setBathsItems((prevBathsItems) => ({
            ...prevBathsItems,
            [value]: !prevBathsItems[value],
        }));
    };

    const handleShowersItems = (value) => {
        setShowersItems((prevShowersItems) => ({
            ...prevShowersItems,
            [value]: !prevShowersItems[value],
        }));
    };

    const accessibility = [
        { title: t('accessibility_title1'), value: "LèvePersonnePlafond" },
        { title: t('accessibility_title2'), value: "NotificationSonore" },
        { title: t('accessibility_title3'), value: "NotificationVisuelle" },
        { title: t('accessibility_title4'), value: "PoignéesDePorteLevier" },
        { title: t('accessibility_title5'), value: "RéglableEnHauteur" },
        { title: t('accessibility_title6'), value: "AffichageEnBraille" },
        { title: t('accessibility_title7'), value: "TélévisionAvecSousTitres" },
        { title: t('accessibility_title8'), value: "KitAccessibilitéTéléphonique" },
        { title: t('accessibility_title9'), value: "OreillerVibrant" },
        { title: t('accessibility_title10'), value: "AlarmeIncendieVisuelle" }
    ]

    const bath = [
        { title: t('bath_title1'), value: "BaignoireAccessible" },
        { title: t('bath_title2'), value: "DoucheAccessible" },
        { title: t('bath_title3'), value: "ToilettesAccessibles" },
        { title: t('bath_title4'), value: "CordonUrgence" },
        { title: t('bath_title5'), value: "PrisesElectriques" },
        { title: t('bath_title6'), value: "ComptoirAccessible" }
    ]

    const showers = [
        { title: t('shower_title_1'), value: "BarreAppuiDansLaDouche" },
        { title: t('shower_title_2'), value: "DouchetteAmain" },
        { title: t('shower_title_3'), value: "PommeDeDoucheReglable" },
        { title: t('shower_title_4'), value: "SiègeDeDouchePortable" },
        { title: t('shower_title_5'), value: "DoucheAccessibleAmobilite" },
        { title: t('shower_title_6'), value: "DoucheAvecSystemDetransfert" },
    ]

    return (
        <div className='my-3 fs-6'>
            <>
                <span className='fs-5 text-blue'>{t('details_access_title')}</span>
                {accessibility.map((check, index) => (
                    <div key={index} className='my-3'>

                        <>
                            <CheckInput id={check.value} label={check.title} value={check.value} checked={roomsItems[check.value] || false} onChange={() => handleRoomsItems(check.value)} />
                        </>

                        {roomsItems[check.value] && (
                            <>
                                {['LèvePersonnePlafond', 'NotificationSonore', 'NotificationVisuelle', 'PoignéesDePorteLevier', 'AffichageEnBraille', 'TélévisionAvecSousTitres', 'KitAccessibilitéTéléphonique', 'OreillerVibrant', 'AlarmeIncendieVisuelle'].includes(check.value) && (
                                    <RoomOption />
                                )}
                            </>
                        )}

                    </div>
                ))}
            </>

            <>
                <span className='fs-5 text-blue'>{t('deails_acess_t2')}</span>
                {bath.map((check, index) => (
                    <div key={index} className='my-3'>

                        <>
                            <CheckInput id={check.value} label={check.title} value={check.value} checked={bathsItems[check.value] || false} onChange={() => handleBathsItems(check.value)} />
                        </>

                        {bathsItems[check.value] && (
                            <>

                                {['PrisesElectriques', 'CordonUrgence'].includes(check.value) && (
                                    <RoomOption />
                                )}

                                {check.value === 'BaignoireAccessible' && (
                                    <>
                                        <RoomOption />
                                        <div className='px-3'>

                                            <div className='my-3'>
                                                <CheckInput id="BarreBaignoire" label={t('bath_bar')} value={infos.bar} onChange={handleGrabBar} />
                                            </div>

                                            {infos.bar && (
                                                <Height label={t('bath_bar_height')} />
                                            )}

                                            <div className='my-3'>
                                                <CheckInput id="SiegeDeBainPortable" label={t('bath_seat')} />
                                            </div>
                                        </div>
                                    </>
                                )}

                                {check.value === 'DoucheAccessible' && (
                                    <div className='p-3'>
                                        {
                                            showers.map((showerscheck, indexOf) => (
                                                <div className='my-3' key={indexOf}>

                                                    <CheckInput id={showerscheck.value} label={showerscheck.title} value={showerscheck.value} checked={showersItems[showerscheck.value] || false} onChange={() => handleShowersItems(showerscheck.value)} />

                                                    {showersItems[showerscheck.value] && (
                                                        <>
                                                            {showerscheck.value === 'BarreAppuiDansLaDouche' && (
                                                                <>
                                                                    <RoomOption />
                                                                    <Height label={t('shower_bar_height')} />
                                                                </>
                                                            )}

                                                            {showerscheck.value === 'DoucheAccessibleAmobilite' && (
                                                                <>
                                                                    <RoomOption />
                                                                    <Height label={t('shower_width')} />
                                                                </>
                                                            )}

                                                            {['DouchetteAmain', 'PommeDeDoucheReglable', 'SiègeDeDouchePortable', 'DoucheAvecSystemDetransfert'].includes(showerscheck.value) && (
                                                                <RoomOption />
                                                            )}
                                                        </>
                                                    )}

                                                </div>
                                            ))
                                        }
                                    </div>
                                )}

                                {check.value === 'ToilettesAccessibles' && (
                                    <div className='p-3'>
                                        <div className='my-3'>
                                            <CheckInput id="Toiletgrabbar" label={t('toilet_bar')} value={infos.toilet} onChange={handleToilet} />
                                        </div>

                                        {infos.toilet && (
                                            <>
                                                <RoomOption />
                                                <Height label={t('toilet_bar_height')} />
                                            </>
                                        )}

                                        <div className='my-3'>
                                            <CheckInput id="Toiletraisedseat" label={t('raised_toilet')} value={infos.seat} onChange={handleSeat} />
                                        </div>

                                        {infos.seat && (
                                            <>
                                                <RoomOption />
                                                <Height label={t('toilet_height')} />
                                            </>
                                        )}
                                    </div>
                                )}

                                {check.value === 'ComptoirAccessible' && (
                                    <>
                                        <RoomOption />
                                        <Height label={t('vanity_height')} />
                                    </>
                                )}

                            </>
                        )}

                    </div>
                ))}
            </>
        </div>
    )
}

export default DetailsAccessibility