import React, { useState } from "react";
import { Row, Col, Button, Table } from "rsuite";
import Head from "../../components/Head";
import { FaArrowTrendUp } from "react-icons/fa6";
import MAFacture from "../../assets/slide/facture.pdf"
import { Modal} from "react-bootstrap"
import Draggable from 'react-draggable'

const { Column, HeaderCell, Cell } = Table;

const Demanderpayment = () => {

    const [showContent, setShowContent] = useState(false);
    const [bookings, setBookings] = useState([
        { id: 1, numeroreservation: "6952225874", numerofacture: "4953455341", nomclient: "Riadh mahrez", arrivé: "2024-05-16", datecreation: "2024-05-18", montant: "DZD 57.49", status: "payé", taxe: "2.60", total: "DZD  60.09" },
        { id: 2, numeroreservation: "6952225874", numerofacture: "8573455341", nomclient: "zineddine zidane", arrivé: "2024-06-18", datecreation: "2024-07-16", montant: "DZD 25.55", status: "En attente", taxe: "3.90", total: "DZD 29.45" },
        { id: 3, numeroreservation: "6952225874", numerofacture: "5847455341", nomclient: "cristiano ronaldo", arrivé: "2024-05-06", datecreation: "2024-05-17", montant: "DZD 65.15", status: "payé", taxe: "1.25", total: "DZD 66.4" },
        { id: 4, numeroreservation: "6952225874", numerofacture: "4953455341", nomclient: "lionel messi", arrivé: "2024-05-01", datecreation: "2024-05-15", montant: "DZD 1.24", status: "Annulé", taxe: "2.90", total: "DZD 4.14" },
        { id: 5, numeroreservation: "6952225874", numerofacture: "8573455341", nomclient: "Riadh mahrez", arrivé: "2024-05-02", datecreation: "2024-05-16", montant: "DZD 158.47", status: "En attente", taxe: "1.80", total: "DZD 160.27" },
    ]);
    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleSelectAll = () => {
        const newCheckedItems = {};
        bookings.forEach((booking, index) => {
            newCheckedItems[index] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (index) => {
        const newCheckedItems = { ...checkedItems, [index]: !checkedItems[index] };
        setCheckedItems(newCheckedItems);

        const allChecked = Object.values(newCheckedItems).every((value) => value);
        setSelectAll(allChecked);
    };

    const calculateTotal = (bookings) => {
        const total = bookings.reduce((acc, booking) => {
            const frateValue = parseFloat(booking.total.replace("DZD ", "").replace(/\s/g, ""));
            return !isNaN(frateValue) ? acc + frateValue : acc;
        }, 0);
        return total.toFixed(2);
    };

    const downloadPDF = () => {
        const link = document.createElement('a');
        link.href = MAFacture;
        link.download = 'facture.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

 
const handleSearch = () => {
    const dummyBookings = [
        { id: 1, numeroreservation: "6952225874", numerofacture: "4953455341", nomclient: "Riadh mahrez", arrivé: "2024-05-16", datecreation: "2024-05-18", montant: "DZD 57.49", status: "payé", taxe: "2.60", total: "DZD  60.09" },
        { id: 2, numeroreservation: "6952225874", numerofacture: "8573455341", nomclient: "zineddine zidane", arrivé: "2024-06-18", datecreation: "2024-07-16", montant: "DZD 25.55", status: "En attente", taxe: "3.90", total: "DZD 29.45" },
        { id: 3, numeroreservation: "6952225874", numerofacture: "5847455341", nomclient: "cristiano ronaldo", arrivé: "2024-05-06", datecreation: "2024-05-17", montant: "DZD 65.15", status: "payé", taxe: "1.25", total: "DZD 66.4" },
        { id: 4, numeroreservation: "6952225874", numerofacture: "4953455341", nomclient: "lionel messi", arrivé: "2024-05-01", datecreation: "2024-05-15", montant: "DZD 1.24", status: "Annulé", taxe: "2.90", total: "DZD 4.14" },
        { id: 5, numeroreservation: "6952225874", numerofacture: "8573455341", nomclient: "Riadh mahrez", arrivé: "2024-05-02", datecreation: "2024-05-16", montant: "DZD 158.47", status: "En attente", taxe: "1.80", total: "DZD 160.27" },
    ];
    setBookings(dummyBookings);
    setShowContent(true); 
};
const handleEditButtonClick = () => {
    setShowModal(true);
};
const handleConfirmDeactivate = () => {
    setShowModal(false);
    handleShowSuccessMessage();
};
const handleCloseModal = () => {
    setShowModal(false);
};
const [showModal, setShowModal] = useState(false);
const handleShowSuccessMessage = () => {
    setShowSuccessMessage(true);
};

const [showSuccessMessage, setShowSuccessMessage] = useState(false);


    return (
        <section className="mb-4">
            <Head
                data="Ibis Paris Maine Mont"
                p_root="Rooms . Rate adjustment"
                dynamicData="Hotel Number"
                dd="102377512"
            />
            <div lg="5" xl="5" sm="12" className="container mb-4 py-4 d-flex flex-column">
                <div lg="5" xl="5" sm="12" className="px-2 mb-3 d-flex flex-column">
                    <Row className="d-flex flex-column">
                        <Col xl="auto" className="text-dark fs-4 pt-2 fw-bold">
                            Demander un paiement IdDH Group
                        </Col> 
                        <Col>
                            <span>Obtenez le paiement des réservations IDH Group payées en ligne à l’avance par les clients en créant une demande de paiement. Le paiement peut être demandé dès que <br/> le client quitte l’établissement.</span>
                        </Col>
                    </Row>
                </div>
           
                <div className="bg-white mt-4 rounded">
                    <Row className="d-flex flex-row">
                        <Col className="col-4">
                            <div  className="py-4 px-2 d-flex flex-column border-end">
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="fw-bolder fs-6">Saisissez les N° des réservations</span>
                                    <span className="rounded-5 fs-5 px-2 text-white" style={{backgroundColor:'#8b74f9'}}>1</span>
                                </div>

                                <div className="mb-1 fw-bolder fs-5" style={{color:'#8b74f9'}}>première étape <FaArrowTrendUp /></div>
                                <div className="mb-1 fw-medium fs-7" style={{color:'#8b74f9'}}></div>
                                <div className=" fw-medium "><span>Vous pouvez saisir plusieurs numéros de réservation en les séparant avec une virgule</span></div>
                            </div>
                        </Col>
                        <Col className="col-4">
                            <div  className="py-4 px-2 d-flex flex-column border-end">
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="fw-bolder fs-6">Confirmer les détails</span>
                                    <span className="rounded-5 fs-5 px-2 text-white" style={{backgroundColor:'#29c763'}}>2</span>
                                </div>

                                <div className="mb-1 fw-bolder fs-5" style={{color:'#29c763'}}>deuxième étape <FaArrowTrendUp /></div>
                                <div className="mb-1 fw-medium fs-7" style={{color:'#29c763'}}> </div>
                                <div className=" fw-medium "><span>Vérifiez attentivement les informations fournies. Assurez-vous de leur exactitude et de leur exhaustivité. </span></div>
                            </div>
                        </Col>
                        <Col className="col-4">
                            <div  className="py-4 px-2 d-flex flex-column rounded">
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="fw-bolder fs-6">Envoyer la demande</span>
                                    <span className="rounded-5 fs-5 px-2 text-white" style={{backgroundColor:'#f97316'}}>3</span>
                                </div>

                                <div className="mb-1 fw-bolder fs-5" style={{color:'#f97316'}}>troisième étape <FaArrowTrendUp /></div>
                                <div className="mb-1 fw-medium fs-7" style={{color:'#f97316'}}></div>
                                <div className=" fw-medium "><span>Une fois que vous avez vérifié et confirmé tous les détails, appuyez sur Envoyer pour transmettre votre demande au système</span></div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-3 my-2 bg-white rounded">
                    <div className="my-2"><span className="fs-5 px-2 py-2 text-dark fw-bolder">Suivi de votre demande</span></div>
                    <Row className="py-1 d-flex flex-row justify-content-between">
                        <Col className="col-8">
                            <div style={{backgroundColor:'#f2f0fe'}} className="p-4 d-flex flex-column rounded">
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="fw-bolder fs-6" style={{color:'#8b74f9'}}>Numéros des réservations</span>
                                    <span className="p-1 rounded text-white" style={{backgroundColor:'#8b74f9'}}></span>
                                </div>
                                <div className="mb-3 mt-3 fs-8" >Saisissez les numéros des réservations. Vous pouvez saisir plusieurs numéros de réservation en les séparant avec une virgule.</div>
                                <Row className="d-flex flex-row justify-content-between">
                                    <input type="text" placeholder="N° de la facture" className="form-control me-2" aria-label="Text input with 2 dropdown buttons" />
                    
                                </Row>
                            </div>
                        </Col>
                        <Col className="col-4">
                            <div style={{backgroundColor:'#e8f9ee'}} className="p-4 d-flex flex-column rounded">
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="fw-bolder fs-6" style={{color:'#29c763'}}>Ajouter ma demande</span>
                                    <span className="p-1 rounded text-white" style={{backgroundColor:'#29c763'}}></span>
                                </div>

                                <div className="mb-3 mt-3 fs-8" >Veuillez cliquer sur le bouton ci-dessous pour ajouter votre demande.</div>
                                <Row className="d-flex ">
                                    <div className="container  d-flex flex-column">
                                    <Button color="green" appearance="primary" onClick={handleSearch} className="px-5">
                                        Ajouter
                                    </Button>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                {showContent && (
                <div className="my-1">
                    <Row className="border rounded p-2">
                        <Col className="justify-content-enter col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <Table height={400} data={bookings} autoHeight className="w-100">
                                <Column width={40}>
                                    <HeaderCell>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </HeaderCell>
                                    <Cell>
                                        {(rowData, rowIndex) => (
                                            <input
                                                type="checkbox"
                                                checked={checkedItems[rowIndex]}
                                                onChange={() => handleCheckboxChange(rowIndex)}
                                            />
                                        )}
                                    </Cell>
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">N° de réservation</HeaderCell>
                                    <Cell dataKey="numeroreservation" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">N° de facture</HeaderCell>
                                    <Cell dataKey="numerofacture" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">Client</HeaderCell>
                                    <Cell dataKey="nomclient" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">Arrivée</HeaderCell>
                                    <Cell dataKey="arrivé" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">Départ</HeaderCell>
                                    <Cell dataKey="datecreation" />
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell className="fs-6 fw-bold text-dark">Prix demandé</HeaderCell>
                                    <Cell dataKey="montant" />
                                </Column>
                                <Column width={140}>
                                    <HeaderCell className="fs-6 fw-bold text-dark">Taxes demandées</HeaderCell>
                                    <Cell dataKey="taxe"  className=""/>
                                </Column>
                                <Column width={125}>
                                    <HeaderCell className="fs-6 fw-bold text-dark">Total demandé</HeaderCell>
                                    <Cell dataKey="total"  className=""/>
                                </Column>
                            </Table>
                            <div className="fs-6 fw-bold text-dark d-flex flex-row justify-content-between mt-3 mx-2">
                                <span>5 réservations </span>
                                <span>Montant total de la facture: DZD {calculateTotal(bookings)}</span>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-4  mb-2">
                        <div className="d-flex flex-row my-2">
                            <input type="checkbox" className="me-3" />
                            <p>Je reconnais qu'il est de mon devoir, ainsi que celui de mon établissement, de verser les taxes et de veiller à ce que toutes les informations concernant les réservations soient correctes et précises. Cela inclut la vérification des numéros de réservation, des montants facturés, ainsi que des dates d'arrivée et de départ. En tant que représentant de l'établissement, je m'engage à respecter toutes les obligations fiscales et à assurer l'intégrité des données relatives aux réservations effectuées par nos clients.</p>
                        </div>
                        <div className="d-flex  my-4 justify-content-end">
                            <Button color="blue" appearance="primary" className="px-4 me-2" onClick={handleEditButtonClick}>
                                Créer ma Facture
                            </Button>
                            <Button color="cyan" appearance="primary" className="px-4">
                                Annuler
                            </Button>
                        </div>
                    </div>
                </div>
                )}
            </div>
            <Modal show={showModal} onHide={handleCloseModal} size="md" className='rounded-2 p-2'>
                <Draggable>
                    <div className=''>
                        <Modal.Header className='bg-white' closeButton>
                            <Modal.Title className="text-modal fw-bold fs-5 ms-2">Facture demandée</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='bg-white text-modal d-flex flex-column'>
                            <div className='p-2'>
                               <span>Votre demande de paiement a été envoyée avec succès</span>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='bg-white'>
                            <div className='p-2'>
                               <span style={{color:'green'}}>Votre numéro de suivi: 325558214</span>
                            </div>
                        </Modal.Footer>
                    </div>
                </Draggable>
            </Modal>
        </section>
        
    );
};

export default Demanderpayment;
