import { Row, Col } from "react-bootstrap";
import { Star, MapPin } from "lucide-react";
import { Link } from "react-router-dom";
import Top from "./components/Top";
import Input from "../../components/forms/Input";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi2";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Home = () => {

  const { t } = useTranslation();

  const data = [
    { title: "Ibis Paris Maine Montparnasse", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", number: "102453697", income: "10", score: 90, status: "Bookable" },
    { title: "Ibis Paris Maine Montparnasse", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", number: "102453697", income: "10", score: 90, status: "Bookable" },
    { title: "Ibis Paris Maine Montparnasse", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", number: "102453697", income: "10", score: 90, status: "Bookable" },
    { title: "Ibis Paris Maine Montparnasse", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", number: "102453697", income: "10", score: 90, status: "Bookable" },
    { title: "Ibis Paris Maine Montparnasse", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", number: "102453697", income: "10", score: 90, status: "Bookable" },
    { title: "Ibis Paris Maine Montparnasse", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", number: "102453697", income: "10", score: 90, status: "Bookable" },
  ];

  return (
    <section className="mb-4">
      <Top t={t} />
      <div className="px-5 pt-1">
        <Row className="my-3">
          <span className="fs-4 mb-1 fw-bold text-dark">{t('today')}</span>
          <Col md="6" lg="3" className="my-2 p-1">
            <div className="bg-white border rounded p-2">
              <Row className="d-flex flex-row">
                <Col xl="3" className="mt-2 d-flex justify-content-center align-items-center" style={{ height: "3vw", width: "5vw" }}>
                  <span className="d-flex justify-content-center align-items-center rounded-circle" style={{ backgroundColor: "#f3f6f9", width: "100%", height: "100%" }}>
                    <HiCurrencyDollar style={{ fontSize: "1.5vw", color: "#25a0e2" }} />
                  </span>
                </Col>
                <Col xl="5" className="d-flex flex-column">
                  <div className="fs-6 text-uppercase" style={{ color: "#878a99" }}>
                    {t('bookings')}
                  </div>

                  <div className="text-dark fs-4 fw-bold">5</div>
                </Col>
                <Col xl="4" className="d-flex justify-content-end align-items-end">
                  <span className="px-2 rounded" style={{ backgroundColor: "#d9f5f0", color: "#00bd9d" }}>
                    <IoIosArrowUp /> 9.32 %{" "}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="6" lg="3" className="my-2 p-1">
            <div className="bg-white border rounded p-2">
              <Row className="d-flex flex-row">
                <Col xl="3" className="mt-2 d-flex justify-content-center align-items-center" style={{ height: "3vw", width: "5vw" }}>
                  <span className="d-flex justify-content-center align-items-center rounded-circle" style={{ backgroundColor: "#f3f6f9", width: "100%", height: "100%" }}>
                    <FaArrowAltCircleLeft style={{ fontSize: "1.5vw", color: "#25a0e2" }} />
                  </span>
                </Col>
                <Col xl="5" className="d-flex flex-column">
                  <div className="fs-6 text-uppercase" style={{ color: "#878a99" }}>
      
                    {t('arrivals')}
                  </div>
                  <div className="text-dark fs-4 fw-bold">3</div>
                </Col>
                <Col xl="4" className="d-flex justify-content-end align-items-end">
                  <span className="px-2 rounded" style={{ backgroundColor: "#fde8e4", color: "#f06548" }}>
                    <IoIosArrowDown /> 5.08 %{" "}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="6" lg="3" className="my-2 p-1">
            <div className="bg-white border rounded p-2">
              <Row className="d-flex flex-row">
                <Col xl="3" className="mt-2 d-flex justify-content-center align-items-center" style={{ height: "3vw", width: "5vw" }}>
                  <span className="d-flex justify-content-center align-items-center rounded-circle" style={{ backgroundColor: "#f3f6f9", width: "100%", height: "100%" }}>
                    <FaArrowAltCircleRight style={{ fontSize: "1.5vw", color: "#25a0e2" }} />
                  </span>
                </Col>
                <Col xl="5" className="d-flex flex-column">
                  <div className="fs-6 text-uppercase" style={{ color: "#878a99" }}>
                    {t('departures')}
                  </div>
                  <div className="text-dark fs-4 fw-bold">1</div>
                </Col>
                <Col xl="4" className="d-flex justify-content-end align-items-end">
                  <span className="px-2 rounded" style={{ backgroundColor: "#d9f5f0", color: "#00bd9d" }}>
                    <IoIosArrowUp /> 6.24 %{" "}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="6" lg="3" className="my-2 p-1">
            <div className="bg-white border rounded p-2">
              <Row className="d-flex flex-row">
                <Col xl="3" className="mt-2 d-flex justify-content-center align-items-center" style={{ height: "3vw", width: "5vw" }}>
                  <span className="d-flex justify-content-center align-items-center rounded-circle" style={{ backgroundColor: "#f3f6f9", width: "100%", height: "100%" }}>
                    <MdOutlineCancel style={{ fontSize: "1.5vw", color: "#25a0e2" }} />
                  </span>
                </Col>
                <Col xl="5" className="d-flex flex-column">
                  <div className="fs-6 text-uppercase" style={{ color: "#878a99" }}>
                   
                    {t('cancellations')}
                  </div>
                  <div className="text-dark fs-4 fw-bold">2</div>
                </Col>
                <Col xl="4" className="d-flex justify-content-end align-items-end">
                  <span className="px-2 rounded" style={{ backgroundColor: "#d9f5f0", color: "#00bd9d" }}>
                    <IoIosArrowUp /> 7.31 %
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="container my-4">
        <form action="#">
          <fieldset className="my-3 bg-white">
            <>
              <span className="fs-4 fw-bold text-dark">{t('search_hotel')}</span>
              <p className="fs-6">{t('search_hotel_caption')}</p>
              <div className="my-3">
                <Input placeholder={t('accommodation_name')} type="text" />
              </div>
            </>
          </fieldset>
        </form>
        <div className="my-3">
          {data.map((hotel, index) => (
            <Link key={index} to="/myhotel/details" className="text-dark text-decoration-none">
              <div className="bg-white border recommendations-item my-3">
                <Row>
                  <Col md="3">
                    <div className="h-100">
                      <img alt="myhotel" src="https://images.pexels.com/photos/2291624/pexels-photo-2291624.jpeg?auto=compress&cs=tinysrgb&w=600" height="100%" width="100%" />
                    </div>
                  </Col>
                  <Col sm="7" md="6" className="mt-4 d-flex align-items-center">
                    <div className="p-3 pb-5 h-100 border-end">
                      <div className="d-flex align-items-center">
                        <MapPin strokeWidth={1} size={20} />
                        <span className="mx-1"> Paris </span>
                      </div>
                      <div className="title-blue fw-bold my-2 fs-5">{hotel.title}</div>
                      <div className="d-flex align-items-center my-2">
                        <div className="d-flex align-items-center">
                          <Star color="#FFB21D" fill="#FFB21D" size={15} />
                          <Star color="#FFB21D" fill="#FFB21D" size={15} />
                          <Star color="#FFB21D" fill="#FFB21D" size={15} />
                        </div>
                      </div>
                      <p className="my-2 fs-6">{hotel.content}</p>
                    </div>
                  </Col>
                  <Col sm="5" md="3" className="mt-5">
                    <div className="d-flex flex-column align-items-left justify-content-between ">
                      <span className="py-2">
                        {t('hotel_num')} : <b className="">{hotel.number}</b>
                      </span>
                      <span className="py-2">
                        {t('income')} : <b>{hotel.income} EUR</b>
                      </span>
                      <span className="py-2">
                        {t('score')} : <b>{hotel.score}%</b>
                      </span>
                      <span className="py-2">
                        {t('status')} : <b>{hotel.status}</b>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Home;
