import Home from "./Home";
import Newrate from "./Newrate";
import Updatecancel from "./Updatecancel";
import Newfees from "./Newfees";
import Adjustedrate from "./Adjustedrate";
import Menu from "./Menu";
import BookingHotels from "./BookingHotels";
import Bookingdetails from "./Bookingdetails";
import Invoicedetails from "./Invoicedetails";
import Paymentglobal from "./Paymentglobal";
import Facturedetails from "./Facturedetails";
import Demanderpayment from "./Demanderpayment";

function Main(props) {
  const composite = {
    home: <Home />,
    details: <Menu />,
    newrate: <Newrate />,
    updatecancel: <Updatecancel />,
    newfees: <Newfees />,
    adjustedrate: <Adjustedrate />,
    bookinghotels: <BookingHotels />,
    bookingdetails: <Bookingdetails />,
    invoicedetails: <Invoicedetails />,
    paymentglobal: <Paymentglobal />,
    facturedetails: <Facturedetails />,
    demanderpayment: <Demanderpayment />,
  };

  return <>{eval("composite." + props.component)}</>;
}

export default Main;
