import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const Type = () => {

  const { t } = useTranslation();

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const data = [
    { title: t('Transport1'), value: "Arrêts multiples" },
    { title: t('Transport2'), value: "Croisière" },
    { title: t('Transport3'), value: "Escale" },
    { title: t('Transport4'), value: "Excursion en bord de mer" },
    { title: t('Transport5'), value: "Inter-îles" },
    { title: t('Transport7'), value: "Transfert depuis/vers l'aéroport" },
    { title: t('Transport9'), value: "Transfert depuis/vers le port" },
    { title: t('Transport11'), value: "Transport intercité" },
    { title: t('Transport12'), value: "Ville" },
    { title: t('Transport13'), value: "Visite" },
  ];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="7" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('CarsDetails')}</h2>
              <p className="fs-5 fw-light">{t('Transportdesp1')}</p>
              <div className="fs-6 my-4">
                {data.map((check, index) => (
                  <div key={index} className="my-3">
                    <>
                      <CheckInput id={check.value} label={check.title} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} disabled={Object.keys(checkedItems).length > 2} />
                    </>
                  </div>
                ))}
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Type;
