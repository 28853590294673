import React from 'react'
import Input from '../../../components/forms/Input'
import { Col, Row } from 'react-bootstrap'
import { useState } from 'react'
import RadioInput from '../../../components/forms/RadioInput'


const Extracharges = ({ t }) => {

    const [infos, setInfos] = useState({
        fees: "Montant"
    });

    const handleFees = (value) => {
        setInfos({ ...infos, fees: value });
    };

    return (
        <>
            <div className="d-flex fs-6">
                <div className="mx-2">
                    <RadioInput name="ExtraFees" label={t('amount')} id="Montant" value="Montant" onChange={() => handleFees("Montant")} />
                </div>

                <div className="mx-2">
                    <RadioInput name="ExtraFees" label={t('percentage')} id="Pourcentage" value="Pourcentage" onChange={() => handleFees("Pourcentage")} />
                </div>
            </div>

            <Row>
                <Col md="4" className='my-3'>
                    <Input placeholder={`${infos.fees === "Montant" ? 'Montant (EUR)' : 'Pourcentage (%)'}`} type="number" />
                </Col>
                <Col md="4" className='my-3'>
                    <Input placeholder='Adult' type="number" />
                </Col>
                <Col md="4" className='my-3'>
                    <Input placeholder='Child' type="number" />
                </Col>
                <Col md="10" className='my-3 p-3' style={{ backgroundColor: '#d1eaff' }}>
                    <p className='fs-6 '>{t('ho_act_newrate_stayNote')}</p>


                </Col>
            </Row>
        </>
    )
}

export default Extracharges