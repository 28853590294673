import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Steps } from "rsuite";
import { Button } from "rsuite";
import CheckInput from "../../components/forms/CheckInput";
import Ratesupdate from "./components/Ratesupdate";
import Restrictionupdate from "./components/Restrictionupdate";
import RateD from "../../assets/slide/upgrade.svg";
import ManageHeader from "../../components/ManageHeader";
import { ShieldAlert } from "lucide-react";
import DateInput from "../../components/forms/DateInput";

const Restrictions = ({ t }) => {
  const [setSelectedRange] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectStatus, setSelectStatus] = useState({
    roomCol1: false,
    roomCol2: false,
    roomCol3: false,
  });

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSelectRange = (dateRange) => {
    setSelectedRange(dateRange);
  };

  const handleSelectAll = (colId) => {
    const newSelectStatus = { ...selectStatus, [colId]: !selectStatus[colId] };
    setSelectStatus(newSelectStatus);
    const checkboxes = document.querySelectorAll(`#${colId} input[type="checkbox"]`);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = newSelectStatus[colId];
    });
  };

  return (
    <section className="mb-5">
      <ManageHeader title={t('ho_act_tarif_rest')} content={t('ho_act_tarif_rest_txt')} icon={<ShieldAlert size={25} strokeWidth={1.5} />} />
      <div className="container my-4">
        <Row className="bgr p-4 pb-0 rounded my-4">
          <Col className="d-flex flex-column my-5">
            <span className="w-75 m-auto text-dark fs-4 fw-bolder text-center">
              {t('ho_act_tarif_rest_txt1')} <br />
              <b className="text-blue fs-2">{t('ho_act_tarif_rest_txt2')}</b>
              <br /> {t('ho_act_tarif_rest_txt3')}
            </span>
          </Col>
          <div className="d-flex justify-content-center">
            {" "}
            <img alt="rate adjustement" src={RateD} />
          </div>
        </Row>
        <Row className="my-4">
          <Col>
            <Steps current={currentStep}>
              <Steps.Item title={<span style={{ fontWeight: "bold" }}>{t('ho_act_tarif_rest_date')}</span>} description={t('ho_act_tarif_rest_datep')} />
              <Steps.Item title={<span style={{ fontWeight: "bold" }}>{t('ho_act_tarif_rest_tarif')}</span>} description={t('ho_act_tarif_rest_tarifp')} />
              <Steps.Item title={<span style={{ fontWeight: "bold" }}>{t('ho_act_tarif_rest_maj')}</span>} description={t('ho_act_tarif_rest_majp')} />
              <Steps.Item title={<span style={{ fontWeight: "bold" }}>{t('ho_act_tarif_rest_rest')}</span>} description={t("ho_act_tarif_rest_restp")} />
            </Steps>
          </Col>
        </Row>
      </div>
      <div className="container">
        <Row>
          <Col>
            {currentStep === 0 && (
              <>
                <Row>
                  <Col md="6" lg="4" className="d-flex flex-row">
                    <DateInput leftLabel={t('from')} rightLabel={t('to')} onChange={handleSelectRange} />
                  </Col>
                </Row>
              </>
            )}

            {currentStep === 1 && (
              <>
                <form action="">
                  <fieldset className="my-3 bg-white p-5">
                    <>
                      <span className="fs-4 fw-bold text-dark">{t('ho_act_tarif_rest_select')}</span>
                      <p className="fs-6 mb-4 w-75"> {t('ho_act_tarif_rest_selectp')}</p>
                    </>
                    <Row className="d-flex flex-row">
                      <Col md="4" xl="4" className="mb-3 " id="roomCol1">
                        <span className="fs-5 fw-bold text-dark">Standard double room</span>
                        <div className="text-blue my-3" onClick={() => handleSelectAll("roomCol1")}>
                          {" "}
                          <CheckInput id="" label={selectStatus["roomCol1"] ? t('deselect_all') : t('select_all')} />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt" label="Breakfast Included (S)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt1" label="Full Board (S)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt2" label="Half Board (S)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt3" label="Breakfast Included (P)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt4" label="Full Board (P)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt5" label="Half Board (P)" />
                        </div>
                      </Col>
                      <Col md="4" xl="4" className="mb-3" id="roomCol2">
                        <span className="fs-5 fw-bold text-dark">Single room</span>
                        <div className="text-blue my-3" onClick={() => handleSelectAll("roomCol2")}>
                          {" "}
                          <CheckInput id="" label={selectStatus["roomCol2"] ? t('deselect_all') : t('select_all')} />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt7" label="Breakfast Included (S)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt8" label="Full Board (S)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt9" label="Half Board (S)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt10" label="Breakfast Included (P)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt11" label="Full Board (P)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt12" label="Half Board (P)" />
                        </div>
                      </Col>
                      <Col md="4" xl="4" className="mb-3" id="roomCol3">
                        <span className="fs-5 fw-bold text-dark">Suite</span>
                        <div className="text-blue my-3" onClick={() => handleSelectAll("roomCol3")}>
                          {" "}
                          <CheckInput id="" label={selectStatus["roomCol3"] ? t('deselect_all') : t('select_all')} />
                        </div>

                        <div className="my-3">
                          <CheckInput id="roomt14" label="Breakfast Included (S)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt15" label="Full Board (S)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt16" label="Half Board (S)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt17" label="Breakfast Included (P)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt18" label="Full Board (P)" />
                        </div>
                        <div className="my-3">
                          <CheckInput id="roomt19" label="Half Board (P)" />
                        </div>
                      </Col>
                    </Row>
                  </fieldset>
                </form>
              </>
            )}
            {currentStep === 2 && (
              <>
                <form action="">
                  <fieldset className="my-3 bg-white p-5">
                    <>
                      <h2 className="fs-4 fw-bold text-dark">{t('ho_act_tarif_rest_selectU')}</h2>
                      <p className="fs-6 mb-4 w-75">{t('ho_act_tarif_rest_no')}</p>
                    </>
                    <Row className="d-flex flex-row">
                      <Col>
                        <Ratesupdate t={t} />
                      </Col>
                    </Row>
                  </fieldset>
                </form>
              </>
            )}
            {currentStep === 3 && (
              <>
                <form action="">
                  <fieldset className="my-3 bg-white p-5">
                    <>
                      <h2 className="fs-4 fw-bold text-dark">{t('ho_act_tarif_rest_majpP')}</h2>
                      <p className="fs-6 mb-4 w-75">{t('ho_act_tarif_rest_no')}</p>
                    </>
                    <Row className="d-flex flex-row">
                      <Col>
                        <Restrictionupdate t={t} />
                      </Col>
                    </Row>
                  </fieldset>
                </form>
              </>
            )}
          </Col>
        </Row>
      </div>
      <div className="container">
        <Row>
          <Col>
            <div className="p-2 my-4 d-flex flex-row">
              <span className="d-flex justify-content-start w-50">
                {currentStep > 0 && (
                  <Button color="orange" appearance="primary" onClick={handlePrevStep} className="px-5">
                    {t('previous')}
                  </Button>
                )}
              </span>
              <span className="d-flex justify-content-end w-50">
                {currentStep < 3 && (
                  <Button color="cyan" appearance="primary" onClick={handleNextStep} className="px-5">
                    {t('next')}
                  </Button>
                )}
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Restrictions;
