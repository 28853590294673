import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Accessibility, AirVent, Bath, BedIcon, ChefHat, ChevronsDown, ChevronsUp, Coffee, Gamepad2, Refrigerator, Ruler, ShowerHead, View } from "lucide-react";
import { Panel, PanelGroup } from "rsuite";
import DetailsRoom from "./DetailsRoom";
import DetailsBathroom from "./DetailsBathroom";
import Detailskitchen from "./Detailskitchen";
import DetailsViews from "./DetailsViews";
import Bed from "./Bed";
import DetailsPricing from "./DetailsPricing";
import KitchenFeatures from "./KitchenFeatures";
import DetailsLayout from "./DetailsLayout";
import BathroomFacilities from "./BathroomFacilities";
import DetailsBedding from "./DetailsBedding";
import DetailsAccessibility from "./DetailsAccessibility";
import DetailsDrinks from "./DetailsDrinks";
import DetailsEntertainment from "./DetailsEntertainment";
import DetailsConditioning from "./DetailsConditioning";
import { useTranslation } from "react-i18next";

const Informations = () => {
  const { t } = useTranslation();
  const [tarification, setTarification] = useState(null);
  const [optionSelectionnee, setOptionSelectionnee] = useState(null);
  const [roomDisplayed, setRoomDisplayed] = useState(false);

  const handleSave = () => {
    if (tarification) {
      const optionDetails = {
        label: tarification === "daily" ? t('pricing_on_daily_basis') : t('pricing_by_occupation'),
        description: tarification === "daily" ? t('pricing_daily_text') : t('pricing_occupation_text'),
      };
      setOptionSelectionnee(optionDetails);
    }
  };

  const handleModifier = () => {
    setTarification(null);
    setOptionSelectionnee(null);
  };

  const handleAddRoom = () => {
    setRoomDisplayed(true);
  };

  const [displayedPanels, setDisplayedPanels] = useState(false);

  const togglePanels = () => {
    setDisplayedPanels(!displayedPanels);
  };

  const panels = [
    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <ShowerHead className="mx-3" size={20} strokeWidth={1.5} /> {t('bathroom_details')}
        </div>
      }
      eventKey={1}>
      <DetailsBathroom />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <ChefHat className="mx-3" size={20} strokeWidth={1.5} /> {t('kitchen')}
        </div>
      }
      eventKey={2}>
      <Detailskitchen />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <Refrigerator className="mx-3" size={20} strokeWidth={1.5} /> {t('kitchen_features')}
        </div>
      }
      eventKey={3}>
      <KitchenFeatures />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <View className="mx-3" size={20} strokeWidth={1.5} /> {t('room_views')}
        </div>
      }
      eventKey={4}>
      <DetailsViews />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <Ruler className="mx-3" size={20} strokeWidth={1.5} /> {t('bedroom_layout')}
        </div>
      }
      eventKey={5}>
      <DetailsLayout />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <Bath className="mx-3" size={20} strokeWidth={1.5} /> {t('bathroom_equipment')}
        </div>
      }
      eventKey={6}>
      <BathroomFacilities />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <BedIcon className="mx-3" size={20} strokeWidth={1.5} /> {t('bedding')}
        </div>
      }
      eventKey={7}>
      <DetailsBedding />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <Accessibility className="mx-3" size={20} strokeWidth={1.5} /> {t('accessibility')}
        </div>
      }
      eventKey={8}>
      <DetailsAccessibility />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <Coffee className="mx-3" size={20} strokeWidth={1.5} /> {t('drinks_in_the_room')}
        </div>
      }
      eventKey={9}>
      <DetailsDrinks />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <Gamepad2 className="mx-3" size={20} strokeWidth={1.5} />{t('inroom_entertainment')}
        </div>
      }
      eventKey={10}>
      <DetailsEntertainment />
    </Panel>,

    <Panel
      header={
        <div className="fw-light fs-5 d-flex align-items-center">
          <AirVent className="mx-3" size={20} strokeWidth={1.5} /> {t('adjustable_air_conditionning')}
        </div>
      }
      eventKey={11}>
      <DetailsConditioning />
    </Panel>,
  ];

  return (
    <section className="container form-transition">
      <Row className="justify-content-center">
        <Col lg="10" xl="8" className="my-3">
          <>
            <div className="fs-3 fw-medium text-dark">{t('rooms_and_rates')}</div>
            <p className="fs-5 fw-light">{t('add_room_text')}</p>

            <div className="my-4 p-3 border">
              <span className="fw-light text-blue fs-5 d-flex align-items-center">{t('pricing_type')}</span>
              <p className="fs-6 my-1">{t('pricing_type_text')}</p>
              {!optionSelectionnee ? (
                <>
                  <div className="d-flex my-3">
                    <input type="radio" id="daily" name="tarification" onChange={() => setTarification("daily")} />
                    <label htmlFor="daily" className="mx-2">
                      <div className="fs-6 text-dark fw-medium">{t('pricing_on_daily_basis')}</div>
                      <p className="fw-light">{t('pricing_daily_text')}</p>
                    </label>
                  </div>

                  <div className="d-flex my-3">
                    <input type="radio" id="occupancy" name="tarification" onChange={() => setTarification("occupancy")} />
                    <label htmlFor="occupancy" className="mx-2">
                      <div className="fs-6 text-dark fw-medium">{t('pricing_by_occupation')}</div>
                      <p className="fw-light">{t('pricing_occupation_text')}</p>
                    </label>
                  </div>
                </>
              ) : (
                ""
              )}

              {optionSelectionnee && (
                <>
                  <div className="fs-6 text-dark">
                    {optionSelectionnee.label} -{" "}
                    <span className="text-blue" role="button" onClick={handleModifier}>
                      {t('edit')}
                    </span>
                  </div>
                  <p>{optionSelectionnee.description}</p>
                </>
              )}

              {!optionSelectionnee ? (
                <button type="button" className="btn-blue p-3 px-4 rounded-5" onClick={handleSave}>
                  {t('save')}
                </button>
              ) : (
                ""
              )}
            </div>

            {!roomDisplayed && (
              <div className="p-3 my-4 border">
                <Panel header={<div className="fw-light fs-5 d-flex align-items-center">{t('add_room_types')}</div>} collapsible defaultExpanded>
                  <p className="fs-6">{t('new_room_text')}</p>

                  <button type="button" onClick={handleAddRoom} className="btn-outline-blue my-3 p-3 px-5 rounded-5">
                    {t('add_room_type')}
                  </button>
                </Panel>
              </div>
            )}

            {roomDisplayed && (
              <>
                <div className="p-3 my-4 border">
                  <span className="fw-light text-blue fs-5 d-flex align-items-center">{t('room_information')}</span>
                  <p className="fs-6 my-1">{t('room_information_text')}</p>
                  <DetailsRoom />
                </div>

                <PanelGroup accordion defaultActiveKey={1} className="my-4 border rounded-0">
                  <div className="p-3">
                    <span className="fw-light text-blue fs-5 d-flex align-items-center">{t('room_services_and_equipments')}</span>
                    <p className="fs-6 my-1">{t('room_services_text')}</p>
                  </div>

                  <div>
                    {displayedPanels
                      ? panels.map((element, index) => (
                        <div className="border-bottom" key={index}>
                          {element}
                        </div>
                      ))
                      : panels.slice(0, 3).map((element, index) => (
                        <div className="border-bottom" key={index}>
                          {element}
                        </div>
                      ))}
                    {panels.length > 3 && (
                      <div className="fs-5 text-blue m-3" type="button" onClick={togglePanels}>
                        {displayedPanels ? (
                          <span>
                            {t('show_less')} <ChevronsUp size={20} />
                          </span>
                        ) : (
                          <span>
                            {t('show_more')}
                            <ChevronsDown size={20} />
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </PanelGroup>

                <div className="p-3 my-4 border">
                  <span className="fw-light text-blue fs-5 d-flex align-items-center">{t('beds_and_occupancy')}</span>
                  <p className="fs-6 my-1">{t('beds_and_occupancy_text')}</p>
                  <Bed />
                </div>

                <div className="p-3 my-4 border">
                  <span className="fw-light text-blue fs-5 d-flex align-items-center">{t('base_rate')}</span>
                  <p className="fs-6 my-1">{t('base_rate_text')}</p>
                  <DetailsPricing />
                </div>
              </>
            )}
          </>
        </Col>
      </Row>
    </section>
  );
};

export default Informations;
