import { ChevronUp, ChevronDown } from 'lucide-react'
import React from 'react'
import { useTranslation } from "react-i18next";

const SalesCountires = () => {

    const data = [
        { id: 1, img: "https://demos.pixinvent.com/vuexy-html-admin-template/assets/vendor/fonts/flags/1x1/us.svg", sales: "8,567€", country: "United states", count: "25.8%", status: "up" },
        { id: 2, img: "https://demos.pixinvent.com/vuexy-html-admin-template/assets/vendor/fonts/flags/1x1/br.svg", sales: "2,415€", country: "Brazil", count: "6.2%", status: "down" },
        { id: 3, img: "https://demos.pixinvent.com/vuexy-html-admin-template/assets/vendor/fonts/flags/1x1/fr.svg", sales: "7,987€", country: "France", count: "16.2%", status: "up" },
        { id: 4, img: "https://demos.pixinvent.com/vuexy-html-admin-template/assets/vendor/fonts/flags/1x1/cn.svg", sales: "12€", country: "Chine", count: "14.8%", status: "up" },
        { id: 5, img: "https://demos.pixinvent.com/vuexy-html-admin-template/assets/vendor/fonts/flags/1x1/in.svg", sales: "936€", country: "Inde", count: "11.9%", status: "down" }
    ]

    const { t } = useTranslation();

    return (
        <div className="rounded px-3 border h-100">
            <div className='my-3'>
                <div className="text-dark fw-medium fs-5">{t('sales_by_country')}</div>
                <p className='fs-6 fw-light'>{t('monthly_sales_overview')}</p>
            </div>
            <div className='mt-4'>
                {data.map(item => (
                    <div key={item.id} className='d-flex align-items-center justify-content-between my-3'>
                        <span className='d-flex'>
                            <img className='rounded-circle' src={item.img} width="45" height="45" alt={`Illustration du drapeau - ${item.country}`} />
                            <div className='mx-3'>
                                <div className='fs-6 fw-medium text-dark'>{item.sales}</div>
                                <div className='fs-6 fw-light'>{item.country}</div>
                            </div>
                        </span>
                        {item.status === "up" ?
                            <span className="fs-6 text-green">
                                <ChevronUp size={20} strokeWidth={1.5} /> {item.count}
                            </span> :
                            <span className="fs-6 text-red">
                                <ChevronDown size={20} strokeWidth={1.5} /> {item.count}
                            </span>}

                    </div>
                ))}
            </div>
        </div>
    )
}

export default SalesCountires
