import { useState } from "react";
import Input from "./Input";
import { Plus, Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";

const Highlights = () => {

  const { t } = useTranslation();

  const [forms, setForms] = useState([{ id: 1, value: "" }]);

  const addForm = () => {
    if (forms.length < 5) {
      const newId = forms.length + 1;
      setForms([...forms, { id: newId, value: "" }]);
    }
  };

  const removeForm = (id) => {
    const updatedForms = forms.filter((form) => form.id !== id);
    setForms(updatedForms);
  };

  const handleInputChange = (id, value) => {
    const updatedForms = forms.map((form) => (form.id === id ? { ...form, value } : form));
    setForms(updatedForms);
  };

  return (
    <div className="mt-1">
      <div className="my-3 d-flex flex-column"></div>
      {forms.map((form) => (
        <div className="my-3 d-flex flex-column" key={form.id}>
          <Input type="text" handleChange={(id, value) => handleInputChange(form.id, value)} value={form.value} />
          {forms.length > 1 && (
            <div role="button" className="align-self-end mt-2 border text-danger p-2 rounded-5" onClick={() => removeForm(form.id)}>
              <Trash2 size={20} /> <span className="text-dark">{t('delete')}</span>
            </div>
          )}
        </div>
      ))}
      {forms.length < 5 && (
        <span role="button" className="border rounded-5 text-dark text-center p-2 px-3" onClick={addForm}>
          <Plus size={20} /> {t('add')}
        </span>
      )}
    </div>
  );
};

export default Highlights;
