import { Col, Row } from "react-bootstrap";
import { RadioTile, RadioTileGroup } from "rsuite";
import { useTranslation } from "react-i18next";
const Choice = ({ text, onChange, selectedChoice }) => {
  const { t } = useTranslation();
  return (
    <Row className="align-items-center">
      <Col lg="8">
        <span className="fs-6 fw-medium text-dark">{text}</span>
      </Col>
      <Col lg="4">
        <RadioTileGroup defaultValue={selectedChoice}>
          <Row>
            <Col xs="6" sm="4" md="3" lg="6" className="my-3">
              <RadioTile value="yes" onChange={onChange} className={`rounded-0 ${selectedChoice === "yes" ? "border-blue text-white" : "border"}`}>
                <div className={`mt-2 fs-6`}>{t('yes')}</div>
              </RadioTile>
            </Col>

            <Col xs="6" sm="4" md="3" lg="6" className="my-3">
              <RadioTile value="no" onChange={onChange} className={` rounded-0 ${selectedChoice === "no" ? "border-blue text-white" : "border"}`}>
                <div className={`mt-2 fs-6`}>{t('no')}</div>
              </RadioTile>
            </Col>
          </Row>
        </RadioTileGroup>
      </Col>
    </Row>
  );
};

export default Choice;
