import { Row, Col } from "react-bootstrap";
import Input from "../../../components/forms/Input";

const Calendar = ({ t, group, filters, handleInputChange }) => {
  return (
    <div className="my-3 text-dark">
      <Row className="align-items-center">
        <Col md="4" className="my-1">
          <span className="text-blue">{t('min_age')} : </span>
          <div className="my-2">
            <Input
              type="number"
              placeholder="Âge min"
              value={filters[group].min}
              handleChange={(e) => handleInputChange(e, group, 'min')}
              disabled={!filters[group].checked}
              name={`${group}-min`}
            />
          </div>
        </Col>

        <Col md="4" className="my-1">
          <span className="text-blue">{t('max_age')}: </span>
          <div className="my-2">
            <Input
              type="number"
              placeholder="Âge max"
              value={filters[group].max}
              handleChange={(e) => handleInputChange(e, group, 'max')}
              disabled={!filters[group].checked}
              name={`${group}-max`}
            />
          </div>
        </Col>

        <Col md="4" className="my-3">
          <span className="text-blue">{t('client_pay')} :</span>
          <div className="my-2">
            <Input
              type="number"
              placeholder="Montant"
              value={filters[group].amount}
              handleChange={(e) => handleInputChange(e, group, 'amount')}
              name={`${group}-amount`}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Calendar;
