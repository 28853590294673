import React, { useState } from 'react';
import Input from '../../components/forms/Input';
import { message } from 'antd';
import { useTranslation } from "react-i18next";

const SignUp = ({ handleLogin }) => {

  const { t } = useTranslation();

  const [pseudo, setPseudo] = useState('');
  const [password, setPassword] = useState('');

  const handlePseudo = (e) => {
    setPseudo(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleError = () => {
    message.error('Identifiants incorrects');
  };

  return (
    <div className='text-dark py-5'>
      <div className='text-center'>
        <div className='fs-3 fw-bold'>{t('register')}</div>
        <p className='fs-5 fw-lighter'>{t('register_text')}</p>
      </div>

      <div className='my-5'>

        <div className='my-4'>
          <Input placeholder={t('pseudo')} type="text" />
        </div>

        <div className='my-4'>
          <Input value={pseudo} handleChange={handlePseudo} placeholder={t('email')} type="email" />
        </div>

        <div className='my-4'>
          <Input value={password} handleChange={handlePassword} placeholder={t('password')} type="password" />
        </div>

        {pseudo && password ? (
          <button
            className='btn-blue p-4 w-100'
            onClick={() => {
              if (pseudo === "idh" && password === "idh2024") {
                handleLogin();
              } else {
                handleError();
              }
            }}
          >
            {t('register')}
          </button>
        ) : (
          <button className='btn-outline-blue p-4 w-100' disabled>
            {t('register')}
          </button>
        )}
      </div>
    </div>
  );
};

export default SignUp;
