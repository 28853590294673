import { Row, Col } from "react-bootstrap";
import Choice from "../../../components/Choice";
import CheckInput from "../../../components/forms/CheckInput";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, DateRangePicker } from "rsuite";
import ValidityAndDuration from "./ValidityAndDuration";
import Cancel from "./Cancel";
import MeetingPoint from "./MeetingPoint";
import Schedule from "./Schedule";

const OptionSetup = (props) => {

  const { t } = useTranslation();

  const handleEnd = () => {
    setInfos({ ...infos, end: !infos.end });
  };

  const { beforeToday } = DateRangePicker;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  const [infos, setInfos] = useState({
    end: false,
  });

  return (
    <section className="bg-white form-transition">
      <Row className="justify-content-center">
        <Col lg={`${props.lg ? props.lg : "7"}`} xl="6" className="my-3">
          <>
            <h2 className="fs-3 lh-base fw-medium text-dark">{t('activityperso')}</h2>
            <p className="fs-5 fw-light">{t('activitypersoP')}</p>

            <div className="my-4">
              <Choice
                text={
                  <>
                    <div className="fs-5 fw-medium text-dark">{t('activityprive')}</div> <p className="fs-6 fw-light">{t('activitypriveP')}</p>
                  </>
                }
              />
            </div>
            <ValidityAndDuration />
            <Schedule />
            <div className="my-4">
              <h2 className="fs-5 fw-medium text-dark">{t('activitytime1')}</h2>
              <p className="fs-6 fw-light">{t('activitytime1P')}</p>
              <Row className="my-3 fs-6">
                <Col md="6" className="my-3">
                  <DatePicker className="border w-100 p-3" oneTap placeholder="Début" onChange={handleStartDate} value={startDate} shouldDisableDate={beforeToday()} />
                </Col>

                {infos.end && (
                  <Col md="6" className="my-3">
                    <DatePicker className="border w-100 p-3" oneTap placeholder="Fin" onChange={handleEndDate} value={endDate} shouldDisableDate={beforeToday()} />
                  </Col>
                )}
              </Row>
              <div className="my-3 fs-6">
                <CheckInput id="DateFinActivité" label={t('activityMy')} onChange={handleEnd} />
              </div>
            </div>


            <Cancel />
            <MeetingPoint t={t} />
          </>
        </Col>
      </Row>

    </section>
  );
};

export default OptionSetup;
