import { Row, Col } from "react-bootstrap";
import { useState } from "react";

const Discount = ({ t }) => {
  const data = [
    {
      id: 1,
      title: t('promo_new_ads'),
      content: t('promo_new_ads_caption'),
      defaultValue: 20,
      isChecked: false,
    },
    {
      id: 2,
      title: t('weekly_discount'),
      content: t('weekly_discount_caption'),
      defaultValue: 10,
      isChecked: false,
    },
    {
      id: 3,
      title: t('monthly_discount'),
      content: t('monthly_discount_caption'),
      defaultValue: 15,
      isChecked: false,
    },
  ];

  const [dataItems, setDataItems] = useState(data);

  const handleCheckboxChange = (id) => {
    setDataItems((prevData) =>
      prevData.map((item) => {
        if (item.id === id) {
          return { ...item, isChecked: !item.isChecked };
        }
        return item;
      })
    );
  };

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="7" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('reductions_title')}</h2>
              <p className="fs-5 fw-light">{t('reductions_caption')}</p>

              <div className="mt-4 text-dark">
                {dataItems.map((item) => (
                  <Row
                    key={item.id}
                    className="m-0 my-3 border rounded-4 p-3 py-4 align-items-center bg-light
                      ">
                    <Col xl="2" className="my-3">
                      <div className="d-flex align-items-center justify-content-center bg-white border p-2 rounded fs-5">
                        <input style={{ width: "40px" }} type="number" className="border-0 fw-medium text-center bg-white" defaultValue={item.defaultValue} disabled={!item.isChecked} />%
                      </div>
                    </Col>
                    <Col xl="9" className="my-3">
                      <div>
                        <div className="fs-6 fw-bold">{item.title}</div>
                        <div className="fs-6 fw-light">{item.content}</div>
                      </div>
                    </Col>
                    <Col xl="1" className="my-3">
                      <input type="checkbox" checked={item.isChecked} onChange={() => handleCheckboxChange(item.id)} />
                    </Col>
                  </Row>
                ))}
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Discount;
