import Accordion from 'react-bootstrap/Accordion'
import { User, CheckCheck, X } from 'lucide-react'
import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import { message } from 'antd';

function Tarifs() {

    const { t } = useTranslation();

    const handleConfirmDeactivate = () => {
        setTimeout(() => {
            messageSuccess()
            setShow(false)
        }, 100);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const messageSuccess = () => {
        message.success(t('disabled_txt'));
    };

    return (
        <>
            <Accordion className='bg-white' defaultActiveKey="0" flush>
                <Accordion.Item className='bg-white' eventKey="0">
                    <Accordion.Header className='bg-white' >6 {t('ho_act_cancellation_nivtar')}</Accordion.Header>
                    <Accordion.Body>
                        <div className='container'>
                            <Row>
                                <Col className='d-flex flex-column mb-4'>
                                    <h6 className=' my-2'> Full Board (203290245)</h6>
                                    <b className='text-blue'>{t('ho_act_newrate_hotel')}</b>
                                    <span className='my-1'> <CheckCheck className='mx-1' strokeWidth={2} width={18} />{t('ho_act_code')} : BB </span>
                                    <span className='my-1'> <User className='mx-1' strokeWidth={2} width={18} />{t('ho_act_occu')} : 2</span>
                                    <span className='mt-1'> <X className='mx-1' strokeWidth={2} width={18} />{t('ho_act_cancel')} : </span>
                                    <span className=' text-blue'> <Link to='../updatecancel' className='mx-1'>{t('cancel_policy_and_change')}</Link>  </span>
                                    <span className='mt-4'>
                                        <Link to="../newrate" className="link-style">{t('ho_act_update')}</Link>
                                        <button className='bg-white text-primary mx-3' onClick={handleShow}> {t('delete')}</button>
                                    </span>
                                </Col>
                                <Col className='d-flex flex-column mb-4'>
                                    <h6 className=' my-2'> Full Board (203290245)</h6>
                                    <b className='text-blue'>{t('ho_act_newrate_hotel')}</b>
                                    <span className='my-1'> <CheckCheck className='mx-1' strokeWidth={2} width={18} />{t('ho_act_code')} : BB </span>
                                    <span className='my-1'> <User className='mx-1' strokeWidth={2} width={18} />{t('ho_act_occu')} : 2</span>
                                    <span className='mt-1'> <X className='mx-1' strokeWidth={2} width={18} />{t('ho_act_cancel')} : </span>
                                    <span className=' text-blue'> <Link to='../updatecancel' className='mx-1'>{t('cancel_policy_and_change')}</Link>  </span>
                                    <span className='mt-4'>
                                        <Link to="../newrate" className="link-style">{t('ho_act_update')}</Link>
                                        <button className='bg-white text-primary mx-3' onClick={handleShow}> {t('delete')}</button>
                                    </span>
                                </Col>
                                <Col className='d-flex flex-column mb-4'>
                                    <h6 className=' my-2'> Full Board (203290245)</h6>
                                    <b className='text-blue'>{t('ho_act_newrate_hotel')}</b>
                                    <span className='my-1'> <CheckCheck className='mx-1' strokeWidth={2} width={18} />{t('ho_act_code')} : BB </span>
                                    <span className='my-1'> <User className='mx-1' strokeWidth={2} width={18} />{t('ho_act_occu')} : 2</span>
                                    <span className='mt-1'> <X className='mx-1' strokeWidth={2} width={18} />{t('ho_act_cancel')} : </span>
                                    <span className=' text-blue'> <Link to='../updatecancel' className='mx-1'>{t('cancel_policy_and_change')}</Link>  </span>
                                    <span className='mt-4'>
                                        <Link to="../newrate" className="link-style">{t('ho_act_update')}</Link>
                                        <button className='bg-white text-primary mx-3' onClick={handleShow}> {t('delete')}</button>
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='d-flex flex-column mb-4'>
                                    <h6 className=' my-2'> Full Board (203290245)</h6>
                                    <b className='text-blue'>{t('ho_act_newrate_hotel')}</b>
                                    <span className='my-1'> <CheckCheck className='mx-1' strokeWidth={2} width={18} />{t('ho_act_code')} : BB </span>
                                    <span className='my-1'> <User className='mx-1' strokeWidth={2} width={18} />{t('ho_act_occu')} : 2</span>
                                    <span className='mt-1'> <X className='mx-1' strokeWidth={2} width={18} />{t('ho_act_cancel')} : </span>
                                    <span className=' text-blue'> <Link to='../updatecancel' className='mx-1'>{t('cancel_policy_and_change')}</Link>  </span>
                                    <span className='mt-4'>
                                        <Link to="../newrate" className="link-style">{t('ho_act_update')}</Link>
                                        <button className='bg-white text-primary mx-3' onClick={handleShow}> {t('delete')}</button>
                                    </span>
                                </Col>
                                <Col className='d-flex flex-column mb-4'>
                                    <h6 className=' my-2'> Full Board (203290245)</h6>
                                    <b className='text-blue'>{t('ho_act_newrate_hotel')}</b>
                                    <span className='my-1'> <CheckCheck className='mx-1' strokeWidth={2} width={18} />{t('ho_act_code')} : BB </span>
                                    <span className='my-1'> <User className='mx-1' strokeWidth={2} width={18} />{t('ho_act_occu')} : 2</span>
                                    <span className='mt-1'> <X className='mx-1' strokeWidth={2} width={18} />{t('ho_act_cancel')} : </span>
                                    <span className=' text-blue'> <Link to='../updatecancel' className='mx-1'>{t('cancel_policy_and_change')}</Link>  </span>
                                    <span className='mt-4'>
                                        <Link to="../newrate" className="link-style">{t('ho_act_update')}</Link>
                                        <button className='bg-white text-primary mx-3' onClick={handleShow}> {t('delete')}</button>
                                    </span>
                                </Col>
                                <Col className='d-flex flex-column mb-4'>
                                    <h6 className=' my-2'> Full Board (203290245)</h6>
                                    <b className='text-blue'>{t('ho_act_newrate_hotel')}</b>
                                    <span className='my-1'> <CheckCheck className='mx-1' strokeWidth={2} width={18} />{t('ho_act_code')} : BB </span>
                                    <span className='my-1'> <User className='mx-1' strokeWidth={2} width={18} />{t('ho_act_occu')} : 2</span>
                                    <span className='mt-1'> <X className='mx-1' strokeWidth={2} width={18} />{t('ho_act_cancel')} : </span>
                                    <span className=' text-blue'> <Link to='../updatecancel' className='mx-1'>{t('cancel_policy_and_change')}</Link>  </span>
                                    <span className='mt-4'>
                                        <Link to="../newrate" className="link-style">{t('ho_act_update')}</Link>
                                        <button className='bg-white text-primary mx-3' onClick={handleShow}> {t('delete')}</button>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>


            </Accordion>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <div className='text-dark fs-3 text-dark'>{t('disable_rate_level')}</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-dark'>
                        {t('disable_rate_level_txt')}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn-secondary-blue p-3" onClick={handleClose}>
                        {t('close')}
                    </button>
                    <button className="btn-blue p-3" onClick={handleConfirmDeactivate}>
                        {t('save')}
                    </button>
                </Modal.Footer>
            </Modal>


        </>

    );
}

export default Tarifs;