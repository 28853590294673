import { Accessibility } from "lucide-react";
import ManageHeader from "../../components/ManageHeader";

const Services = () => {
    
  return (
    <section>
        <ManageHeader
                title="Services et équipements de l’hébergement"
                content="Indiquez les services et équipements dont peuvent profiter les voyageurs pendant leur séjour."
                icon={<Accessibility size={25} strokeWidth={1.5} />}
            />
        <div className="container my-4">
          Services
        </div>
    </section>
  )
}

export default Services
