import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import ParkingFees from "./ParkingFees";
import ParkingType from "./ParkingType";
import Input from "../../../components/forms/Input";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsParking = ({ checked, checkedItems }) => {

  const { t } = useTranslation();

  const mesures = [
    { title: t('feet'), value: "Pieds" },
    { title: t('kilometers'), value: "Kilométres" },
    { title: t('meters'), value: "Métres" },
    { title: t('miles'), value: "Miles" },
  ];

  const parking = [
    { title: t('garage'), value: "Garage" },
    { title: t('kilometers'), value: "Carport" },
  ];

  return (
    <div className="my-3 fs-6">
      <>
        <>
          {["SansVoiturier", "AvecVoiturier", "Prolongé"].includes(checked) && (
            <>
              <ParkingFees />
              <ParkingType />
            </>
          )}

          {checked === "Camion" && <ParkingFees />}

          {checked === "Dehors" && (
            <>
              <ParkingFees />
              <Row>
                <Col sm="6" xl="8" className="my-3">
                  <Input placeholder={t('parking_distance')} type="number" />
                </Col>
                <Col sm="6" xl="4" className="my-3">
                  <Select data={mesures} />
                </Col>
              </Row>
            </>
          )}

          {checked === "HorsRue" && (
            <div className="px-3">
              {parking.map((item, index) => (
                <div key={index} className="my-3">
                  <CheckInput id={item.value} label={item.title} />
                </div>
              ))}
            </div>
          )}

          {checked === "EmplacementsLimités" && (
            <div className="p-3 my-3">
              <CheckInput id="NombreLimitéParHébergement" label={t('parking_limited_places')} info={t('parking_max_places')} />
            </div>
          )}
        </>
      </>
    </div>
  );
};

export default DetailsParking;
