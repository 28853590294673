import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Badge } from 'rsuite'
import { CiBadgeDollar } from "react-icons/ci"
import { Button } from 'rsuite'
import Rate1 from '../../assets/slide/bg-d.png'
import RateD from '../../assets/slide/elcomparador.png'
import { GiTakeMyMoney } from "react-icons/gi"
import { IoIosArrowUp } from 'react-icons/io'
import { ArrowUp, MonitorCheck } from 'lucide-react'
import { GiSandsOfTime } from "react-icons/gi"
import { GrMoney } from "react-icons/gr"
import { FiActivity } from "react-icons/fi"
import { IoMdTrendingDown } from "react-icons/io"
import { FaArrowTrendUp } from "react-icons/fa6"
import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import Draggable from 'react-draggable'
import { Link } from 'react-router-dom'
import ManageHeader from "../../components/ManageHeader";

const Rateadj = ({ t }) => {
    const [showModal, setShowModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleCloseSecondModal = () => setShowSecondModal(false);

    const handleOpenModal = () => setShowModal(true);

    const handleShowSecondModal = () => {
        handleCloseModal();
        setShowSecondModal(true);
    };

    return (
        <section>
            <ManageHeader
                title={t('ho_act_tarification_auto')}
                content={t('ho_act_tarification_auto_caption')}
                icon={<MonitorCheck size={25} strokeWidth={1.5} />}
            />

            <div className='container my-4'>
                <Row className='border rounded bg-white'>
                    <Col md="6" lg="9" className='p-3'>
                        <div className='p-2'>
                            <div className='backimgcontent mt-3'>
                                <Badge color='blue' content="Automatique"></Badge>
                                <div className='fs-4 fw-bold text-dark mt-2 mb-3'>{t('ho_act_tarification_auto')}</div>
                                <div><span className='' style={{ color: '#333333' }}>{t('ho_act_tarification_auto_txt')}</span></div>
                                <span className='fs-8 fw-bold text-dark' to='#'>
                                    <div className='mt-3 d-flex justify-content-between'>
                                        {t('ho_act_tarification_txt1')}
                                        <span className='text-blue d-flex justify-content-end'><CiBadgeDollar className='fs-6' /></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col xl='3' className='backrate'>

                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col md="6" lg="4" className='my-2 p-1'>
                        <div className='bg-white border rounded p-3 h-100'>
                            <div className='d-flex justify-content-between'>
                                <div className=''>
                                    <div className='fs-5'>{t('ho_act_tarification_plus')}</div>
                                    <div className='fs-7 fw-bold text-dark my-2'>{t('ho_act_tarification_plus_txt')}</div>

                                </div>
                                <div>
                                    <GrMoney className='text-orange' style={{ width: '50px', height: '50px' }} />
                                </div>
                            </div>
                            <div className='d-flex flex-row justify-content-between'>
                                <span className='text-blue'><ArrowUp /> 72%</span>
                                <span className='ms-4'><Badge className='p-1' color='cyan' content="Boost reservations"></Badge></span>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="4" className='my-2 p-1'>
                        <div className='bg-white border rounded p-3 h-100'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div className='fs-5'>{t('ho_act_tarification_optimisation')}</div>
                                    <div className='fs-7 fw-bold text-dark my-2'>{t('ho_act_tarification_optimisation_txt')}</div>
                                </div>
                                <div>
                                    <GiSandsOfTime className='text-green' style={{ width: '60px', height: '60px' }} />
                                </div>
                            </div>
                            <div className='d-flex flex-row justify-content-between'>
                                <span className='text-green'><ArrowUp /> 55%</span>
                                <span className='ms-4'><Badge className='p-1' color='blue' content="Time saving"></Badge></span>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="4" className='my-2 p-1'>
                        <div className='bg-white border rounded p-3 h-100'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div className='fs-5'>{t('ho_act_tarification_visibilte')}</div>
                                    <div className='fs-7 fw-bold text-dark my-2'>{t('ho_act_tarification_visibilite_txt')}</div>
                                </div>
                                <div>
                                    <FiActivity className='text-blue' style={{ width: '50px', height: '50px' }} />
                                </div>
                            </div>
                            <div className='d-flex flex-row justify-content-between'>
                                <span className='text-orange'><ArrowUp /> 25%</span>
                                <span className='ms-4'><Badge className='p-1' color='green' content="Heighten presence"></Badge></span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='border rounded bg-white'>
                    <Col md="6" lg="8" className='pb-3 px-1'>
                        <div className='p-2'>
                            <div className='backimgcontent mt-2'>
                                <div className='fs-4 fw-bold text-dark mt-2 mb-4 d-flex justify-content-between'>{t('ho_act_tarification_comment')}<CiBadgeDollar className='mt-2 text-blue' /></div>
                                <div><span className='' style={{ color: '#333333' }}>{t('ho_act_tarification_comment_txt')}</span></div>
                                <span className='fs-5 fw-bold text-dark' >
                                    <div className='mt-4 d-flex'>
                                        {t('ho_act_tarification_hausse')} <FaArrowTrendUp className='mt-1 ms-3' color='green' />
                                        <div className=''>
                                            <Badge className='mx-5' color="cyan" content="200 $">
                                                <Button >{t('ho_act_tarification_tarif')}</Button>
                                            </Badge>
                                            <Badge className='me-5' color="blue" content="205 $">
                                                <Button >{t('ho_act_tarification_booking')} </Button>
                                            </Badge>
                                            <Badge color="violet" content="205 $">
                                                <Button >{t('ho_act_tarification_ajuste')}</Button>
                                            </Badge>
                                        </div>
                                    </div>
                                </span>
                                <span className='fs-5 fw-bold text-dark' >
                                    <div className='mt-4 d-flex'>
                                        {t('ho_act_tarification_baisse')}  <IoMdTrendingDown className='mt-2 ms-4' color='red' />
                                        <div className=''>
                                            <Badge className='mx-5' color='violet' content="210 $">
                                                <Button >{t('ho_act_tarification_tarif')}</Button>
                                            </Badge>
                                            <Badge className='me-5' color="cyan" content="119 $">
                                                <Button >{t('ho_act_tarification_booking')} </Button>
                                            </Badge>
                                            <Badge color="blue" content="119 $">
                                                <Button >{t('ho_act_tarification_ajuste')}</Button>
                                            </Badge>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col xl='4' className='p-4 pe-5 mt-2'>
                        <img alt='rate adjustement' src={RateD} style={{ width: '100%', height: '100%' }} />
                    </Col>
                </Row>
                <Row className='d-flex flex-row align-items-start my-4'>
                    <Col lg='8' className='backgroundimageR border rounded p-2'>
                        <div className='d-flex flex-row'>
                            <div className='content mt-3'>
                                <div className='fs-5 fw-bold text-dark mb-3'>{t('ho_act_tarification_vosTarifs')}</div>
                                <div><span className='' style={{ color: '#333333' }}>{t('ho_act_tarification_vosTarifs_txt')}</span></div>
                                <div className='mt-4'>
                                    <Button appearance="primary" color="yellow" className='me-4 px-5 fw-medium' onClick={handleOpenModal}>{t('register')}</Button>
                                    <Button appearance="primary" color="cyan" className='px-5 fw-medium'>
                                        <Link to="../adjustedrate" className="text-white">{t('ho_act_tarification_tarifsadj')}</Link>
                                    </Button></div>
                            </div>
                            <div className='p-4'>
                                <img alt='rate adjustment' src={Rate1} />
                            </div>
                        </div>
                    </Col>
                    <Col lg='4' className=''>
                        <div className='bg-white border rounded' >
                            <div className='content'>
                                <div className='d-flex'>
                                    <GiTakeMyMoney className='mt-4 fs-1 text-green' />
                                    <span className='fs-3 fw-bold text-dark mt-4 mx-3'>{t('ho_act_tarification_ventes')}</span>
                                </div>
                                <div><span className='ms-5 mb-4 ps-2' style={{ color: '#333333' }}>{t('ho_act_tarification_avecAj')}</span></div>
                                <div className='mt-3 ps-3'><span className='text-green fw-bolder fs-4'>$  559,526.564</span></div>

                                <div className='fs-8 ps-3 mb-3 fw-bold text-dark d-flex flex-row'>
                                    <Badge className='mt-3 me-4 py-1 px-2 bg-green rounded'>
                                        45% <IoIosArrowUp />
                                    </Badge>
                                    <div className='mt-3 p-1 d-flex justify-content-between'>
                                        {t('ho_act_tarification_auto_vs')}
                                        <span className='text-orang'></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered className='rounded-2 p-2'>
                <Draggable>
                    <div className='border rounded bg-white'>
                        <Modal.Header closeButton className="modal-header">
                            <Modal.Title className="text-modal">{t('ho_act_tarification_auto_merci')}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body className='bg-white text-modal d-flex flex-column'>
                            <p>{t('ho_act_tarification_auto_merci_txt')}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="cyan" appearance="primary" onClick={handleShowSecondModal} className="me-2">{t('register')}</Button>
                            <Button color="orange" appearance="primary" onClick={handleCloseModal}>{t('cancel')}</Button>
                        </Modal.Footer>
                    </div>
                </Draggable>
            </Modal>

            <Modal show={showSecondModal} onHide={handleCloseSecondModal} centered className='rounded-2 p-2'>
                <Draggable>
                    <div className='border rounded bg-white'>
                        <Modal.Header closeButton className="modal-header">
                            <Modal.Title className="text-modal">{t('ho_act_tarification_auto_merci')}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body className='bg-white text-modal d-flex flex-column'>
                            <p>{t('ho_act_tarification_auto_done')}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="orange" appearance="primary" onClick={handleCloseSecondModal}>{t('close')}</Button>
                        </Modal.Footer>
                    </div>
                </Draggable>
            </Modal>
        </section>
    );
}

export default Rateadj;
