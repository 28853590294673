import { Info, MinusCircle, PlusCircle } from "lucide-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Informations = ({ data, handleIncrement, handleChange }) => {
  return (
    <>
      {data.map((item, index) => (
        <div className="d-flex justify-content-between py-4 border-bottom fs-6 mt-3" key={index}>
          <div className="text-dark fs-5">
            {item.label}
            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>{item.content}</Tooltip>}>
              <span className="text-blue mx-1" role="button">
                <Info size={20} strokeWidth={1.5} />
              </span>
            </OverlayTrigger>
          </div>

          <div className="d-flex align-items-center">
            <button type="button" className="mx-3 bg-white text-blue" onClick={() => handleIncrement(item.key, "decrement")} disabled={item.value <= item.min}>
              <MinusCircle strokeWidth={1} size={40} />
            </button>

            <input className="border-0 text-center fs-6" value={item.value} type="number" style={{ width: "30px", height: "30px" }} onChange={(e) => handleChange(item.key, e.target.value)} />

            <button type="button" className="mx-3 bg-white  text-blue" onClick={() => handleIncrement(item.key, "increment")} disabled={item.value >= item.max}>
              <PlusCircle strokeWidth={1} size={40} />
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default Informations;
