import { Row, Col } from "react-bootstrap";
import { RadioTile, RadioTileGroup } from "rsuite";
import { useState } from "react";
import { AiOutlineMessage,AiOutlineThunderbolt } from "react-icons/ai";

const Booking = ({ t }) => {
  const [infos, setInfos] = useState({
    choice: "",
  });

  const handleChoice = (value) => {
    setInfos({ ...infos, choice: value });
  };

  const data = [
    { title: t('instant_booking'), icon: <AiOutlineThunderbolt size={35} />, value: "Réservation instantanée", content: t('instant_booking_caption') },
    {
      title: t('accept_requests'),
      icon:<AiOutlineMessage size={35} />,
      value: "Accepter ou refuser les demandes",
      content: t('accept_requests_caption'),
    },
  ];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="6" className="my-3">
            <div className="bg-white">
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('booking_title')}</h2>
              <p className="fs-5 fw-light">{t('booking_caption')}</p>
              <RadioTileGroup>
                <Row className="mt-3">
                  {data.map((choice, index) => (
                    <Col key={index} xs="12" className="my-2">
                      <RadioTile onChange={handleChoice} value={choice.value} className={`rounded-4 py-5 p-3 ${infos.choice === choice.value ? "border-dark" : "border"}`}>
                        <div className="text-dark d-flex">
                          {choice.icon}
                          <span className="mx-3 ">
                            <div className="fs-5 fw-medium">{choice.title}</div>
                            <p className="fs-6 fw-light">{choice.content}</p>
                          </span>
                        </div>
                      </RadioTile>
                    </Col>
                  ))}
                </Row>
              </RadioTileGroup>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Booking;
