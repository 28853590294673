import { Row, Col } from "react-bootstrap";
import { Home } from "lucide-react";
import ManageHeader from "../../components/ManageHeader";
import { useParams } from 'react-router-dom';
import products from '../../data/products.json'
import { Progress } from "rsuite";
import BookingActivities from "./components/BookingActivities";

const Details = ({ t }) => {

  const { id } = useParams();
  const item = products.products.find(item => item.id === parseInt(id));

  return (
    <section>
      <ManageHeader title={t('my_product_title')} content={t('manage_caption')} icon={<Home size={25} strokeWidth={1.5} />} />

      <div className="container py-4">
        <Row>
          <Col xl="6" className="my-3">
            <div className="border rounded p-3 h-100">
              <Row className="justify-content-between align-items-end">
                <Col xl="9">
                  <div className="fs-4 fw-medium text-dark">{item.title}</div>
                  <div className="fs-5 text-capitalize my-2">{item.type}</div>
                  <p className="fs-6 fw-light my-2">{t('created_the')} : {item.date}</p>
                  <div className="mt-3">
                    <Progress.Line className="p-0" percent={item.progress} strokeColor="#8077EC" />
                  </div>
                </Col>
                <Col xl="2">
                  <div className="fs-6 fw-light text-blue">{t('continue')}</div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="6" xl="3" className="my-3">
            <div className="rounded text-white text-center bg-blue h-100 p-5">
              <div className="fs-1 fw-medium">{item.amount}€</div>
              <p className="fs-5 fw-light">{t('total_benefits_for_this_product')}</p>
            </div>
          </Col>
          <Col md="6" xl="3" className="my-3">
            <div className="rounded text-dark text-center bg-orange h-100 p-5">
              <div className="fs-1 fw-medium">{item.bookings}</div>
              <p className="fs-5 fw-light">{t('total_bookings_for_this_product')}</p>
            </div>
          </Col>
        </Row>
        <BookingActivities t={t} />
      </div>
    </section>
  );
};

export default Details;
