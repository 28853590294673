import ManageHeader from '../../components/ManageHeader'
import { CheckCircle, ShieldAlert, Wrench } from 'lucide-react'
import { Col, Row } from 'react-bootstrap'
import Title from '../product/Title'
import { useState } from 'react'
import Description from '../product/Description'
import Inclusions from '../product/components/Inclusions'
import Additional from '../product/Additional'
import Photos from '../product/Photos'
import { Collapse } from 'antd';

const Administration = ({ t }) => {

    const [view, setView] = useState(t('title'))

    const toggleView = (value) => (
        setView(value)
    )

    const buttons = [
        { title: t('title'), component: <Title lg="12" xl="12" />, status: true },
        { title: t('description'), component: <Description lg="12" xl="12" />, status: true },
        { title: t('inclusions'), component: <Inclusions lg="12" xl="12" />, status: true },
        { title: t('additionnal'), component: <Additional lg="12" xl="12" />, status: false },
        { title: t('photos'), component: <Photos lg="12" xl="12" />, status: false },
        
    ]

    const items = [
        {
            key: '1',
            label: t('main_informations'),
            children: <>{buttons.slice(0, 5).map((button) => (
                <div role='button' className='fs-6 fw-light mb-2' onClick={() => (toggleView(button.title))}>
                    {button.status ? <span className='text-green'><CheckCircle size={20} strokeWidth={1.5} /></span> : <span className='text-red'><ShieldAlert size={20} strokeWidth={1.5} /></span>} <span className='mx-2'>{button.title}</span>
                </div>
            ))}</>,
        },
        {
            key: '2',
            label: t('whats_included'),
            children: <>{buttons.slice(5, 8).map((button) => (
                <div role='button' className='fs-6 fw-light mb-2' onClick={() => (toggleView(button.title))}>
                    {button.status ? <span className='text-green'><CheckCircle size={20} strokeWidth={1.5} /></span> : <span className='text-red'><ShieldAlert size={20} strokeWidth={1.5} /></span>} <span className='mx-2'>{button.title}</span>
                </div>
            ))}</>,
        },
        {
            key: '3',
            label: t('extra_informations'),
            children: <>{buttons.slice(8, 12).map((button) => (
                <div role='button' className='fs-6 fw-light mb-2' onClick={() => (toggleView(button.title))}>
                    {button.status ? <span className='text-green'><CheckCircle size={20} strokeWidth={1.5} /></span> : <span className='text-red'><ShieldAlert size={20} strokeWidth={1.5} /></span>} <span className='mx-2'>{button.title}</span>
                </div>
            ))}</>,
        }
        ,
        {
            key: '4',
            label: t('availability'),
            children: <>{buttons.slice(12, 16).map((button) => (
                <div role='button' className='fs-6 fw-light mb-2' onClick={() => (toggleView(button.title))}>
                    {button.status ? <span className='text-green'><CheckCircle size={20} strokeWidth={1.5} /></span> : <span className='text-red'><ShieldAlert size={20} strokeWidth={1.5} /></span>} <span className='mx-2'>{button.title}</span>
                </div>
            ))}</>,
        }
    ];
    return (
        <section>
            <ManageHeader
                title={t('admin')}
                content={t('admin_caption')}
                icon={<Wrench size={25} strokeWidth={1.5} />}
            />

            <div className="container-lg py-4">

                <Row>
                    <Col md="4" lg="4" xl="3" className='my-3'>
                        <>
                            <Collapse defaultActiveKey={['1']} ghost items={items} />
                        </>
                    </Col>
                    <Col md="8" lg="8" xl="9">
                        {buttons.map((button) => (
                            <>
                                {view === button.title && button.component}
                            </>
                        ))}
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Administration
