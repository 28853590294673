import TagInput from "../../../components/forms/TagInput";
import Textarea from "../../../components/forms/Textarea";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Restrictions = (props) => {

  const { t } = useTranslation();

  const [moreInfo, setMoreInfo] = useState("");

  const handleMoreInfo = (event) => {
    setMoreInfo(event.target.value);
  };

  const notSuitable = [
    { title: "Bébés", value: "Bébés" },
    { title: "Enfants de moins de 05 ans", value: "Enfants de moins de 05 ans" },
    { title: "Enfants de moins de 10 ans", value: "Enfants de moins de 10 ans" },
    { title: "Enfants de moins de 18 ans", value: "Enfants de moins de 18 ans" },
    { title: "Enfants de moins de 90 cm", value: "Enfants de moins de 90 cm" },
    { title: "Plus de 90 ans", value: "Plus de 90 ans" },
    { title: "Moins de 20 ans", value: "Moins de 20 ans" },
    { title: "Personnes de plus de 100 kg", value: "Personnes de plus de 100 kg" },
    { title: "Personnes de plus de 180 cm", value: "Personnes de plus de 180 cm" },
    { title: "Personnes de plus de 55 ans", value: "Personnes de plus de 55 ans" },
    { title: "Personnes de plus de 75 ans", value: "Personnes de plus de 75 ans" },
    { title: "Personnes de moins de 100 cm", value: "Personnes de moins de 100 cm" },
    { title: "Personnes de moins de 150 cm", value: "Personnes de moins de 150 cm" },
    { title: "Diabètique", value: "Diabètique" },
    { title: "Malentendants", value: "Malentendants" },
    { title: "Femmes enceintes", value: "Femmes enceintes" },
    { title: "A mobilité réduite", value: "A mobilité réduite" },
    { title: "Allergiques aux animaux", value: "Allergiques aux animaux" },
    { title: "Non-nageurs", value: "Non-nageurs" },
    { title: "Sans permis de conduire", value: "Sans permis de conduire" },
  ];

  const notAllowed = [
    { title: "Alcool et drogues", value: "Alcool et drogues" },
    { title: "Bagages ou sacs", value: "Bagages ou sacs" },
    { title: "Appareils photo", value: "Appareils photo" },
    { title: "Téléphones portables", value: "Téléphones portables" },
    { title: "Béquilles", value: "Béquilles" },
    { title: "Boissons", value: "Boissons" },
    { title: "Nourriture", value: "Nourriture" },
    { title: "Drones", value: "Drones" },
    { title: "Nourrir les animaux", value: "Nourrir les animaux" },
    { title: "Feux d'artifice", value: "Feux d'artifice" },
    { title: "Lampes de poche", value: "Lampes de poche" },
    { title: "Chaussures à talons hauts", value: "Chaussures à talons hauts" },
    { title: "Bijoux", value: "Bijoux" },
    { title: "Nudité", value: "Nudité" },
    { title: "Animaux domestiques", value: "Animaux domestiques" },
    { title: "Fumer", value: "Fumer" },
    { title: "Objets de valeur", value: "Objets de valeur" },
    { title: "Armes ou objets tranchants", value: "Armes ou objets tranchants" },
  ];

  const mustBring = [
    { title: "Alcool et drogues", value: "Alcool et drogues" },
    { title: "Bagages ou sacs", value: "Bagages ou sacs" },
    { title: "Appareils photo", value: "Appareils photo" },
    { title: "Téléphones portables", value: "Téléphones portables" },
    { title: "Béquilles", value: "Béquilles" },
    { title: "Boissons", value: "Boissons" },
    { title: "Nourriture", value: "Nourriture" },
    { title: "Drones", value: "Drones" },
    { title: "Nourrir les animaux", value: "Nourrir les animaux" },
    { title: "Feux d'artifice", value: "Feux d'artifice" },
    { title: "Lampes de poche", value: "Lampes de poche" },
    { title: "Chaussures à talons hauts", value: "Chaussures à talons hauts" },
    { title: "Bijoux", value: "Bijoux" },
    { title: "Nudité", value: "Nudité" },
    { title: "Animaux domestiques", value: "Animaux domestiques" },
    { title: "Fumer", value: "Fumer" },
    { title: "Objets de valeur", value: "Objets de valeur" },
    { title: "Armes ou objets tranchants", value: "Armes ou objets tranchants" },
  ];

  return (
    <>
      <div className="my-4">
        <>
          <div className="fs-5 fw-medium text-dark">{t('activity2Contr1')}</div>
          <p className="fs-6">{t('activity2Contr1p')}</p>
          <div className="mt-3">
            <TagInput list="ActivityNotsuitable" data={notSuitable} />
          </div>
        </>
      </div>

      <div className="my-4">
        <>
          <div className="fs-5 fw-medium text-dark">{t('activity2Contr2')}</div>
          <p className="fs-6">{t('activity2Contr2p')}</p>
          <div className="mt-3">
            <TagInput list="activityNotallowed" data={notAllowed} />
          </div>
        </>
      </div>

      <div className="my-4">
        <>
          <div className="fs-5 fw-medium text-dark">{t('activity2Contr3')}</div>
          <p className="fs-6">{t('activity2Contr3p')}</p>
          <div className="mt-3">
            <TagInput list="activityMustbring" data={mustBring} />
          </div>
        </>
      </div>

      <div className="my-4">
        <>
          <div className="fs-5 fw-medium text-dark">{t('activity2Contr4')}</div>
          <p className="fs-6">{t('activity2Contr4p')}</p>
          <div className="mt-3">
            <Textarea value={moreInfo} handleChange={handleMoreInfo} rows={8} maxlength={1000} />
          </div>
          <div className="text-secondary fw-bold">{t('min_of')} {1000 - moreInfo.length} {t('chars_left')}</div>
        </>
      </div>
    </>
  );
};

export default Restrictions;
