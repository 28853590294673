import { CheckCircle2, MinusCircle, PlusCircle } from "lucide-react";
import { useState, useEffect } from "react";

const RatesPerDemand = ({ t, price }) => {
  const [infos, setInfos] = useState({
    low: price,
    middle: price,
    strong: price,
    strongest: price,
  });

  useEffect(() => {
    setInfos({
      low: price,
      middle: price,
      strong: price,
      strongest: price,
    });
  }, [price]);

  const handleIncrement = (key, operation) => {
    setInfos((prevInfos) => {
      const newValue = operation === "increment" ? prevInfos[key] + 1 :
        operation === "decrement" ? prevInfos[key] - 1 :
          prevInfos[key];
      return { ...prevInfos, [key]: newValue };
    });
  };

  const applyRecommendation = (key, recommendation) => {
    setInfos((prevInfos) => ({
      ...prevInfos,
      [key]: recommendation,
    }));
  };

  const data = [
    { label: t('weak'), content: t('weak_caption'), value: infos.low, min: 11, key: "low", recommendation: 14 },
    { label: t('average'), content: t('average_caption'), value: infos.middle, min: 11, key: "middle", recommendation: 19 },
    { label: t('strong'), content: t('strong_caption'), value: infos.strong, min: 11, key: "strong", recommendation: 35 },
    { label: t('very_strong'), content: t('very_strong_caption'), value: infos.strongest, min: 11, key: "strongest", recommendation: 46 },
  ];

  const priceRanges = {
    low: { min: 11, max: 17, add: 2 },
    middle: { min: 15, max: 23, add: 3 },
    strong: { min: 27, max: 43, add: 5 },
    strongest: { min: 37, max: 57, add: 6 },
  };

  const getClassForPrice = (item) => {
    const range = priceRanges[item.key];
    if (range) {
      if (item.value <= range.min || item.value >= range.max) {
        return "text-red";
      } else if (item.value >= range.min + range.add && item.value <= range.max - range.add) {
        return "text-green";
      } else {
        return "text-orange";
      }
    }
    return "";
  };

  return (
    <>
      <h2 className="fs-5 lh-base fw-medium text-dark">{t('demand_title')}</h2>
      <p className="fs-6 fw-light">{t('demand_caption')}</p>
      <div className="my-3">
        {data.map((item, index) => (
          <div className="d-flex justify-content-between py-4 fs-6" key={index}>
            <div className="text-dark">
              <div className="fs-5">{item.label}</div>
              <div className="fs-6 fw-light mt-1">{item.content}</div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <span className={`fs-1 mx-3 ${getClassForPrice(item)}`}>{item.value} €</span>
              <button type="button" className="mx-1 bg-white text-blue" onClick={() => handleIncrement(item.key, "decrement")} disabled={item.value <= item.min}>
                <MinusCircle strokeWidth={1} size={40} />
              </button>

              <button type="button" className="mx-1 bg-white text-blue" onClick={() => handleIncrement(item.key, "increment")}>
                <PlusCircle strokeWidth={1} size={40} />
              </button>
              <button type="button" title="Appliquer nos recommandations" className="mx-1 bg-white text-green" onClick={() => applyRecommendation(item.key, item.recommendation)}>
                <CheckCircle2 strokeWidth={1} size={40} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RatesPerDemand;
